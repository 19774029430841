import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LayoutState, adapter } from './layout.state';
import { layoutFeatureKey } from './layout.reducer';

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectLayoutState = createFeatureSelector<LayoutState>(layoutFeatureKey);

export const selectLayoutEntities = createSelector(
  selectLayoutState,
  (layoutState) => layoutState.entities
);

export const selectAllLayouts = createSelector(selectLayoutState, selectAll);

export const selectLayoutById = (id: number) => createSelector(
  selectLayoutEntities,
  (entities) => entities[id]
);
