import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentSchedulerRoutingModule } from './appointment-scheduler-routing.module';
import { AppointmentSchedulerStoreModule } from './store/appointment-scheduler-store.module';
import { AppointmentSchedulerComponent } from './components/appointment-scheduler/appointment-scheduler.component';
import { AppointmentSchedulerContainerComponent } from './containers/appointment-scheduler-container/appointment-scheduler-container.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OverlayModule } from '@angular/cdk/overlay';
import { LayoutModule } from '@angular/cdk/layout';
@NgModule({
  declarations: [
    AppointmentSchedulerComponent,
    AppointmentSchedulerContainerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    AppointmentSchedulerRoutingModule,
    AppointmentSchedulerStoreModule,
    OverlayModule,
    LayoutModule
  ],
  exports: [
    AppointmentSchedulerComponent,
    AppointmentSchedulerContainerComponent
  ]
})
export class AppointmentSchedulerModule { }
