<div class="d-flex align-items-center">
    <div class="flex-column w-100">
        <h4 [innerHTML]="'Support Guide: ' + result._highlightResult.title?.value"></h4>
        <p [innerHTML]="result._highlightResult.indexed_attached_pdf?.value | highlightedResult">
        </p>
    </div>
    <div class="ms-auto">
        <a [href]="result.attached_pdf_absolute_url" target="_blank" class="btn btn-info btn-sm text-nowrap"
            style="margin-left: 20px;">
            <i class="fas fa-file-pdf"></i> View PDF
        </a>
    </div>
</div>