import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Distributor } from 'src/app/shared/models/distributor.interface';
import { ListItem, DistributorListSchema, DistributorListItem, DistributorSchema } from 'src/app/shared/models/schema-types.interface';

@Component({
  selector: 'app-distributors-list',
  templateUrl: './distributors-list.component.html',
  styleUrls: ['./distributors-list.component.scss']
})
export class DistributorsListComponent implements OnChanges {
  @Input() distributors: Distributor[] = []; //

  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService, private router: Router, private route: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.post && this.post) {



    if (changes.distributors && this.distributors) {
      const itemListElement: DistributorListItem[] = this.distributors.map((distributor, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'Organization',
          name: distributor.name,
          logo: distributor.logo,
          description: distributor.description,
          location: distributor.location,
          address: {
            '@type': 'PostalAddress',
            streetAddress: distributor.address.line1,
            postalCode: distributor.address.postcode,
            addressCountry: distributor.address.country.iso_3166_1_a2,
          },
          telephone: distributor.telephone,
          fax: distributor.fax,
          email: distributor.email,
          website: distributor.website,
        } as DistributorSchema,
      }));

      const distributorListSchema: DistributorListSchema = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        'name': 'Distributor Listing',
        'description': 'A list of Scinomix distributors',
        itemListElement,
      };

      this.schemaService.updateSchema(distributorListSchema);
    }

  }


}
