import { Component, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent {
  @ViewChild('template') template: TemplateRef<any>;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "modal-fullscreen-xl"
  };
  currentData: any;

  constructor(private modalService: BsModalService, private gtmService: GtmService) { }

  openModal(data: any) {
    const gtmPayload = {
      event: "media_viewer_opened",
      media_type: "image",
      media_file: data.file || null,
      media_title: data.title,
    };
    this.gtmService.push(gtmPayload)
    this.currentData = data;
    this.modalRef = this.modalService.show(this.template, this.config);
  }
}
