import { Component, Input } from '@angular/core';
import { Product } from '../../models/product.interface';

@Component({
  selector: 'app-product-card-container',
  templateUrl: 'product-card-container.component.html',
})
export class ProductCardContainerComponent {
  @Input() product: Product | null = null;
}
