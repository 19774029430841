<li
  [ngClass]="{ 'nav-item': menuType === 'top', 'is-divider': menuItem.isDivider, 'dropdown-submenu': menuItem.children && menuItem.children.length }">
  <ng-container *ngIf="menuItem.isDivider">
    <span class="divider-label" [attr.id]="(menuItem.id) ? menuItem.id : ''">{{ menuItem.label }}</span>
  </ng-container>
  <ng-container *ngIf="!menuItem.isDivider">
    <a [ngClass]="{
      'nav-link': menuType === 'top',
      'dropdown-toggle': menuItem.children && menuItem.children.length
    }" [routerLink]="menuItem.children && menuItem.children.length ? undefined : menuItem.path"
      [class.disabled]="menuItem.disabled" [attr.id]="menuItem.id ? 'navbarDropdown-' + menuItem.id : null"
      role="button" [attr.data-bs-toggle]="menuItem.children && menuItem.children.length ? 'dropdown' : null"
      aria-expanded="true" (click)="toggleSubmenu($event, menuItem, topmostParent)">{{ menuItem.label }}
    </a>

  </ng-container>
  <ng-container *ngIf="menuItem.children && menuItem.children.length">
    <ul class="dropdown-menu" [attr.aria-labelledby]="menuItem.id ? 'navbarDropdown-' + menuItem.id : null">
      <app-menu-item *ngFor="let childItem of menuItem.children" [menuItem]="childItem" [topmostParent]="topmostParent || menuItem"
        [menuType]="menuType"></app-menu-item>
    </ul>
  </ng-container>
</li>