import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchResultsContainerComponent } from './containers/search-results-container/search-results-container.component';

const routes: Routes = [{
  path: 'search', component: SearchResultsContainerComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SearchRoutingModule { }