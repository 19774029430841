<div class="container mt-3">
  <div class="row">
    <div class="col-xl-12 mb-3 d-flex justify-content-end">
      <button class="btn btn-primary" (click)="mediaEditor.newMedia()">{{uploadButtonText}}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12">
      <!-- Use cdkDropList directive here and bind it to your array of items -->
      <div cdkDropList class="list-group" (cdkDropListDropped)="drop($event)">
        <!-- Use cdkDrag inside your ngFor loop -->
        <div *ngFor="let media of mediaItems; let i = index" class="list-group-item" cdkDrag>
          <div class="row align-items-center">
            
            <!-- Embedding the media-item component with necessary bindings -->
            <app-media-item [media]="media" (changes)="onChanges($event)"
              (editRequested)="mediaEditor.openEditorModal($event)" (disassociateRequested)="onDisassociateRequested($event)"></app-media-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-media-editor #mediaEditor [objectType]="objectType"></app-media-editor>
