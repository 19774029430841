// services/testimonials.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Testimonial } from 'src/app/shared/models/testimonial.interface';


@Injectable({
  providedIn: 'root',
})
export class TestimonialsService {
  private readonly apiUrl = "api/v1/testimonials"; // Replace this with your API endpoint
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  constructor(private http: HttpClient) { }

  getTestimonials(): Observable<Testimonial[]> {
    return this.http.get<Testimonial[]>(this.apiUrl);
  }

  getTestimonialsByProductSlug(slug: string): Observable<Testimonial[]> {
    const url = `${this.apiUrl}/by_product?slug=${slug}`;
    return this.http.get<Testimonial[]>(url);
  }
}
