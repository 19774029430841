import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormArray } from "@angular/forms";
import { generateSlug } from "src/app/core/utils/string-utils";

@Component({
  selector: "app-keywords",
  templateUrl: "./keywords.component.html",
  styleUrls: ["./keywords.component.scss"],
})
export class KeywordsComponent implements OnInit, OnChanges {
  @Input() initialKeywords: { name: string; slug: string }[] = [];
  @Output() keywordsChange = new EventEmitter<{ create: any[]; edit: any[]; delete: any[] }>();

  keywordsForm: FormGroup;
  originalKeywords: { name: string; slug: string }[] = [];

  constructor(private fb: FormBuilder) {
    this.keywordsForm = this.fb.group({
      keywords: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.populateKeywords();
    if (this.initialKeywords && this.initialKeywords.length > 0) {
      this.originalKeywords = [...this.initialKeywords];
    }

    this.keywords.valueChanges.subscribe(() => {
      const keywordChanges = this.identifyKeywordChanges();
      // console.log(keywordChanges);
      if (keywordChanges) {
        this.keywordsChange.emit(keywordChanges);
      }
    });
  }

  ngOnChanges() {
    if (this.initialKeywords && this.initialKeywords.length > 0) {
      this.populateKeywords();
      this.originalKeywords = [...this.initialKeywords]; // Update original keywords on changes
    }
  }

  populateKeywords() {
    const keywordsFormArray = this.keywords as FormArray;
    keywordsFormArray.clear(); // Clear existing form array

    if (this.initialKeywords && this.initialKeywords.length > 0) {
      this.initialKeywords.forEach((keyword) => {
        keywordsFormArray.push(this.createKeywordFormGroup(keyword));
      });
    }
  }

  createKeywordFormGroup(keyword: { name: string; slug: string }): FormGroup {
    const keywordFormGroup = this.fb.group({
      name: new FormControl(keyword.name),
      slug: new FormControl(keyword.slug),
    });

    keywordFormGroup.get("name").valueChanges.subscribe((value) => {
      keywordFormGroup.get("slug").setValue(generateSlug(value), { emitEvent: false });
    });

    return keywordFormGroup;
  }

  get keywords(): FormArray {
    return this.keywordsForm.get("keywords") as FormArray;
  }

  addKeyword() {
    const newKeywordFormGroup = this.createKeywordFormGroup({ name: "", slug: "" });
    this.keywords.push(newKeywordFormGroup);
  }

  removeKeyword(index: number) {
    this.keywords.removeAt(index);
  }

  onSave() {
    const keywordChanges = this.identifyKeywordChanges();
    if (keywordChanges) {
      this.keywordsChange.emit(keywordChanges);
    }
  }

  identifyKeywordChanges() {
    const currentKeywords = this.keywords.value;
    const keywordChanges = {
      create: currentKeywords, // All current keywords are treated as newly created
      edit: [], // No keywords are considered edited when deleting and adding them anew
      delete: this.originalKeywords, // All original keywords are treated as deleted
    };
    return keywordChanges;
  }
}
