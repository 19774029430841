import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb.interface';

@Component({
  selector: 'app-breadcrumb-container',
  templateUrl: './breadcrumb-container.component.html',
  styleUrls: ['./breadcrumb-container.component.scss']
})
export class BreadcrumbContainerComponent implements OnInit {
  @Input() breadcrumbs: Breadcrumb[] = [];

  constructor() { }

  ngOnInit(): void { }
}
