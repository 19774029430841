import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AlertService } from "@fluentllc/core";
import { PaginationInfo } from "@fluentllc/core";
import { Observable } from "rxjs";
import { ModalService } from "src/app/core/services/modal.service";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import { JobPosting } from "src/app/shared/models/job-posting.interface";
import { AppState } from "src/app/store/app.state";
import { selectAllJobPostings, selectPaginationInfo } from "../../store/job-posting.selectors";
import { loadPaginatedJobPostings } from "../../store/job-posting.actions";
import * as JobPostingActions from "../../store/job-posting.actions";
@Component({
  selector: "app-job-posting-admin-list-container",
  templateUrl: "./job-posting-admin-list-container.component.html",
  styleUrls: ["./job-posting-admin-list-container.component.scss"],
})
export class JobPostingAdminListContainerComponent implements OnInit {
  posts$: Observable<JobPosting[]>;
  total$: Observable<number>;
  paginationInfo$: Observable<PaginationInfo>;
  currentPage: number = 1;
  pageSize: number = 10;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private alertService: AlertService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.posts$ = this.store.select(selectAllJobPostings);
    this.loadPaginatedPosts(this.currentPage);
    this.paginationInfo$ = this.store.select(selectPaginationInfo);
    const newMetaTags = {
      title: "Job Postings",
      description: "",
      keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  loadPaginatedPosts(page: number): void {
    this.store.dispatch(loadPaginatedJobPostings({ page: page, limit: this.pageSize }));
    this.paginationInfo$ = this.store.select(selectPaginationInfo);
  }

  async openCustomModal(postId: number) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Delete Job Posting', cssClass: 'btn btn-danger', action: 'confirm' }
    ];

    const result = await this.modalService.openModal(
      'Confirm Deletion', 
      'Are you sure you want to delete this job posting? This action cannot be undone. Please confirm if you wish to permanently remove this post from your account.', 
      buttons
    );

    // Handle the result based on action
    switch (result) {
      case 'cancel':
        // Handle cancel
        break;
      case 'confirm':
        this.store.dispatch(JobPostingActions.deleteJobPosting({ id: postId }));
        break;
    }
  }


  onPageChange(newPage: number): void {
    // this.currentPage = newPage;
    this.loadPaginatedPosts(newPage);
  }

  onChangePageSize(newSize: number): void {
    this.pageSize = newSize;
    this.loadPaginatedPosts(this.currentPage);
  }

  onEdit(postId: number): void {
    this.router.navigate(["/admin/job/manage", postId]);
  }

  onCreate(): void {
    this.router.navigate(["/admin/job/create"]);
  }

  onDelete(postId: number): void {
    this.openCustomModal(postId)
  }
  
}
