import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';
import { loadJobPostingBySlug } from '../../store/job-posting.actions';
import { selectJobPostingBySlugFactory } from '../../store/job-posting.selectors';

@Component({
  selector: 'app-job-posting-detail-container',
  templateUrl: './job-posting-detail-container.component.html',
  styleUrls: ['./job-posting-detail-container.component.scss']
})
export class JobPostingDetailContainerComponent {
  job$: Observable<JobPosting>;

  constructor(private store: Store, private route: ActivatedRoute) {
    const slug = this.route.snapshot.paramMap.get('slug');
    this.store.dispatch(loadJobPostingBySlug({ slug }));
    this.job$ = this.store.select(selectJobPostingBySlugFactory(slug));
  }
}
