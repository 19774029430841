import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as ButtonActions from './button.actions';

@Injectable()
export class ButtonEffects {
    // buttonClicked$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(ButtonActions.buttonClicked),
    //             tap(({ type }) => {
    //                 // Perform any side effects or external actions here.
    //                 // console.log(`Button of type ${type} clicked`);
    //             })
    //         ),
    //     { dispatch: false }
    // );

    constructor(private actions$: Actions) { }
}
