import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FAQContainerComponent } from './containers/faq/faqcontainer/faqcontainer.component';
import { ServiceAgreementsContainerComponent } from './containers/service-agreements-container/service-agreements-container.component';
import { ServiceSupportContainerComponent } from './containers/service-support-container/service-support-container.component';
import { TroubleshootingContainerComponent } from './containers/troubleshooting-container/troubleshooting-container.component';

const routes: Routes = [
  {
    path: "",
    component: ServiceSupportContainerComponent,
  },
  {
    path: "faq",
    component: FAQContainerComponent
  },
  {
    path: "service-agreement",
    component: ServiceAgreementsContainerComponent
  },
  {
    path: "troubleshooting",
    component: TroubleshootingContainerComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ServiceSupportRoutingModule { }