import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaState, adapter } from './media.state';
import { mediaFeatureKey } from './media.reducer';
import { Media } from '../models/media.interface';

// Utilizing the selectors provided by NgRx Entity
const { selectAll, selectEntities } = adapter.getSelectors();

const priorityMapping: { [key: string]: number } = {
  'top': 0,
  'high': 1,
  'medium': 2,
  'low': 3,
  'no-priority': 4,
};

export const selectMediaState = createFeatureSelector<MediaState>(mediaFeatureKey);

// Selector to get all media as an array
export const selectAllMedia = createSelector(selectMediaState, selectAll);

// Selector to get media entities as a dictionary
export const selectMediaEntities = createSelector(selectMediaState, selectEntities);

export const selectSpecificationsMediaList = createSelector(
  selectAllMedia,
  (mediaList: Media[]): Media[] => mediaList.filter(media => media.type === 'pdf')
);

export const selectCarouselMediaList = createSelector(
  selectAllMedia,
  (mediaList: Media[]): Media[] => mediaList.filter(media => media.type === 'external' || media.type === 'image')
);

export const selectMediaInBackendOrder = createSelector(
  selectCarouselMediaList, // Assumes selectEntities is a selector that selects the entities object map
  (entities) => {
    const mediaArray = Object.values(entities);
    return mediaArray.sort((a, b) => a.weight - b.weight);
  }
);

export const selectSpecificationsInBackendOrder = createSelector(
  selectSpecificationsMediaList, // Assumes selectEntities is a selector that selects the entities object map
  (entities) => {
    const mediaArray = Object.values(entities);
    return mediaArray.sort((a, b) => a.weight - b.weight);
  }
);


// New selector for sorting the carousel media list
export const selectSortedCarouselMediaList = createSelector(
  selectCarouselMediaList,
  (mediaList: Media[]): Media[] => {
    return mediaList.sort((a, b) => {
      const priorityA = priorityMapping[a.priority];
      const priorityB = priorityMapping[b.priority];
      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }
      return a.weight - b.weight;
    });
  }
);

export const selectMediaLoading = createSelector(
  selectMediaState,
  (state: MediaState) => state.loading
);

export const selectMediaError = createSelector(
  selectMediaState,
  (state: MediaState) => state.error
);

// Using entities to select media list by type
export const selectMediaListByType = createSelector(
  selectAllMedia,
  (mediaList: Media[], props: { mediaType: string }) => mediaList.filter(media => media.type === props.mediaType)
);
