import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Post } from 'src/app/shared/models/post.interface';
import { postFeatureKey } from './post.reducer';
import { PostState, adapter } from './post.state';

export const selectPostState = createFeatureSelector<PostState>(postFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

/**
 * Selects the array of post IDs from the post state.
 * 
 * @param state - The post state.
 * @returns An array of post IDs.
 */
export const selectPostIds = createSelector(
    selectPostState,
    selectIds
);

/**
 * Selects the post entities from the post state.
 * @returns A selector function that returns the post entities.
 */
export const selectPostEntities = createSelector(
    selectPostState,
    selectEntities
);

/**
 * Selects all posts from the post state.
 * 
 * @returns An array of all posts.
 */
export const selectAllPosts = createSelector(
    selectPostState,
    selectAll
);

export const selectPublicPosts = createSelector(
    selectAllPosts,
    (posts: Post[]) => posts.filter(post => 
        post.published_at === null || new Date(post.published_at).getTime() <= new Date().getTime()
    )
);

/**
 * Selects the total number of posts from the post state.
 * 
 * @param state - The post state.
 * @returns The total number of posts.
 */
export const selectPostTotal = createSelector(
    selectPostState,
    selectTotal
);

/**
 * Returns a selector function that retrieves a post by its slug.
 * @param slug - The slug of the post to retrieve.
 * @returns A selector function that returns the post with the specified slug.
 */
export const selectPostBySlugFactory = (slug: string) => {
    return createSelector(
        selectPostEntities,
        (entities: { [id: number]: Post }) => {
            // console.log('Entities:', entities);
            // console.log('Looking for slug:', slug);
            return Object.values(entities).find(post => post.slug === slug);
        }
    );
};

export const selectCurrentPage = createSelector(selectPostState, (state) => state.currentPage);
export const selectPageSize = createSelector(selectPostState, (state) => state.pageSize);
// Paginated posts for admin
export const selectPaginatedPostsAdmin = createSelector(
    selectAllPosts,
    selectCurrentPage,
    selectPageSize,
    (allPosts, currentPage, pageSize) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return allPosts.slice(startIndex, endIndex);
    }
);

// Paginated posts for public view
export const selectPaginatedPostsPublic = createSelector(
    selectPublicPosts,
    selectCurrentPage,
    selectPageSize,
    (publicPosts, currentPage, pageSize) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return publicPosts.slice(startIndex, endIndex);
    }
);
/**
 * Selects the loading state of the post.
 * @param state - The post state.
 * @returns True if the post is currently loading, false otherwise.
 */
export const isPostLoading = createSelector(
    selectPostState,
    (state: PostState) => state.loading
)

/**
 * Selects the pagination information from the post state.
 * 
 * @returns The pagination information object.
 */
export const selectPaginationInfo = createSelector(
    selectPostState,
    (state: PostState) => state.paginationInfo
);

export const selectFormState = createSelector(
    selectPostState,
    (state: PostState) => state.formState
  );
