import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';

// Phone Validator
function phoneValidator(control: AbstractControl): ValidationErrors | null {
  const phoneNumber = control.value;
  if (!phoneNumber) {
    return null;
  }

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US'); // Replace 'US' with your default country code if needed
    if (parsedPhoneNumber.isValid()) {
      return null;
    }
  } catch (error) {
    console.error('Phone number parsing error:', error);
  }

  return { invalidPhone: true };
}

// Zip Code Validator
function zipCodeValidator(): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    // You can use "i18n-iso-countries" to get the country data and implement country-specific zip code validation logic here
    // This might require regular expressions or other validation logic based on the country

    return null;
  };
}

function stateRequiredValidator(): Validators {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const countryControl = control.parent?.get('country');
    if (countryControl?.value === 'US' || countryControl?.value === 'CA') {
      return control.value ? null : { required: true };
    }
    return null;
  };
}

export { phoneValidator, zipCodeValidator, stateRequiredValidator };
