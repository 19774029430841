<app-product-admin-list 
  [products]="products$ | async"
  [paginationInfo]="paginationInfo$ | async"
  (pageChanged)="onPageChange($event)"
  (itemsPerPageChanged)="onChangePageSize($event)"
  (edit)="onEdit($event)" 
  (delete)="onDelete($event)" 
  (create)="onCreate()"
  (filterChanged)="onFilterChange($event)">
</app-product-admin-list>

