import { createFeatureSelector, createSelector } from '@ngrx/store';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';
import { JobPostingFeatureKey } from './job-posting.reducer';
import { adapter, JobPostingState } from './job-posting.state';

/**
 * Selects the job posting state from the feature state.
 */
export const selectJobPostingState = createFeatureSelector<JobPostingState>(JobPostingFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

/**
 * Selects the job posting IDs from the job posting state.
 */
export const selectJobPostingIds = createSelector(
    selectJobPostingState,
    selectIds
);

/**
 * Selects the job posting entities from the job posting state.
 */
export const selectJobPostingEntities = createSelector(
    selectJobPostingState,
    selectEntities
);

/**
 * Selects all job postings from the job posting state.
 */
export const selectAllJobPostings = createSelector(
    selectJobPostingState,
    selectAll
);

/**
 * Selects the total number of job postings from the job posting state.
 */
export const selectJobPostingTotal = createSelector(
    selectJobPostingState,
    selectTotal
);

/**
 * Selects a job posting by its ID.
 * @param id - The ID of the job posting.
 * @returns A selector function that returns the job posting with the specified ID.
 */
export const selectJobPostingById = (id: number) =>
    createSelector(
        selectJobPostingEntities,
        (entities) => entities[id]
    );

/**
 * Selects the loading state of the job posting.
 */
export const selectJobPostingLoading = createSelector(
    selectJobPostingState,
    (state) => state.loading
);

/**
 * Selects the error state of the job posting.
 */
export const selectJobPostingError = createSelector(
    selectJobPostingState,
    (state) => state.error
);

/**
 * Returns a selector function that retrieves a job posting by its slug.
 * @param slug - The slug of the job posting.
 * @returns A selector function that returns the job posting with the specified slug.
 */
export const selectJobPostingBySlugFactory = (slug: string) => {
    return createSelector(
        selectJobPostingEntities,
        (entities: { [id: number]: JobPosting }) => {
            return Object.values(entities).find(jobPosting => jobPosting.slug === slug);
        }
    );
};

/**
 * Selects the current page from the job posting state.
 */
export const selectCurrentPage = createSelector(selectJobPostingState, (state) => state.currentPage);

/**
 * Selects the page size from the job posting state.
 */
export const selectPageSize = createSelector(selectJobPostingState, (state) => state.pageSize);

/**
 * Selects the paginated job postings based on the current page and page size.
 */
export const selectPaginatedJobPostings = createSelector(
    selectAllJobPostings,
    selectCurrentPage,
    selectPageSize,
    (allJobPostings, currentPage, pageSize) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return allJobPostings.slice(startIndex, endIndex);
    }
);

/**
 * Selects the loading state of the job posting.
 */
export const isJobPostingLoading = createSelector(
    selectJobPostingState,
    (state: JobPostingState) => state.loading
);

/**
 * Selects the pagination information from the job posting state.
 */
export const selectPaginationInfo = createSelector(
    selectJobPostingState,
    (state: JobPostingState) => state.paginationInfo
);

/**
 * Selects the form state from the job posting state.
 */
export const selectFormState = createSelector(
    selectJobPostingState,
    (state: JobPostingState) => state.formState
);
