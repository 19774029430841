import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { CustomItem, CustomItemClass } from 'src/app/shared/models/custom-item.interface';
declare var hbspt: any;
@Component({
  selector: 'app-lander-b2b-tubes20240320',
  templateUrl: './lander-b2b-tubes20240320.component.html',
  styleUrls: ['./lander-b2b-tubes20240320.component.scss']
})
export class LanderB2bTubes20240320Component implements OnInit, AfterViewInit, OnChanges {
  @Input() subtitle: string;
  @Input() title: string = 'Keep Your Lab Running Smoothly';
  @Input() titleHighlight: string = 'With Scinomix Tubes & Labels';
  @Input() description: string = 'Avoid downtime with readily-available, cost-effective tubes that ensure continuous productivity for your lab.'
  @Input() heroImage: string = 'https://scinomix.s3.amazonaws.com/media/1-5__screwcap__no_ribbing__conical-_styled_.jpg';
  pageName: string;
  pageUri: string;
  successMessage$: Observable<string | null>;
  submitted: boolean = false;
  ipAddress: string;
  customItems: CustomItem[] = []

  constructor(
    private metaTagsService: MetaTagsService,
    private schemaService: SchemaService,
    private _elementRef: ElementRef
  ) {
    
  }

  ngOnInit(): void {
    let newTitle = this.title + ' ' + this.titleHighlight;
    const newMetaTags = {
      title: newTitle,
      description: this.description,
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.pageName = newTitle;
    this.pageUri = window.location.href;
    this.metaTagsService.updateMetaTags(newMetaTags);

    this.customItems = [
      new CustomItemClass({ name: 'High Quality', description: 'High-quality, ultra-pure polypropylene tubes', icon: 'fa-solid fa-medal' }),
      new CustomItemClass({ name: 'Readily Available', description: "Manufactured in the US and readily available", icon: 'fa-solid fa-bolt' }),
      new CustomItemClass({ name: 'Competitive Pricing', description: 'Short lead times and competitive pricing', icon: 'fa-solid fa-star'}),
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }





  ngAfterViewInit(): void {
    // Embed the HubSpot form script
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        portalId: '4896724',
        formId: '72256ffb-c53f-4791-911b-b21ffa4fbabf',
        target: '#hubspotForm',
        region: "na1",
      });
    } else {
      const scriptTag = document.createElement('script');
      scriptTag.src = '//js.hsforms.net/forms/shell.js';
      scriptTag.addEventListener('load', () => {
        hbspt.forms.create({
          portalId: '4896724',
          formId: '72256ffb-c53f-4791-911b-b21ffa4fbabf',
          target: '#hubspotForm',
          region: "na1",
        });
      });
      this._elementRef.nativeElement.appendChild(scriptTag);
    }
  }

}
