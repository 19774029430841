import { Component } from '@angular/core';

@Component({
  selector: 'app-vr-tour-container',
  templateUrl: './vr-tour-container.component.html',
  styleUrls: ['./vr-tour-container.component.scss']
})
export class VrTourContainerComponent {

}
