import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { Post } from 'src/app/shared/models/post.interface';
import { BlogListSchema, ListItem } from 'src/app/shared/models/schema-types.interface';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnChanges {
  @Input() posts: Post[];
  breadcrumbs: Breadcrumb[] = [];
  bxsUser: SafeHtml;
  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService, private router: Router, private route: ActivatedRoute,     private httpClient: HttpClient,
    private sanitizer: DomSanitizer) {
      this.httpClient.get('assets/icons/bxs-user.svg', { responseType: 'text' }).subscribe(data => {
        const svgWithTitle = data.replace('<svg', '<svg aria-labelledby="iconTitle" ').replace('<title>', '<title id="iconTitle">');
        this.bxsUser = this.sanitizer.bypassSecurityTrustHtml(svgWithTitle);
      });
     }

  private buildPostUrl(slug: string): string {
    const baseUrl = window.location.protocol + '//' + window.location.host;
    return `${baseUrl}/news/${slug}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.post && this.post) {

    const newMetaTags = {
      title: 'Lab Insights Blog',
      description: 'Lab Insights Blog',
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };



    this.metaTagsService.updateMetaTags(newMetaTags);
    this.breadcrumbs = [
      { label: 'Home', url: '/' },
      { label: 'Lab Insights Blog', url: '/news-events' }
    ];

    if (changes.posts && this.posts) {
      const itemListElement: ListItem[] = this.posts.map((post, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': post.id.toString(),
          name: post.title,
          url: this.buildPostUrl(post.slug), // You should use the correct URL structure here, using 'post.slug' as an example.
        },
      }));

      const blogListSchema: BlogListSchema = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement,
      };

      this.schemaService.updateSchema(blogListSchema);
    }

  }
}
