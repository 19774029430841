<div *ngIf="guides">
    <div class="blog-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card card-default">
                        <!-- Card Header with New Post Button -->
                        <div class="card-header d-flex justify-content-between align-items-center admin-listing">
                            <div>Support Guide Manager</div>
                            <button class="btn btn-secondary" (click)="create.emit()">Upload Support Guide</button>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <!-- Table Head -->
                                    <thead>
                                        <tr>
                                            <th>Support Guide Name</th>
                                            <th>Part Number</th>
                                            <th>Actions</th>
                                    </thead>
                                    <!-- Table Body -->
                                    <tbody>
                                        <tr *ngFor="let guide of guides">
                                            <td>{{ guide.title }}</td>
                                            <td>{{ guide.part_number }}</td>
                                            <td>
                                                <div class="action-buttons">
                                                    <button class="btn btn-secondary btn-sm" (click)="edit.emit(guide.id)" >Edit</button>
                                                    <button class="btn btn-danger btn-sm" (click)="delete.emit(guide.id)">Delete</button>
                                                  </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Card Footer -->
                        <div class="card-footer">
                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
