import { createReducer, on } from '@ngrx/store';
import * as JobApplicationActions from './job-application.actions';
import { JobApplicationState, initialState, adapter } from './job-application.state';

export const jobApplicationFeatureKey = 'jobApplications';

export const jobApplicationReducer = createReducer(
  initialState,

  on(JobApplicationActions.addJobApplication, (state) => ({
    ...state,
    loading: true,
    error: null
  })),

  on(JobApplicationActions.addJobApplicationSuccess, (state, { jobApplication }) => {
    return adapter.addOne(jobApplication, { ...state, loading: false });
  }),

  on(JobApplicationActions.addJobApplicationFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error
  })),
  on(JobApplicationActions.setSuccessMessage, (state, { message }) => ({
    ...state,
    successMessage: message
  })),

  on(JobApplicationActions.resetJobApplicationSlice, () => initialState),
);

