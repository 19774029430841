import { createReducer, on } from '@ngrx/store';
import * as OptionHashActions from './option-hash.actions';
import { optionAdapter, initialOptionHashState } from './option-hash.state';

export const OptionHashFeatureKey = 'optionHash';

export const OptionHashReducer = createReducer(
  initialOptionHashState,
  on(OptionHashActions.loadOptionHash, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OptionHashActions.loadOptionHashSuccess, (state, { optionHash }) =>
    optionAdapter.setAll(optionHash, { ...state, loading: false })
  ),
  on(OptionHashActions.loadOptionHashFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);
