<footer class="footer-area pt-100 pb-75">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <div class="pb-30"><app-logo-container
                            srcset="assets/images/scinomix_w@2x.png, assets/images/scinomix_w@2x.png 2x, assets/images/scinomix_w@2x.png 3x, assets"  height="64" width="200"></app-logo-container>
                    </div>
                    <p>Scinomix is committed to providing lab managers and scientists with exceptional lab automation
                        solutions. Our comprehensive product line-up caters to the varying needs of lab professionals,
                        featuring innovative tools aimed at simplifying workflows, decreasing errors, and improving
                        laboratory efficiency. Become a part of the Scinomix family and discover the positive impact our
                        solutions can have on your lab's performance.</p>

                </div>

            </div>
            <div class="col-xl-3 col-lg-2 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Useful Links</h3>
                    <app-menu-container menuType="footer" context="footer"
                        customClass="quick-links"></app-menu-container>
                </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Lab Insights Blog</h3>
                    <div class="footer-widget-blog">
                        <app-post-list-widget-container placement="footer" [maxArticles]=3
                            [shouldShowMore]="false"></app-post-list-widget-container>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Information</h3>
                    <ul class="footer-information">
                        <li>
                            <i class="flaticon-039-emergency-call"></i>
                            Call Today
                            <span><a href="tel:13142989800">+1.314.298.9800</a></span>
                        </li>
                        <li>
                            <i class="flaticon-041-placeholder"></i>
                            Headquarters
                            <span>4372 Green Ash Drive<br>Earth City, MO 63045-1213</span>
                        </li>
                    </ul>

                </div>
                <div class="single-footer-widget">
                    <h3 class="widget-title">Let's Connect on Social</h3>
                    <app-social-links [additionalClasses]="['left-align', 'pl-10']"></app-social-links>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- Copyright -->
<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <p><span>© {{ currentYear }} {{ settings.getAppSetting("name") }}. All Rights Reserved</span></p>
        </div>
    </div>
</div>