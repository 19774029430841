import { Component } from '@angular/core';

@Component({
  selector: 'app-scinomix-story',
  templateUrl: './scinomix-story.component.html',
  styleUrls: ['./scinomix-story.component.scss']
})
export class ScinomixStoryComponent {

}
