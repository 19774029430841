<!-- Card Body -->
<div class="card-body">
    <div class="table-responsive">
        <table class="table table-striped" *ngIf="productAttributeValues.length; else noAttributeValues">
            <!-- Table Head -->
            <thead>
                <tr>
                    <th>Name</th>
                    <!-- <th>Code</th> -->
                    <!-- <th>Type</th> -->
                    <th>Value</th>
                    <!-- <th>Actions</th> -->
                </tr>
            </thead>
            <!-- Table Body -->
            <tbody>
                <tr *ngFor="let attribute of productAttributeValues; trackBy: trackByAttribute">
                    <td>{{ attribute.name }}</td>
                    <!-- <td>{{ attribute.code }}</td> -->
                    <!-- <td>{{ attribute.type }}</td> -->
                    <td><app-product-attribute-value-editor [value]="attribute"
                            (update)="onUpdate($event)"></app-product-attribute-value-editor></td>
                    <!-- <td>
                        <div class="action-buttons">
                            <button class="btn btn-danger btn-sm" (click)="onDelete(attribute.id)">Delete</button>
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>

        <ng-template #noAttributeValues>
            <p>No attribute values are set.</p>
            <!-- <button class="btn btn-primary" (click)="create.emit()">Add Attribute Values</button> -->
        </ng-template>
    </div>
</div>
<!-- Card Footer
<div class="card-footer">
    <app-pagination [paginationInfo]="paginationInfo" (pageChanged)="onPageChange($event)"
        (itemsPerPageChanged)="onItemsPerPageChange($event)"></app-pagination>
</div> -->