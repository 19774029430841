import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-call-to-action-button-container',
  templateUrl: './call-to-action-button-container.component.html',
  styleUrls: ['./call-to-action-button-container.component.scss']
})
export class CallToActionButtonContainerComponent {
  @Input() ctaTitle: String = "Learn More"
  @Input() ctaUrl: string = "#"
}
