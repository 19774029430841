import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationInfo } from '@fluentllc/core';
import { Layout } from 'src/app/shared/models/layout.interface';

@Component({
  selector: 'app-layout-admin-list',
  templateUrl: './layout-admin-list.component.html',
  styleUrls: ['./layout-admin-list.component.scss']
})
export class LayoutAdminListComponent {
  @Input() layouts: Layout[];
  @Input() paginationInfo: PaginationInfo;
  @Output() edit = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();
  @Output() create = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();
}
