import { Component, Input } from '@angular/core';
import { Media } from '../../models/media.interface';

@Component({
  selector: 'app-helpful-resources-container',
  templateUrl: './helpful-resources-container.component.html',
  styleUrls: ['./helpful-resources-container.component.scss']
})
export class HelpfulResourcesContainerComponent {
  @Input() resources: Media[];
}
