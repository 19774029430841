// product-filter.selectors.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductFilterState } from './product-filter.state';
import { OptionHashOption } from 'src/app/features/products/models/option-hash.interface';
import { selectOptionHash, selectAllOptionHashs } from './option-hash.selectors';
import { Product } from '../models/product.interface';
import { ProductFilterFeatureKey } from './product-filter.reducer';

export const selectProductFilterState = createFeatureSelector<ProductFilterState>(ProductFilterFeatureKey);

export const selectSelectedOptions = createSelector(
    selectProductFilterState,
    (state) => {
        return state.selectedOptions;
    }
);

export const selectFilteredOptions = createSelector(
    selectProductFilterState,
    (state) => {
        return state.filteredOptions;
    }
);


export const selectVariationOptions = createSelector(
    selectProductFilterState,
    (state) => state.variationOptions
);

export const selectIsOptionAvailable = (optionCode: string, optionValue: string) =>
    createSelector(
        selectFilteredOptions,
        (filteredOptions) => filteredOptions[optionCode]?.includes(optionValue) || false
    );

export const selectIsOptionSelected = (optionCode: string, optionValue: string) =>
    createSelector(
        selectSelectedOptions,
        (selectedOptions) => selectedOptions[optionCode] === optionValue
    );

export const selectIsOptionDisabled = (optionCode: string, optionValue: string) =>
    createSelector(
        selectFilteredOptions,
        selectVariationOptions,
        (filteredOptions, variationOptions) =>
            !filteredOptions[optionCode]?.includes(optionValue) &&
            (!variationOptions[optionCode] || variationOptions[optionCode].length > 0)
    );

export const selectFilteredProduct = createSelector(
    selectSelectedOptions,
    selectAllOptionHashs,
    (selectedOptions, allOptions): Product[] =>
        allOptions.reduce((filteredProduct: Product[], option) => {
            if (selectedOptions[option.option_code]) {
                return filteredProduct.filter((product) =>
                    product.attributes.some(
                        (attribute) =>
                            attribute.code === option.option_code &&
                            attribute.value === selectedOptions[option.option_code]
                    )
                );
            } else {
                return filteredProduct;
            }
        }, [])
);


export const selectFilteredProductVariations = createSelector(
    selectFilteredProduct,
    (filteredProduct) => {
        const productMap = new Map<number, Product>();
        filteredProduct.forEach((product) => productMap.set(product.id, product));
        return Array.from(productMap.values());
    }
);

export const selectOptionValuesByGroup = (groupSlug: string) =>
    createSelector(
        selectAllOptionHashs,
        (optionHashs) => {
            return optionHashs
                .filter((optionHash) => optionHash.group_slug === groupSlug)
                .map((optionHash) => optionHash.values)
                .reduce((values, options) => values.concat(options), [])
                .map((optionValue) => optionValue.slug || '')
                .filter((slug) => !!slug);
        }
    );



