// dynamic-component.service.ts
import { Injectable, Injector, Type, Inject } from '@angular/core';
import { DYNAMIC_COMPONENTS_MAP } from '../tokens/dynamic-component.tokens';


@Injectable({
    providedIn: 'root',
})
export class DynamicComponentService {
    constructor(
        private injector: Injector,
        @Inject(DYNAMIC_COMPONENTS_MAP) private componentsMap: Map<string, Type<any>>
    ) { }

    getComponentType(type: string): Type<any> {
        return this.componentsMap.get(type);
    }
}
