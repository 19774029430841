import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators';
import { of, from } from 'rxjs';

import * as ProductRequestActions from '../store/product-request.actions';
import { ProductRequestService } from 'src/app/core/services/products/product-request.service';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { GtmService } from 'src/app/core/services/gtm.service';

@Injectable()
export class ProductRequestEffects {

    addProductRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductRequestActions.addProductRequest),
            mergeMap((action) => {
                this.productRequestService.setContextData(action.contextData);
                return from(this.productRequestService.submitRequest(action.productRequest)).pipe(
                    map((response: any) => {
                        this.store.dispatch(ProductRequestActions.setSuccessMessage({ message: response.inlineMessage }));
                        this.gtmService.push({ 
                            event: 'form_submission', 
                            form_type: 'product_inquiry',
                            productRequestId: response.id, // Assuming the response contains an id
                            productRequestDetailsMessage: action.productRequest.message,
                            productRequestDetailProductsInterested: action.productRequest.products_interested,
                            pageName: action.contextData.pageName,
                            pageUrl: action.contextData.pageUri,
                        });
    
                        return ProductRequestActions.addProductRequestSuccess({
                            productRequest: action.productRequest,
                        });
                    }),
                    catchError((error) =>
                        of(ProductRequestActions.addProductRequestFailure({ error }))
                    )
                );
            })
        )
    );

    resetProductRequestSlice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductRequestActions.addProductRequestSuccess),
            delay(10000), // Adjust the delay (in milliseconds) as needed
            tap(() => this.store.dispatch(ProductRequestActions.resetProductRequestSlice()))
        ), { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private productRequestService: ProductRequestService,
        private store: Store,
        private gtmService: GtmService
    ) { }
}
