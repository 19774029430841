import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostDetailContainerComponent } from './containers/post-detail-container/post-detail-container.component';
import { PostListContainerComponent } from './containers/post-list-container/post-list-container.component';
import { PostResolver } from './resolvers/post-resolver.service';

const routes: Routes = [
  {
    path: 'news-events',
    component: PostListContainerComponent
  },
  {
    path: 'news/:slug',
    component: PostDetailContainerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogRoutingModule { }