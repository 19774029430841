import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { BlogEffects } from './blog.effects';
import * as blog from './blog.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(blog.blogFeatureKey, blog.blogReducer),
    EffectsModule.forFeature([BlogEffects]),
  ],
})
export class BlogStoreModule { }