import { createReducer, on } from '@ngrx/store';
import * as DemoRequestActions from './appointment-scheduler.actions';
import { DemoRequestState, initialState, adapter } from './appointment-scheduler.state';

export const demoRequestFeatureKey = 'demoRequests';

export const demoRequestReducer = createReducer(
    initialState,

    on(DemoRequestActions.addDemoRequest, (state) => ({
        ...state,
        loading: true,
        error: null
    })),

    on(DemoRequestActions.addDemoRequestSuccess, (state, { demoRequest }) => {
        return adapter.addOne(demoRequest, { ...state, loading: false });
    }),

    on(DemoRequestActions.addDemoRequestFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error
    })),
    on(DemoRequestActions.setSuccessMessage, (state, { message }) => ({
        ...state,
        successMessage: message
    })),

    on(DemoRequestActions.resetDemoRequestSlice, () => initialState),
);

