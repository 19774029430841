<ng-template #template>
    <div class="modal-content">
        <div class="modal-body">
            <div class="embed-container">
                <button type="button" class="close" data-dismiss="modal" (click)="modalRef.hide()" aria-label="Close">
                </button>
                <div class="video-responsive">
                    <iframe [src]="combinedLink" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" [title]="title">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</ng-template>