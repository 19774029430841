// option-hash.selectors.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OptionHashFeatureKey } from './option-hash.reducer';
import { optionAdapter, OptionHashState } from './option-hash.state';
import { OptionHashValue } from 'src/app/features/products/models/option-hash.interface';

export const selectOptionHashState = createFeatureSelector<OptionHashState>(OptionHashFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = optionAdapter.getSelectors();

export const selectOptionHashIds = createSelector(
    selectOptionHashState,
    selectIds
);

export const selectOptionHashEntities = createSelector(
    selectOptionHashState,
    selectEntities
);

export const selectAllOptionHashs = createSelector(
    selectOptionHashState,
    selectAll
);

export const selectOptionHashTotal = createSelector(
    selectOptionHashState,
    selectTotal
);

export const selectOptionHashById = (id: number) =>
    createSelector(
        selectOptionHashEntities,
        (entities) => entities[id]
    );

export const selectOptionHashLoading = createSelector(
    selectOptionHashState,
    (state) => state.loading
);

export const selectOptionHashError = createSelector(
    selectOptionHashState,
    (state) => state.error
);

// Select option values by group slug
export const selectOptionValuesByGroupSlug = (groupSlug: string) =>
    createSelector(
        selectAllOptionHashs,
        (optionHashs) => {
            return optionHashs
                .filter((optionHash) => optionHash.group_slug === groupSlug)
                .map((optionHash) => optionHash.values)
                .flat();
        }
    );

// Select all available option codes
export const selectAllOptionCodes = createSelector(
    selectAllOptionHashs,
    (optionHashs) => {
        return optionHashs.map((optionHash) => optionHash.option_code);
    }
);

// Select all available option values for a given option code
export const selectAllOptionValuesByCode = (optionCode: string) =>
    createSelector(
        selectAllOptionHashs,
        (optionHashs) => {
            const optionValues: OptionHashValue[] = [];
            optionHashs.forEach((optionHash) => {
                const optionValue = optionHash.values.find((value) => value.value === optionCode);
                if (optionValue) {
                    optionValues.push(optionValue);
                }
            });
            return optionValues;
        }
    );

export const selectOptionHash = selectOptionHashEntities;