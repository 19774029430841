import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanderA2PlusEOL20230815ContainerComponent } from './containers/lander-a2-plus-eol20230815-container/lander-a2-plus-eol20230815-container.component';
import { ScinomixAQ20240306Component } from './components/scinomix-aq20240306/scinomix-aq20240306.component';
import { LayoutContainerComponent } from './containers/layout-container/layout-container.component';
import { LandingPageContainerComponent } from './containers/landing-page-container/landing-page-container.component';
import { LayoutAdminManageContainerComponent } from './containers/layout-admin-manage-container/layout-admin-manage-container.component';
import { LandingPageAdminListContainerComponent } from './containers/landing-page-admin-list-container/landing-page-admin-list-container.component';
import { LandingPageAdminManageContainerComponent } from './containers/landing-page-admin-manage-container/landing-page-admin-manage-container.component';
import { LanderB2bTubes20240320Component } from './components/lander-b2b-tubes20240320/lander-b2b-tubes20240320.component';


const routes: Routes = [
  {
    path: "cab-a2-plus/end-of-life",
    component: LanderA2PlusEOL20230815ContainerComponent,
  },
  {
    path: "20240306/scinomix-aq",
    component: ScinomixAQ20240306Component
  },
  {
    path: "scinomix/tubes",
    component: LanderB2bTubes20240320Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingPagesRoutingModule { }