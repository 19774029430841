import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SupportGuide } from 'src/app/shared/models/support-guide.interface';
import { PaginationInfo } from "@fluentllc/core";
import * as SupportGuideActions from '../../store/support-guide.actions';
import { Store } from "@ngrx/store";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import { AlertService } from "src/app/core/services/alert.service";
import { ModalService } from "src/app/core/services/modal.service";
import { AppState } from "src/app/store/app.state";
import { Router } from "@angular/router";
import { selectAllSupportGuides, selectSupportGuidePaginationInfo } from '../../store/support-guide.selectors';
import { SupportGuideService } from 'src/app/core/services/support-guide.service';
@Component({
  selector: 'app-troubleshooting-admin-list-container',
  templateUrl: './troubleshooting-admin-list-container.component.html',
  styleUrls: ['./troubleshooting-admin-list-container.component.scss']
})
export class TroubleshootingAdminListContainerComponent implements OnInit{
  guides$: Observable<SupportGuide[]>;
  total$: Observable<number>;
  paginationInfo$: Observable<PaginationInfo>;
  currentPage: number = 1;
  pageSize: number = 10;
  loading$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private alertService: AlertService,
    private modalService: ModalService,
    private supportGuideService: SupportGuideService
  ) {
    this.store.dispatch(SupportGuideActions.loadSupportGuides());
  }

  ngOnInit(): void {
    this.store.dispatch(SupportGuideActions.loadSupportGuides());

    this.guides$ = this.store.select(selectAllSupportGuides);
    this.loadPaginatedGuides(this.currentPage);
    this.paginationInfo$ = this.store.select(selectSupportGuidePaginationInfo);
    const newMetaTags = {
      title: "Troubleshooting Guides",
      description: "",
      keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  loadPaginatedGuides(page: number): void {
    this.store.dispatch(SupportGuideActions.loadPaginatedSupportGuides({ page: page, limit: this.pageSize }));
    this.paginationInfo$ = this.store.select(selectSupportGuidePaginationInfo);
  }

  async openCustomModal(guideId: number) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Delete', cssClass: 'btn btn-danger', action: 'delete' }
    ];
    const result = await this.modalService.openModal('Delete Guide', 'Are you sure you want to delete this guide?', buttons);
    
    switch (result) {
      case 'delete':
        this.store.dispatch(SupportGuideActions.deleteSupportGuide({ id: guideId }));
        break;
      default:
        break;
    }
  }

  onEdit(guideId: number): void {
    this.router.navigate(['/admin/troubleshooting-guides/manage', guideId]);
  }

  onDelete(guideId: number): void {
    this.openCustomModal(guideId);
  }

  onCreate(): void {
    this.router.navigate(['/admin/troubleshooting-guides/create']);
  }

  onPageChange(page: number): void {
    // this.currentPage = page;
    this.loadPaginatedGuides(this.currentPage);
  }

  onChangePageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadPaginatedGuides(this.currentPage);
  }
}
