import { Component, Input } from '@angular/core';
import { Product } from '../../models/product.interface';

@Component({
  selector: 'app-tube-card-container',
  templateUrl: './tube-card-container.component.html',
  styleUrls: ['./tube-card-container.component.scss']
})
export class TubeCardContainerComponent {
  @Input() product: Product | null = null;
}
