// page-banner-container.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb.interface';

@Component({
  selector: 'app-page-banner-container',
  template: `
    <app-page-banner
      [backgroundImage]="backgroundImage"
      [title]="title"
      [breadcrumbs]="breadcrumbs"
      [headingLevel]="headingLevel"
    ></app-page-banner>
  `,
})
export class PageBannerContainerComponent {
  @Input() backgroundImage: string | null = null;
  @Input() title: string = '';
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() headingLevel;

}
