import { Component, Input } from '@angular/core';
import { FAQ } from 'src/app/shared/models/faq.interface';
import { Link } from 'src/app/shared/models/link.interface';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent {
  @Input() faqs: FAQ[] = [];

}
