// src/app/shared/containers/multi-step-form-container/multi-step-form-container.component.ts
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DynamicFormService } from 'src/app/core/services/dynamic-form.service';
import { DynamicFormStep } from 'src/app/shared/models/dynamic-form-step.interface';
import * as MultiStepFormActions from 'src/app/shared/modules/multi-step-form/store/multi-step-form.actions'
@Component({
  selector: 'app-multi-step-form-container',
  templateUrl: './multi-step-form-container.component.html',
  styleUrls: ['./multi-step-form-container.component.scss'],
})
export class MultiStepFormContainerComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  steps: DynamicFormStep[] = [];
  private formDataSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private dynamicFormService: DynamicFormService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({});

    this.dynamicFormService.getSteps().subscribe((fetchedSteps: DynamicFormStep[]) => {
      this.steps = fetchedSteps;
      this.steps.forEach(step => {
        step.formStep.initialize();
        this.formGroup.addControl(step.formStep.key, step.formStep.formGroup);
        if (step.formStep.model) {
          step.formStep.formGroup.setValue(step.formStep.model);
        }
      });
    });

    this.formDataSubscription = this.formGroup.valueChanges.subscribe(formData => {
      this.store.dispatch(MultiStepFormActions.updateModel({ model: formData }));
    });



  }

  ngOnDestroy(): void {
    // Unsubscribe from the form data subscription to avoid memory leaks
    this.formDataSubscription.unsubscribe();
  }
}



