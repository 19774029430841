import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-search-btn-container',
  templateUrl: './search-btn-container.component.html',
  styleUrls: ['./search-btn-container.component.scss']
})
export class SearchBtnContainerComponent {
  @Output() searchToggle = new EventEmitter<void>();
  searchClassApplied = false;

  searchToggleClass() {
    this.searchClassApplied = !this.searchClassApplied;
    this.searchToggle.emit();
  }

}
