import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchItem } from 'src/app/shared/models/search-item.interface';
import { SearchMetadata } from 'src/app/shared/models/search-metadata.interface';

export abstract class SearchService {
    abstract search(indexName: string, query: string): Observable<{ items: SearchItem[], metadata: SearchMetadata }>;
    abstract multiSearch(queries: { indexName: string; query: string }[]): Observable<{ items: SearchItem[], metadata: SearchMetadata }[]>;
    // abstract autocomplete(query: string, indexName: string): Observable<string[]>;
}


export const SEARCH_SERVICE = new InjectionToken<SearchService>('SearchService');
