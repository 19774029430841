import { createReducer, on } from '@ngrx/store';
import { showOffsidebar, hideOffsidebar } from './offsidebar.actions';
import { initialState, OffsidebarState } from './offsidebar.state';

export const offsidebarFeatureKey = 'offsidebar';

export const offsidebarReducer = createReducer(
    initialState,
    on(showOffsidebar, (state, { config }) => ({
        ...state,
        isVisible: true,
        componentConfig: config,
    })),
    on(hideOffsidebar, (state) => ({ ...state, isVisible: false, componentConfig: null }))
);