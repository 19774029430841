<div class="container">
    <div class="row">
        <div class="col-lg-7 pb-30">

            <h1>Application for {{job.title}}</h1>
            <div class="job-application-form" *ngIf="!submitted; else requestProductInfoResp">

                <p class="pb-30">Thank you for your interest in joining our team. Please fill out the form below with
                    your
                    personal
                    information and upload your resume and cover letter. All fields are . Once you've completed the
                    form, click the 'Submit' button to send your application. A member of the Scinomix Human Resources
                    team
                    will review your application and will be in contact with you shortly. We appreciate your patience
                    during
                    this process and look forward to potentially welcoming you to our team.</p>
                <!-- <form [formGroup]=" applicationForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" formControlName="firstName" class="form-control"
                                    placeholder="First Name*">
                                <div *ngIf="isInvalidAndTouched('firstName')" class="error-message">
                                    First Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" formControlName="lastName" class="form-control"
                                    placeholder="Last Name*">
                                <div *ngIf="isInvalidAndTouched('lastName')" class="error-message">
                                    Last Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <input type="email" formControlName="email" class="form-control" placeholder="Email*">
                                <div *ngIf="isInvalidAndTouched('email')" class="error-message">
                                    Your Email address is required
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <input type="tel" formControlName="phoneNumber" class="form-control"
                                    placeholder="Phone Number*">
                                <div *ngIf="isInvalidAndTouched('phoneNumber')" class="error-message">
                                    Your Phone is required
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <label for="resume">Upload Resume*</label>
                                <input type="file" (change)="onFileChange($event, 'resume')" formControlName="resume"
                                    class="form-control" id="resume" class="file-input">
                                <div *ngIf="isInvalidAndTouched('resume')" class="error-message">
                                    Resume is required
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                            <div class="form-group">
                                <label for="coverLetter">Upload Cover Letter*</label>
                                <input type="file" (change)="onFileChange($event, 'coverLetter')"
                                    formControlName="coverLetter" id="coverLetter" class="file-input">
                                <div *ngIf="isInvalidAndTouched('coverLetter')" class="error-message">
                                    A Cover Letter is required
                                </div>
                            </div>
                        </div>
                        <input type="hidden" formControlName="jobPosition">
                        <div class="col-lg-12 col-sm-12">
                            <button type="submit" [disabled]="applicationForm.invalid" class="sci-default-btn">Submit
                                Your Application</button>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 pt-30">
                        <p class="-field-note">* Indicates field</p>
                    </div>
                </form> -->
                <div id="hubspotForm"></div>

            </div>
            <ng-template #requestProductInfoResp>
                <div class="box-shadow" *ngIf="successMessage">
                    <div [innerHTML]="successMessage" class="p-30"></div>
                </div>
            </ng-template>
        </div>
        <div class="col-lg-5">
            <app-job-postings-sidebar-navigation-container
                [currentJobPosting]="job"></app-job-postings-sidebar-navigation-container>
        </div>
    </div>
</div>