// src/app/core/services/generic.service.ts

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class GenericService {
    constructor(private http: HttpClient) { }

    getByParams(url: string, paramsObj: { [key: string]: any }): Observable<any[]> {
        let params = new HttpParams();
        for (let key in paramsObj) {
            if (paramsObj[key]) {
                params = params.append(key, paramsObj[key]);
            }
        }

        return this.http.get<any[]>(url, { params: params });
    }
}
