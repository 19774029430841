// src/app/store/selectors/faq.selectors.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FAQFeatureKey } from './faq.reducer';
import { adapter, FAQState } from './faq.state';


export const selectFAQState = createFeatureSelector<FAQState>(FAQFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectFAQIds = createSelector(
    selectFAQState,
    selectIds
);

export const selectFAQEntities = createSelector(
    selectFAQState,
    selectEntities
);

export const selectAllFAQs = createSelector(
    selectFAQState,
    selectAll
);

export const selectFAQTotal = createSelector(
    selectFAQState,
    selectTotal
);

export const selectFAQById = (id: number) =>
    createSelector(
        selectFAQEntities,
        (entities) => entities[id]
    );

export const selectFAQLoading = createSelector(
    selectFAQState,
    (state) => state.loading
);

export const selectFAQError = createSelector(
    selectFAQState,
    (state) => state.error
);
