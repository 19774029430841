import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as LayoutActions from './layout.actions';
import { LayoutService } from 'src/app/core/services/layout.service';
import { Router } from '@angular/router';
import { AlertService } from '@fluentllc/core';

@Injectable()
export class LayoutEffects {
  loadLayouts$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.loadLayouts),
    mergeMap(() => this.layoutService.getLayouts().pipe(
      tap(layouts => console.log(layouts)),
      map(layouts => LayoutActions.loadLayoutsSuccess({ layouts })),
      catchError(error => of(LayoutActions.loadLayoutsFailure({ error })))
    ))
  ));
addLayout$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.addLayout),
    mergeMap(action => this.layoutService.createLayout(action.layout).pipe(
      map(layout => LayoutActions.addLayoutSuccess({ layout })),
      catchError(error => of(LayoutActions.addLayoutFailure({ error })))
    )),
    tap({
      next: () => {
        this.router.navigate(["/admin/landing-pages/layouts"]); // Adjust the URL as needed
        this.alertService.show({ message: "Layout successfully added", type: "success" });
      },
      error: (error) => {
        console.error("Error creating product:", error);
        this.alertService.show({ message: "Error creating layout.", type: "danger" });
      },
    })
  ));

  updateLayout$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.updateLayout),
    mergeMap(action => this.layoutService.updateLayout(action.layout).pipe(
      map(layout => LayoutActions.updateLayoutSuccess({ layout })),
      catchError(error => of(LayoutActions.updateLayoutFailure({ error })))
    )),
    tap({
      next: () => {
        this.router.navigate(["/admin/landing-pages/layouts"]); // Adjust the URL as needed
        this.alertService.show({ message: "Layout successfully updated", type: "success" });
      },
      error: (error) => {
        console.error("Error creating product:", error);
        this.alertService.show({ message: "Error creating layout.", type: "danger" });
      },
    })
  ));

  deleteLayout$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.deleteLayout),
    mergeMap(action => this.layoutService.deleteLayout(action.id).pipe(
      map(() => LayoutActions.deleteLayoutSuccess({ id: action.id })),
      catchError(error => of(LayoutActions.deleteLayoutFailure({ error })))
    ))
  ));


  constructor(
    private actions$: Actions,
    private layoutService: LayoutService,
    private router: Router,
    private alertService: AlertService,
  ) {}

}
