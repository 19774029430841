import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DistributorActions from './distributors.actions';
import { DistributorService } from 'src/app/core/services/distributor.service';

@Injectable()
export class DistributorsEffects {
    loadDistributors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DistributorActions.loadDistributors),
            switchMap(() =>
                this.distributorService.getDistributors().pipe(
                    map((distributors) => DistributorActions.loadDistributorsSuccess({ distributors })),
                    catchError((error) => of(DistributorActions.loadDistributorsFailure({ error })))
                )
            )
        )
    );

    loadDistributorById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DistributorActions.loadDistributorById),
            switchMap(({ id }) =>
                this.distributorService.getDistributorById(id).pipe(
                    map((distributor) => DistributorActions.loadDistributorByIdSuccess({ distributor })),
                    catchError((error) => of(DistributorActions.loadDistributorByIdFailure({ error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private distributorService: DistributorService
    ) { }
}
