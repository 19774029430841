import { Injectable } from '@angular/core';
import { ElementRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NavbarRefService {
    private navbarElementRef: ElementRef;

    setNavbarElementRef(ref: ElementRef): void {
        this.navbarElementRef = ref;
    }

    getNavbarElementRef(): ElementRef {
        return this.navbarElementRef;
    }
}