<div [ngSwitch]="headerType">
    <h1 *ngSwitchCase="'h1'">
        {{ title }}
        <span *ngIf="titleHighlight" class="title-highlight">{{ titleHighlight }}</span>
    </h1>
    <h2 *ngSwitchCase="'h2'">
        {{ title }}
        <span *ngIf="titleHighlight" class="title-highlight">{{ titleHighlight }}</span>
    </h2>
    <h3 *ngSwitchCase="'h3'">
        {{ title }}
        <span *ngIf="titleHighlight" class="title-highlight">{{ titleHighlight }}</span>
    </h3>
    <h1 *ngSwitchDefault>
        {{ title }}
        <span *ngIf="titleHighlight" class="title-highlight">{{ titleHighlight }}</span>
    </h1>
</div>