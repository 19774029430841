<div class="pagination-container d-flex justify-content-end align-items-center">
    <!-- Dropdown for selecting items per page -->
    <div class="dropdown-container">
      <select class="form-select form-select-sm" [value]="pageSize" (change)="onItemsPerPageChange($event)">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="100">100</option>
      </select>
    </div>
  
    <!-- Pagination Bar -->
    <ul class="pagination pagination-sm">
      <!-- Pagination Items -->
      <li class="page-item" *ngFor="let page of visiblePages" [class.active]="page == currentPageNumber">
        <a href="javascript:void(0);" class="page-link" (click)="onPageChange(page, $event)">{{ page }}</a>
      </li>
      
      <!-- Next Page Logic -->
      <li class="page-item" [class.disabled]="currentPageNumber >= totalPages.length">
        <a href="javascript:void(0);" class="page-link" (click)="incrementPage($event)">»</a>
      </li>
    </ul>
  </div>
  