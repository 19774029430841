<div *ngIf="!submitted; else demoResp">
    <h3>Schedule a Virtual Demonstration</h3>
    <p>
        Schedule a convenient, no-pressure 30-minute virtual demonstration of our
        lab automation solutions using Zoom video conferencing.
    </p>

    <form class="wrap-form appointment_form clearfix" (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <label>
                    <span class="text-input"><i class="fa fa-user" aria-hidden="true"></i>
                        <input name="firstname" type="text" class="form-control" formControlName="firstname"
                            placeholder="First Name*" required="required" />
                        <span class="text-danger" *ngIf="hasError('firstname', 'required')">First name is
                            required</span>
                    </span>
                </label>
            </div>
            <div class="col-lg-6 col-sm-12">
                <label>
                    <span class="text-input">
                        <i class="fa fa-user" aria-hidden="true"></i><input name="lastname" type="text"
                            placeholder="Last Name*" required="required" class="form-control"
                            formControlName="lastname" />
                        <span class="text-danger" *ngIf="hasError('lastname', 'required')">Last name is
                            required</span></span>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <label>
                    <span class="text-input mb-3"><i class="fa fa-building" aria-hidden="true"></i><input name="company"
                            type="text" value="" placeholder="Company*" required="required" formControlName="company"
                            class="form-control" /><span class="text-danger"
                            *ngIf="hasError('company', 'required')">Company is required*</span></span>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-12 form-group" style="margin-bottom:30px">
                <label class="custom-select" for="country">Country*</label>
                <ng-select [items]="countries" bindLabel="name" bindValue="code" formControlName="country"
                    (change)="onCountryChange($event)">
                </ng-select>
                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['country'].invalid && form.controls['country'].touched">
                    Please select your country.
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 form-group" style="margin-bottom:30px" *ngIf="showStateDropdown">
                <label class="custom-select" for="stateUs">State/Province*</label>
                <ng-select *ngIf="showStateDropdown" [items]="states" bindLabel="name" bindValue="code"
                    placeholder="Select a state" formControlName="stateUs" [clearable]="true">
                </ng-select>

                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['stateUs'].invalid && form.controls['stateUs'].touched">
                    Please select your state/province.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <label>
                    <span class="text-input">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <input type="email" placeholder="E-mail*" formControlName="email" class="form-control" />
                        <span class="text-danger" *ngIf="hasError('email', 'required')">Your email is required so we
                            may contact you</span>
                        <span class="text-danger" *ngIf="hasError('email', 'email')">Enter a valid email</span>
                    </span>
                </label>
            </div>
            <!-- Add the remaining fields with similar Bootstrap structure -->
            <div class="col-lg-6 col-sm-12">
                <span class="text-input">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <input type="tel" class="form-control text-input" id="phone" formControlName="phone"
                        placeholder="Phone" />
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <div class="form-group date">
                    <span class="text-input">
                        <i class="fa fa-calendar"></i>
                        <input name="requested_date_time" id="requested_date_time" placeholder="Requested Date Time"
                            class="form-control datepicker-right" bsDatepicker [bsConfig]="datePickerConfig"
                            [minDate]="minDate" formControlName="requested_date_time" />
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label class="form-group">
                    <span class="text-input">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                        <textarea formControlName="message" class="form-control"
                            placeholder="Please tell us about your lab in order to personalize the demonstration, for example, tube/plate types and sizes required"></textarea>
                    </span>
                </label>
                <button
                    class="submit sci-btn sci-btn-size-md sci-btn-shape-rounded sci-btn-style-fill sci-btn-color-skincolor"
                    type="submit" [disabled]="form.invalid">
                    Schedule appointment
                </button>
                <div class="small pt-10">*Indicates required field</div>
            </div>
        </div>
    </form>
</div>
<ng-template #demoResp>
    <div class="box-shadow" *ngIf="successMessage">
        <h3>Demonstration Requested</h3>
        <div [innerHTML]="successMessage" class="small"></div>
    </div>
</ng-template>