import { Component, Input } from '@angular/core';
import { Link } from '../../models/link.interface';

@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss']
})
export class SidebarNavigationComponent {
  @Input() menuTitle: string;
  @Input() links: Link[] = [];
}
