<div *ngIf="posts">
    <div class="blog-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card card-default">
                        <!-- Card Header with New Post Button -->
                        <div class="card-header d-flex justify-content-between align-items-center admin-list">
                            <div>Post Manager</div>
                            <button class="btn btn-secondary" (click)="create.emit()">New Post</button>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <!-- Table Head -->
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <!-- <th>Author</th> -->
                                            <th>Tags</th>
                                            <th>Created Date</th>
                                            <th>Updated Date</th>
                                            <th>Scheduled Date</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <!-- Table Body -->
                                    <tbody>
                                        <tr *ngFor="let post of posts">
                                            <td>{{ post.title }}</td>
                                            <!-- <td>{{ post.author.user.first_name }} {{ post.author.user.last_name }}</td> -->
                                            <td>
                                                <span *ngFor="let keyword of post.keywords" class="badge bg-info me-1">{{ keyword.name }}</span>
                                            </td>
                                            <td>{{ post.created_date | date }}</td>
                                            <td>{{ post.modified_date | date }}</td>
                                            <td>{{ post.published_at | date:'medium' }}</td>
                                            <td>
                                                <span class="badge" [ngClass]="{'bg-success': getStatusBadge(post) === 'Published', 'bg-info': getStatusBadge(post) === 'Scheduled', 'bg-secondary': getStatusBadge(post) === 'Draft'}">{{ getStatusBadge(post) }}</span>
                                            </td>
                                            <td>
                                                <div class="action-buttons">
                                                    <button class="btn btn-secondary btn-sm" (click)="edit.emit(post.slug)">Edit</button>
                                                    <button class="btn btn-danger btn-sm" (click)="delete.emit(post.id)">Delete</button>
                                                  </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Card Footer -->
                        <div class="card-footer">
                            <app-pagination
                                [paginationInfo]="paginationInfo"
                                (pageChanged)="onPageChange($event)"
                                (itemsPerPageChanged)="onItemsPerPageChange($event)"
                            ></app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
