import { Component, Input, OnChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Media } from '../../models/media.interface';
import { Observable } from 'rxjs';
import { selectCarouselMediaList, selectMediaInBackendOrder, selectSortedCarouselMediaList } from '../../store/media.selectors';

@Component({
  selector: 'app-product-carousel-container',
  templateUrl: './product-carousel-container.component.html',
  styleUrls: ['./product-carousel-container.component.scss']
})
export class ProductCarouselContainerComponent implements OnChanges {
  @Input() productSlug: string;
  media$: Observable<Media[]>;

  constructor(private store: Store) { }

  ngOnChanges(): void {
    // console.log(this.productSlug)
    if (this.productSlug) {
      this.media$ = this.store.pipe(select(selectMediaInBackendOrder));
    }
  }
}
