import { Component, Input } from '@angular/core';
import { CustomItem } from '../../models/custom-item.interface';

@Component({
  selector: 'app-bullet-features-container',
  templateUrl: './bullet-features-container.component.html',
  styleUrls: ['./bullet-features-container.component.scss']
})
export class BulletFeaturesContainerComponent {
  @Input() customItems: CustomItem[] = [];
  @Input() numColumns: number = 3;
  @Input() layout: 'horizontal' | 'vertical' | 'horizontal-icon-above' = 'horizontal';
}
