<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <div class="pt-30">
                <h1>Service Agreements</h1>
            </div>
            <div class="ptb-30">
                <h3 class="title">Installation</h3>
                <p>Scinomix provides trained personnel to install your newly purchased equipment. Installation includes
                    machine setup and trial runs, as well as comprehensive teaching sessions for all users.</p>
                <h3 class="title">Service Agreements</h3>
                <p>We offer a variety of Service Agreements frequently tailoring them to specific customer needs.
                    Standard Service Agreements come with one preventive maintenance visit every year and two service
                    calls. Please contact your sales representative for a quote at <a
                        href="mailto:sales@scinomix.com">sales@scinomix.com</a>.</p>

                <h3 class="title">Preventative Maintenance</h3>
                <p>Preventive Maintenance visits are offered as part of a Service Agreement or as a one time service
                    visit with charge. Preventive maintenance visits are a comprehensive check including, but not
                    limited to:</p>
                <ul>
                    <li class="first">Air supply tubing</li>
                    <li>Belt and bearing wear</li>
                    <li class="last">Motor functionality</li>
                </ul>
                <p>Please contact your sales representative for a quote at&nbsp;<a
                        href="mailto:sales@scinomix.com">sales@scinomix.com</a>.</p>
            </div>

        </div>
        <div class="col-lg-4">
            <app-services-support-sidebar-navigation-container></app-services-support-sidebar-navigation-container>
        </div>
    </div>
</div>