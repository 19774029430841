import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { SearchService, SEARCH_SERVICE } from 'src/app/core/services/search/abstract-search.service';
import * as SearchActions from './search.actions';
import { SearchItem } from 'src/app/shared/models/search-item.interface';
import { SearchMetadata } from 'src/app/shared/models/search-metadata.interface';
import { GtmService } from 'src/app/core/services/gtm.service';

@Injectable()
export class SearchEffects {
    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SearchActions.search),
            switchMap(action => {
                this.gtmService.push({ 
                    event: 'search', 
                    search_term: action.query 
                });
                return this.searchService.multiSearch(action.indexes.map(index => ({ indexName: index, query: action.query }))).pipe(
                    map((results) => {
                        const searchItems: SearchItem[] = [];
                        const searchMetadata: Partial<SearchMetadata> = {};

                        results.forEach(({ items, metadata }) => {
                            searchItems.push(...items);

                            // Aggregate metadata, this is just an example,
                            // you need to decide how to handle metadata from multiple indices.
                            searchMetadata.page = (searchMetadata.page || 0) + metadata.page;
                            searchMetadata.nbPages = (searchMetadata.nbPages || 0) + metadata.nbPages;
                            searchMetadata.hitsPerPage = (searchMetadata.hitsPerPage || 0) + metadata.hitsPerPage;
                            searchMetadata.nbHits = (searchMetadata.nbHits || 0) + metadata.nbHits;
                            // Handle other properties...
                        });

                        return SearchActions.searchSuccess(searchItems, searchMetadata as SearchMetadata);
                    }),
                    catchError(error => of(SearchActions.searchFailure(error)))
                )
                })
        )
    );


    constructor(
        private actions$: Actions,
        private gtmService: GtmService,
        @Inject(SEARCH_SERVICE) private searchService: SearchService,
    ) { }
}
