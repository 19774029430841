import { Component } from '@angular/core';

@Component({
  selector: 'app-lander-a2-plus-eol20230815-container',
  templateUrl: './lander-a2-plus-eol20230815-container.component.html',
  styleUrls: ['./lander-a2-plus-eol20230815-container.component.scss']
})
export class LanderA2PlusEOL20230815ContainerComponent {
  title = "CAB A2+ Printer End of Life Notice"
  titleHighlight = "for Sci-Print VX Series & Sci-Print MP2 & MP2+ Machines"
  heroImage: string = 'assets/images/vx2_vxl_a2plus_EOL.jpg';
  description = `

<h2>Important Notice:</h2>
<p><span class="highlight">The A2+ printer</span> in your Sci-Print VXL, Sci-Print VX2, Sci-Print MP2, or Sci-Print MP2+ is approaching its end of life on <span class="highlight">December 31, 2025</span>. As we approach this date, it's crucial to understand how this affects your lab operations and to explore available solutions.</p>
<h2>What This Means for You</h2>
<ul>
    <li><strong>Limited Support:</strong> After the December 31, 2025 end of life date, Scinomix will continue supporting the A2+ printer to the best of our ability. However, due to manufacturer discontinuation, obtaining necessary parts and service will become increasingly challenging.</li>
    <li><strong>Impact on Specific Models:</strong> This notice is particularly relevant if you own models 1043, 1044, 1045, 1046, and 1061 purchased before November 2019.</li>
</ul>
<h2>Our Commitment to You</h2>
<p>Scinomix is dedicated to ensuring seamless lab operations for our clients. As we transition, we pledge:</p>
<ul>
    <li><strong>Transparency:</strong> We believe in being upfront about the changes and the implications for your labs.</li>
    <li><strong>Support:</strong> While the A2+ printer is being phased out, our commitment to offering the best possible support remains unchanged.</li>
    <li><strong>Consultation:</strong> We encourage you to get in touch with us. Let's strategize and plan for your lab's future needs.</li>
</ul>
<h2>Take Action</h2>
<p>To ensure your lab operations continue without a hitch:</p>
<ol>
    <li><strong>Contact Your Account Manager:</strong> They're ready to assist and provide guidance. Reach out at <a href="mailto:sales@scinomix.com">sales@scinomix.com</a>.</li>
    <li><strong>Gather More Information:</strong> If you need further details or have questions, contact us at <a href="tel:3142989800">314-298-9800</a> or <a href="mailto:service@scinomix.com">service@scinomix.com</a>.</li>
    <li><strong>Explore:</strong> Visit this page for regular updates about the A2+ End of Life.</li>
</ol>
<p><strong>A message from Ellie Proffer, President of Scinomix</strong></p>
<blockquote>"We deeply value our partnership with labs worldwide. As we navigate this change, we're here to support and guide you every step of the way. Thank you for placing your trust in Scinomix."</blockquote>
<h2>Schedule a System Evaluation</h2>
<p>Don't delay. Ensure your lab's uninterrupted functionality.</p><p><strong>Book a consultation with our support staff now to see if you need to take additional steps.</strong><ul><li><a style="text-decoration:underline;" href="https://meetings.hubspot.com/mark-harris8" target="_blank">Schedule an evaluation with Mark Harris</a></li><li><a target="_blank" href="https://meetings.hubspot.com/jason-lindsey1" style="text-decoration:underline;">Schedule an evaluation with Jason Lindsey</a></li><li><a href="mailto:service@scinomix.com">Send an email to our support staff</a></li></ul></p>
  `
}
