import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareersRoutingModule } from './careers-routing.module';
import { CareersStoreModule } from './store/careers-store.module';
import { CareersContainerComponent } from './containers/careers-container/careers-container.component';
import { CareersComponent } from './components/careers/careers.component';
import { JobPostingsStoreModule } from './store/job-posting-store.module';
import { JobPostingDetailComponent } from './components/job-posting-detail/job-posting-detail.component';
import { JobPostingDetailContainerComponent } from './containers/job-posting-detail-container/job-posting-detail-container.component';
import { JobPostingListComponent } from './components/job-posting-list/job-posting-list.component';
import { JobPostingListContainerComponent } from './containers/job-posting-list-container/job-posting-list-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JobApplicationComponent } from './components/job-application/job-application.component';
import { JobApplicationContainerComponent } from './containers/job-application-container/job-application-container.component';
import { JobPostingsSidebarNavigationContainerComponent } from './containers/job-postings-sidebar-navigation-container/job-postings-sidebar-navigation-container.component';
import { JobPostingSidebarNavigationComponent } from './components/job-posting-sidebar-navigation/job-posting-sidebar-navigation.component';
import { JobApplicationsStoreModule } from './store/job-application-store.module';
import { JobPostingAdminListContainerComponent } from './containers/job-posting-admin-list-container/job-posting-admin-list-container.component';
import { JobPostingAdminListComponent } from './components/job-posting-admin-list/job-posting-admin-list.component';
import { JobPostingAdminManageComponent } from './components/job-posting-admin-manage/job-posting-admin-manage.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule as FluentSharedModule}  from 'src/app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { JobPostingAdminManageContainerComponent } from './containers/job-posting-admin-manage-container/job-posting-admin-manage-container.component';
@NgModule({
  declarations: [
    CareersContainerComponent,
    CareersComponent,
    JobPostingDetailComponent,
    JobPostingDetailContainerComponent,
    JobPostingListComponent,
    JobPostingListContainerComponent,
    JobApplicationComponent,
    JobApplicationContainerComponent,
    JobPostingsSidebarNavigationContainerComponent,
    JobPostingSidebarNavigationComponent,
    JobPostingAdminListContainerComponent,
    JobPostingAdminListComponent,
    JobPostingAdminManageComponent,
    JobPostingAdminManageContainerComponent
  ],
  imports: [
    CommonModule,
    CareersRoutingModule,
    JobPostingsStoreModule,
    JobApplicationsStoreModule,
    FontAwesomeModule,
    FluentSharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    BsDatepickerModule,
  ],
})
export class CareersModule { }
