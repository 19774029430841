import { createReducer, on } from '@ngrx/store';
import { loadBlogs, loadBlogsSuccess, loadBlogsFailure } from './blog.actions';
import { initialState, BlogState, adapter } from './blog.state';
export const blogFeatureKey = 'blogs';
export const blogReducer = createReducer(
    initialState,
    on(loadBlogs, (state) => {
        return { ...state, loading: true, error: null };
    }),
    on(loadBlogsSuccess, (state, { blogs }) => {
        return adapter.setAll(blogs, { ...state, loading: false });
    }),
    on(loadBlogsFailure, (state, { error }) => {
        return { ...state, loading: false, error };
    })
);
