import { Component, Input } from '@angular/core';
import { Media } from '../../models/media.interface';

@Component({
  selector: 'app-helpful-resources',
  templateUrl: './helpful-resources.component.html',
  styleUrls: ['./helpful-resources.component.scss']
})
export class HelpfulResourcesComponent {
  @Input() resources: Media[];
}
