import { createReducer, on } from '@ngrx/store';
import * as ProductClassActions from './product-class.actions';
import { adapter, initialState } from './product-class.state';

export const productClassFeatureKey = 'productClasses';

export const productClassReducer = createReducer(
  initialState,
  // Handle loading all product classes
  on(ProductClassActions.loadProductClasses, state => ({
    ...state,
    loading: true,
    error: null,
  })),

  // Handle successful load of all product classes
  on(ProductClassActions.loadProductClassesSuccess, (state, { productClasses }) =>
    adapter.setAll(productClasses, {
      ...state,
      loading: false,
      error: null,
    })
  ),

  // Handle failure in loading all product classes
  on(ProductClassActions.loadProductClassesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  // Handle loading a single product class
  on(ProductClassActions.loadProductClass, state => ({
    ...state,
    loading: true,
  })),

  // Handle successful load of a single product class
  on(ProductClassActions.loadProductClassSuccess, (state, { currentProductClass }) => {
    const updatedState = adapter.upsertOne(currentProductClass, state);
    return {
      ...updatedState,
      currentProductClass: currentProductClass,
      loading: false,
      error: null,
    };
  }),

  // Handle failure in loading a single product class
  on(ProductClassActions.loadProductClassFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
);
