<div *ngIf="posts">
    <app-page-banner-container headingLevel='1' backgroundImage="/assets/images/default_abstract.jpg" title="Lab Insights Blog"
        [breadcrumbs]="breadcrumbs"></app-page-banner-container>
    <!-- <h2>{{ categoryData.name }}</h2>
    <p>{{ categoryData.description }}</p>
    <img *ngIf="categoryData.page_title_image" [src]="categoryData.page_title_image" alt="{{ categoryData.name }}"> -->
</div>
<div class="blog-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4" *ngFor="let post of posts">
                        <div class="single-blog">
                            <div class="blog-image">
                                <a [routerLink]="['/news', post.slug]" class="d-block">
                                    <img [src]="post.image" alt="{{post.title}}" *ngIf="post.image">
                                </a>
                                <div *ngIf="post.published_at; else modifiedDateBlock">
                                    <div class="date">{{post.published_at | date:'mediumDate'}}</div>
                                </div>
                                <ng-template #modifiedDateBlock>
                                    <div class="date">{{post.modified_date | date:'mediumDate'}}</div>
                                </ng-template>
                                <div class="tag-wrapper">
                                    <div class="tag" *ngFor="let tag of post.keywords">
                                        <a [routerLink]="['/news', post.slug]">{{tag.name}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="blog-content">
                                <h3>
                                    <a [routerLink]="['/news', post.slug]">
                                        {{ post.title }}
                                    </a>
                                </h3>
                                <p [innerHTML]="post.content | htmlToText  | truncateDescription:150:false"></p>
                                <a [routerLink]="['/news', post.slug]" class="blog-btn">Read
                                    More</a>
                                <ul class="entry-meta">
                                    <li>
                                        <div class="blue-icon" [innerHTML]="bxsUser"></div>
                                        <span>By <a routerLink="/blog-grid">{{post.author.user.first_name}}
                                                {{post.author.user.last_name}}</a></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>