// src/app/shared/modules/multi-step-form/store/multi-step-form.actions.ts

import { createAction, props } from '@ngrx/store';
import { DynamicFormStep } from 'src/app/shared/models/dynamic-form-step.interface';

export const setCurrentStep = createAction(
    '[MultiStepForm] Set Current Step',
    props<{ step: number }>()
);

export const setSteps = createAction(
    '[MultiStepForm] Set Steps',
    props<{ steps: DynamicFormStep[] }>()
);


export const completeStep = createAction(
    '[MultiStepForm] Complete Step',
    props<{ step: number; data: any }>()
);

export const updateCompletedStep = createAction(
    '[MultiStepForm] Update Completed Step',
    props<{ step: number; data: any }>()
);

export const uncompleteStep = createAction(
    '[MultiStepForm] Uncomplete Step',
    props<{ step: number }>()
);

export const resetMultiStepForm = createAction(
    '[MultiStepForm] Reset MultiStepForm'
);

export const submitForm = createAction(
    '[MultiStepForm] Submit Form',
    props<{ formData: any; successCallback: () => void }>()
);

export const submitFormSuccess = createAction('[MultiStepForm] Submit Form Success');

export const submitFormFailure = createAction(
    '[MultiStepForm] Submit Form Failure',
    props<{ error: any }>()
);

export const updateFormData = createAction(
    '[MultiStepForm] Update Form Data',
    props<{ step: number; data: { [key: string]: any } }>()
);

export const updateModel = createAction(
    '[MultiStepForm] Update Model',
    props<{ model: { [key: string]: any } }>()
);