import { Component, Input } from '@angular/core';
import { Distributor } from 'src/app/shared/models/distributor.interface';

@Component({
  selector: 'app-distributor-card-container',
  templateUrl: './distributor-card-container.component.html',
  styleUrls: ['./distributor-card-container.component.scss']
})
export class DistributorCardContainerComponent {
  @Input() distributor: Distributor;
}
