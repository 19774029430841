<div class="container">
    <div class="row align-items-top pb-100 pt-50">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <app-title-hashtag [hashtagSubtitle]="subtitle"></app-title-hashtag>
          <app-title [title]="title" [titleHighlight]="titleHighlight"></app-title>
          <div [innerHTML]="description" class="mb-3"></div>
          <div class="mb-5">
            The AQ Aliquoting System is the next innovative automation system from Scinomix designed to aliquot reagents or samples with labeling, validation, and sample tracking. Workflows supported include sample aliquoting, sample pooling, sample dilution, and reagent dispensing.
          </div>
          <app-virtual-demo-form (formSubmit)="onFormSubmit($event)" [successMessage]="successMessage$ | async"
          [submitted]="submitted"></app-virtual-demo-form>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <app-product-carousel-container
        [productSlug]="slug"></app-product-carousel-container>
      </div>
    </div>
  </div>