import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as MediaActions from './media.actions';
import { ProductsApiService } from 'src/app/core/services/products/products-api.service';
import { selectCurrentProduct } from './products.selectors';
import { Media } from '../models/media.interface';

@Injectable()
export class MediaEffects {
  constructor(
    private actions$: Actions,
    private productsApiService: ProductsApiService,
    private store: Store
  ) {}

  // loadMedia$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MediaActions.loadMedia),
  //     mergeMap((action) =>
  //       this.productsApiService.getMedia(action.slug).pipe(
  //         map((media) => MediaActions.loadMediaSuccess({ media })),
  //         catchError((error) => of(MediaActions.loadMediaFailure({ error })))
  //       )
  //     )
  //   )
  // );

  // updateMediaWeights$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MediaActions.updateMediaWeights),
  //     mergeMap((action) =>
  //       this.productsApiService.updateMediaWeights(action.updatedMediaList).pipe(
  //         map((updatedMediaList: Media[]) => MediaActions.updateMediaWeightsSuccess({ updatedMediaList })),
  //         catchError((error) => of(MediaActions.updateMediaWeightsFailure({ error })))
  //       )
  //     )
  //   )
  // );

  // reloadMediaForNewProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MediaActions.loadMedia),
  //     withLatestFrom(this.store.pipe(select(selectCurrentProduct))),
  //     mergeMap(([action, currentProduct]) => {
  //       // console.log(currentProduct)
  //       if (action.slug && action.slug !== currentProduct.slug) {
  //         return this.productsApiService.getMedia(action.slug).pipe(
  //           map(media => MediaActions.loadMediaSuccess({ media })),
  //           catchError(error => of(MediaActions.loadMediaFailure({ error })))
  //         );
  //       }
  //       // If the product has not changed, do not reload media to avoid flashing or loss of state
  //       return of(MediaActions.skipMediaReload());
  //     })
  //   )
  // );

  updateMediaWeights$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MediaActions.updateMediaWeights),
    mergeMap((action) =>
      this.productsApiService.updateMediaWeights(action.updatedMediaList).pipe(
        // tap((updatedMedia) => console.log("Updated Weights Media:", updatedMedia)), // Log the successful save
        concatMap((updatedMediaList: Media[]) => [
          MediaActions.updateMediaWeightsSuccess({ updatedMediaList: updatedMediaList }),
          // MediaActions.loadMedia({ slug: action.productSlug })
        ]),
        catchError((error) => of(MediaActions.updateMediaWeightsFailure({ error })))
      )
    )
  )
);

  loadMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMedia),
      concatMap((action) =>
        this.productsApiService.getMedia(action.slug).pipe(
          // tap((media) => console.log("Load Media:", media)),
          map((media) => MediaActions.loadMediaSuccess({ media })),
          catchError((error) => {
            console.error("Error:", error);
            return of(MediaActions.loadMediaFailure({ error }));
          })
        )
      )
    )
  );

  loadMediaByTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMediaByTags),
      mergeMap((action) =>
        this.productsApiService.getMediaByTags(action.params).pipe(
          map((media) => MediaActions.loadMediaByTagsSuccess({ media })),
          catchError((error) => of(MediaActions.loadMediaByTagsFailure({ error })))
        )
      )
    )
  );


  uploadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.uploadFilesToProduct),
      mergeMap((action) =>
        this.productsApiService.uploadMediaToProduct(action.files, action.productSlug).pipe(
          map((response) => MediaActions.uploadFilesToProductSuccess({ response })),
          catchError((error) => of(MediaActions.uploadFilesToProductFailure({ error })))
        )
      )
    )
  );

  createMediaForProduct$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MediaActions.createMediaForProduct),
    concatMap(action =>
      this.productsApiService.createMediaForProduct(action.media, action.file, action.thumbnail, action.productSlug).pipe(
        // tap((media) => console.log("createMedia:", media)),
        map(media => MediaActions.createMediaForProductSuccess({ media })),
        catchError(error => of(MediaActions.createMediaForProductFailure({ error })))
      )
    )
  ));

  removeProductAssociation$ = createEffect(() =>
  this.actions$.pipe(
    ofType(MediaActions.removeProductAssociation),
    mergeMap(action =>
      this.productsApiService.removeProductAssociation(action.productSlug, action.mediaId).pipe(
        map(() => MediaActions.removeProductAssociationSuccess({ productSlug: action.productSlug, mediaId: action.mediaId })),
        catchError(error => of(MediaActions.removeProductAssociationFailure({ error })))
      )
    )
  ));


  saveEditedMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.saveEditedMedia),
      mergeMap((action) =>
        this.productsApiService.updateMedia(action.media, action.file, action.thumbnail).pipe(
          map((updatedMedia) => MediaActions.saveEditedMediaSuccess({ media: updatedMedia })),
          catchError((error) => of(MediaActions.saveEditedMediaFailure({ error })))
        )
      )
    )
  );

  deleteMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.deleteMedia),
      mergeMap((action) =>
        this.productsApiService.deleteMedia(action.mediaId).pipe(
          map(() => MediaActions.deleteMediaSuccess({ mediaId: action.mediaId })),
          catchError((error) => of(MediaActions.deleteMediaFailure({ error })))
        )
      )
    )
  );

  reloadMediaForNewProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMedia),
      withLatestFrom(this.store.pipe(select(selectCurrentProduct))),
      mergeMap(([action, currentProduct]) => {
        if (action.slug !== currentProduct?.slug) {
          return this.productsApiService.getMedia(action.slug).pipe(
            map((media) => MediaActions.loadMediaSuccess({ media })),
            catchError((error) => of(MediaActions.loadMediaFailure({ error })))
          );
        }
        return of(MediaActions.skipMediaReload());
      })
    )
  );
}
