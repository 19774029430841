<!-- alert-container.component.html -->
<div *ngFor="let position of ['top-left', 'top-right', 'bottom-left', 'bottom-right']" class="position-fixed p-3" style="z-index: 1030"
    [ngClass]="{
      'top-0': position === 'top-left' || position === 'top-right',
      'bottom-0': position === 'bottom-left' || position === 'bottom-right',
      'start-0': position === 'top-left' || position === 'bottom-left',
      'end-0': position === 'top-right' || position === 'bottom-right'
    }">
  <ng-container *ngFor="let alert of alertService.alerts$ | async">
    <app-alert *ngIf="alert.position === position" [alert]="alert" (close)="alertService.close(alert.id)"></app-alert>
  </ng-container>
</div>

