import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';

@Component({
  selector: 'app-careers-container',
  templateUrl: './careers-container.component.html',
  styleUrls: ['./careers-container.component.scss']
})
export class CareersContainerComponent implements OnInit {

  constructor(private metaTagsService: MetaTagsService) {

  }
  
  ngOnInit(): void {
    const newMetaTags = {
      title: 'Scinomix Careers',
      description: 'Explore exciting career opportunities at Scinomix. Join a team of passionate professionals dedicated to innovation and growth. Shape your future with us!',
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }
}
