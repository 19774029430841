// src/app/shared/components/spinner/spinner.component.ts
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SpinnerState } from '../../store/spinner-store/spinner.state';
import { selectIsSpinnerVisible } from '../../store/spinner-store/spinner.selectors';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() isVisible: boolean = false;

  constructor(private store: Store<SpinnerState>, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.cdr.detectChanges();
  }
}
