import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnChanges {
  @Input() additionalClasses = [];
  @Input() color: string = '#000000'; // Default color is black
  bxlFacebookIcon: SafeHtml;
  bxlLinkedInIcon: SafeHtml;
  bxlTwitterIcon: SafeHtml;
  bxlYoutubeIcon: SafeHtml;

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.loadSvgIcons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['color']) {
      this.loadSvgIcons(); // Reload SVG icons if color input changes
    }
  }

  private loadSvgIcons() {
    this.loadSvg('assets/icons/bxl-facebook.svg', (svg) => this.bxlFacebookIcon = svg);
    this.loadSvg('assets/icons/bxl-linkedin.svg', (svg) => this.bxlLinkedInIcon = svg);
    this.loadSvg('assets/icons/pajamas--twitter.svg', (svg) => this.bxlTwitterIcon = svg);
    this.loadSvg('assets/icons/youtube-icon.svg', (svg) => this.bxlYoutubeIcon = svg);
  }

  private loadSvg(path: string, callback: (svg: SafeHtml) => void) {
    this.httpClient.get(path, { responseType: 'text' }).subscribe(data => {
      const updatedSvg = this.updateSvgColor(data, this.color);
      callback(this.sanitizer.bypassSecurityTrustHtml(updatedSvg));
    });
  }

  private updateSvgColor(svg: string, color: string): string {
    // This example assumes you're changing the color of <path> elements. Adjust as needed.
    const regex = /<path(.*?)>/g;
    const newSvg = svg.replace(regex, (match) => {
      if (match.indexOf('fill=') === -1) {
        // If there's no fill attribute, add one
        return match.slice(0, -1) + ` fill="${color}">`;
      } else {
        // If there is a fill attribute, replace it
        return match.replace(/fill="[^"]*"/, `fill="${color}"`);
      }
    });
    return newSvg;
  }
}
