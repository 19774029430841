<div class="container">
    <div class="row">
        <div class="col-lg-7 ptb-75">
            <h1>Scinomix Privacy Notice & Policy</h1>
            <div class="pb-30"><object class="wp-block-file__embed"
                    data="https://scinomix.com/wp-content/uploads/2023/01/SCID118-Scinomix-Privacy-Notice-and-Policy.pdf"
                    type="application/pdf" style="width:100%;height:600px"
                    aria-label="Embed of SCID118-Scinomix-Privacy-Notice-and-Policy."></object><a
                    id="wp-block-file--media-121aba10-8274-4d7c-b1d2-ddeb3f0b1f61"
                    href="https://scinomix.com/wp-content/uploads/2023/01/SCID118-Scinomix-Privacy-Notice-and-Policy.pdf">SCID118-Scinomix-Privacy-Notice-and-Policy</a><a
                    href="https://scinomix.com/wp-content/uploads/2023/01/SCID118-Scinomix-Privacy-Notice-and-Policy.pdf"
                    class="wp-block-file__button" download=""
                    aria-describedby="wp-block-file--media-121aba10-8274-4d7c-b1d2-ddeb3f0b1f61">Download</a></div>







            <p>This Notice and Policy (“Notice”) is provided by Scinomix, Inc.(“Scinomix”, “we” or “us”), applies to all
                users of our website https://www.scinomix.com (“Website”), any related applications, and our social
                media accounts; and to all individuals who share their personal information with us for any business
                purpose, including our customers or prospective customers, suppliers, vendors, staff of regulatory
                authorities, employees or prospective employees, and visitors to our sites and offices.<br></p>



            <p>We take your privacy very seriously. Please read this privacy Notice carefully as it contains important
                information on who we are, and how and why we access, collect, process, store, use and share your
                Personal Information (as defined below). It also explains your rights in relation to your Personal
                Information and how to contact us in the event you have any questions.<br></p>



            <ol>
                <li><strong>CHANGES TO THIS NOTICE<br></strong>This Notice may be changed, modified, supplemented, or
                    updated at any time. While we will endeavor to notify you of any changes to our Notice through our
                    Website or direct communications as may be authorized by you, we encourage you to periodically
                    review this page for the latest information on our privacy practices.<br><br></li>
                <li><strong>NO SALE OF PERSONAL INFORMATION<br></strong>We do not sell your Personal Information and
                    have not sold your Personal Information to any third party.<br><br></li>
                <li><strong>INFORMATION WE MAY COLLECT AND DISCLOSE ABOUT YOU<br></strong>We may access, collect,
                    process, store, use, share and be responsible for some or all of the following personal information
                    that identifies, relates to, describes, is reasonably capable of being associated with, or could
                    reasonably be linked, directly or indirectly, with a particular consumer or household (“Personal
                    Information”):</li>
            </ol>



            <p><strong>Categories</strong>: <strong>Specific Pieces of Personal Information that may be
                    collected</strong></p>



            <p><strong>A. Identifiers:</strong> Any information relating to an identified or identifiable individual,
                such as their name, alias, postal address, email address, IP address, telephone number, online
                identifier, user ID, account name, or other similar identifiers.</p>



            <p><strong>B. Internet or similar network or application activity:</strong> Browsing history, search
                history, information on a consumer’s interaction with our Website, application, or advertisement, and
                cookies.</p>



            <p><strong>C. Information that you may voluntarily provide to us:</strong> Any other Personal Information
                that you may voluntarily provide to us (for example, in an email to our customer service)</p>



            <p>This Personal Information is required to provide products and services to you. If you do not provide
                Personal Information we ask for, it may delay or prevent us from providing products or services to you.
            </p>



            <ol start="4">
                <li><strong>HOW YOUR PERSONAL INFORMATION IS COLLECTED<br></strong>We collect the majority of your
                    Personal Information directly from you through our Website and, under certain circumstances, via
                    email, a form on our Website or by telephone. With respect to visitors to our offices, we may
                    collect Personal Information from you in person as well as by telephone, text, or email. We may also
                    collect your Personal Information directly from a third-party, including with your consent, as
                    required and appropriate.<br><br></li>
                <li><strong>PURPOSES OF COLLECTION<br></strong>Personal Information is collected for the following
                    purposes:<br>● To provide and/or maintain our products or services;<br>● To provide you with
                    information, products or services that you request from us;<br>● To allow you to participate in
                    interactive features of our Website and any related applications when you choose to do so;<br>● To
                    provide you with notices about your account and/or subscription, including expiration and renewal
                    notices, email instructions, etc.;<br>● To fulfill or meet any other reason for which the
                    information is provided;<br>● To provide you with email alerts, text message and other notices
                    concerning our products or services or news that may be of interest to you;<br>● To provide customer
                    support;<br>● For testing, research, analysis, development and improvement of product and service
                    offerings;<br>● To improve our Website and present its contents to you;<br>● To monitor the usage of
                    our Website or related applications;<br>● To host, store and otherwise process information needed
                    for business continuity and disaster recovery.<br>● To carry out our obligations and enforce our
                    rights arising from any contracts entered into between you and us, and to protect us, our employees,
                    clients, and the public against theft, legal liability, fraud, or abuse;<br>● To detect, prevent and
                    address technical issues;<br>● As necessary or appropriate to protect the rights, property or safety
                    of us, our clients or others, including system and network administration and security,
                    infrastructure monitoring, identity and credential management, verification and authentication, and
                    access control; monitoring and other controls needed to safeguard the security and integrity of
                    transactions and/or recordation of<br>information;<br>● To respond to law enforcement requests,
                    subpoenas, including to law enforcement agencies and courts in the United States and as required by
                    applicable law, court order, or governmental regulations;<br>● As described to you when collecting
                    your Personal Information; and<br>● To evaluate or conduct a merger, divestiture, restructuring,
                    reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a
                    going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal
                    Information held by us is among the assets transferred.</li>
            </ol>



            <p>We will not collect additional categories of Personal Information or use the Personal Information we
                collected for materially different, unrelated, or incompatible purposes without providing you notice.
            </p>



            <ol start="6">
                <li><strong>SHARING YOUR INFORMATION<br></strong>We may disclose or share some, or all, of the Personal
                    Information we collect about you to third parties such as service providers who aid us in processing
                    and protecting your Personal Information to host the Website or related applications, send email or
                    other communications, generate leads, process payments, provide analytics and advertising services,
                    or moderate online discussions. When a customer buys a product or a service from us, we may share
                    names, or other contact information that is necessary for the third party to provide products or
                    services in the United States, Canada, and other jurisdiction. Our service providers are given
                    Personal Information they need to perform their designated functions, and we do not authorize them
                    to use or disclose Personal Information for any other purposes.<br><br>We may disclose or share your
                    information if required to do so by law or in the good-faith belief that such action is necessary
                    to: (a) conform to the edicts of the law or comply with legal process served on us, (b) protect and
                    defend our rights or property or our customers or users, or (c) under exigent circumstances to
                    protect the personal safety of our users, customers, or the general public. We may disclose or share
                    your information to fulfill the purpose for which you provide it.<br><br>We may aggregate data we
                    acquire about our customers. If we aggregate such data and provide it to external parties, our
                    customers’ Personal Information will not be attached to or included in such aggregated data. Also,
                    in the event that all or a portion of our assets are acquired by or merged with a third-party
                    entity, we reserve the right, in any of these circumstances, to transfer or assign the information
                    that we have collected from you, including Personal Information and user data, in connection with
                    such merger, acquisition, sale, or other change of control.<br><br></li>
                <li><strong>COOKIES AND OTHER TECHNOLOGIES<br></strong>Cookies are small text files placed on your
                    device to store data that can be recalled by a web server in the domain that placed the cookie. We
                    may use cookies, web beacons, and other tracking technologies (such as scripts and tags) on our
                    online services. We collect information from cookies via third party technology platforms such as
                    HubSpot and Google Analytics. We also use cookies, web beacons, and other tracking technologies to
                    help our online services function properly, to collect information about how users interact and use
                    our online services, and to ensure the security of our network and related networks.<br><br>You have
                    a variety of tools to control the data collected by cookies and similar technologies. For example,
                    you can use controls in your internet browser to limit how the websites you visit are able to use
                    cookies, alert you about the cookies and to withdraw your consent by clearing or blocking
                    cookies.<br><br></li>
                <li><strong>LINKS TO OTHER WEBSITES<br></strong>Our Websites may contain links to other sites (“Third
                    Party Websites”). These links are provided solely for the convenience of our customers. We do not
                    necessarily endorse, sanction or verify the accuracy of the information contained on Third Party
                    Websites. Once you access a Third-Party Website through a link on our Website, you are no longer
                    covered by the privacy Notice of Our Website. Concerns regarding a Third-Party Website should be
                    directed to the Third-Party Website itself. We bear no responsibility for any action associated with
                    any Third-Party Website.<br><br></li>
                <li><strong>POLICIES FOR CHILDREN<br></strong>Our Website is not directed to individuals under the age
                    of 13, nor do they contain information which would be potentially harmful to minors in any way.
                    However, we advise all visitors to Our Websites under the age of 13 not to disclose or provide any
                    Personal Information. In the event that we discover that a child under the age of 13 has provided
                    Personal Information to us, we will delete the child’s Personal Information in accordance with the
                    Children’s Online Privacy Protection Act of 1998. Please see the Federal Trade Commission’s website
                    for this act.<br><br>Notwithstanding the foregoing, pursuant to 47 U.S.C. Section 230 (d), as
                    amended, we hereby notify you that parental control protections are commercially available to assist
                    you in limiting access to material that is harmful to minors. More information on the availability
                    of such software can be found through publicly available sources. You may wish to contact your
                    Internet Service Provider for more information.<br><br>We collect Personal Information on minors,
                    when provided by their parents or legal guardians, or by institutions or government entities that
                    have provided services to the minors, for the purposes of requesting services from us for the
                    benefit of such minors.<br><br></li>
                <li><strong>HOW LONG YOUR PERSONAL INFORMATION WILL BE KEPT<br></strong>We will retain and use your
                    Personal Information to the extent necessary to comply with our legal or contractual obligations and
                    to provide you with the products and services that you request from us. Thereafter, we may retain
                    your Personal Information only to the extent necessary to:<br><br>● To respond to any questions,
                    complaints or claims made by you or on your behalf;<br>● To provide services to you as contracted
                    for by you;<br>● To show that we treated you fairly; or<br>● To keep records required by
                    law.<br><br>We will not retain your Personal Information for longer than necessary for the purposes
                    set out in this Notice. Different retention periods apply for different types of Personal
                    Information. When it is no longer necessary to retain your Personal Information, we will delete or
                    anonymize it.<br><br></li>
                <li><strong>KEEPING YOUR PERSONAL INFORMATION SECURE<br></strong>We have implemented reasonable
                    technical, physical, and administrative security measures to prevent Personal Information from being
                    accidentally lost, used, or accessed in an unauthorized way. These measures offer appropriate
                    reasonable security level, taking into account the state of the technology, the implementation costs
                    and also the nature, the scale, the context and the processing purposes. We limit access to your
                    Personal Information to those who have a genuine business need to access it. Those processing your
                    information will do so only in an authorized manner and are subject to a duty of confidentiality.
                    The measures are also intended to prevent unnecessary collection and further processing of Personal
                    Information. The Personal Information is contained behind secured networks and is only accessible by
                    a limited number of persons who have special access rights to such systems, and are required to keep
                    the information confidential. We also have procedures in place to deal with any suspected data
                    security breach. We will notify you and any applicable regulator of a suspected data security breach
                    where we are legally required to do so.</li>
            </ol>



            <ol start="12">
                <li><strong>PLEASE KEEP YOUR DEVICES AND PASSWORDS SECURE<br></strong>Please keep your devices secure
                    and do not share devices, logins, or passwords to such devices with others as there is a risk that
                    they make access Personal Information that you stored on your device or that you request be
                    transmitted to you from us to your device. If you receive login information, including passwords, to
                    an encrypted account or application, please do not share this information with others. PLEASE SECURE
                    YOUR INFORMATION AND DO NOT DISCLOSE YOUR PASSWORDS AND ANY SENSITIVE INFORMATION TO ANYONE ELSE. If
                    you believe that your Personal Information was compromised while requesting or receiving services
                    from us, please notify us at <a href="mailto:info@scinomix.com">info@scinomix.com</a>.<br><br></li>
                <li><strong>YOUR AGREEMENT TO THIS NOTICE<br></strong>By using our online services, you are agreeing to
                    our practices described in this Notice, which includes the collection and use of your Personal
                    Information. Your continued use of our online services following the posting of changes to this
                    Notice will be deemed your acceptance of those changes.<br><br></li>
                <li><strong>CONTACT INFORMATION</strong><br>If you have any questions or comments about this Notice, or
                    the ways in which we collect and use your Personal Information, please do not hesitate to contact us
                    at: <br>By email: info@scinomix.com<br>By phone: 314.298.9800<br>By mail: <br>Scinomix, Inc.<br>4372
                    Green Ash Drive<br>Earth City, MO 63045-1213</li>
            </ol>

        </div>
    </div>
</div>