import { Component, Input } from '@angular/core';
import { CustomItem } from '../../models/custom-item.interface';

@Component({
  selector: 'app-bullet-feature-item-horizontal-icon-above',
  templateUrl: './bullet-feature-item-horizontal-icon-above.component.html',
  styleUrls: ['./bullet-feature-item-horizontal-icon-above.component.scss']
})
export class BulletFeatureItemHorizontalIconAboveComponent {
  @Input() customItem: CustomItem;
}
