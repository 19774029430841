
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';
import { PaginationState } from 'src/app/core/state/pagination.state';

export interface JobPostingState extends EntityState<JobPosting>, PaginationState {
  loading: boolean;
  error: any;
  formState: JobPosting | null;
}

export const adapter: EntityAdapter<JobPosting> = createEntityAdapter<JobPosting>();

export const initialState: JobPostingState = adapter.getInitialState({
  loading: false,
  error: null,
  total: 0,
  currentPage: 1,
  pageSize: 10,
  paginationInfo: null,
  formState: null, 
});
