import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { Link } from 'src/app/shared/models/link.interface';

@Component({
  selector: 'app-service-support',
  templateUrl: './service-support.component.html',
  styleUrls: ['./service-support.component.scss']
})
export class ServiceSupportComponent implements OnChanges {
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() bannerTitle: string;
  @Input() links: Link[] = [];
  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService, private router: Router, private route: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {

    const newMetaTags = {
      title: 'Services & Support',
      description: "Discover reliable and efficient Service & Support from Scinomix, your trusted lab automation solutions provider.Navigate through our Service Agreements, dive into our helpful FAQs, and utilize our effective Troubleshooting resources.We're committed to empowering your lab's smooth operations.",
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };

    this.metaTagsService.updateMetaTags(newMetaTags);
  }
}
