import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent {
  @Output() toggle = new EventEmitter<void>();

  onToggle() {
    // console.log('BurgerMenuComponent - onToggle() called');
    this.toggle.emit();
  }
}
