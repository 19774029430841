import { Component, OnInit } from '@angular/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { MetaTagsState } from 'src/app/features/seo/store/meta-tags.state';

@Component({
  selector: 'app-scinomix-experience',
  templateUrl: './scinomix-experience.component.html',
  styleUrls: ['./scinomix-experience.component.scss']
})
export class ScinomixExperienceComponent implements OnInit {
  constructor(private metaTagsService: MetaTagsService,) {

  }

  ngOnInit(): void {
    const newMetaTags = {
      title: 'The Scinomix Experience: Lab Automation Virtual Experience',
      description:
        'Experience groundbreaking VR and AR technologies. VR immerses you in a virtual world, while AR blends the digital with your reality. Use a VR headset or your smartphone for VR, and simply press a button for AR. No special equipment needed. Discover captivating and interactive ways to interact with our products. Dive into new environments or merge digital content with your space. Prepare to be amazed!',
      keywords:
        'laboratory automation solutions, lab equipment, lab productivity',
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

}
