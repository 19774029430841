import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VrViewerRoutingModule } from './vr-viewer-routing.module';
import { VrViewerStoreModule } from './store/vr-viewer-store.module';
import { VrViewerContainerComponent } from './containers/vr-viewer-container/vr-viewer-container.component';
import { VrViewerComponent } from './components/vr-viewer/vr-viewer.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { VrTourContainerComponent } from './containers/vr-tour-container/vr-tour-container.component';
import { VrTourComponent } from './components/vr-tour/vr-tour.component';

@NgModule({
  declarations: [
    VrViewerContainerComponent,
    VrViewerComponent,
    VrTourContainerComponent,
    VrTourComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    VrViewerRoutingModule,
    VrViewerStoreModule,
    TooltipModule
  ],
  exports: [
    VrViewerContainerComponent,
    VrTourContainerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VrViewerModule { }
