import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
@Component({
  selector: 'app-background-video-hero-container',
  templateUrl: './background-video-hero-container.component.html',
  styleUrls: ['./background-video-hero-container.component.scss']
})
export class BackgroundVideoHeroContainerComponent implements AfterViewInit {

  @ViewChild('textWrapper') textWrapper;

  @Input() videoSource: string;

  @Output() ctaButtonClick = new EventEmitter<void>();

  ngAfterViewInit(): void {
    this.animateText();
  }

  private animateText() {
    const textWrapper = document.querySelector('.ml11');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({ loop: true })
      .add({
        targets: '.ml11 .letter',
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        duration: 700,
        delay: (el, i) => 90 * (i + 1)
      })
      .add({
        targets: '.ml11',
        opacity: 0,
        duration: 700,
        easing: 'easeOutExpo',
        delay: 1000
      });
  }

  onCtaButtonClick() {
    this.ctaButtonClick.emit();
  }
} {

}
