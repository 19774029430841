import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, of, switchMap, tap } from 'rxjs';
import { Testimonial } from 'src/app/shared/models/testimonial.interface';
import { loadTestimonialsByProductSlug, loadTestimonials, resetTestimonials } from '../../store/testimonials.actions';
import { selectAllTestimonials } from '../../store/testimonials.selectors';

@Component({
  selector: 'app-testimonials-list-container',
  templateUrl: './testimonials-list-container.component.html',
  styleUrls: ['./testimonials-list-container.component.scss']
})
export class TestimonialsListContainerComponent implements OnChanges {
  testimonials$: Observable<Testimonial[]>;
  @Input() productSlug?: string;
  @Input() title: string = 'Customer';
  @Input() subhead: string = 'Testimonials';
  constructor(private store: Store) {
    // this.testimonials$ = this.store.select(selectAllTestimonials);
  }

  customOwlOptions: OwlOptions = {
    nav: false,
    margin: 25,
    loop: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    navText: [
      '<i class="flaticon-011-chevron-1"></i>',
      '<i class="flaticon-010-chevron"></i>'
    ],
    responsive: {
      0: {
        items: 1
      },
      515: {
        items: 1
      },
      695: {
        items: 2
      },
      935: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('productSlug' in changes) {
      this.store.dispatch(resetTestimonials());
      // If productSlug is available, dispatch action to load testimonials by product slug
      if (this.productSlug) {
        this.store.dispatch(loadTestimonialsByProductSlug({ slug: this.productSlug }));
      } else {
        // If no productSlug, dispatch action to load all testimonials

      }

      // Select testimonials from the store

    } else {
      this.store.dispatch(loadTestimonials());
    }

    this.testimonials$ = this.store.select(selectAllTestimonials);
  }


}
