import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SupportGuide } from 'src/app/shared/models/support-guide.interface';
import { AppState } from "src/app/store/app.state";
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as SupportGuideActions from '../../store/support-guide.actions';
import { selectSupportGuideByIdFactory } from '../../store/support-guide.selectors';

@Component({
  selector: 'app-troubleshooting-admin-manage-container',
  templateUrl: './troubleshooting-admin-manage-container.component.html',
  styleUrls: ['./troubleshooting-admin-manage-container.component.scss']
})
export class TroubleshootingAdminManageContainerComponent implements OnInit {
  supportGuide$: Observable<SupportGuide>;
  isEditMode: boolean = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private metaTagsService: MetaTagsService,
  ) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Check if the current URL is '/admin/post/create'
          if (this.router.url === '/admin/troubleshooting/create') {
            // Dispatch the clearPostFormState action
            this.store.dispatch(SupportGuideActions.clearSupportGuideFormState());
          }
        }
      });
    }

    ngOnInit(): void {
      this.route.params.subscribe(params => {
      const guideId = params['id'];
      let guideTitle = 'Create Troubleshooting Guide';
      if(guideId) {
        this.isEditMode = true;
        guideTitle = 'Edit Troubleshooting Guide';
        this.store.dispatch(SupportGuideActions.loadSupportGuideById({ id: guideId }));
        this.supportGuide$ = this.store.select(selectSupportGuideByIdFactory(guideId));
      } else {
        this.isEditMode = false;
        this.supportGuide$ = new Observable<SupportGuide>(); // Initialize with a new guide
      }

      const newMetaTags = {
        title: guideTitle,
        description: '',
        keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      };
      this.metaTagsService.updateMetaTags(newMetaTags);
    });

        
  }

  onSave(event: any): void {
    const product_slugs = event.productTags;
    this.store.dispatch(SupportGuideActions.saveSupportGuideFormState({ supportGuide: event.supportGuide}));
    if (this.isEditMode) {
      this.store.dispatch(SupportGuideActions.updateSupportGuide({ supportGuide: event.supportGuide, productSlugs: product_slugs, attachedPdf: event?.attachedPdf}));
    } else {
      this.store.dispatch(SupportGuideActions.createSupportGuide({ supportGuide: event.supportGuide, productSlugs: product_slugs, attachedPdf: event?.attachedPdf }));
    }
    this.store.dispatch(SupportGuideActions.clearSupportGuideFormState());
  }

  onCancel(): void {
    this.router.navigate(['/admin/troubleshooting-guides']);
    this.store.dispatch(SupportGuideActions.clearSupportGuideFormState());
  }

}
