import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { SolutionTagsEffects } from './solution-tags.effects';
import * as SolutionTags from './solution-tags.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(SolutionTags.solutionTagsFeatureKey, SolutionTags.SolutionTagsReducer),
        EffectsModule.forFeature([SolutionTagsEffects]),
    ],
})
export class SolutionTagsStoreModule { }
