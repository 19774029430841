import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiStepFormRoutingModule } from './multi-step-form-routing.module';
import { MultiStepFormStoreModule } from './store/multi-step-form-store.module';
import { MultiStepFormComponent } from './components/multi-step-form/multi-step-form.component';
import { MultiStepFormContainerComponent } from './containers/multi-step-form-container/multi-step-form-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StepOneComponent } from './components/step1.component';
import { StepTwoComponent } from './components/step2.component';
import { StepThreeComponent } from './components/step3.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyFieldInput } from '@ngx-formly/bootstrap/input';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { DynamicFormService } from 'src/app/core/services/dynamic-form.service';

@NgModule({
  declarations: [MultiStepFormComponent, MultiStepFormContainerComponent, StepOneComponent,
    StepTwoComponent, StepThreeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MultiStepFormRoutingModule,
    MultiStepFormStoreModule,
    FormlyModule.forRoot({
      types: [
        { name: 'input', component: FormlyFieldInput },
      ]
    }),
    FormlyBootstrapModule
  ],
  exports: [
    MultiStepFormRoutingModule,
    MultiStepFormStoreModule,
    MultiStepFormComponent,
    MultiStepFormContainerComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent
  ],
  providers: [DynamicFormService,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent],
})
export class MultiStepFormModule { }
