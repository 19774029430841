import { Injectable, RendererFactory2, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  dataLayerPush(value: any): void {
    const script = this.renderer.createElement('script');
    script.text = `
      if(!window.dataLayer){
        window.dataLayer = [];
      }
      window.dataLayer.push(${JSON.stringify(value)});
    `;
    this.renderer.appendChild(this.document.head, script);
  }
}
