import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { SolutionsEffects } from './solutions.effects';
import { solutionsFeatureKey, solutionsReducer } from './solutions.reducer';


@NgModule({
    imports: [
        StoreModule.forFeature(solutionsFeatureKey, solutionsReducer),
        EffectsModule.forFeature([SolutionsEffects]),
    ],
})
export class SolutionsStoreModule { }