// import { Injectable } from '@angular/core';
// import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable()
// export class CacheBusterInterceptor implements HttpInterceptor {
//     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//         // Extract the path from the full URL, removing the domain
//         const url = new URL(req.url, window.location.origin);
//         const path = url.pathname;
//         // Check if the URL is for API or assets and it's a GET request
//         if (req.method === 'GET' && (path.startsWith('/api/v1') || path.startsWith('/assets'))) {
//             const currentTimeStamp = Date.now();
//             const modifiedReq = req.clone({
//                 params: req.params.append('cb', currentTimeStamp.toString()),
//                 url: path  // Use the normalized path for the request
//             });
//             return next.handle(modifiedReq);
//         }
//         return next.handle(req);
//     }
// }


// import { Injectable } from '@angular/core';
// import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable()
// export class CacheBusterInterceptor implements HttpInterceptor {
//     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//         // Normalize the URL by removing the leading slash if present
//         const normalizedUrl = req.url.startsWith('/') ? req.url.slice(1) : req.url;

//         // Check for GET requests and the normalized URLs starting with 'api/v1' or 'assets'
//         if (req.method === 'GET' && (normalizedUrl.startsWith('api/v1') || normalizedUrl.startsWith('assets'))) {
//             const currentTimeStamp = Date.now();
//             const modifiedReq = req.clone({
//                 params: req.params.set('cb', currentTimeStamp.toString()),
//             });
//             return next.handle(modifiedReq);
//         }
//         return next.handle(req);
//     }
// }

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CacheBusterInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Extract the path from the URL to handle various domain scenarios
        const url = new URL(req.url, window.location.origin);
        const path = url.pathname;

        // Check if the path matches '/api/v1' at any position after domain
        if (req.method === 'GET' && (path.includes('/api/v1'))) {
            const currentTimeStamp = Date.now();
            // Update the 'cd' parameter with the current timestamp, ensuring it's always fresh
            const modifiedReq = req.clone({
                params: req.params.set('cb', currentTimeStamp.toString())
            });

            return next.handle(modifiedReq);
        }

        // For requests that do not match the criteria, pass them through unchanged
        return next.handle(req);
    }
}
