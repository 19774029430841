import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationInfo } from '@fluentllc/core';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';

@Component({
  selector: 'app-job-posting-admin-list',
  templateUrl: './job-posting-admin-list.component.html',
  styleUrls: ['./job-posting-admin-list.component.scss']
})
export class JobPostingAdminListComponent {
  @Input() jobPostings: JobPosting[];
  @Input() paginationInfo: PaginationInfo;
  @Output() edit = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();
  @Output() create = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();

  onPageChange(newPage: number): void {
    this.pageChanged.emit(newPage);
  }

  onItemsPerPageChange(paginateBy: number): void {
    this.itemsPerPageChanged.emit(paginateBy);
  }
}
