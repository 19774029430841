<ng-container *ngIf="benefitsArray?.length; else showBenefits">
    <h5>Superior Results</h5>
    <ul>
      <li *ngFor="let benefit of benefitsArray; let i = index">
        <strong *ngIf="benefit.title">{{benefit.title}}&mdash;</strong>{{ benefit.description }}
      </li>
    </ul>
  </ng-container>
  
  <ng-template #showBenefits>
    <h5 *ngIf="benefits?.length">Superior Results</h5>
    <ul *ngIf="benefits?.length">
      <li *ngFor="let benefit of benefits; index as i">
        {{ benefit.name }}
      </li>
    </ul>
  </ng-template>
  