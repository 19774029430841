import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Layout } from 'src/app/shared/models/layout.interface';
import * as fromLayoutActions from '../../store/layout.actions';

@Component({
  selector: 'app-layout-admin-manage-container',
  templateUrl: './layout-admin-manage-container.component.html',
  styleUrls: ['./layout-admin-manage-container.component.scss']
})
export class LayoutAdminManageContainerComponent {

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  onCancel(): void {
    this.router.navigate(["/admin/landing-pages/layouts"]);
  }

  onSave(layout: Layout): void {
    console.log(layout);
    this.store.dispatch(fromLayoutActions.addLayout({ layout }));
  }

}
