import { Injectable, Inject, PLATFORM_ID, RendererFactory2, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { DataLayerService } from './data-layer.service';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private isBrowser: boolean;
  private dataLayer: object[];
  private renderer: Renderer2;
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject('gtmId') private gtmId: string,
    private dataLayerService: DataLayerService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.renderer = this.rendererFactory.createRenderer(null, null);
    // Initialize dataLayer on the server to prevent undefined errors
    if (this.isBrowser) {
      this.init(this.gtmId);
      this.addGtmToDom();
    }
  }

  public init(gtmId: string): void {
    // if dataLayer already initialized, return
    if (this.isBrowser && this.document['dataLayer']) return;

    this.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.id': gtmId });
  }

  public push(obj: object): void {
    this.dataLayerService.dataLayerPush(obj);
  }

  private addGtmToDom(): void {
    if (this.document.getElementById('gtm-script')) {
      return;
    }
    
    const script = this.document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.gtmId}');`;
    this.renderer.setAttribute(script, 'async', '');
    this.renderer.appendChild(this.document.head, script);

    const noscript = this.document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    this.document.body.appendChild(script);
    this.document.body.appendChild(noscript);
  }
}
