import { NgModule } from "@angular/core";
import { LayoutModule } from "./layout/layout.module";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { AuthModule } from "@fluentllc/core";
import { UserModule } from "./user/user.module";
// import { MembershipModule } from "./membership/membership.module";
import { TenantsModule } from "@fluentllc/core";
import { DashboardModule } from "./dashboard/dashboard.module";
import { HomeModule } from "./home/home.module";
import { AppointmentSchedulerModule } from "./appointment-scheduler/appointment-scheduler.module";
import { SEOModule } from "./seo/seo.module";
import { ProductsModule } from "./products/products.module";
import { TestimonialsModule } from "./testimonials/testimonials.module";
import { ROICalculatorModule } from "./roi-calculator/roi-calculator.module";
import { ServiceSupportModule } from "./service-support/service-support.module";
import { CareersModule } from "./careers/careers.module";
import { DistributorsModule } from "./distributors/distributors.module";
import { SearchModule } from "./search/search.module";
import { VrViewerModule } from "./vr-viewer/vr-viewer.module";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ProductsBySolutionModule } from "./products-by-solution/products-by-solution.module";
import { PagesModule } from "./pages/pages.module";
import { LandingPagesModule } from "./landing-pages/landing-pages.module";
import { ScinomixAdminModule } from "./scinomix-admin/scinomix-admin.module";
import { TaggingModule } from "./tagging/tagging.module";
@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    LayoutModule,
    DashboardModule,
    // MembershipModule,
    TaggingModule,
    HomeModule,
    AppointmentSchedulerModule,
    SEOModule,
    ProductsModule,
    ProductsBySolutionModule,
    TestimonialsModule,
    ROICalculatorModule,
    ServiceSupportModule,
    CareersModule,
    DistributorsModule,
    SearchModule,
    VrViewerModule,
    CarouselModule,
    PagesModule,
    LandingPagesModule,
    ScinomixAdminModule
  ],
  exports: [LayoutModule, RouterModule, SEOModule],

})
export class FeaturesModule { }
