import { Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SettingsService } from "src/app/core/services/settings.service";

@Component({
  selector: "app-top-header",
  templateUrl: "./top-header.component.html",
  styleUrls: ["./top-header.component.scss"],
})
export class TopHeaderComponent {
  bxEnvelopeOpenIcon: SafeHtml;
  bxsPhoneIcon: SafeHtml;
  bxWrenchIcon: SafeHtml;
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer, public settings: SettingsService) {
    this.httpClient.get("assets/icons/bx-envelope-open.svg", { responseType: "text" }).subscribe((data) => {
      const svgWithTitle = data
        .replace("<svg", '<svg aria-labelledby="iconTitle" ')
        .replace("<title>", '<title id="iconTitle">');
      this.bxEnvelopeOpenIcon = this.sanitizer.bypassSecurityTrustHtml(svgWithTitle);
    });
    this.httpClient.get("assets/icons/bxs-phone.svg", { responseType: "text" }).subscribe((data) => {
      const svgWithTitle = data
        .replace("<svg", '<svg aria-labelledby="iconTitle" ')
        .replace("<title>", '<title id="iconTitle">');
      this.bxsPhoneIcon = this.sanitizer.bypassSecurityTrustHtml(svgWithTitle);
    });
    this.httpClient.get("assets/icons/bx-wrench.svg", { responseType: "text" }).subscribe((data) => {
      const svgWithTitle = data
        .replace("<svg", '<svg aria-labelledby="iconTitle" class="svg-down" ')
        .replace("<title>", '<title id="iconTitle">');
      this.bxWrenchIcon = this.sanitizer.bypassSecurityTrustHtml(svgWithTitle);
    });
  }
}
