<div class="d-flex align-items-center">
    <div class="flex-column w-100">
        <h4 [innerHTML]="'FAQ: ' + result._highlightResult.question?.value"></h4>
        <p [innerHTML]="result._highlightResult.answer?.value | highlightedResult"></p>
    </div>
    <div class="ms-auto">
        <div [routerLink]="['/services-support/faq']" target="_blank" class="btn btn-info btn-sm text-nowrap"
            style="margin-left: 20px;">
            Browse FAQ Section
        </div>
    </div>
</div>