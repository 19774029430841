import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { ProductsEffects } from './products.effects';
import { productsFeatureKey, productsReducer } from './products.reducer';


@NgModule({
  imports: [
    StoreModule.forFeature(productsFeatureKey, productsReducer),
    EffectsModule.forFeature([ProductsEffects]),
  ],
})
export class ProductsStoreModule { }