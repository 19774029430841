import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { DemoRequestEffects } from './appointment-scheduler.effects';
import * as appointmentScheduler from './appointment-scheduler.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(appointmentScheduler.demoRequestFeatureKey, appointmentScheduler.demoRequestReducer),
    EffectsModule.forFeature([DemoRequestEffects]),
  ],
})
export class AppointmentSchedulerStoreModule { }