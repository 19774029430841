import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeAll, mergeMap, switchMap, tap } from "rxjs/operators";
import { forkJoin, from, of } from "rxjs";
import * as ProductsActions from "./products.actions";
import { ProductsApiService } from "src/app/core/services/products/products-api.service";
import * as OptionHashActions from "./option-hash.actions";
import { Router } from "@angular/router";
import { AlertService } from "src/app/core/services/alert.service";
import { Store } from "@ngrx/store";

@Injectable()
export class ProductsEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadProducts),
      mergeMap(() =>
        this.productsApiService.getProducts().pipe(
          map((products) => ProductsActions.loadProductsSuccess({ products })),
          catchError((error) => of(ProductsActions.loadProductsFailure({ error })))
        )
      )
    )
  );

  loadProduct$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProductsActions.loadProduct),
    mergeMap((action) => {
      // console.log('loadProduct action triggered with slug:', action.slug); // Debugging line
      return this.productsApiService.getProduct(action.slug).pipe(
        switchMap((currentProduct) => {
          // console.log('Product fetched successfully:', currentProduct); // Debugging line
          return of(ProductsActions.loadProductSuccess({ currentProduct }));
        }),
        catchError((error) => {
          // console.error('Error fetching product:', error); // Debugging line
          return of(ProductsActions.loadProductFailure({ error }))
        })
      )
    })
  )
);

  loadPaginatedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadPaginatedProducts),
      switchMap((action) =>
        this.productsApiService.getPaginatedProducts(action.page, action.limit).pipe(
          // tap((response) => console.log(response)),
          map((response) =>
            ProductsActions.loadPaginatedProductsSuccess({
              products: response.products,
              total: parseInt(response.pagination?.count || "0", 10),
              paginationInfo: response.pagination,
            })
          ),
          catchError((error) => of(ProductsActions.loadPaginatedProductsFailure({ error })))
        )
      )
    )
  );

  createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.createProduct),
      switchMap((action) => {
        return this.productsApiService.createProduct(action.product).pipe(
          map((product) => ProductsActions.createProductSuccess({ product })),
          catchError((error) => of(ProductsActions.createProductFailure({ error })))
        );
      }),
      tap({
        next: () => {
          this.router.navigate(["/admin/products"]); // Adjust the URL as needed
          this.alertService.show({ message: "Product successfully added", type: "success" });
        },
        error: (error) => {
          console.error("Error creating product:", error);
          this.alertService.show({ message: "Error creating product.", type: "danger" });
        },
      })
    )
  );

  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.updateProduct),
      switchMap((action) => {
        return this.productsApiService.updateProduct(action.product, action.imageFiles).pipe(
          map((product) => ProductsActions.updateProductSuccess({ product })),
          catchError((error) => of(ProductsActions.updateProductFailure({ error })))
        );
      }),
      tap((result) => {
        if (result.type === ProductsActions.updateProductSuccess.type) {
          this.store.dispatch(ProductsActions.clearSelectedProduct());
          this.router.navigate(["/admin/products"]); // Adjust the URL as needed
          this.alertService.show({ message: "Update successful!", type: "info" });
        }
      })
    )
  );

  deleteProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.deleteProduct),
      switchMap((action) =>
        this.productsApiService.deleteProduct(action.productId).pipe(
          map(() => ProductsActions.deleteProductSuccess({ productId: action.productId })),
          catchError((error) => of(ProductsActions.deleteProductFailure({ error })))
        )
      )
    )
  );

  loadProductsBySolution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadProductsBySolution),
      mergeMap((action) =>
        this.productsApiService.getProductsBySolution(action.solution).pipe(
          map((products) => ProductsActions.loadProductsBySolutionSuccess({ products })),
          catchError((error) => of(ProductsActions.loadProductsBySolutionFailure({ error })))
        )
      )
    )
  );

  loadProductsByKeyword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadProductsByKeyword),
      mergeMap((action) =>
        this.productsApiService.getProductsByKeywords(action.keyword).pipe(
          map((products) => ProductsActions.loadProductsByKeywordSuccess({ products })),
          catchError((error) => of(ProductsActions.loadProductsByKeywordFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private productsApiService: ProductsApiService,
    private router: Router,
    private alertService: AlertService,
    private store: Store
  ) {}
}
