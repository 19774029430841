import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { of } from "rxjs";

import * as SupportGuideActions from "./support-guide.actions";
import { SupportGuideService } from "src/app/core/services/support-guide.service";
import { Router } from "@angular/router";
import { AlertService } from "src/app/core/services/alert.service";

@Injectable()
export class SupportGuideEffects {
  loadSupportGuides$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SupportGuideActions.loadSupportGuides),
        mergeMap(() =>
          this.supportGuideService.getSupportGuides().pipe(
            // tap((supportGuides) => console.log(supportGuides)),
            map((supportGuides) => SupportGuideActions.loadSupportGuidesSuccess({ supportGuides })),
            catchError((error) => of(SupportGuideActions.loadSupportGuidesFailure({ error })))
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  loadPaginatorSupportGuides$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupportGuideActions.loadPaginatedSupportGuides),
      mergeMap((action) =>
        this.supportGuideService.getPaginatorSupportGuides(action.page, action.limit).pipe(
          map((response) =>
            SupportGuideActions.loadPaginatedSupportGuidesSuccess({
              supportGuides: response.supportGuides,
              total: parseInt(response.pagination.count, 10),
              paginationInfo: response.pagination,
            })
          ),
          catchError((error) => of(SupportGuideActions.loadPaginatedSupportGuidesFailure({ error })))
        )
      )
    )
  );

  loadSupportGuidesById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupportGuideActions.loadSupportGuideById),
      mergeMap((action) =>
        this.supportGuideService.getSupportGuideById(action.id).pipe(
          map((supportGuide) => SupportGuideActions.loadSupportGuideByIdSuccess({ supportGuide })),
          catchError((error) => of(SupportGuideActions.loadSupportGuideByIdFailure({ error })))
        )
      )
    )
  );

  createSupportGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupportGuideActions.createSupportGuide),
      mergeMap((action) =>
        this.supportGuideService.createSupportGuide(action.supportGuide, action.productSlugs, action.attachedPdf).pipe(
          map((supportGuide) => {
            return SupportGuideActions.createSupportGuideSuccess({ supportGuide });
          }),
          catchError((error) => of(SupportGuideActions.createSupportGuideFailure({ error })))
        )
      ),
      tap({
        next: () => {
          this.router.navigate(["/admin/troubleshooting-guides"]);
          this.alertService.show({ message: "Support Guide created successfully", type: "success" });
        },
        error: (error) => {
          this.alertService.show({ message: "Error creating support guide", type: "danger" });
        },
      })
    )
  );

  updateSupportGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupportGuideActions.updateSupportGuide),
      mergeMap((action) =>
        this.supportGuideService.updateSupportGuide(action.supportGuide, action.productSlugs, action.attachedPdf).pipe(
          // tap((supportGuide) => console.log(supportGuide)),
          map((supportGuide) => {
            return SupportGuideActions.updateSupportGuideSuccess({ supportGuide: supportGuide, productSlugs: action?.productSlugs, attachedPdf: action?.attachedPdf});
          }),
          catchError((error) => of(SupportGuideActions.updateSupportGuideFailure({ error })))
        )
      ),
      tap({
        next: () => {
          this.router.navigate(["/admin/troubleshooting-guides"]);
          this.alertService.show({ message: "Support Guide updated successfully", type: "success" });
        },
        error: (error) => {
          this.alertService.show({ message: "Error updating support guide", type: "danger" });
        },
      })
    )
  );

  deleteSupportGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupportGuideActions.deleteSupportGuide),
      mergeMap((action) =>
        this.supportGuideService.deleteSupportGuide(action.id).pipe(
          map(() => {
            return SupportGuideActions.deleteSupportGuideSuccess({ id: action.id });
          }),
          catchError((error) => of(SupportGuideActions.deleteSupportGuideFailure({ error })))
        )
      ),
      tap({
        next: () => {
          this.alertService.show({ message: "Support Guide deleted successfully", type: "success" });
        },
        error: (error) => {
          this.alertService.show({ message: "Error deleting support guide", type: "danger" });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private supportGuideService: SupportGuideService,
    private router: Router,
    private alertService: AlertService
  ) {}
}
