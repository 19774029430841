import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as BlogActions from './blog.actions';
import { BlogService } from 'src/app/core/services/blog.service';


@Injectable()
export class BlogEffects {
    loadBlogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BlogActions.loadBlogs),
            switchMap(() =>
                this.blogService.getBlogs().pipe(
                    map((blogs) => BlogActions.loadBlogsSuccess({ blogs })),
                    catchError((error) => of(BlogActions.loadBlogsFailure({ error }))),
                ),
            ),
        ),
    );
    constructor(private actions$: Actions, private blogService: BlogService) { }
}
