import { createAction, props } from '@ngrx/store';
import { Distributor } from 'src/app/shared/models/distributor.interface';

export const loadDistributors = createAction('[Distributor] Load Distributors');
export const loadDistributorsSuccess = createAction(
    '[Distributor] Load Distributors Success',
    props<{ distributors: Distributor[] }>()
);
export const loadDistributorsFailure = createAction(
    '[Distributor] Load Distributors Failure',
    props<{ error: any }>()
);

export const loadDistributorById = createAction(
    '[Distributor] Load Distributor By ID',
    props<{ id: number }>()
);

export const loadDistributorByIdSuccess = createAction(
    '[Distributor] Load Distributor By ID Success',
    props<{ distributor: Distributor }>()
);

export const loadDistributorByIdFailure = createAction(
    '[Distributor] Load Distributor By ID Failure',
    props<{ error: any }>()
);
