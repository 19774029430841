import { Component } from '@angular/core';
import { Link } from 'src/app/shared/models/link.interface';

@Component({
  selector: 'app-services-support-sidebar-navigation-container',
  templateUrl: './services-support-sidebar-navigation-container.component.html',
  styleUrls: ['./services-support-sidebar-navigation-container.component.scss']
})
export class ServicesSupportSidebarNavigationContainerComponent {
  links: Link[] = [
    { name: 'Service Agreements', url: '/services-support/service-agreement', target: '_self', icon: 'home-icon' },
    { name: 'FAQs', url: '/services-support/faq', target: '_self', icon: 'about-icon' },
    { name: 'Troubleshooting', url: '/services-support/troubleshooting', target: '_self', icon: 'contact-icon' },
  ];
  menuTitle: string = 'Services & Support Links';
}
