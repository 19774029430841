import { createAction, props } from "@ngrx/store";
import { ProductSlug } from "src/app/shared/models/product-slug.interface";
import { Tag } from "src/app/shared/models/tag.interface";

export const loadProductTags = createAction("[ProductTags] Load ProductTags");
export const loadProductTagsSuccess = createAction(
  "[ProductTags] Load ProductTags Success",
  props<{ productTags: Tag[] }>()
);
export const loadProductTagsFailure = createAction("[ProductTags] Load ProductTags Failure", props<{ error: any }>());
