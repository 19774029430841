import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadSchema, updateSchema } from '../../store/schema.actions';
import { selectSchema, selectSchemaLoading, selectSchemaError } from '../../store/schema.selectors';
import { Schema } from 'src/app/shared/models/schema.interface';

@Component({
  selector: 'app-schema-container',
  template: `
    <app-schema
      [schema]="schema$ | async"
      [loading]="loading$ | async"
      [error]="error$ | async"
      (updateSchema)="onUpdateSchema($event)"
    ></app-schema>
  `,
})
export class SchemaContainerComponent implements OnInit {
  schema$: Observable<Schema | null>;
  loading$: Observable<boolean>;
  error$: Observable<any>;

  constructor(private store: Store) { }

  ngOnInit() {
    this.schema$ = this.store.select(selectSchema);
    this.loading$ = this.store.select(selectSchemaLoading);
    this.error$ = this.store.select(selectSchemaError);

    this.store.dispatch(loadSchema());
  }

  onUpdateSchema(schema: Schema) {
    this.store.dispatch(updateSchema({ schema }));
  }
}
