import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SearchItem } from 'src/app/shared/models/search-item.interface';
import { SearchMetadata } from 'src/app/shared/models/search-metadata.interface';


export interface SearchState {
    query: string;
    results: SearchItem[];
    metadata: SearchMetadata;
    error: any;
    isLoading: boolean;
}

export const initialState: SearchState = {
    query: '',
    results: [],
    metadata: null, // initialize metadata to null
    error: null,
    isLoading: false
};

