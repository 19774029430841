<div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="emitAction('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ content }}</p>
  </div>
  <div class="modal-footer">
    <button *ngFor="let btn of buttons" type="button" [ngClass]="btn.cssClass" (click)="emitAction(btn.action)">
      {{ btn.label }}
    </button>
  </div>
  