import { Component, Input, OnInit } from '@angular/core';
import { TagsComponent } from '../tags/tags.component';
import { Store, select } from '@ngrx/store';
import { AppState } from '@fluentllc/core';
import { FormBuilder } from '@angular/forms';
import * as fromProductTags from '../../store/product-tags.actions';
import { selectAllProductTags } from '../../store/product-tags.selectors';

@Component({
  selector: 'app-product-tags',
  templateUrl: '../tags/tags.component.html',
  styleUrls: ['../tags/tags.component.scss']
})
export class ProductTagsComponent extends TagsComponent implements OnInit {
  @Input() tags: string[] = [];
  @Input() useSlugs: boolean = false
  constructor(private productsTagStore: Store<AppState>, fb: FormBuilder) {
    super(fb, productsTagStore);
  }

  ngOnInit(): void {
    this.initialSlugs = this.tags
    // Dispatch action to load solution tags
    this.productsTagStore.dispatch(fromProductTags.loadProductTags());
    this.title = "Associated Products";
    // Subscribe to the solution tags slice and update the available tags
    // this.solutionTagStore.pipe(select(selectAllSolutionTags)).subscribe(allTags => {
      // this.availableTags = allTags;
    // });
    this.productsTagStore.pipe(select(selectAllProductTags)).subscribe(allTags => {
      this.availableTags = allTags;
    });
    super.ngOnInit();
    
  }

}
