import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { SupportGuide } from 'src/app/shared/models/support-guide.interface';
import * as SupportGuideActions from '../../store/support-guide.actions';
import { selectSupportGuideFormState } from '../../store/support-guide.selectors';
import { AlertService } from 'src/app/core/services/alert.service';

function pdfValidator(control: FormControl): {[key: string]: any} | null {
  // First, check if the control value is indeed a File object
  if (control.value && control.value instanceof File) {
    const file: File = control.value;
    const extension = file.name.split('.').pop().toLowerCase();
    return extension === 'pdf' ? null : { 'notPdf': true };
  }
  // If there's no file, or the file is not valid, return null to indicate no error
  return null;
}



@Component({
  selector: 'app-troubleshooting-admin-manage',
  templateUrl: './troubleshooting-admin-manage.component.html',
  styleUrls: ['./troubleshooting-admin-manage.component.scss']
})
export class TroubleshootingAdminManageComponent implements OnInit {
  @Input() supportGuide: SupportGuide;
  @Output() save = new EventEmitter<{supportGuide: SupportGuide, productTags: string[], attachedPdf: File | null}>();
  @Output() cancel = new EventEmitter<void>();
  isEditMode: boolean = false;
  supportGuideForm: FormGroup;
  public editorDisabled = false;
  isSettingValue = false;
  attached_pdf: File | null = null;
  productTagSlugs: string[] = [];
  useSlugs = true;
  get f() {
    return this.supportGuideForm.controls;
  }

  

  constructor(private fb: FormBuilder, private store: Store, private alert: AlertService) {
    this.supportGuideForm = this.fb.group({
      title: new FormControl(this.supportGuide?.title, Validators.required),
      part_number: new FormControl(this.supportGuide?.part_number, Validators.required),
      id: new FormControl(this.supportGuide?.id),
      attached_pdf: new FormControl(null, [pdfValidator])
    });
  }

  setEditModeValidators(): void {
    this.supportGuideForm.get('attached_pdf').clearValidators();
    this.supportGuideForm.get('attached_pdf').setValidators([pdfValidator]);
    this.supportGuideForm.get('attached_pdf').updateValueAndValidity();
  }

  setNewModeValidators(): void {
    this.supportGuideForm.get('attached_pdf').setValidators([Validators.required, pdfValidator]);
    this.supportGuideForm.get('attached_pdf').updateValueAndValidity();
  }

  onFileChange(event: any, type: string): void {
    const file = event.target.files[0];
    if (type === 'attached_pdf') {
      // Validate the file type before setting
      if (file.type !== 'application/pdf') {
        this.supportGuideForm.controls['attached_pdf'].setErrors({ 'notPdf': true });
        // Optionally, reset the file input or inform the user
        this.attached_pdf = null;
        this.alert.show({message: 'Only PDF files are allowed.', type: 'danger', position: 'top-right', dismissable: true, autoClose: true, duration: 5000});
      } else {
        this.attached_pdf = file;
        this.supportGuideForm.controls['attached_pdf'].setValue(file);
        this.supportGuideForm.controls['attached_pdf'].setErrors(null); // Clear any existing errors
      }
    } 
  }
  

  onProductTagsChange(productTagSlugs: string[] | number[]) {
    if (typeof productTagSlugs[0] === 'string') {
      // Safe to treat as string[] now
      this.productTagSlugs = productTagSlugs as string[];
    }
  }

  ngOnInit(): void {
    if (this.supportGuide && this.supportGuide.id) {
      this.productTagSlugs = this.supportGuide.products;
      this.isEditMode = true;
      this.setEditModeValidators();
      this.supportGuideForm.patchValue({
        id: this.supportGuide.id,
        title: this.supportGuide.title,
        part_number: this.supportGuide.part_number
      });
    } else {
      this.setNewModeValidators();
    }

    this.supportGuideForm.valueChanges.subscribe((supportGuide) => {
      if(!this.isSettingValue) {
        this.store.dispatch(SupportGuideActions.saveSupportGuideFormState({ supportGuide }));
      }
    });

    this.store.dispatch(SupportGuideActions.loadSupportGuideFormState());

    this.store.pipe(select(selectSupportGuideFormState)).subscribe((formState) => {
      if (formState) {
        this.isSettingValue = true;
        this.supportGuideForm.patchValue(formState);
        this.isSettingValue = false;
      }
    });
  }

  onSave()  {
    if (this.supportGuideForm.valid) {
      this.save.emit({supportGuide: this.supportGuideForm.value, productTags: this.productTagSlugs, attachedPdf: this.attached_pdf});
    }
  }

  

  onCancel() {
    this.cancel.emit();
  }
}
