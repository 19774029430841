// offsidebar-container.component.ts
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentConfig } from '../../interfaces/component-config.interface';
import { OffsidebarState } from '../../store/offsidebar/offsidebar.state';

@Component({
  selector: 'app-offsidebar-container',
  templateUrl: './offsidebar-container.component.html',
  styleUrls: ['./offsidebar-container.component.scss'],
})
export class OffsidebarContainerComponent {
  isVisible: boolean;
  componentConfig: ComponentConfig;

  constructor(private store: Store<{ offsidebar: OffsidebarState }>) {
    store.select('offsidebar').subscribe((state) => {
      this.isVisible = state?.isVisible;
      this.componentConfig = state?.componentConfig;
    });
  }
}
