import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { JobApplicationEffects } from './job-application.effects';
import * as jobApplications from './job-application.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(jobApplications.jobApplicationFeatureKey, jobApplications.jobApplicationReducer),
        EffectsModule.forFeature([JobApplicationEffects]),
    ],
})
export class JobApplicationsStoreModule { }
