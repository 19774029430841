// store/selectors/testimonials.selectors.ts
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { testimonialsFeatureKey } from './testimonials.reducer';
import { TestimonialsState } from './testimonials.state';


export const selectTestimonialsState = createFeatureSelector<TestimonialsState>(testimonialsFeatureKey);

export const selectAllTestimonials = createSelector(
    selectTestimonialsState,
    (state: TestimonialsState) => state.testimonials
);

export const selectIsLoadingTestimonials = createSelector(
    selectTestimonialsState,
    (state: TestimonialsState) => state.loading
);