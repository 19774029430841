import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription, take } from "rxjs";
import { Product } from "../../models/product.interface";
import { PaginationInfo } from "@fluentllc/core";
import { Store, select } from "@ngrx/store";
import { Router } from "@angular/router";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import { AlertService } from "src/app/core/services/alert.service";
import { ModalService } from "src/app/core/services/modal.service";
import { AppState } from "src/app/store/app.state";
import { selectAllProducts, selectPageSize, selectPaginationInfo, selectProductsByKeyword } from "../../store/products.selectors";
import * as ProductActions from "../../store/products.actions";
import * as MediaActions from "../../store/media.actions";
import { loadProductClasses } from "../../store/product-class.actions";

@Component({
  selector: "app-product-admin-list-container",
  templateUrl: "./product-admin-list-container.component.html",
  styleUrls: ["./product-admin-list-container.component.scss"],
})
export class ProductAdminListContainerComponent implements OnInit, OnDestroy{
  products$: Observable<Product[]>;
  total$: Observable<number>;
  paginationInfo$: Observable<PaginationInfo>;
  currentPage: number = 1;
  pageSize: number = 10;
  pageSize$: Observable<number>;
  loading$: Observable<boolean>;
  keyword: string = '';
  private subscriptions: Subscription = new Subscription();
  
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private alertService: AlertService,
    private modalService: ModalService
  ) {
    this.store.dispatch(ProductActions.loadProducts());
  }

  applyFilters(): void {
    this.products$ = this.store.select(selectProductsByKeyword(this.keyword));
  }

  clearMedia() {
    this.store.dispatch(MediaActions.clearMedia());
  }

  onFilterChange(keyword: string): void {
    this.keyword = keyword;
    this.applyFilters();
  }

  ngOnInit(): void {
    this.store.dispatch(loadProductClasses())
    this.clearMedia();
    this.pageSize$ = this.store.select(selectPageSize);
    this.products$ = this.store.select(selectAllProducts);
    this.loadPaginatedProducts(this.currentPage);
    this.applyFilters();
    this.paginationInfo$ = this.store.select(selectPaginationInfo);

    this.subscriptions.add(
      this.store.select(selectPageSize).subscribe(pageSize => {
        this.pageSize = pageSize;
        this.loadPaginatedProducts(this.currentPage);
      })
    );

    const newMetaTags = {
      title: "Scinomix Products",
      description: "",
      keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  loadPaginatedProducts(page: number): void {
    this.store.dispatch(ProductActions.loadPaginatedProducts({ page: page, limit: this.pageSize }));
    this.paginationInfo$ = this.store.select(selectPaginationInfo);
  }

  onPageChange(newPage: number): void {
    // console.log(newPage);
    // this.currentPage = newPage;
    this.loadPaginatedProducts(newPage);
  }

  onChangePageSize(newSize: number): void {
    this.store.dispatch(ProductActions.updatePageSize({ pageSize: newSize }));
    this.loadPaginatedProducts(this.currentPage);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  async openCustomModal(productId: number) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Delete Product', cssClass: 'btn btn-danger', action: 'confirm' }
    ];

    const result = await this.modalService.openModal(
      'Confirm Deletion', 
      'Are you sure you want to delete this product? This action cannot be undone. Please confirm if you wish to permanently remove this product from your catalog.', 
      buttons
    );

    // Handle the result based on action
    switch (result) {
      case 'cancel':
        // Handle cancel
        break;
      case 'confirm':
        this.store.dispatch(ProductActions.deleteProduct({ productId }));
        break;
    }
  }

  onEdit(productSlug: string): void {
    // Navigation logic for editing a post
    this.router.navigate(["/admin/product/manage", productSlug]);
    
  }

  onDelete(postId: number): void {
    this.openCustomModal(postId)
  }

  onCreate(): void {
    this.router.navigate(["/admin/product/create"]);
  }
}
