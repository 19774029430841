import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import * as OptionHashs from './option-hash.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(OptionHashs.OptionHashFeatureKey, OptionHashs.OptionHashReducer),
        // EffectsModule.forFeature([OptionHashEffects]),
    ],
})
export class OptionHashsStoreModule { }
