import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProductTagsState } from "./product-tags.state";
import { productTagsFeatureKey } from "./product-tags.reducer";
import { adapter } from "./product-tags.state";


export const selectProductTagsState = createFeatureSelector<ProductTagsState>(productTagsFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectProductTagsIds = createSelector(
    selectProductTagsState,
    selectIds
);

export const selectProductTagsEntities = createSelector(
    selectProductTagsState,
    selectEntities
);

export const selectAllProductTags = createSelector(
    selectProductTagsState,
    selectAll
);

export const selectProductTagsTotal = createSelector(
    selectProductTagsState,
    selectTotal
);

export const selectProductTagById = (id: number) =>
    createSelector(
        selectProductTagsEntities,
        (entities) => entities[id]
    );

export const selectProductTagsLoading = createSelector(
    selectProductTagsState,
    (state) => state.loading
);

export const selectProductTagsError = createSelector(
    selectProductTagsState,
    (state) => state.error
);