import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CareersContainerComponent } from './containers/careers-container/careers-container.component';
import { JobApplicationContainerComponent } from './containers/job-application-container/job-application-container.component';
import { JobPostingDetailContainerComponent } from './containers/job-posting-detail-container/job-posting-detail-container.component';


const routes: Routes = [
  {
    path: "",
    component: CareersContainerComponent,
  },
  {
    path: 'job/:slug',
    component: JobPostingDetailContainerComponent
  },
  {
    path: 'job-application/:slug',
    component: JobApplicationContainerComponent
  },
  {
    path: 'job-application',
    redirectTo: '/about/employment',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CareersRoutingModule { }