import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogRoutingModule } from './blog-routing.module';
import { BlogStoreModule } from './store/blog-store.module';
import { BlogListContainerComponent } from './containers/blog-list-container/blog-list-container.component';
import { BlogDetailContainerComponent } from './containers/blog-detail-container/blog-detail-container.component';
import { BlogListComponent } from './components/blog-list/blog-list.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { SharedModule as AppSharedModule, SharedModule } from 'src/app/shared/shared.module';
import { SharedModule as FluentSharedModule}  from 'src/app/shared/shared.module';
import { PostStoreModule } from './store/post-store.module';
import { PostListContainerComponent } from './containers/post-list-container/post-list-container.component';
import { PostDetailContainerComponent } from './containers/post-detail-container/post-detail-container.component';
import { PostListComponent } from './components/post-list/post-list.component';
import { PostDetailComponent } from './components/post-detail/post-detail.component';
import { PostListWidgetComponent } from './components/post-list-widget/post-list-widget.component';
import { PostListWidgetContainerComponent } from './containers/post-list-widget-container/post-list-widget-container.component';
import { PostResolver } from './resolvers/post-resolver.service';
import { PostAdminListContainerComponent } from './containers/post-admin-list-container/post-admin-list-container.component';
import { PostAdminListComponent } from './components/post-admin-list/post-admin-list.component';
import { PostManageContainerComponent } from './containers/post-manage-container/post-manage-container.component';
import { PostManageComponent } from './components/post-manage/post-manage.component';
import { RouterModule } from '@angular/router';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    BlogListContainerComponent,
    BlogDetailContainerComponent,
    BlogListComponent,
    BlogDetailComponent,
    PostListContainerComponent,
    PostDetailContainerComponent,
    PostListComponent,
    PostDetailComponent,
    PostListWidgetComponent,
    PostListWidgetContainerComponent,
    PostAdminListContainerComponent,
    PostAdminListComponent,
    PostManageContainerComponent,
    PostManageComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    AppSharedModule,
    RouterModule,
    FluentSharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule,
    BlogRoutingModule,
    BlogStoreModule,
    PostStoreModule,
    BsDatepickerModule,
  ],
  exports: [PostListWidgetContainerComponent, PostManageContainerComponent],
  providers: [PostResolver]
})
export class BlogModule { }
