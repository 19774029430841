import { Component, Input, Output, EventEmitter } from '@angular/core';

interface ModalButtonConfig {
  label: string;
  cssClass: string;
  action: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() buttons: ModalButtonConfig[] = [];
  @Output() action = new EventEmitter<string>();

  emitAction(actionType: string): void {
    this.action.emit(actionType);
  }
}
