import { createAction, props } from '@ngrx/store';
import { PaginationInfo } from "@fluentllc/core";
import { SupportGuide} from 'src/app/shared/models/support-guide.interface';

export const loadSupportGuides = createAction('[SupportGuide] Load SupportGuides');
export const loadSupportGuidesSuccess = createAction(
  '[SupportGuide] Load SupportGuides Success',
  props < { supportGuides: SupportGuide[] }> ()
);
export const loadSupportGuidesFailure = createAction(
  '[SupportGuide] Load SupportGuides Failure',
  props<{ error: any }>()
);

export const loadSupportGuideById = createAction(
  '[SupportGuide] Load SupportGuide By Id',
  props<{ id: number }>()
);

export const loadSupportGuideByIdSuccess = createAction(
  '[SupportGuide] Load SupportGuide By Id Success',
  props<{ supportGuide: SupportGuide }>()
);

export const loadSupportGuideByIdFailure = createAction(
  '[SupportGuide] Load SupportGuide By Id Failure',
  props<{ error: any }>()
);

export const createSupportGuide = createAction(
  '[SupportGuide Admin] Create SupportGuide',
  props<{ supportGuide: SupportGuide, productSlugs?: string[], attachedPdf?: File }>()
);

export const createSupportGuideSuccess = createAction(
  '[SupportGuide Admin] Create SupportGuide Success',
  props<{ supportGuide: SupportGuide, productSlugs?: string[], attachedPdf?: File }>()
);

export const createSupportGuideFailure = createAction(
  '[SupportGuide Admin] Create SupportGuide Failure',
  props<{ error: any }>()
);

export const updateSupportGuide = createAction(
  '[SupportGuide Admin] Update SupportGuide',
  props<{ supportGuide: SupportGuide, productSlugs?: string[], attachedPdf?: File }>()
);

export const updateSupportGuideSuccess = createAction(
  '[SupportGuide Admin] Update SupportGuide Success',
  props<{ supportGuide: SupportGuide, productSlugs?: string[], attachedPdf?: File }>()
);

export const updateSupportGuideFailure = createAction(
  '[SupportGuide Admin] Update SupportGuide Failure',
  props<{ error: any }>()
);

export const deleteSupportGuide = createAction(
  '[SupportGuide Admin] Delete SupportGuide',
  props<{ id: number }>()
);

export const deleteSupportGuideSuccess = createAction(
  '[SupportGuide Admin] Delete SupportGuide Success',
  props<{ id: number }>()
);

export const deleteSupportGuideFailure = createAction(
  '[SupportGuide Admin] Delete SupportGuide Failure',
  props<{ error: any }>()
);

export const loadPaginatedSupportGuides = createAction(
  '[SupportGuide] Load Paginated SupportGuides',
  props<{ page: number; limit: number }>()
);

export const loadPaginatedSupportGuidesSuccess = createAction(
  '[SupportGuide] Load Paginated SupportGuides Success',
  props<{ supportGuides: SupportGuide[]; total: number, paginationInfo: PaginationInfo }>()
);

export const loadPaginatedSupportGuidesFailure = createAction(
  '[SupportGuide] Load Paginated SupportGuides Failure',
  props<{ error: any }>()
);

export const updateCurrentPage = createAction(
  '[SupportGuide] Update Current Page',
  props<{ page: number }>()
);

export const updatePageSize = createAction(
  '[SupportGuide] Update Page Size',
  props<{ size: number }>()
);

export const saveSupportGuideFormState = createAction(
  '[SupportGuide] Save SupportGuide Form State',
  props<{ supportGuide: SupportGuide }>()
);

export const loadSupportGuideFormState = createAction(
  '[SupportGuide] Load SupportGuide Form State'
);

export const clearSupportGuideFormState = createAction(
  '[SupportGuide] Clear SupportGuide Form State'
);
