import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardContainerComponent } from "./containers/dashboard-container/dashboard-container.component";
import { SharedModule } from "src/app/shared/shared.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [DashboardComponent, DashboardContainerComponent],
  imports: [SharedModule, DashboardRoutingModule, RouterModule],
})
export class DashboardModule {}
