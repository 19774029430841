<!-- Show error message -->
<!-- <div *ngIf="error">
    Error: {{ error }}
</div> -->

<!-- Show list of products -->
<ul *ngIf="!loading && !error">
    <li *ngFor="let product of products">
        {{ product.title }}
    </li>
</ul>