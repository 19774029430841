<div class="container">
    <div class="row">
        <div class="col-lg-8 col-sm-12">
            <h1>The Scinomix Experience</h1>
            <p>Experience groundbreaking VR and AR technologies. VR immerses you in a virtual world, while AR blends the
                digital with your reality. Use a VR headset or your smartphone for VR, and simply press a button for AR.
                No special equipment needed. Discover captivating and interactive ways to interact with our products.
                Dive into new environments or merge digital content with your space. Prepare to be amazed!</p>
            <p><a href="https://support.google.com/cardboard/answer/6295046?hl=en&ref_topic=6295044&sjid=8763360258522549178-NA"
                    target="_blank">Need help?</a>
            </p>
            <app-vr-tour-container></app-vr-tour-container>
        </div>
        <div class="col-lg-4 col-sm-12">
            <app-help-widget-container></app-help-widget-container>
        </div>
    </div>
</div>