import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Product } from "../models/product.interface";
import { PaginationState } from 'src/app/core/state/pagination.state';

export interface ProductsState extends EntityState<Product>, PaginationState{
    currentProduct: Product | null;
    error: string | null;
    loading: boolean;
    formState: Partial<Product> | null;
    pageSize: number;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: ProductsState = adapter.getInitialState({
    currentProduct: null,
    error: null,
    loading: false,
    total: 0,
    currentPage: 1,
    pageSize: 10,
    paginationInfo: null,
    formState: null,
  });
  




