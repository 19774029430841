import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Product } from '../../models/product.interface';
import { selectAllProducts } from '../../store/products.selectors';

@Component({
  selector: 'app-related-products-container',
  templateUrl: './related-products-container.component.html',
  styleUrls: ['./related-products-container.component.scss']
})
export class RelatedProductsContainerComponent {
  @Input() teaser: string = 'Recommended';
  @Input() title: string = 'Lab Automation Solutions Related to'
  @Input() description: string = 'Choose from an assortment of cutting-edge lab automation solutions to match your project requirements and achieve greater efficiency in your lab.';
  @Input() relatedProducts: Product[] = [];
  constructor() {
    // console.log(this.relatedProducts);
  }


}
