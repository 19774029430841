import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormStepComponent } from 'src/app/shared/models/form-step.interface';
import { DynamicFormStep } from 'src/app/shared/models/dynamic-form-step.interface';
import * as MultiStepFormActions from 'src/app/shared/modules/multi-step-form/store/multi-step-form.actions';
import { Store } from '@ngrx/store';
import { selectFormData } from '../store/multi-step-form.selectors';
import { BaseFormStepComponent } from './base-form-step/base-form-step.component';
import { take } from 'rxjs';

@Component({
    selector: 'app-step-one',
    template: `
    <h2>{{ title }}</h2>
    <form [formGroup]="formGroup">
      <formly-form [fields]="fields" [form]="formGroup"></formly-form>
    </form>
  `,
})
export class StepOneComponent extends BaseFormStepComponent implements OnInit, AfterViewInit {
    key = 'stepOne';
    title = 'Step One from Component';
    formGroup: FormGroup;
    fields: FormlyFieldConfig[];
    model: any = {};

    constructor(cd: ChangeDetectorRef, store: Store) {
        super(cd, store);
        this.initializeFields();
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    initializeFields() {
        this.fields = [
            {
                key: 'firstName',
                type: 'input',
                templateOptions: {
                    label: 'First Name',
                    required: true,
                },
                modelOptions: {
                    updateOn: 'change',
                },
            },
            {
                key: 'lastName',
                type: 'input',
                templateOptions: {
                    label: 'Last Name',
                    required: true,
                },
                modelOptions: {
                    updateOn: 'change',
                }
            },
        ];
    }

    initialize(): DynamicFormStep {
        this.formGroup = this.createForm();
        return {
            title: this.title,
            formStep: {
                key: this.key,
                title: this.title,
                formGroup: this.formGroup,
                fields: this.fields,
                initialize: () => this.initialize(),
                createForm: () => this.createForm(),
                model: this.model,
            }
        };
    }

    createForm(): FormGroup {
        return new FormGroup({
            firstName: new FormControl(this.model.firstName, Validators.required),
            lastName: new FormControl(this.model.lastName, Validators.required),
        });
    }

    getFields(): FormlyFieldConfig[] { // Implement the abstract method
        return this.fields;
    }
}
