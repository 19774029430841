// File: services/support-guide.service.ts

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { JobPosting } from "src/app/shared/models/job-posting.interface";
import { PaginationInfo } from "@fluentllc/core";
import { deleteJobPosting } from "src/app/features/careers/store/job-posting.actions";

@Injectable({
  providedIn: "root",
})
export class CareersService {
  private readonly apiUrl = "api/v1/careers";

  constructor(private http: HttpClient) {}

  getJobPostings(): Observable<JobPosting[]> {
    return this.http.get<JobPosting[]>(this.apiUrl);
  }

  getJobPostingBySlug(slug: string): Observable<JobPosting> {
    // Replace this URL with the correct API endpoint for fetching a single post by its slug
    const params = new HttpParams().set("slug", slug.toString());
    return this.http.get<JobPosting>(`${this.apiUrl}/by_slug`, { params });
  }

  getPaginatedJobPostings(
    page: number,
    pageSize: number
  ): Observable<{ jobPostings: JobPosting[]; pagination: PaginationInfo }> {
    const params = new HttpParams().set("page", page.toString()).set("page_size", pageSize.toString());

    return this.http.get<JobPosting[]>(this.apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const jobPostings = response.body;
        const pagination: PaginationInfo = {
          paginated: response.headers.get("x-paginated") === "true",
          paginatedBy: response.headers.get("x-paginated-by"),
          currentPage: response.headers.get("x-pagination-current"),
          nextPage: response.headers.get("x-pagination-next"),
          count: response.headers.get("x-pagination-count"),
        };
        return { jobPostings: jobPostings, pagination };
      })
    );
  }

  createJobPosting(jobPosting: JobPosting): Observable<JobPosting> {
    return this.http.post<JobPosting>(this.apiUrl, jobPosting);
  }

  updateJobPosting(jobPosting: JobPosting): Observable<JobPosting> {
    return this.http.put<JobPosting>(`${this.apiUrl}/${jobPosting.id}`, jobPosting);
  }

  deleteJobPosting(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
