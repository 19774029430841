import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../../models/category.interface';
import { Store } from '@ngrx/store';
import { AlertService, AppState } from '@fluentllc/core';
import { Router } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { ModalService } from 'src/app/core/services/modal.service';
import * as CategoryActions from '../../store/category.actions';
import { selectCategoryData } from '../../store/solutions.selectors';
import { selectAllCategories } from '../../store/category.selectors';
@Component({
  selector: 'app-category-admin-list-container',
  templateUrl: './category-admin-list-container.component.html',
  styleUrls: ['./category-admin-list-container.component.scss']
})
export class CategoryAdminListContainerComponent implements OnInit{
  categories$: Observable<Category[]>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private alertService: AlertService,
    private modalService: ModalService
  ) {
    
  }

  ngOnInit(): void {
    this.store.dispatch(CategoryActions.resetCategories());
    this.store.dispatch(CategoryActions.loadCategories());

    this.categories$ = this.store.select(selectAllCategories);
    const newMetaTags = {
      title: "Categories",
      description: "",
      keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  onEdit(categoryId: string): void {
    // Navigation logic for editing a post
    this.router.navigate(["/admin/products/category/manage", categoryId]);
  }
}
