import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsStoreModule } from './store/products-store.module';
import { ProductListContainerComponent } from './containers/product-list-container/product-list-container.component';
import { ProductDetailContainerComponent } from './containers/product-detail-container/product-detail-container.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { SolutionsStoreModule } from './store/solutions-store.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductCardContainerComponent } from './containers/product-card-container/product-card-container.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { MediaStoreModule } from './store/media-store.module';
import { ProductCarouselContainerComponent } from './containers/product-carousel-container/product-carousel-container.component';
import { FeaturesContainerComponent } from './containers/features-container/features-container.component';
import { FeaturesComponent } from './components/features/features.component';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { BenefitsContainerComponent } from './containers/benefits-container/benefits-container.component';
import { HelpfulResourcesContainerComponent } from './containers/helpful-resources-container/helpful-resources-container.component';
import { HelpfulResourcesComponent } from './components/helpful-resources/helpful-resources.component';
import { RequestProductInformationComponent } from './components/request-product-information/request-product-information.component';
import { TestimonialsListContainerComponent } from '../testimonials/containers/testimonials-list-container/testimonials-list-container.component';
import { TestimonialsModule } from '../testimonials/testimonials.module';
import { RelatedProductsComponent } from './components/related-products/related-products.component';
import { ProductRequestStoreModule } from './store/product-request-store.module';
import { RelatedProductsContainerComponent } from './containers/related-products-container/related-products-container.component';
import { RequestProductInformationContainerComponent } from './containers/request-product-information-container/request-product-information-container.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OptionHashsStoreModule } from './store/option-hash-store.module';
import { ProductFiltersStoreModule } from './store/product-filter-store.module';
import { ProductOptionsComponent } from './components/product-options/product-options.component';
import { ProductTableListingContainerComponent } from './containers/product-table-listing-container/product-table-listing-container.component';
import { ProductTableListingComponent } from './components/product-table-listing/product-table-listing.component';
import { ProductClassStoreModule } from './store/product-class-store.module';
import { ProductLabelListingComponent } from './components/product-label-listing/product-label-listing.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TubeListingComponent } from './components/tube-listing/tube-listing.component';
import { TubeCardComponent } from './components/tube-card/tube-card.component';
import { TubeSublistingComponent } from './components/tube-sublisting/tube-sublisting.component';
import { TubeDetailComponent } from './components/tube-detail/tube-detail.component';
import { TubeCardContainerComponent } from './containers/tube-card-container/tube-card-container.component';
import { ProductAdminListContainerComponent } from './containers/product-admin-list-container/product-admin-list-container.component';
import { ProductAdminManageContainerComponent } from './containers/product-admin-manage-container/product-admin-manage-container.component';
import { ProductAdminListComponent } from './components/product-admin-list/product-admin-list.component';
import { ProductAdminManageComponent } from './components/product-admin-manage/product-admin-manage.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TaggingModule } from '../tagging/tagging.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MediaModule } from '../media/media.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SpecificationsAdminComponent } from './components/specifications-admin/specifications-admin.component';
import { FeatureAdminComponent } from './components/feature-admin/feature-admin.component';
import { BenefitAdminComponent } from './components/benefit-admin/benefit-admin.component';
import { CategoryAdminListContainerComponent } from './containers/category-admin-list-container/category-admin-list-container.component';
import { CategoryAdminManageContainerComponent } from './containers/category-admin-manage-container/category-admin-manage-container.component';
import { CategoryAdminListComponent } from './components/category-admin-list/category-admin-list.component';
import { CategoryAdminManageComponent } from './components/category-admin-manage/category-admin-manage.component';
import { CategoriesStoreModule } from './store/category-store.module';
import { CategoryListingComponent } from './components/category-listing/category-listing.component';
import { ProductAttributeValueAdminListComponent } from './components/product-attribute-value-admin-list/product-attribute-value-admin-list.component';
import { ProductAttributeValueAdminManageComponent } from './components/product-attribute-value-admin-manage/product-attribute-value-admin-manage.component';
import { ProductAttributeValueEditorComponent } from './components/product-attribute-value-editor/product-attribute-value-editor.component';
@NgModule({
  declarations: [
    ProductListContainerComponent,
    ProductDetailContainerComponent,
    ProductListComponent,
    ProductDetailComponent,
    ProductCardContainerComponent,
    ProductCardComponent,
    ProductCarouselContainerComponent,
    FeaturesContainerComponent,
    FeaturesComponent,
    BenefitsComponent,
    BenefitsContainerComponent,
    HelpfulResourcesContainerComponent,
    HelpfulResourcesComponent,
    RequestProductInformationComponent,
    RequestProductInformationContainerComponent,
    RelatedProductsContainerComponent,
    RelatedProductsComponent,
    ProductOptionsComponent,
    ProductTableListingContainerComponent,
    ProductTableListingComponent,
    ProductLabelListingComponent,
    TubeListingComponent,
    TubeCardComponent,
    TubeSublistingComponent,
    TubeDetailComponent,
    TubeCardContainerComponent,
    ProductAdminListContainerComponent,
    ProductAdminManageContainerComponent,
    ProductAdminListComponent,
    ProductAdminManageComponent,
    SpecificationsAdminComponent,
    FeatureAdminComponent,
    BenefitAdminComponent,
    CategoryAdminListContainerComponent,
    CategoryAdminManageContainerComponent,
    CategoryAdminListComponent,
    CategoryAdminManageComponent,
    CategoryListingComponent,
    ProductAttributeValueAdminListComponent,
    ProductAttributeValueAdminManageComponent,
    ProductAttributeValueEditorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProductsRoutingModule,
    ProductsStoreModule,
    SolutionsStoreModule,
    CategoriesStoreModule,
    MediaStoreModule,
    ProductRequestStoreModule,
    TestimonialsModule,
    NgSelectModule,
    CarouselModule,
    OptionHashsStoreModule,
    ProductFiltersStoreModule,
    ProductClassStoreModule,
    FontAwesomeModule,
    NgxSummernoteModule,
    TaggingModule,
    TabsModule.forRoot(),
    MediaModule,
    DragDropModule
  ],
  exports: [
    ProductCardContainerComponent,
    ProductLabelListingComponent,
    TubeListingComponent,
    TubeSublistingComponent,
    ProductCarouselContainerComponent,
    RequestProductInformationContainerComponent
  ]
})
export class ProductsModule { }
