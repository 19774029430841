<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
            <div class="about-content">
                <app-title-hashtag [hashtagSubtitle]="hashtagSubtitle"></app-title-hashtag>
                <app-title [title]="title" [titleHighlight]="titleHighlight" [headerType]="headerType"></app-title>
                <p *ngIf="description" [innerHTML]="description"></p>
                <div class="row">
                    <app-bullet-features-container [customItems]="customItems"></app-bullet-features-container>
                </div>
            </div>
        </div>
    </div>
</div>