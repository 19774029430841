import { Component } from '@angular/core';

@Component({
  selector: 'app-scinomix-story-container',
  templateUrl: './scinomix-story-container.component.html',
  styleUrls: ['./scinomix-story-container.component.scss']
})
export class ScinomixStoryContainerComponent {

}
