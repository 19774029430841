import { createAction, props } from '@ngrx/store';
// import { SolutionTag} from 'src/app/shared/models/solution-tag.interface';
import { Tag } from 'src/app/shared/models/tag.interface';

export const loadSolutionTags = createAction('[SolutionTags] Load SolutionTags');
export const loadSolutionTagsSuccess = createAction(
  '[SolutionTags] Load SolutionTags Success',
  props < { SolutionTags: Tag[] }> ()
);
export const loadSolutionTagsFailure = createAction(
  '[SolutionTags] Load SolutionTags Failure',
  props<{ error: any }>()
);
