import { Component, Input } from '@angular/core';
import { FAQSearchResult } from 'src/app/shared/models/faq-search-result.interface';

@Component({
  selector: 'app-faqsearch-result',
  templateUrl: './faqsearch-result.component.html',
  styleUrls: ['./faqsearch-result.component.scss']
})
export class FAQSearchResultComponent {
  @Input() result!: FAQSearchResult;
}
