import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { OwlLogger } from 'ngx-owl-carousel-o/lib/services/logger.service';
import { Tag } from 'src/app/shared/models/tag.interface';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit, OnChanges {
  @Input() title: string = 'Tags';
  @Output() tagsChange = new EventEmitter<number[] | string[]>();
  availableTags: Tag[] = [];
  @Input() initialTags: number[] = [];
  @Input() initialSlugs: string[] = [];
  tagsForm: FormGroup;
  @Input() useSlugs: boolean = false; // Determine if we are using slugs or IDs

  constructor(private fb: FormBuilder, private store: Store<any>) {
    this.tagsForm = this.fb.group({
      selectedTags: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.populateTags();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTags'] || changes['initialSlugs']) {
      this.populateTags();
    }
  }

  get selectedTags(): FormArray {
    return this.tagsForm.get('selectedTags') as FormArray;
  }

  populateTags() {
    const tagsFormArray = this.selectedTags;
    tagsFormArray.clear();
    if (this.useSlugs) {
      this.initialSlugs.forEach((slug) => {
        tagsFormArray.push(new FormControl(slug));
      });
    } else {
      this.initialTags.forEach((tagId) => {
        tagsFormArray.push(new FormControl(tagId));
      });
    }
  }

  addTagFromEvent(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const value = this.useSlugs ? 
      this.availableTags.find(t => t.id.toString() === selectElement.value)?.slug : 
      Number(selectElement.value);
    if (value && !this.selectedTags.value.includes(value)) {
      this.selectedTags.push(new FormControl(value));
      this.emitChanges();
    }
  }

  removeTag(index: number): void {
    this.selectedTags.removeAt(index);
    this.emitChanges();
  }

  findTagName(value: number | string): string {
    const tag = this.useSlugs ? 
      this.availableTags.find(t => t.slug === value) : 
      this.availableTags.find(t => t.id === value);

    return tag ? tag.name : '';
  }

  private emitChanges(): void {
    this.tagsChange.emit(this.selectedTags.value);
  }
}
