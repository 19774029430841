import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Blog } from "src/app/shared/models/blog.interface";

@Injectable({
    providedIn: "root",
})
export class BlogService {
    private readonly apiUrl = "api/v1/blogs"; // Replace this with your API endpoint
    headers = new HttpHeaders({
        "Content-Type": "application/json",
    });

    constructor(private http: HttpClient) { }

    getBlogs(): Observable<Blog[]> {
        return this.http.get<Blog[]>(this.apiUrl);
    }

    getBlogById(id: number): Observable<Blog> {
        return this.http.get<Blog>(`${this.apiUrl}/${id}`);
    }

    createBlog(blog: Blog): Observable<Blog> {
        return this.http.post<Blog>(this.apiUrl, blog);
    }

    updateBlog(blog: Blog): Observable<Blog> {
        return this.http.put<Blog>(`${this.apiUrl}/${blog.id}`, blog);
    }

    deleteBlog(id: number): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${id}`);
    }
}
