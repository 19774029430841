import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { Alert } from '../../../core/models/alert.interface';
import { setTheme } from 'ngx-bootstrap/utils';
@Component({
  selector: 'app-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss'],
})
export class AlertContainerComponent {
  public alert: Alert;
  constructor(public alertService: AlertService) {
    
    setTheme('bs5'); // or 'bs4'
  }
}
