import { Component, Input } from '@angular/core';
import { CustomItem } from '../../models/custom-item.interface';

@Component({
  selector: 'app-bullet-feature-item',
  templateUrl: './bullet-feature-item.component.html',
  styleUrls: ['./bullet-feature-item.component.scss']
})
export class BulletFeatureItemComponent {
  @Input() customItem: CustomItem;
}
