import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-call-to-action-button',
  templateUrl: './call-to-action-button.component.html',
  styleUrls: ['./call-to-action-button.component.scss']
})
export class CallToActionButtonComponent {
  @Input('ctaTitle') ctaTitle: String = "Learn More"
  @Input('ctaUrl') ctaUrl: string = "#"
}
