<div class="container">
    <div class="row">
      <!-- Article Content-->
      <div class="col-xl-9 pb-100">
        <div class="card card-default">
          <div class="card-body">
            <div class="post-edit">
              <app-content-editor (save)="onSave()" (cancel)="onCancel()" [isFormValid]="jobPostingForm.valid">
                <form [formGroup]="jobPostingForm">
                  <div class="form-group">
                    <p>Title</p>
                    <input id="title" class="mb-3 form-control form-control-lg" formControlName="title" />
                    <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="alert alert-danger">
                      Title is required.
                    </div>
                  </div>
                  <div class="form-group">
                      <p>Slug</p>
                      <input id="slug" class="mb-3 form-control form-control-lg" formControlName="slug" placeholder="Leave blank to autogenerate the slug based on the title." />
                    </div>
                  <div class="form-group mb-3">
                    <p>Job Description</p>
                    <summernote formControlName="description" name="description" airMode [ngxSummernote]="config"></summernote>
                    <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)" class="alert alert-danger">
                      Job Description is required.
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <p>Responsibilities</p>
                    <summernote formControlName="responsibilities" name="responsibilities" airMode [ngxSummernote]="config"></summernote>
                  </div>
                  <div class="form-group mb-3">
                    <p>Qualifications &amp; Skills</p>
                    <summernote formControlName="qualifications" name="qualifications" airMode [ngxSummernote]="config"></summernote>
                  </div>
                </form>
              </app-content-editor>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-3">
        <div class="card card-default mb-3">
          <div class="card-body">
            <p class="lead">Article Data</p>
            <app-meta-data [seoData]="post" (seoDataChange)="onSeoDataChange($event)"></app-meta-data>
          </div>
        </div>
        <div class="card card-default mb-3">
          <div class="card-body">
            <p class="lead">Keywords</p>
            <app-keywords [initialKeywords]="post?.keywords" (keywordsChange)="onKeywordsChange($event)"></app-keywords>
          </div>
        </div>
        <div class="card card-default"><div class="card-body">
          <p class="lead">Feature Image</p>
          <app-image-upload [currentImageUrl]="post?.image"  (onImageSelected)="handleImageSelected($event)"></app-image-upload>
        </div></div>
      </div> -->
    </div>
  </div>
  