import { createReducer, on, Action } from '@ngrx/store';
import { initialState } from './search.state';
import * as SearchActions from './search.actions';

export const searchFeatureKey = 'search';

export const searchReducer = createReducer(
    initialState,
    on(SearchActions.search, (state, action) => {
        return {
            ...state,
            query: action.query,
            isLoading: true,
        };
    }),
    on(SearchActions.searchSuccess, (state, action) => {
        return {
            ...state,
            results: action.results,
            metadata: action.metadata,
            isLoading: false,
        };
    }),
    on(SearchActions.searchFailure, (state, action) => {
        return {
            ...state,
            error: action.error,
            isLoading: false,
        };
    }),
    on(SearchActions.resetSearchSlice, (state) => {
        return { ...initialState };
    }),
);