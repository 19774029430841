import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistributorsRoutingModule } from './distributors-routing.module';
import { DistributorsStoreModule } from './store/distributors-store.module';
import { DistributorsComponent } from './components/distributors/distributors.component';
import { DistributorsContainerComponent } from './containers/distributors-container/distributors-container.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DistributorsListContainerComponent } from './containers/distributors-list-container/distributors-list-container.component';
import { DistributorsListComponent } from './components/distributors-list/distributors-list.component';
import { DistributorCardComponent } from './components/distributor-card/distributor-card.component';
import { DistributorCardContainerComponent } from './containers/distributor-card-container/distributor-card-container.component';

@NgModule({
  declarations: [
    DistributorsComponent,
    DistributorsContainerComponent,
    DistributorsListContainerComponent,
    DistributorsListComponent,
    DistributorCardComponent,
    DistributorCardContainerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DistributorsRoutingModule,
    DistributorsStoreModule,
  ],
})
export class DistributorsModule { }
