<div class="card tube-card">
    <div class="card-img-container">
        <a [routerLink]="['/product', product.slug]" class="d-block">
            <img *ngIf="product?.images?.length" class="card-img-top" [src]="product.images[0].original"
                [alt]="product.title">
        </a>
    </div>
    <div class="card-body">
        <h6 class="card-title">
            <a [routerLink]="['/product', product.slug]">{{product.product_name | truncateDescription:50:true}}</a>
        </h6>
        <a type="button" [routerLink]="['/product', product.slug]" class="btn default-btn sci-btn-secondary">View More ></a>
    </div>
</div>
