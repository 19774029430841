import { Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { extractHighlightedResult } from 'src/app/core/utils/string-utils';

@Pipe({
    name: 'highlightedResult'
})
export class HighlightedResultPipe implements PipeTransform {

    constructor(private renderer: Renderer2) { }

    transform(description: string): string {
        return extractHighlightedResult(description);
    }
}
