import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CategoryActions from './category.actions';
import { CategoryApiService } from 'src/app/core/services/products/category-api.service';
import { Category } from '../models/category.interface';
import { AlertService } from "src/app/core/services/alert.service";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable()
export class CategoryEffects {

  loadCategories$ = createEffect(() => this.actions$.pipe(
    ofType(CategoryActions.loadCategories),
    mergeMap(() => this.categoryApiService.getCategories()
      .pipe(
        map(categories => CategoryActions.loadCategoriesSuccess({ categories: categories})),
        catchError(error => of(CategoryActions.loadCategoriesFailure({ error })))
      ))
  )
  );

  loadCategoryChildren$ = createEffect(() => this.actions$.pipe(
    ofType(CategoryActions.loadCategoryChildren),
    mergeMap(action =>
      this.categoryApiService.getChildrenByUrl(action.childrenUrl).pipe(
        switchMap(children => [
          CategoryActions.loadCategoryChildrenSuccess({ categoryId: action.categoryId, children }),
          ...children.filter(child => child.has_children).map(child =>
            CategoryActions.loadCategoryChildren({ categoryId: child.id, childrenUrl: child.children_url })
          )
        ]),
        catchError(error => of(CategoryActions.loadCategoryChildrenFailure({ error })))
      )
    )
  ));

  $updateCategory = createEffect(() => this.actions$.pipe(
    ofType(CategoryActions.updateCategory),
    mergeMap(action =>
      this.categoryApiService.updateCategoryBySlug(action.category.slug, action.category).pipe(
        map(() => CategoryActions.updateCategorySuccess({ category: action.category })),
        catchError(error => of(CategoryActions.updateCategoryFailure({ error })))
      )
    ),
    tap((result) => {
      if (result.type === CategoryActions.updateCategorySuccess.type) {
        this.router.navigate(["/admin/products/categories"]); // Adjust the URL as needed
        this.alertService.show({ message: "Update successful!", type: "info" });
      }
    })
  ));

  constructor(
    private actions$: Actions,
    private categoryApiService: CategoryApiService,
    private router: Router,
    private alertService: AlertService,
    private store: Store
  ) { }
}
