import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProductsBySolutionRoutingModule } from './products-by-solution-routing.module'
import { ProductsBySolutionContainerComponent } from './containers/products-by-solution-container/products-by-solution-container.component'
import { ProductsBySolutionComponent } from './components/products-by-solution/products-by-solution.component'
import { SharedModule } from 'src/app/shared/shared.module'
import { ProductsModule } from '../products/products.module'



@NgModule({
  declarations: [
    ProductsBySolutionContainerComponent,
    ProductsBySolutionComponent
  ],
  imports: [CommonModule, SharedModule, ProductsModule, ProductsBySolutionRoutingModule],
  exports: [
    ProductsBySolutionContainerComponent
  ]
})
export class ProductsBySolutionModule { }
