import { createReducer, on } from '@ngrx/store';
import * as LayoutActions from './navbar.actions';

export const layoutFeatureKey = 'layout';

export interface State {
    isHamburgerMenuOpen: boolean;
}

export const initialState: State = {
    isHamburgerMenuOpen: false
};

export const layoutReducer = createReducer(
    initialState,
    on(LayoutActions.toggleHamburgerMenu, (state) => ({
        ...state,
        isHamburgerMenuOpen: !state.isHamburgerMenuOpen
    })),
    on(LayoutActions.closeHamburgerMenu, (state) => ({
        ...state,
        isHamburgerMenuOpen: false
    }))
);


