import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event) => {
      const canonicalLinkElement = this.getOrCreateCanonicalElement();
      const canonicalUrl = this.getCanonicalUrl();
      canonicalLinkElement.setAttribute('href', canonicalUrl);
    });
  }

  private getCanonicalUrl(): string {
    return this.document.location.protocol + '//' + this.document.location.host + this.router.url;
  }

  private getOrCreateCanonicalElement(): HTMLLinkElement {
    let canonicalLinkElement = this.document.querySelector('link[rel="canonical"]');
    if (!canonicalLinkElement) {
      canonicalLinkElement = this.document.createElement('link');
      canonicalLinkElement.setAttribute('rel', 'canonical');
      this.document.head.appendChild(canonicalLinkElement);
    }
    return canonicalLinkElement as HTMLLinkElement;
  }
}
