import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeoRoutingModule } from './seo-routing.module';
import { SeoStoreModule } from './store/seo-store.module';
import { MetaTagsComponent } from './components/meta-tags/meta-tags.component';
import { MetaTagsContainerComponent } from './containers/meta-tags-container/meta-tags-container.component';
import { SchemaComponent } from './components/schema/schema.component';
import { StructuredDataComponent } from './components/structured-data/structured-data.component';
import { SchemaContainerComponent } from './containers/schema-container/schema-container.component';
import { StructuredDataContainerComponent } from './containers/structured-data-container/structured-data-container.component';

@NgModule({
  declarations: [
    MetaTagsComponent,
    MetaTagsContainerComponent,
    SchemaComponent,
    StructuredDataComponent,
    SchemaContainerComponent,
    StructuredDataContainerComponent
  ],
  imports: [
    CommonModule,
    SeoRoutingModule,
    SeoStoreModule,
  ],
  exports: [
    MetaTagsComponent,
    MetaTagsContainerComponent,
    SchemaContainerComponent,
    StructuredDataContainerComponent
  ]
})
export class SEOModule { }
