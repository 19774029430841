import { Alert } from './alert.interface';

// core/models/alert.model.ts
export function createAlert(alert: Partial<Alert>): Alert {
  return {
    id: alert.id,
    type: alert.type,
    message: alert.message,
    autoClose: alert.autoClose ?? true,
    duration: alert.duration ?? 5000,
    position: alert.position ?? 'top-right',
    dismissable: alert.dismissable ?? true,
  };
}
