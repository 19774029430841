<div class="pb-30"><app-page-banner-container backgroundImage="/assets/images/default_abstract.jpg"
        title="Lab Automation ROI Calculator" pageLink="/roi" [breadcrumbs]="breadcrumbs"></app-page-banner-container>
</div>
<div>
    <app-about-container [customItems]="customItems" [subtitle]="subtitle" [title]="title"
        [titleHighlight]="titleHighlight" [description]="description" [heroImage]="heroImage"></app-about-container>

</div>
<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-12">

            <iframe src="https://app.calconic.com/api/embed/calculator/5e2dd8263063e4001e0e6abb"
                sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups"
                title="Calconic_ Calculator" name="Calconic_ Calculator" height="1817px" scrolling="no"
                style="width: 100%; border: 0; outline: none;"></iframe>
            <script>
                (function () {
                    window.addEventListener("message", function (t) {
                        var e =
                            "https://www.paypalobjects.com/api/checkout.4.0.141.js";
                        if (
                            !document.querySelector('script[src="' + e + '"]') &&
                            t.data &&
                            "LOAD_PAYPAL" === t.data.action
                        ) {
                            var a = document.createElement("script");
                            (a.src = e),
                                (a.async = !0),
                                (a.dataset = {
                                    paypalCheckout: !0,
                                    noBridge: !0,
                                    state: "ppxo_meta",
                                    env: "production"
                                }),
                                document.body.append(a);
                        }
                        if (
                            t.data &&
                            "CALCONIC_UPDATE_HEIGHT" === t.data.action
                        ) {
                            var s = document.querySelector(
                                'iframe[src="https://app.calconic.com/api/embed/calculator/' +
                                t.data.payload.id +
                                '"]'
                            );
                            if (s) {
                                s.height = t.data.payload.height;
                            }
                        }
                    });
                })();
            </script>
        </div>
    </div>
</div>