import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { showSpinner, hideSpinner, startLoading, stopLoading } from './spinner.actions';

@Injectable()
export class SpinnerEffects {
    showSpinner$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(startLoading),
                delay(6000),
                takeUntil(this.actions$.pipe(ofType(stopLoading))),
                tap(() => this.store.dispatch(showSpinner()))
            ),
        { dispatch: false }
    );

    hideSpinner$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(stopLoading),
                tap(() => this.store.dispatch(hideSpinner()))
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private store: Store) { }
}
