import { Component, Input } from '@angular/core';
import { faCheckCircle, IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-section-card',
  templateUrl: './section-card.component.html',
  styleUrls: ['./section-card.component.scss']
})
export class SectionCardComponent {
  @Input() image: string = 'assets/images/service-agreement.jpg';  //300 x 200
  @Input() title: string = 'Service Agreements';
  @Input() description: string = "Scinomix offers preventive maintenance plans and service agreements on all equipment.";
  @Input() link: string = "https://scinomix.com";
  @Input() icon: IconDefinition = faCheckCircle;
  @Input() ctaText: string = "Learn More";
  @Input() iconSize: 'xs' | 'sm' | 'lg' | '1x' | '2x' | '3x' | '4x' = 'lg';
}
