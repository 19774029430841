import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { PostEffects } from './post.effects';
import * as post from './post.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(post.postFeatureKey, post.postReducer),
        EffectsModule.forFeature([PostEffects]),
    ],
})
export class PostStoreModule { }