import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProductClassActions from './product-class.actions';
import { ProductsApiService } from 'src/app/core/services/products/products-api.service';

@Injectable()
export class ProductClassEffects {

  loadProductClasses$ = createEffect(() => this.actions$.pipe(
    ofType(ProductClassActions.loadProductClasses),
    mergeMap(() => this.productsApiService.getProductClasses()
      .pipe(
        map((productClasses) => ProductClassActions.loadProductClassesSuccess({ productClasses })),
        catchError(error => of(ProductClassActions.loadProductClassesFailure({ error })))
      ))
  )
  );

  loadProductClassBySlug$ = createEffect(() => this.actions$.pipe(
    ofType(ProductClassActions.loadProductClass),
    mergeMap(action => this.productsApiService.getProductClassBySlug(action.slug)
      .pipe(
        map((currentProductClass) => ProductClassActions.loadProductClassSuccess({ currentProductClass })),
        catchError(error => of(ProductClassActions.loadProductClassFailure({ error })))
      ))
  ));


  constructor(
    private actions$: Actions,
    private productsApiService: ProductsApiService
  ) { }
}
