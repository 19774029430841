import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { HeaderContainerComponent } from './containers/header-container/header-container.component';
import { LayoutComponent } from './layout.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { OffsidebarComponent } from './components/offsidebar/offsidebar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavsearchComponent } from './components/header/navsearch/navsearch.component';
import { FooterContainerComponent } from './containers/footer-container/footer-container.component';
import { OffsidebarContainerComponent } from './containers/offsidebar-container/offsidebar-container.component';
import { SidebarContainerComponent } from './containers/sidebar-container/sidebar-container.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { MenuContainerComponent } from 'src/app/shared/containers/menu-container/menu-container.component';
import { TopHeaderComponent } from './components/header/top-header/top-header.component';
import { NavbarComponent } from './components/header/navbar/navbar.component';
import { MiddleHeaderComponent } from './components/header/middle-header/middle-header.component';
import { LayoutStoreModule } from './store/store.module';
import { OffsidebarContentDirective } from './directives/offsidebar-content.directive';
import { LayoutRoutingModule } from './layout-routing.module';
import { SEOModule } from '../seo/seo.module';
import { NavbarBrandContainerComponent } from './containers/navbar-brand-container/navbar-brand-container.component';
import { BurgerMenuContainerComponent } from './containers/burger-menu-container/burger-menu-container.component';
import { SearchBtnContainerComponent } from './containers/search-btn-container/search-btn-container.component';
import { NavbarBrandComponent } from './components/navbar-brand/navbar-brand.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { SearchBtnComponent } from './components/search-btn/search-btn.component';
import { AppointmentSchedulerModule } from '../appointment-scheduler/appointment-scheduler.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PostListWidgetContainerComponent } from '../blog/containers/post-list-widget-container/post-list-widget-container.component';
import { BlogModule } from '../blog/blog.module';
import { StickyNavModule } from 'ng2-sticky-nav';
import { SearchNavbarWidgetComponent } from '../search/components/search-navbar-widget/search-navbar-widget.component';
import { SearchModule } from '../search/search.module';
import { SharedModule as FluentSharedModule } from '@fluentllc/core';
@NgModule({
  declarations: [
    HeaderComponent,
    HeaderContainerComponent,
    LayoutComponent,
    FooterComponent,
    OffsidebarComponent,
    OffsidebarContainerComponent,
    SidebarComponent,
    NavsearchComponent,
    FooterContainerComponent,
    OffsidebarContainerComponent,
    SidebarContainerComponent,
    TopHeaderComponent,
    NavbarComponent,
    MiddleHeaderComponent,
    OffsidebarContentDirective,
    NavbarBrandContainerComponent,
    BurgerMenuContainerComponent,
    SearchBtnContainerComponent,
    NavbarBrandComponent,
    BurgerMenuComponent,
    SearchBtnComponent
  ],
  imports: [CommonModule, SharedModule, FluentSharedModule, RouterModule, LayoutStoreModule, LayoutRoutingModule, StickyNavModule, SEOModule, FontAwesomeModule, AppointmentSchedulerModule, BlogModule, SearchModule],
  exports: [LayoutComponent, HeaderComponent, HeaderContainerComponent, OffsidebarComponent,
    OffsidebarContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule {

}
