<app-product-detail *ngIf="!isTube" [product]="product$ | async" [resources]="resources$ | async"
    [filteredProduct]="filteredProduct$ | async" [selectedOptions]="selectedOptions$ | async"
    [isOptionAvailable$]="isOptionAvailable$" [isOptionSelected$]="isOptionSelected$"
    [isOptionDisabled$]="isOptionDisabled$" [filteredOptions]="filteredOptions$ | async"
    [variationOptions]="variationOptions$ | async" [filteredProductVariations]="filteredProductVariations$ | async"
    (onOptionChange)="onOptionChange($event)" [optionHash]="optionHash$ | async" [media]="media$ | async"></app-product-detail>

<app-tube-detail *ngIf="isTube" [product]="product$ | async" [resources]="resources$ | async"
    [filteredProduct]="filteredProduct$ | async" [selectedOptions]="selectedOptions$ | async"
    [isOptionAvailable$]="isOptionAvailable$" [isOptionSelected$]="isOptionSelected$"
    [isOptionDisabled$]="isOptionDisabled$" [filteredOptions]="filteredOptions$ | async"
    [variationOptions]="variationOptions$ | async" [filteredProductVariations]="filteredProductVariations$ | async"
    (onOptionChange)="onOptionChange($event)" [optionHash]="optionHash$ | async"></app-tube-detail>