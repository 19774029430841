import { createAction, props } from '@ngrx/store';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { JobApplication } from 'src/app/shared/models/job-application.interface';


export const addJobApplication = createAction(
  '[Job Application] Add Job Application',
  props<{ jobApplication: JobApplication; contextData: ContextData }>()
);

export const addJobApplicationSuccess = createAction(
  '[Job Application] Add Job Application Success',
  props<{ jobApplication: JobApplication }>()
);

export const addJobApplicationFailure = createAction(
  '[Job Application] Add Job Application Failure',
  props<{ error: any }>()
);

export const setSuccessMessage = createAction(
  '[Job Application] Set Success Message',
  props<{ message: string }>()
);

export const resetJobApplicationSlice = createAction('[Job Application] Reset Job Application Slice');