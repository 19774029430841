export interface SchemaState {
    schema: any;
    loading: boolean;
    error: any;
}

export const initialSchemaState: SchemaState = {
    schema: null,
    loading: false,
    error: null,
};