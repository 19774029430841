import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaTag } from 'src/app/shared/models/meta-tag.interface';

@Component({
  selector: 'app-meta-tags',
  template: '',
})
export class MetaTagsComponent implements OnChanges {
  @Input() metaTags: MetaTag[];
//   <div *ngFor="let tag of metaTags">
//   <ng-container [ngSwitch]="tag.name">
//     <title *ngSwitchCase="'title'">{{ tag.content }}</title>
//     <meta *ngSwitchDefault [attr.name]="tag.name" [attr.content]="tag.content">
//   </ng-container>
// </div>
  constructor(private title: Title, private meta: Meta) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metaTags && changes.metaTags.currentValue) {
      const metaTags = changes.metaTags.currentValue;
      for (const tag of metaTags) {
        if (tag.name === 'title') {
          this.title.setTitle(tag.content);
        } else if (tag.name.startsWith('og:')) {
            this.meta.updateTag({ property: tag.name, content: tag.content });  // Handle Open Graph tags
        } else {
          this.meta.updateTag({ name: tag.name, content: tag.content });
        }
      }
    }
  }


}
