<form [formGroup]="specificationsForm" class="mt-3">
    <!-- Header Row -->
    <div class="row mb-2">
      <div class="col">
        <strong>Specification Name</strong>
      </div>
      <div class="col">
        <strong>Specification Value</strong>
      </div>
      <div class="col-auto">
        <strong>Actions</strong>
      </div>
    </div>
  
    <!-- Specifications Rows -->
    <div formArrayName="specifications" cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let specGroup of specificationsArray.controls; let i = index" [formGroupName]="i" cdkDrag>
        <div class="card mb-3">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto text-center">
                <i class="fas fa-grip-vertical" cdkDragHandle></i> <!-- Font Awesome icon for drag handle -->
              </div>
              <!-- Specification Name -->
              <div class="col">
                <textarea formControlName="name" (change)="emitChanges()" placeholder="Specification Name" class="form-control" aria-label="Specification Name" rows="2"></textarea>
              </div>
              <!-- Specification Value -->
              <div class="col">
                <textarea formControlName="value" (change)="emitChanges()" placeholder="Specification Value" class="form-control" aria-label="Specification Value" rows="2"></textarea>
              </div>
              <!-- Remove Button -->
              <div class="col-auto">
                <button type="button" (click)="removeSpecification(i)" class="btn btn-danger btn-sm">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Footer Buttons -->
    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
      <button type="button" (click)="addSpecification()" class="btn btn-outline-secondary">Add Specification</button>
    </div>
  </form>
  