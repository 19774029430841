import { Component, Input, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { Media } from "src/app/features/products/models/media.interface";
import { selectCarouselMediaList, selectMediaInBackendOrder, selectMediaLoading, selectSortedCarouselMediaList } from "src/app/features/products/store/media.selectors";
import * as MediaActions from "src/app/features/products/store/media.actions";
// import { showSpinner, hideSpinner } from "src/app/shared/store/spinner-store/spinner.actions";
import { Product } from "src/app/features/products/models/product.interface";
import { selectCurrentProduct } from "src/app/features/products/store/products.selectors";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-media-admin-list-container",
  templateUrl: "./media-admin-list-container.component.html",
  styleUrls: ["./media-admin-list-container.component.scss"],
})
export class MediaAdminListContainerComponent implements OnInit {
  @Input() mediaItems: Media[];
  @Input() uploadButtonText: string = "Add/Upload Media";
  @Input() objectType: string = 'media';
  isLoading$: Observable<boolean> = this.store.select(selectMediaLoading);
  currentProduct$: Observable<Product>;
  slug: string;
  constructor(private store: Store, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    
    this.currentProduct$ = this.store.pipe(select(selectCurrentProduct));
    // this.mediaItems$.subscribe(media => console.log(media))
  }

  onOrderChanged(updatedMediaItems: Media[]): void {
    this.store.dispatch(MediaActions.updateMediaWeights({ updatedMediaList: updatedMediaItems, productSlug: this.slug }));
  }

  onDisassociateRequested(media: Media): void {
    this.currentProduct$.subscribe(product => {
      this.slug = product.slug;
      this.store.dispatch(MediaActions.removeProductAssociation({ productSlug: this.slug, mediaId: media.id }));
    });
    }
   

  onSave(updatedMediaItems: Media[]): void {
    // Optionally sort updatedMediaItems based on weight
    // console.log(updatedMediaItems);
    // this.store.dispatch(showSpinner());
    // this.store.dispatch(MediaActions.updateBulkMedia({ updatedMediaList: updatedMediaItems }));
    // this.store.dispatch(hideSpinner());
  }
}
