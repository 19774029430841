import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SolutionTag } from "src/app/shared/models/solution-tag.interface";

@Injectable({
  providedIn: "root",
})
export class SolutionTagsService {
  private readonly apiUrl = "api/v1/solutions"; // Replace this with your API endpoint
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  constructor(private http: HttpClient) { }

  getSolutionTags(): Observable<SolutionTag[]> {
    return this.http.get<SolutionTag[]>(this.apiUrl);
  }

  getSolutionTagById(id: number): Observable<SolutionTag> {
    return this.http.get<SolutionTag>(`${this.apiUrl}/${id}`);
  }

  createSolutionTag(tag: SolutionTag): Observable<SolutionTag> {
    return this.http.post<SolutionTag>(this.apiUrl, tag);
  }

  updateSolutionTag(tag: SolutionTag): Observable<SolutionTag> {
    return this.http.put<SolutionTag>(`${this.apiUrl}/${tag.id}`, tag);
  }

  deleteSolutionTag(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
