import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MetaTag } from 'src/app/shared/models/meta-tag.interface';
import { MetaTagsState } from './meta-tags.state';

export const metaTagsFeatureKey = 'metaTags';

export const getMetaTagsState = createFeatureSelector<MetaTagsState>(metaTagsFeatureKey);

// Selector to get the title from meta tags.
export const getTitle = createSelector(
    getMetaTagsState,
    (state: MetaTagsState) => state.tags['title']
);

// Selector to get the description from meta tags.
export const getDescription = createSelector(
    getMetaTagsState,
    (state: MetaTagsState) => state.tags['description']
);

// Selector to get the keywords from meta tags.
export const getKeywords = createSelector(
    getMetaTagsState,
    (state: MetaTagsState) => state.tags['keywords']
);

export const getMetaTags = createSelector(
    getMetaTagsState,
    (state: MetaTagsState) => {
        // Ensure 'state' and 'state.tags' are defined to prevent runtime errors
        if (!state || !state.tags) {
            return [];
        }
        // Map each key in the 'tags' dictionary to a MetaTag array
        return Object.entries(state.tags).map(([key, content]) => ({
            name: key,
            content: content
        }));
    }
);
// export const getMetaTags = createSelector(
//     getMetaTagsState,
//     (state: MetaTagsState) => {
//         const tags: MetaTag[] = [];
//         if (state.title) {
//             tags.push({ name: 'title', content: state.title });
//         }
//         if (state.description) {
//             tags.push({ name: 'description', content: state.description });
//         }
//         if (state.keywords) {
//             tags.push({ name: 'keywords', content: state.keywords });
//         }

//         if (state.prerenderStatusCode) {
//             tags.push({ name: 'prerender-status-code', content: state.prerenderStatusCode})
//         }
//         return tags;
//     }
// );
