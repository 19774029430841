import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { ButtonContainerComponent } from './containers/button/button-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './components/menu/menu.component';
import { MenuContainerComponent } from './containers/menu-container/menu-container.component';
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SharedStoreModule } from './store/store.module';
import { SpinnerContainerComponent } from './containers/spinner-container/spinner-container.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertContainerComponent } from './containers/alert-container/alert-container.component';
import { HeroComponent } from './components/hero/hero.component';
import { HeroContainerComponent } from './containers/hero-container/hero-container.component';
import { BackgroundVideoHeroContainerComponent } from './containers/background-video-hero-container/background-video-hero-container.component';
import { BackgroundVideoHeroComponent } from './components/background-video-hero/background-video-hero.component';
import { MultiStepFormModule } from './modules/multi-step-form/multi-step-form.module';
import { PageBannerContainerComponent } from './containers/page-banner-container/page-banner-container.component';
import { PageBannerComponent } from './components/page-banner/page-banner.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbContainerComponent } from './containers/breadcrumb-container/breadcrumb-container.component';
import { TruncateDescriptionPipe, TruncatePipe } from './pipes/truncate.pipe';
import { HelpWidgetComponent } from './components/help-widget/help-widget.component';
import { HelpWidgetContainerComponent } from './containers/help-widget-container/help-widget-container.component';
import { LogoComponent } from './components/logo/logo.component';
import { LogoContainerComponent } from './containers/logo-container/logo-container.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { EmbeddedVideoPlayerComponent } from './components/embedded-video-player/embedded-video-player.component';
import { SafePipe } from './pipes/safe.pipe';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { CallToActionSmallWithIcon1Component } from './components/call-to-action-small-with-icon1/call-to-action-small-with-icon1.component';
import { CallToActionSmallWithIcon1ContainerComponent } from './containers/call-to-action-small-with-icon1-container/call-to-action-small-with-icon1-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { AboutContainerComponent } from './containers/about-container/about-container.component';
import { AboutComponent } from './components/about/about.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackContainerComponent } from './containers/feedback-container/feedback-container.component';
import { HtmlToTextPipe } from './pipes/html-to-text.pipe';
import { SidebarNavigationContainerComponent } from './containers/sidebar-navigation-container/sidebar-navigation-container.component';
import { SidebarNavigationComponent } from './components/sidebar-navigation/sidebar-navigation.component';
import { HighlightedResultPipe } from './pipes/highlighted-result.pipe';
import { DynamicAnchorDirective } from './directives/dynamic-anchor.directive';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { BulletFeaturesContainerComponent } from './containers/bullet-features-container/bullet-features-container.component';
import { BulletFeaturesListComponent } from './components/bullet-features-list/bullet-features-list.component';
import { BulletFeatureItemComponent } from './components/bullet-feature-item/bullet-feature-item.component';
import { TitleDescriptionHashtagContainerComponent } from './containers/title-description-hashtag-container/title-description-hashtag-container.component';
import { TitleDescriptionHashtagComponent } from './components/title-description-hashtag/title-description-hashtag.component';
import { TitleHashtagComponent } from './components/title-hashtag/title-hashtag.component';
import { TitleComponent } from './components/title/title.component';
import { VirtualDemonstrationFormPopoverContainerComponent } from './containers/virtual-demonstration-form-popover-container/virtual-demonstration-form-popover-container.component';
import { VirtualDemonstrationFormPopoverComponent } from './components/virtual-demonstration-form-popover/virtual-demonstration-form-popover.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PlayEmbeddedVideoButtonContainerComponent } from './containers/play-embedded-video-button-container/play-embedded-video-button-container.component';
import { PlayEmbeddedVideoButtonComponent } from './components/play-embedded-video-button/play-embedded-video-button.component';
import { VirtualDemoFormComponent } from './components/virtual-demo-form/virtual-demo-form.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ContentEditorComponent } from './components/content-editor/content-editor.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MetaDataComponent } from './components/meta-data/meta-data.component';
import { KeywordsComponent } from './components/keywords/keywords.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalContainerComponent } from './containers/modal-container/modal-container.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavigationBarContainerComponent } from './containers/navigation-bar-container/navigation-bar-container.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { AsideComponent } from './components/aside/aside.component';
import { AsideContainerComponent } from './containers/aside-container/aside-container.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { DragDropFileUploadComponent } from './components/drag-drop-file-upload/drag-drop-file-upload.component';
import { CallToActionButtonComponent } from './components/call-to-action-button/call-to-action-button.component';
import { CallToActionButtonContainerComponent } from './containers/call-to-action-button-container/call-to-action-button-container.component';
import { BulletFeaturesListHorizontalIconAboveComponent } from './components/bullet-features-list-horizontal-icon-above/bullet-features-list-horizontal-icon-above.component';
import { BulletFeatureItemHorizontalIconAboveComponent } from './components/bullet-feature-item-horizontal-icon-above/bullet-feature-item-horizontal-icon-above.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
@NgModule({
  declarations: [
    ButtonComponent,
    ButtonContainerComponent,
    MenuComponent,
    MenuContainerComponent,
    MenuItemComponent,
    SpinnerComponent,
    SpinnerContainerComponent,
    AlertComponent,
    AlertContainerComponent,
    HeroComponent,
    HeroContainerComponent,
    BackgroundVideoHeroContainerComponent,
    BackgroundVideoHeroComponent,
    PageBannerContainerComponent,
    PageBannerComponent,
    BreadcrumbComponent,
    BreadcrumbContainerComponent,
    TruncatePipe,
    TruncateDescriptionPipe,
    HtmlToTextPipe,
    SafePipe,
    HighlightedResultPipe,
    HelpWidgetComponent,
    HelpWidgetContainerComponent,
    LogoComponent,
    LogoContainerComponent,
    CarouselComponent,
    EmbeddedVideoPlayerComponent,
    ImageViewerComponent,
    CallToActionSmallWithIcon1Component,
    CallToActionSmallWithIcon1ContainerComponent,
    StarRatingComponent,
    AboutContainerComponent,
    AboutComponent,
    FeedbackComponent,
    FeedbackContainerComponent,
    SidebarNavigationContainerComponent,
    SidebarNavigationComponent,
    DynamicAnchorDirective,
    SocialLinksComponent,
    BulletFeaturesContainerComponent,
    BulletFeaturesListComponent,
    BulletFeatureItemComponent,
    TitleDescriptionHashtagContainerComponent,
    TitleDescriptionHashtagComponent,
    TitleHashtagComponent,
    TitleComponent,
    VirtualDemonstrationFormPopoverContainerComponent,
    VirtualDemonstrationFormPopoverComponent,
    PlayEmbeddedVideoButtonContainerComponent,
    PlayEmbeddedVideoButtonComponent,
    VirtualDemoFormComponent,
    ContentEditorComponent,
    PaginationComponent,
    MetaDataComponent,
    KeywordsComponent,
    ImageUploadComponent,
    ModalContainerComponent,
    ModalComponent,
    NavigationBarContainerComponent,
    NavigationBarComponent,
    AsideComponent,
    AsideContainerComponent,
    AdminMenuComponent,
    DragDropFileUploadComponent,
    CallToActionButtonComponent,
    CallToActionButtonContainerComponent,
    BulletFeaturesListHorizontalIconAboveComponent,
    BulletFeatureItemHorizontalIconAboveComponent,
    ImageLoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedStoreModule,
    FontAwesomeModule,
    MultiStepFormModule,
    NgxSummernoteModule,
    LayoutModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    AlertModule.forRoot(),
  ],
  exports: [
    ButtonComponent,
    ButtonContainerComponent,
    FormsModule,
    ReactiveFormsModule,
    MenuComponent,
    MenuContainerComponent,
    SpinnerComponent,
    SpinnerContainerComponent,
    AlertComponent,
    AlertContainerComponent,
    HeroComponent,
    HeroContainerComponent,
    BackgroundVideoHeroContainerComponent,
    BackgroundVideoHeroComponent,
    PageBannerContainerComponent,
    BreadcrumbContainerComponent,
    TruncatePipe,
    TruncateDescriptionPipe,
    HtmlToTextPipe,
    SafePipe,
    HighlightedResultPipe,
    HelpWidgetContainerComponent,
    LogoContainerComponent,
    CarouselComponent,
    CallToActionSmallWithIcon1ContainerComponent,
    StarRatingComponent,
    AboutContainerComponent,
    SidebarNavigationContainerComponent,
    DynamicAnchorDirective,
    SocialLinksComponent,
    TitleDescriptionHashtagContainerComponent,
    VirtualDemonstrationFormPopoverContainerComponent,
    PlayEmbeddedVideoButtonContainerComponent,
    VirtualDemoFormComponent,
    BulletFeaturesContainerComponent,
    TitleComponent,
    TitleHashtagComponent,
    ContentEditorComponent,
    PaginationComponent,
    MetaDataComponent,
    KeywordsComponent,
    ImageUploadComponent,
    ModalContainerComponent,
    AsideContainerComponent,
    DragDropFileUploadComponent,
    CallToActionButtonContainerComponent,
    CallToActionSmallWithIcon1ContainerComponent,
    BulletFeaturesListHorizontalIconAboveComponent,
    ImageLoaderComponent
  ],
  providers: [
    HtmlToTextPipe
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
