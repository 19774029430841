<div class="top-header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-7 col-md-12">
                <ul class="top-header-information">
                    <li>
                        <div>
                            <div class="white-icon" [innerHTML]="bxEnvelopeOpenIcon"></div>
                            <a class="mr-5 " href="mailto:sales@scinomix.com">sales@scinomix.com</a>
                        </div>
                    </li>
                    <li class="hide-on-mobile">
                        <div>
                            <div class="white-icon" [innerHTML]="bxWrenchIcon"></div>
                            <a class="ml-5" href="mailto:service@scinomix.com">service@scinomix.com</a>
                        </div>
                    </li>
                    <li>
                        <div>
                            <div class="white-icon" [innerHTML]="bxsPhoneIcon"></div>
                            <a href="tel:3142989800">+1.314.298.9800</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-12">
                <ul class="top-header-optional regular">
                    <li><a href="virtual-demonstration" style="margin-right:25px;">Schedule a Demo</a></li>
                    <li><a routerLink="/about/story-behind-scinomix">Learn The Story Behind Scinomix</a></li>
                </ul>
            </div>
        </div>
    </div>

</div>