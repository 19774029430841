import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Product } from '../../../products/models/product.interface';
import { Category } from '../../../products/models/category.interface';

import * as ProductsActions from '../../../products/store/products.actions';
import * as SolutionsActions from '../../../products/store/solutions.actions';
import { selectProductsBySolution, selectLoading, selectError } from '../../../products/store/products.selectors';
import { selectCategoryData } from '../../../products/store/solutions.selectors';

@Component({
  selector: 'app-products-by-solution-container',
  templateUrl: './products-by-solution-container.component.html',
  styleUrls: ['./products-by-solution-container.component.scss']
})
export class ProductsBySolutionContainerComponent implements OnInit {
  solution: string;
  products$: Observable<Product[]>;
  loading$: Observable<boolean> = this.store.select(selectLoading);
  error$: Observable<string | null> = this.store.select(selectError);
  categoryData$: Observable<Category | null> = this.store.select(selectCategoryData);

  constructor(private store: Store, private route: ActivatedRoute) {
    this.solution = this.route.snapshot.params.solution;

    this.products$ = this.store.select(selectProductsBySolution(this.solution));

  }

  ngOnInit(): void {
    this.store.dispatch(ProductsActions.loadProductsBySolution({ solution: this.solution }));
    this.store.dispatch(SolutionsActions.loadCategory({ categorySlug: this.solution }));
  }
}
