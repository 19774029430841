<div class="d-flex align-items-center">
    <div class="flex-column w-100">
        <h4 [innerHTML]="'Product: ' + result._highlightResult.title?.value"></h4>
        <p [innerHTML]="result._highlightResult.description?.value | highlightedResult"></p>
    </div>
    <div class="ms-auto">
        <div [routerLink]="['/product', result.slug]" target="_blank" class="btn btn-info btn-sm text-nowrap"
            style="margin-left: 20px;">
            View Product Details
        </div>
    </div>
</div>