import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductRequest } from '../../models/product-request.interface';

import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';
import { CountryService } from 'src/app/core/services/country.service';
import { stateRequiredValidator } from './../../../../shared/validators/custom-validators';
import { Country } from 'src/app/shared/models/country.interface';
import { State } from 'src/app/shared/models/state.interface';

@Component({
  selector: 'app-request-product-information',
  templateUrl: './request-product-information.component.html',
  styleUrls: ['./request-product-information.component.scss']
})
export class RequestProductInformationComponent implements OnInit {
  @Output() formSubmit: EventEmitter<ProductRequest> = new EventEmitter();
  @Input() productName: string;
  @Input() pageName: string;
  @Input() pageUri: string;
  @Input() successMessage: string | null;
  @Input() submitted: boolean = false;
  form: FormGroup;
  countries: Country[] = [];
  states: State[] = [];
  formattedPhoneNumber: string = '';
  showStateDropdown: boolean = false;


  constructor(private formBuilder: FormBuilder, private countryService: CountryService) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      company: [''],
      country: [null, Validators.required],
      stateUs: [null, stateRequiredValidator()],
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.countries = this.countryService.getCountries();
    this.form.controls['country'].setValue('US'); // Set default country to US
    this.states = this.countryService.getStatesByCountry('US'); // Load states for US
    this.showStateDropdown = true; // Show state dropdown for US
  }

  onCountryChange(event: any): void {
    const selectedCountry = event.code;
    this.states = this.countryService.getStatesByCountry(selectedCountry);
    this.showStateDropdown = selectedCountry === 'US' || selectedCountry === 'CA';
    if (!this.showStateDropdown) {
      this.form.controls['stateUs'].setValue(null);
    }
  }

  onPhoneInputChange(event: Event): void {
    const inputNumber = (event.target as HTMLInputElement).value;
    const selectedCountry = this.form.value.country;
    const parsedPhoneNumber = parsePhoneNumber(inputNumber, selectedCountry);
    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      this.form.controls['phone'].setValue(parsedPhoneNumber.formatInternational());
      this.form.controls['phone'].setErrors(null);
    } else {
      this.form.controls['phone'].setErrors({ invalid: true });
    }
  }


  onSubmit(): void {
    if (this.form.valid) {
      const productRequest: ProductRequest = {
        firstname: this.form.value.firstName,
        lastname: this.form.value.lastName,
        email: this.form.value.email,
        phone: this.form.value.phone,
        company: this.form.value.company,
        country_iso_alpha2: this.form.value.country,
        message: this.form.value.message,
        products_interested: this.productName,
        state_us: this.showStateDropdown ? this.form.value.stateUs : ''
      };

      this.formSubmit.emit(productRequest);
      this.form.reset();
    }
  }

}
