import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { faCalendarCheck, faFilePdf, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { NavbarRefService } from 'src/app/core/services/navbar-ref.service';
import { VirtualDemonstrationFormPopoverContainerComponent } from 'src/app/shared/containers/virtual-demonstration-form-popover-container/virtual-demonstration-form-popover-container.component';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-appointment-scheduler',
  templateUrl: './appointment-scheduler.component.html',
  styleUrls: ['./appointment-scheduler.component.scss']
})
export class AppointmentSchedulerComponent implements OnInit {
  ctaIcon: IconDefinition = faCalendarCheck;
  private overlayRef: OverlayRef = null;
  @ViewChild('topNavbar') navbar: ElementRef;

  constructor(
    private overlay: Overlay,
    private navbarService: NavbarRefService,
    private breakpointObserver: BreakpointObserver,
    private mediaMatcher: MediaMatcher,
    private gtmService: GtmService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.overlayRef = null;
  }

  toggleAppointmentForm(event: MouseEvent) {
    const isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset);

    isHandset$.subscribe((result) => {
      if (result.matches) {
        this.toggleOverlay();
      } else {
        this.toggleOverlayConnectedToNavbar();
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.gtmService.push({
        event: 'schedule_demo_button_click',
        'virtualPageURL': this.router.url
      });
    }
  }

  private toggleOverlay() {
    const isMobile = this.mediaMatcher.matchMedia('(max-width: 767px)').matches;
    const width = isMobile ? '90%' : '50%';
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    } else {
      const positionStrategy = this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
      const scrollStrategy = this.overlay.scrollStrategies.block();
      const overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy,
        hasBackdrop: true,
        width: width,
        panelClass: 'scrollable-overlay',
      });

      const popoverPortal = new ComponentPortal(
        VirtualDemonstrationFormPopoverContainerComponent
      );

      overlayRef.attach(popoverPortal);
      overlayRef.backdropClick().subscribe(() => {
        overlayRef.detach();
        overlayRef.dispose();
        this.overlayRef = null;
      });

      this.overlayRef = overlayRef;
    }
  }

  private toggleOverlayConnectedToNavbar() {
    const isMobile = this.mediaMatcher.matchMedia('(max-width: 767px)').matches;
    const width = isMobile ? '90%' : '50%';
    const navbarElementRef = this.navbarService.getNavbarElementRef();

    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    } else {
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(navbarElementRef.nativeElement)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 50
          }
        ]);

      const scrollStrategy = this.overlay.scrollStrategies.reposition();

      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy,
        hasBackdrop: true,
        width: width,
      });

      const popoverPortal = new ComponentPortal(
        VirtualDemonstrationFormPopoverContainerComponent
      );

      this.overlayRef.attach(popoverPortal);
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.detach();
        this.overlayRef.dispose();
        this.overlayRef = null;
      });
    }
  }

}
