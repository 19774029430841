<div class="widget widget_inba_posts_thumb">
    <h3 class="widget-title">Recent posts</h3>
    <article class="item" *ngFor="let post of posts | slice:0:maxArticles">
        <a [routerLink]="['/news', post.slug]" class="thumb" *ngIf="post.image">
            <span class="fullimage cover" [style.background-image]="'url(' + post.image + ')'" role="img"></span>

        </a>
        <div class="info">
            <ng-container *ngIf="post.published_at; else modifiedDateBlock">
                <span>{{post.published_at | date:'mediumDate'}}</span>
            </ng-container>
            <ng-template #modifiedDateBlock>
                <span>{{post.modified_date | date:'mediumDate'}}</span>
            </ng-template>
            <h5 class="title usmall">
                <a [routerLink]="['/news', post.slug]">{{post.title}}</a>
            </h5>
        </div>
    </article>
</div>