import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { JobPosting } from "src/app/shared/models/job-posting.interface";

@Component({
  selector: "app-job-posting-admin-manage",
  templateUrl: "./job-posting-admin-manage.component.html",
  styleUrls: ["./job-posting-admin-manage.component.scss"],
})
export class JobPostingAdminManageComponent implements OnInit {
  @Input() post: JobPosting;
  @Output() save = new EventEmitter<{
    jobPosting: JobPosting;
  }>();
  @Output() cancel = new EventEmitter<void>();
  isEditMode: boolean = false;
  jobPostingForm: FormGroup;
  public editorDisabled = false;
  isSettingValue = false;

  get f() {
    return this.jobPostingForm.controls;
  }

  config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
        ["delete", ["deleteRow", "deleteCol", "deleteTable"]],
      ],
      image: [
        ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
        ["float", ["floatLeft", "floatRight", "floatNone"]],
        ["remove", ["removeMedia"]],
      ],
      link: [["link", ["linkDialogShow", "unlink"]]],
      air: [["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]]],
    },
    height: "600px",
    uploadImagePath: "/api/upload",
    toolbar: [
      ["misc", ["codeview", "undo", "redo", "codeBlock"]],
      ["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]],
      ["style", ["style"]],
      ["fontsize", ["fontname", "fontsize", "color"]],
      ["para", ["style0", "ul", "ol", "paragraph", "height"]],
      ["insert", ["table", "picture", "link", "video", "hr"]],
      ["customButtons", ["testBtn"]],
    ],
    codeviewFilter: true,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
    styleTags: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "pre", "blockquote"],
    fontNames: ["Poppins", "Heebo", "Nunito Sans", "sansans-serif"],
    fontNamesIgnoreCheck: ["Poppins", "Heebo", "Nunito Sans"],
    styleWithSpan: false,
    dialogsInBody: true,
    dialogsFade: true,
  };

  constructor(private fb: FormBuilder, private store: Store) {
  }

  ngOnInit(): void {
    this.jobPostingForm = this.fb.group({
      title: [this.post?.title ?? ''],
      company_overview: [this.post?.company_overview ?? ''],
      slug: [this.post?.slug ?? ''],
      description: [this.post?.description ?? '', Validators.required],
      requirements: [this.post?.requirements ?? ''],
      location: [this.post?.location ?? ''],
      company: [this.post?.company ?? ''],
      employment_type: [this.post?.employment_type ?? ''],
      seniority_level: [this.post?.seniority_level ?? ''],
      industry: [this.post?.industry ?? ''],
      responsibilities: [this.post?.responsibilities ?? ''],
      qualifications: [this.post?.qualifications ?? ''],
      benefit: [this.post?.benefits ?? ''],
      application_deadline: [this.post?.application_deadline ?? ''],
      contact_email: [this.post?.contact_email ?? ''],
      contact_phone: [this.post?.contact_phone ?? ''],
    });
  }

  onSave() {
    if (this.jobPostingForm.valid) {
      const post = {
        ...this.post,
        ...this.jobPostingForm.value,
      };
      this.save.emit({ jobPosting: post });
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
