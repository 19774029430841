import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DynamicFormStep } from "src/app/shared/models/dynamic-form-step.interface";
import { FormStepComponent } from "src/app/shared/models/form-step.interface";
import { BaseFormStepComponent } from "./base-form-step/base-form-step.component";

@Component({
    selector: 'app-step-three',
    template: `
    <h2>{{ title }}</h2>
    <form [formGroup]="formGroup">
      <formly-form [fields]="fields" [form]="formGroup"></formly-form>
    </form>
  `,
})
export class StepThreeComponent extends BaseFormStepComponent {
    key = 'stepThree';
    title = 'Step Three from Component';
    formGroup: FormGroup;
    fields: FormlyFieldConfig[];

    constructor(cd: ChangeDetectorRef, store: Store) {
        super(cd, store);
        this.initializeFields();
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    initializeFields() {
        this.fields = [
            {
                key: 'zipCode',
                type: 'input',
                templateOptions: {
                    label: 'Zip Code',
                    required: true,
                },
            },
            {
                key: 'cell',
                type: 'input',
                templateOptions: {
                    label: 'Cell Phone Number',
                    required: true,
                    type: 'tel',
                },
            },
        ];
    }

    initialize(): DynamicFormStep {

        this.formGroup = this.createForm();


        return {
            title: this.title,
            formStep: {
                key: this.key,
                title: this.title,
                formGroup: this.formGroup,
                fields: this.fields,
                model: this.model, // Add this line
                initialize: () => this.initialize(),
                createForm: () => this.createForm()
            }
        };
    }


    createForm(): FormGroup {
        return new FormGroup({
            zipCode: new FormControl(this.model?.zipCode, Validators.required), // Add default value for zipCode
            cell: new FormControl(this.model?.cell, Validators.required), // Add default value for cell
        });
    }

    getFields(): FormlyFieldConfig[] { // Implement the abstract method
        return this.fields;
    }
}
