
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Tag } from 'src/app/shared/models/tag.interface';


export interface ProductTagsState extends EntityState<Tag> {
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<Tag> = createEntityAdapter<Tag>();

export const initialState: ProductTagsState = adapter.getInitialState({
  loading: false,
  error: null,
});
