import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "../../../core/models/menu-item.interface";
import { ButtonClickEvent } from "../../store/button/button.actions";
@Component({
  selector: "app-menu",
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() menuItems: MenuItem[] = [];
  @Input() isVertical: boolean = false;
  @Input() menuType: 'top' | 'footer' = 'top'
  @Input() switcherClassApplied: boolean = false;
  @Input() classApplied: boolean = false;
  @Input() customClass: string = '';
  constructor(private router: Router) { }

  onRequestAppointment(event: ButtonClickEvent) {
    // console.log('Button clicked:', event);
    this.router.navigate(['/appointments']);
  }

}
