import { createAction, props } from '@ngrx/store';
import { Category} from '../models/category.interface';

export const resetCategories = createAction('[Category] Reset Categories');
export const loadCategories = createAction('[Category] Load Categories');
export const loadCategoriesSuccess = createAction(
  '[Category] Load Categories Success',
  props < { categories: Category[] }> ()
);
export const loadCategoriesFailure = createAction(
  '[Category] Load Categories Failure',
  props<{ error: any }>()
);

export const loadCategoryChildren = createAction(
  '[Category] Load Category Children',
  props<{ categoryId: string, childrenUrl: string }>()
);
export const loadCategoryChildrenSuccess = createAction(
  '[Category] Load Category Children Success',
  props<{ categoryId: string, children: Category[] }>()
);
export const loadCategoryChildrenFailure = createAction(
  '[Category] Load Category Children Failure',
  props<{ error: any }>()
);

export const updateCategory = createAction(
  '[Category] Update Category',
  props<{ category: Category }>()
);
export const updateCategorySuccess = createAction(
  '[Category] Update Category Success',
  props<{ category: Category }>()
);
export const updateCategoryFailure = createAction(
  '[Category] Update Category Failure',
  props<{ error: any }>()
);

export const deleteCategory = createAction(
  '[Category] Delete Category',
  props<{ id: string }>()
);
export const deleteCategorySuccess = createAction(
  '[Category] Delete Category Success',
  props<{ id: string }>()
);
export const deleteCategoryFailure = createAction(
  '[Category] Delete Category Failure',
  props<{ error: any }>()
);

export const saveCategoryFormState = createAction(
  '[Category] Save Category Form State',
  props<{ category: Category }>()
);

export const loadCategoryFormState = createAction('[Category] Load Category Form State');
export const clearCategoryFormState = createAction('[Category] Clear Category Form State');




