// option-hash.state.ts

import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { OptionHashOption } from '../models/option-hash.interface'

export interface OptionHashState extends EntityState<OptionHashOption> {
  loading: boolean;
  error: any;
}

export const optionAdapter = createEntityAdapter<OptionHashOption>({
  selectId: (option: OptionHashOption) => option.option_code,
});

export const initialOptionHashState: OptionHashState = optionAdapter.getInitialState({
  loading: false,
  error: null,
});
