<form [formGroup]="benefitsForm" class="mt-3">
    <!-- Header Row -->
    <div class="row mb-2" *ngIf="benefitsArray !=null && benefitsArray.length > 0">
      <div class="col">
        <strong>Benefit Name</strong>
      </div>
      <div class="col">
        <strong>Benefit Description</strong>
      </div>
      <div class="col-auto">
        <strong>Actions</strong>
      </div>
    </div>
    <div class="row mb-2" *ngIf="benefitsArray !=null && benefitsArray.length === 0">
      <div class="col">
        <p>No benefits added yet.</p>
      </div>
    </div>
  
    <!-- Benefits Rows -->
    <div formArrayName="benefits" cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let specGroup of benefitsArray.controls; let i = index" [formGroupName]="i" cdkDrag>
        <div class="card mb-3">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto text-center">
                <i class="fas fa-grip-vertical" cdkDragHandle></i> <!-- Font Awesome icon for drag handle -->
              </div>
              <!-- Benefit Name -->
              <div class="col">
                <textarea formControlName="title" (change)="emitChanges()" placeholder="Benefit Name" class="form-control" aria-label="Benefit Name" rows="2"></textarea>
              </div>
              <!-- Benefit Value -->
              <div class="col">
                <textarea formControlName="description" (change)="emitChanges()" placeholder="Benefit Description" class="form-control" aria-label="Benefit Value" rows="2"></textarea>
              </div>
              <!-- Remove Button -->
              <div class="col-auto">
                <button type="button" (click)="removeBenefit(i)" class="btn btn-danger btn-sm">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Footer Buttons -->
    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
      <button type="button" (click)="addBenefit()" class="btn btn-outline-secondary">Add Benefit</button>
    </div>
  </form>
  