import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss']
})
export class ContentEditorComponent {
  @Output() save = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Input() isFormValid: boolean = true;
  @Input() disableSave: boolean = false;
  onSave() {
    this.save.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}