import { Component, Input } from '@angular/core';
import { PostSearchResult } from 'src/app/shared/models/post-search-result.interface';

@Component({
  selector: 'app-post-search-result',
  templateUrl: './post-search-result.component.html',
  styleUrls: ['./post-search-result.component.scss']
})
export class PostSearchResultComponent {
  @Input() result!: PostSearchResult;
}

