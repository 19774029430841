import { Component, Input, OnInit } from '@angular/core';
import { Post } from 'src/app/shared/models/post.interface';
import { ImageCompressionService } from 'src/app/core/services/image-compression.service';

@Component({
  selector: 'app-post-list-widget',
  templateUrl: './post-list-widget.component.html',
  styleUrls: ['./post-list-widget.component.scss']
})
export class PostListWidgetComponent implements OnInit {
  @Input() posts: Post[] = [];
  @Input() maxArticles: number = 0;

  constructor(private imageCompressionService: ImageCompressionService) {}

  ngOnInit(): void {
    this.compressPostImages();
  }

  async compressPostImages() {
    for (let post of this.posts) {
      if (post.image) {
        post.image = await this.imageCompressionService.fetchAndCompressImage(post.image);
      }
    }
  }
}