<div class="d-flex align-items-center">
    <div class="flex-column w-100">
        <h4 [innerHTML]="'Blog Post: ' + result._highlightResult.title?.value"></h4>
        <p [innerHTML]="result._highlightResult.content?.value | highlightedResult"></p>
    </div>
    <div class="ms-auto">
        <div *ngIf="result.slug" [routerLink]="['/news', result.slug]" target="_blank"
            class="btn btn-info btn-sm text-nowrap" style="margin-left: 20px;">
            View Blog Post
        </div>
    </div>
</div>