import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef, SecurityContext } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { WindowService } from 'src/app/core/services/window.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { Post } from 'src/app/shared/models/post.interface';
import { BlogPostingSchema } from 'src/app/shared/models/schema-types.interface';
import { HtmlToTextPipe } from 'src/app/shared/pipes/html-to-text.pipe';
declare let window: any;

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnChanges, OnInit {
  @Input() post: Post;
  breadcrumbs: Breadcrumb[] = [];
  bxsUser: SafeHtml;
  sanitizedPostContent: SafeHtml;

  constructor(
    private metaTagsService: MetaTagsService,
    private schemaService: SchemaService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private htmlToTextPipe: HtmlToTextPipe,
    private changeDetector: ChangeDetectorRef,
    private windowService: WindowService,
  ) {}

  private buildPostUrl(slug: string): string {
    const baseUrl = window.location.protocol + '//' + window.location.host;
    return `${baseUrl}/news/${slug}`;
  }

  ngOnInit() {
    if (this.post) {
      const newMetaTags = {
        title: this.post.title,
        description: this.htmlToTextPipe.transform(this.post.meta_description),
        keywords: '',
        'og:title': this.post.title,
        'og:description': this.htmlToTextPipe.transform(this.post.meta_description),
        'og:image': this.post.image ? `${this.post.image}` : `${window.location.origin}/assets/images/default_abstract.jpg`,
        'og:url': this.buildPostUrl(this.post.slug),
        'og:type': 'article'
      };

      this.metaTagsService.updateMetaTags(newMetaTags);
    }
    this.httpClient.get('assets/icons/bxs-user.svg', { responseType: 'text' })
        .subscribe(
            data => {
                const svgWithTitle = data.replace('<svg', '<svg aria-labelledby="iconTitle" ').replace('<title>', '<title id="iconTitle">');
                this.bxsUser = this.sanitizer.bypassSecurityTrustHtml(svgWithTitle);
            }
        );
  }

  get prerenderReady(): boolean {
    return window.prerenderReady;
}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.post && this.post) {
      this.sanitizeContent()
      const newMetaTags = {
        title: this.post.title,
        description: this.htmlToTextPipe.transform(this.post.meta_description),
        keywords: '',
        'og:title': this.post.title,
        'og:description': this.htmlToTextPipe.transform(this.post.meta_description),
        'og:image': this.post.image ? `${this.post.image}` : `${window.location.origin}/assets/images/default_abstract.jpg`,
        'og:url': this.buildPostUrl(this.post.slug),
        'og:type': 'Article'
      };

      this.metaTagsService.updateMetaTags(newMetaTags);
      
      this.breadcrumbs = [
        { label: 'Home', url: '/' },
        { label: this.post.title, url: '/news-events/' + this.post.slug },
      ];

      const postUrl = this.buildPostUrl(this.post.slug);

      const blogPostingSchema: BlogPostingSchema = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        'headline': this.post.title,
        'description': this.post.meta_description,
        'datePublished': this.post.published_at,
        'dateModified': this.post.modified_date,
        'author': {
          '@type': 'Person',
          'name': `${this.post.author.user.first_name} ${this.post.author.user.last_name}`
        },
        'mainEntityOfPage': {
          '@type': 'WebPage',
          '@id': postUrl
        },
        'url': postUrl
      };

      this.schemaService.updateSchema(blogPostingSchema);
      this.windowService.windowRef.prerenderReady = true;
    }
  } 

  private sanitizeContent(): void {
    if (this.post && this.post.content) {
      this.sanitizedPostContent = this.sanitizer.sanitize(SecurityContext.HTML, this.post.content);
    }
  }
}

