import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../store/app.state";
import {
  selectHasRoleFor,
  selectHasPermissionFor,
  selectRoles,
  selectPermissions,
  AccessOperation,
  RolesPermissionsService
} from "@fluentllc/core";
import { map, Observable, take, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { combineLatest, of } from "rxjs";
import { MenuItem } from "../models/menu-item.interface";
import { MenuUtility } from "../utils/menu-utils";
// import { RolesPermissionsService } from "./roles-permissions.service";

@Injectable({ providedIn: "root" })
export class MenuService {
  constructor(
    private rolesPermissionsService: RolesPermissionsService,
    private menuUtility: MenuUtility,
    private store: Store<AppState>,
    private http: HttpClient) {
    this.disableFunctions.disableIfUserDelete = () => {
      let disabled = false;
      this.hasPermission("user_delete").subscribe((hasPermission) => {
        disabled = hasPermission;
      });
      return disabled;
    };
  }
  private menuConfigUrl = "assets/menu-config.json";
  private disableFunctions = {
    disableIfUserDelete: this.disableIfUserDelete,
  };
  private disableIfUserDelete(): boolean {
    const userPermissions = this.store.select(selectPermissions);
    return true;
  }
  // Check if the user has a specific role
  private hasRole(role: string): Observable<boolean> {
    return this.store.select(selectHasRoleFor(role)).pipe(take(1));
  }

  // Check if the user has a specific permission
  private hasPermission(permission: string): Observable<boolean> {
    return this.store.select(selectHasPermissionFor(permission)).pipe(take(1));
  }

  isMenuItemDisabled(menuItem: MenuItem): Observable<boolean> {
    if (menuItem.disabledFunction) {
      const disableFn = this.disableFunctions[menuItem.disabledFunction];
      if (disableFn) {
        return of(disableFn());
      }
    }

    if (menuItem.disabledPermission) {
      return this.hasPermission(menuItem.disabledPermission).pipe(map((hasPermission) => !hasPermission));
    }

    return of(false);
  }

  // Determine if a menu item should be visible based on the user's roles and permissions
  private isMenuItemVisible(menuItem: MenuItem): Observable<boolean> {
    const roleObservables = menuItem.roles?.map((role) => this.hasRole(role)) || [];
    const permissionObservables = menuItem.permissions?.map((permission) => this.hasPermission(permission)) || [];

    const hasRequiredRole$ = roleObservables.length
      ? menuItem.rolesOp === "AND"
        ? combineLatest(roleObservables).pipe(map((results) => results.every(Boolean)))
        : combineLatest(roleObservables).pipe(map((results) => results.some(Boolean)))
      : of(true);

    const hasRequiredPermission$ = permissionObservables.length
      ? menuItem.permissionsOp === "AND"
        ? combineLatest(permissionObservables).pipe(map((results) => results.every(Boolean)))
        : combineLatest(permissionObservables).pipe(map((results) => results.some(Boolean)))
      : of(true);

    return combineLatest([hasRequiredRole$, hasRequiredPermission$]).pipe(
      map(([hasRequiredRole, hasRequiredPermission]) => {
        let accessCheck = true;

        if (menuItem.operator === "OR") {
          accessCheck = hasRequiredRole || hasRequiredPermission;
        } else if (menuItem.operator === "AND") {
          accessCheck = hasRequiredRole && hasRequiredPermission;
        } else {
          accessCheck = hasRequiredRole || hasRequiredPermission;
        }
        return accessCheck;
      })
    );
  }

  private sortMenuItems(menuItems: MenuItem[]): MenuItem[] {
    return menuItems
      .sort((a, b) => {
        const aOrder = a.hasOwnProperty("order") && a.order !== null ? a.order : Number.MAX_VALUE;
        const bOrder = b.hasOwnProperty("order") && b.order !== null ? b.order : Number.MAX_VALUE;

        if (aOrder !== Number.MAX_VALUE || bOrder !== Number.MAX_VALUE) {
          if (aOrder !== bOrder) {
            return aOrder - bOrder;
          }
          return a.label.localeCompare(b.label);
        }
        return 0;
      })
      .map((item) => {
        if (item.children) {
          return { ...item, children: this.sortMenuItems(item.children) };
        }
        return item;
      });
  }

  checkMenuItemAccess(menuItem: MenuItem): Observable<boolean> {
    return this.rolesPermissionsService.checkRolesAndPermissions(
      menuItem.roles,
      menuItem.permissions,
      menuItem.rolesOp || AccessOperation.OR,
      menuItem.permissionsOp || AccessOperation.OR,
      menuItem.operator || AccessOperation.OR
    );
  }

  //Filter the menu items based on their visibility
  // private filterMenuItems(menuItems: MenuItem[]): Observable<MenuItem[]> {
  //   const visibility$ = menuItems.map((item) =>
  //     this.isMenuItemVisible(item).pipe(
  //       switchMap((isVisible) => {
  //         if (isVisible && item.children) {
  //           return this.filterMenuItems(item.children).pipe(
  //             switchMap((filteredChildren) => {
  //               const sortedChildren = this.sortMenuItems(filteredChildren);
  //               return this.isMenuItemDisabled(item).pipe(
  //                 map((disabled) => {
  //                   return { ...item, children: sortedChildren, disabled };
  //                 })
  //               );
  //             })
  //           );
  //         }
  //         return this.isMenuItemDisabled(item).pipe(
  //           map((disabled) => {
  //             return isVisible ? { ...item, disabled } : null;
  //           })
  //         );
  //       })
  //     )
  //   );

  //   return combineLatest(visibility$).pipe(map((items) => items.filter((item) => item !== null)));
  // }

  private filterMenuItems(menuItems: MenuItem[]): Observable<MenuItem[]> {
    const visibility$ = menuItems.map((item) =>
      this.menuUtility.isMenuItemVisible(item).pipe(
        switchMap(isVisible => {
          if (!isVisible) {
            return of(null); // If not visible, return null to filter out the item
          }

          // Check access based on roles and permissions
          return this.checkMenuItemAccess(item).pipe(
            switchMap(hasAccess => {
              if (!hasAccess) {
                return of(null); // If no access, return null to filter out the item
              }

              // Handle children and disabled state
              if (item.children) {
                return this.filterMenuItems(item.children).pipe(
                  switchMap(filteredChildren => {
                    const sortedChildren = this.menuUtility.sortMenuItems(filteredChildren);
                    return this.isMenuItemDisabled(item).pipe(
                      map(disabled => ({ ...item, children: sortedChildren, disabled }))
                    );
                  })
                );
              }

              return this.isMenuItemDisabled(item).pipe(
                map(disabled => ({ ...item, disabled }))
              );
            })
          );
        })
      )
    );

    return combineLatest(visibility$).pipe(
      map(items => items.filter(item => item !== null) as MenuItem[])
    );
  }

  // Get the menu items based on the provided context (e.g. 'global', 'dashboard', 'profile')
  getMenuItems(context?: string): Observable<MenuItem[]> {
    return combineLatest([
      this.http.get<{ [key: string]: MenuItem[] }>(this.menuConfigUrl),
      this.store.select(selectRoles),
      this.store.select(selectPermissions),
    ]).pipe(
      switchMap(([menuConfig]) => {
        const menuContext = context || "global";
        const menuItems = menuConfig[menuContext] || [];


        return this.filterMenuItems(menuItems);
      }),
      switchMap((menuItems) => this.filterMenuItems(menuItems)),
      map((filteredItems) => this.sortMenuItems(filteredItems))
    );
  }
}
