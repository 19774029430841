import { Component, Input } from '@angular/core';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';

@Component({
  selector: 'app-job-posting-detail',
  templateUrl: './job-posting-detail.component.html',
  styleUrls: ['./job-posting-detail.component.scss']
})
export class JobPostingDetailComponent {
  @Input() job: JobPosting;
}
