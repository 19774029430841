import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CategoryEffects } from './category.effects';
import * as Categories from './category.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(Categories.CategoryFeatureKey, Categories.CategoryReducer),
        EffectsModule.forFeature([CategoryEffects]),
    ],
})
export class CategoriesStoreModule { }
