<ng-container *ngIf="searchPerformed">
    <h3>Filters</h3>

    <!-- Bootstrap 5 Dropdown -->
    <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false">
            Filter by type
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li *ngFor="let item of searchIndexes" class="dropdown-item">
                <label class="c-checkbox">
                    <input type="checkbox" value="" id="checkbox{{item.index}}"
                        [(ngModel)]="indexCheckStates[item.index]" (change)="onIndexChange($event, item.index)">
                    <span class="fas fa-check"></span>{{item.display}}
                </label>

            </li>
        </ul>
    </div>
</ng-container>