import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchRoutingModule } from './search-routing.module';
import { SearchStoreModule } from './store/search-store.module';
import { SearchResultsContainerComponent } from './containers/search-results-container/search-results-container.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SearchService, SEARCH_SERVICE } from 'src/app/core/services/search/abstract-search.service';
import { AlgoliaSearchService } from 'src/app/core/services/search/algolia-search.service';
import { FormsModule } from '@angular/forms';
import { HighlightedResultPipe } from 'src/app/shared/pipes/highlighted-result.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchComponent } from './components/search/search.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { ProductSearchResultComponent } from './components/product-search-result/product-search-result.component';
import { SupportGuideSearchResultComponent } from './components/support-guide-search-result/support-guide-search-result.component';
import { SearchNavbarWidgetComponent } from './components/search-navbar-widget/search-navbar-widget.component';
import { FAQSearchResultComponent } from './components/faqsearch-result/faqsearch-result.component';
import { PostSearchResultComponent } from './components/post-search-result/post-search-result.component';

@NgModule({
  declarations: [
    SearchResultsContainerComponent,
    SearchResultsComponent,
    SearchComponent,
    SearchFilterComponent,
    ProductSearchResultComponent,
    SupportGuideSearchResultComponent,
    SearchNavbarWidgetComponent,
    FAQSearchResultComponent,
    PostSearchResultComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SearchRoutingModule,
    SearchStoreModule,
    FormsModule,
  ],
  exports: [
    SearchNavbarWidgetComponent,
    SearchResultsComponent,
    SearchComponent
  ],
  providers: [
    // { provide: SEARCH_SERVICE, useClass: AlgoliaSearchService },
  ],
})
export class SearchModule { }
