import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Post } from 'src/app/shared/models/post.interface';
import { loadPostsByBlogId } from '../../store/post.actions';
import { selectAllPosts, selectPublicPosts } from '../../store/post.selectors';

@Component({
  selector: 'app-post-list-container',
  templateUrl: './post-list-container.component.html',
  styleUrls: ['./post-list-container.component.scss']
})
export class PostListContainerComponent implements OnInit {
  posts$: Observable<Post[]>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadPostsByBlogId({ blogId: 1 }));
    this.posts$ = this.store.select(selectPublicPosts);
  }
}
