import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { ProductTagsEffects } from './product-tags.effects';
import * as ProductTags from './product-tags.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ProductTags.productTagsFeatureKey, ProductTags.ProductTagsReducer),
        EffectsModule.forFeature([ProductTagsEffects]),
    ],
})
export class ProductTagsStoreModule { }
