import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DemoRequestState, adapter } from './appointment-scheduler.state';

const getDemoRequestState = createFeatureSelector<DemoRequestState>('demoRequests');

// Use the entity adapter to create selector functions
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const getDemoRequestsLoading = createSelector(
    getDemoRequestState,
    (state: DemoRequestState) => state.loading
);

export const getDemoRequestsError = createSelector(
    getDemoRequestState,
    (state: DemoRequestState) => state.error
);

export const selectDemoRequestIds = createSelector(
    getDemoRequestState,
    selectIds // shorthand for state => selectIds(state)
);

export const selectDemoRequestEntities = createSelector(
    getDemoRequestState,
    selectEntities // shorthand for state => selectEntities(state)
);

export const selectAllDemoRequests = createSelector(
    getDemoRequestState,
    selectAll // shorthand for state => selectAll(state)
);

export const selectDemoRequestTotal = createSelector(
    getDemoRequestState,
    selectTotal // shorthand for state => selectTotal(state)
);

export const getSuccessMessage = createSelector(
    getDemoRequestState,
    (state: DemoRequestState) => state.successMessage
);