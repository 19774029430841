<div class="container">
  <div class="row align-items-top pb-100 pt-50">
    <div class="col-lg-7 col-md-12">
      <div class="about-content">
        <app-title-hashtag [hashtagSubtitle]="subtitle"></app-title-hashtag>
        <app-title [title]="title" [titleHighlight]="titleHighlight"></app-title>
        <div class="mb-4">
          <h5 [innerHTML]="description"></h5>
          <div class="d-flex justify-content-start pt-30 pb-30"><app-call-to-action-button-container
              ctaUrl="/consumables/tubes" ctaTitle="View Our Tubes"></app-call-to-action-button-container></div>
        </div>
        <div class="pb-10">
          <p>Scinomix offers a variety of high-quality, ultra-pure polypropylene tubes
            validated for laboratory automation. Our commitment to optimizing your
            laboratory efficiency led us to create a tube product line that is manufactured in
            the US and readily available. Our short lead times and competitive pricing allow
            you to streamline your supply chain.</p>
          <!-- <ul>
                    <li>High-quality, ultra-pure polypropylene tubes</li>
                    <li>Manufactured in the US and readily available</li>
                    <li>Short lead times and competitive pricing</li>
                </ul>
                <p>Our tubes are compatible with a variety of laboratory automation systems and
                    are available in a variety of sizes and colors. We offer sterile and non-sterile
                    options to meet your specific needs. Our tubes are manufactured in a
                    controlled environment and are certified free of RNase, DNase, and
                    endotoxins.</p> -->

        </div>
        <div class="pb-50">
          <app-bullet-features-container layout="horizontal-icon-above" [customItems]="customItems"></app-bullet-features-container>
        </div>
        


        <div class="pb-20">
          <app-call-to-action-small-with-icon1-container ctaTitle="Tubes Order Form"
            ctaUrl="assets/Tube%20Order%20Form.pdf">
          </app-call-to-action-small-with-icon1-container>

        </div>
        <div class="d-flex justify-content-start">
          <div class="wd-auto">
            <h6 class="pb-20"><a href="tel:+13142989800">Call +1.314.298.9800</a> or <a
                href="mailto:sales@scinomix.com">Email sales@scinomix.com</a> for more information </h6>
          </div>
        </div>
        <div class="mb-1"><strong>Quick Links:</strong></div>
        <ul>
          <li><a routerLink="/consumables/screw-cap-microtubes" fragment="screw-cap-microtbues">Screw Cap Cryofreeze Microtubes &
              Caps (0.5mL - 2.0mL)</a></li>

          <li><a routerLink="/consumables/cryo-tubes" fragment="cryo-tubes">Cryofreeze Tubes (1mL - 4.5mL)</a></li>
          <li><a routerLink="/consumables/centrifuge-tubes" fragment="centrifuge-tubes">Conical Tubes (15mL & 50mL)</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-5 col-md-12">
      <div class="about-image mb-4">
        <img [src]="heroImage" [alt]="title" class="img-fluid">
      </div>
      <div class="card mb-5">
        <div class="card-body p-30">
          <div class="section-title with-desc-clearfix">
            <div class="title-header">
              <h5 class="title">Request a Quote</h5>
            </div>
          </div>
          <div id="hubspotForm"></div>
        </div>
      </div>


    </div>
  </div>
  <!-- <div class="row">
        <div class="col-lg-12">
            <app-tube-sublisting
            keyword="screw-cap-microtubes"
            title="Screw Cap Microtubes & Caps"
            subtitle="Capped or Uncapped • Sterile or Non-Sterile • 16 Cap Color Options"
          ></app-tube-sublisting>
          <app-tube-sublisting
            keyword="cryo-tubes"
            title="Cryofreeze Tubes"
            subtitle="Internally or Externally Threaded • Capped & Sterilized"
          ></app-tube-sublisting>
          <app-tube-sublisting
            keyword="centrifuge-tubes"
            title="Conical Tubes"
            subtitle="Externally-threaded • Capped with Blue Cap"
          ></app-tube-sublisting>
          <app-tube-sublisting
            keyword="cryo-freezer-racks"
            title="Cryo Freezer Racks"
            subtitle="1.0 / 1.8mL Tubes • 81-Place • 100-Place • Assorted colors"
          ></app-tube-sublisting>
        </div>
    </div> -->
</div>