<div #container style="position:relative; height:0; padding-top:56.25%">
    <iframe #scinomixExperience style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        src="/assets/vr/360/20230531/index.htm" name="The Scinomix Experience" frameborder="0" allowfullscreen="true"
        allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"></iframe>
</div>
<div class="ptb-10 d-flex align-items-center justify-content-end" [tooltip]="tooltip" placement="right"
    triggers="hover click" container="body">
    <span class="small pr-10">
        The Scinomix Experience: What is the purpose of the AR and VR buttons?
    </span>
    <i class="fas fa-info-circle" size="lg"></i>
</div>