import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { parsePhoneNumber } from 'libphonenumber-js';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { DemoRequest } from '../../models/demo-request.interface';
import { stateRequiredValidator } from './../../validators/custom-validators';
import { Country } from '../../models/country.interface';
import { State } from '../../models/state.interface';
import { CountryService } from 'src/app/core/services/country.service';


@Component({
  selector: 'app-virtual-demo-form',
  templateUrl: './virtual-demo-form.component.html',
  styleUrls: ['./virtual-demo-form.component.scss'],
})
export class VirtualDemoFormComponent {
  @Output() formSubmit: EventEmitter<DemoRequest> = new EventEmitter();
  form: FormGroup;
  model: DemoRequest;
  datePickerConfig: Partial<BsDatepickerConfig>;
  bsValue = '';
  minDate: Date;
  formattedPhoneNumber: string = '';
  selectedCountryCode: string;
  countries: Country[] = [];
  states: State[] = [];
  showStateDropdown: boolean = false;
  
  @Input() successMessage: string | null;
  @Input() submitted: boolean = false;
  constructor(fb: FormBuilder, private bsLocaleService: BsLocaleService, private countryService: CountryService) {
    this.form = fb.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      company: [null, Validators.required],
      requested_date_time: [null],
      phone: [
        null
      ],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      message: [null],
      country: [null, Validators.required],
      stateUs: [null, stateRequiredValidator()],
    }, { updateOn: 'blur' });
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 2);
    this.datePickerConfig = {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      returnFocusToInput: true,
      dateInputFormat: 'MM/DD/YYYY',
      adaptivePosition: true
      // configure other datepicker options as needed
    };
  }

  ngOnInit(): void {
    defineLocale('en-gb', enGbLocale);
    this.bsLocaleService.use('en-gb');
    this.countries = this.countryService.getCountries();
    this.form.controls['country'].setValue('US'); // Set default country to US
    this.states = this.countryService.getStatesByCountry('US'); // Load states for US
    this.showStateDropdown = true; // Show state dropdown for US
  }

  onCountryChange(event: any): void {
    const selectedCountry = event.code;
    this.states = this.countryService.getStatesByCountry(selectedCountry);
    this.showStateDropdown = selectedCountry === 'US' || selectedCountry === 'CA';
    if (!this.showStateDropdown) {
      this.form.controls['stateUs'].setValue(null);
    }
  }

  isInvalidAndTouched(name: string): boolean {
    const control = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName] &&
      this.form.controls[controlName].hasError(errorName) &&
      (this.form.controls[controlName].dirty ||
        this.form.controls[controlName].touched);
  }

  onPhoneInputChange(event: Event): void {
    const inputNumber = (event.target as HTMLInputElement).value;
    const selectedCountry = this.form.value.country;
    const parsedPhoneNumber = parsePhoneNumber(inputNumber, selectedCountry);
    if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
      this.form.controls['phone'].setValue(parsedPhoneNumber.formatInternational());
      this.form.controls['phone'].setErrors(null);
    } else {
      this.form.controls['phone'].setErrors({ invalid: true });
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      const demoRequest: DemoRequest = {
        firstname: this.form.value.firstname,
        lastname: this.form.value.lastname,
        email: this.form.value.email,
        phone: this.form.value.phone,
        company: this.form.value.company,
        requested_date_time: this.form.value.requested_date_time,
        message: this.form.value.message,
        country_iso_alpha2: this.form.value.country,
        state_us: this.showStateDropdown ? this.form.value.stateUs : ''
      };

      this.formSubmit.emit(demoRequest);
      this.form.reset();
    }
  }
}
