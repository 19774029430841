import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { DistributorsEffects } from './distributors.effects';
import * as distributors from './distributors.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(distributors.distributorFeatureKey, distributors.distributorReducer),
    EffectsModule.forFeature([DistributorsEffects]),
  ],
})
export class DistributorsStoreModule { }