<div class="container">
    <div class="row">
        <div class="col-lg-7 ptb-75">
            <h1>The Story Behind Scinomix</h1>
            <h3>How Did Scinomix Get Started?</h3>
            <p>Scinomix started out by providing custom solutions for labs. This consisted of customized machines for a
                variety of applications including cell biology and liquid handling. In cell biology; customized machines
                for cell growth, cell lysis, and cell harvesting. In liquid handling; customized systems for media
                filling machines (agar, diet, etc.), petri dishes, micro-plates, tubes/vials, urinalysis, excipient
                mixer, and high throughput filling.</p>
            <p>Over the years, through developing custom projects, it was clear there was a need for automated tube and
                microplate labelers in labs. In 2009, the first Sci-Print MP2 was sold. The Sci-Print MP2 is a
                semi-automated microplate labeler that prints and applies labels onto a variety of microtiter plates for
                sample tracking. One year later, the first automated vial labelers were sold; the Sci-Print VX2
                (fully-automated tube labeler) and the Sci-Print SOLO (semi-automated tube labeler). From this point on,
                developing a standard line of automated vial and plate labelers became the main focus.</p>
            <h3>Where Did the Name “Scinomix” Come From</h3>
            <p>This is a frequently asked question, so we thought it necessary to explain. It’s actually pretty simple –
                “Sci” stands for Science, and “nomix” stands for Genomics. So, put the two together, and you get
                SCINOMIX!</p>
            <h3>How Do You Pronounce Scinomix?</h3>
            <p>
                The name "Scinomix" is a blend of the words "Science" and "Genomics". It would be pronounced as
                "Sci-nom-ix". The "Sci" part is pronounced like the word "sigh", the "nom" part is pronounced like the
                word "gnome", and the "ix" part is pronounced like "mix". So, all together, it's "Sigh-nome-ix".</p>
            <h3>Plans for the Future</h3>
            <p>Scinomix has recently expanded its reach globally. Plans for the future are to maintain and continue that
                growth. Innovation and creativity continue to play a strong role in our vision for the future. From the
                beginning, our mission has always been to provide solutions for customers, and that will always be the
                driving force behind Scinomix.</p>
        </div>
        <div class="col-lg-5 ptb-75">
            <img src="/assets/images/scinomix-story.jpg" alt="The Scinomix Story" width="100%">
        </div>
    </div>
</div>