import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScinomixAdminRoutingModule } from './scinomix-admin-routing.module';
import { NgxSummernoteModule } from 'ngx-summernote';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    NgxSummernoteModule,
    ScinomixAdminRoutingModule
  ]
})
export class ScinomixAdminModule { }
