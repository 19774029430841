import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  get windowRef(): any {
    return isPlatformBrowser(this.platformId) ? window : undefined;
  }

  get prerenderReady(): boolean {
    return this.windowRef ? this.windowRef.prerenderReady : false;
  }
}
