<div class="services-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <app-section-card [icon]="serviceAgreementIcon" iconSize="1x"
                    link="/services-support/service-agreement"></app-section-card>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <app-section-card image="assets/images/scinomix-lab-automation-faq.jpg" title="FAQs"
                    description="Explore frequently asked questions and their answers concerning Scinomix equipment."
                    [icon]="faqIcon" link="/services-support/faq"></app-section-card>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <app-section-card title="Troubleshooting" image="assets/images/troubleshooting.jpg"
                    description="Explore our comprehensive troubleshooting guides and solutions to help solve your issue."
                    [icon]="infoIcon" iconSize="1x" link="/services-support/troubleshooting"></app-section-card>
            </div>
        </div>
    </div>
</div>