<div>
    <div class="pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card card-default">
                        <!-- Card Header with New Post Button -->
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <div>Layout Manager</div>
                            <button class="btn btn-secondary" (click)="create.emit()">New Layout</button>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <!-- Table Head -->
                                    <thead>
                                        <tr>
                                            <th>Layout Name</th>
                                            <th>Actions</th>
                                    </thead>
                                    <!-- Table Body -->
                                    <tbody>
                                        <tr *ngFor="let layout of layouts">
                                            <td>{{ layout.name }}</td>
                                            <td>
                                                <div class="action-buttons">
                                                    <button class="btn btn-secondary btn-sm" (click)="edit.emit(layout.id)">Edit</button>
                                                    <button class="btn btn-danger btn-sm" (click)="delete.emit(layout.id)">Delete</button>
                                                  </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Card Footer -->
                        <div class="card-footer">
                            <!-- <app-pagination
                                [paginationInfo]="paginationInfo"
                                (pageChanged)="onPageChange($event)"
                                (itemsPerPageChanged)="onItemsPerPageChange($event)"
                            ></app-pagination> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
