import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardContainerComponent } from '../dashboard/containers/dashboard-container/dashboard-container.component';
import { DashboardComponent } from '../dashboard/components/dashboard/dashboard.component';
import { PostAdminListContainerComponent } from '../blog/containers/post-admin-list-container/post-admin-list-container.component';
import { AuthenticatedGuard, RolePermissionGuard } from '@fluentllc/core';
import { PostManageContainerComponent } from '../blog/containers/post-manage-container/post-manage-container.component';
import { ProductAdminListContainerComponent } from '../products/containers/product-admin-list-container/product-admin-list-container.component';
import { ProductAdminManageContainerComponent } from '../products/containers/product-admin-manage-container/product-admin-manage-container.component';
import { JobPostingListContainerComponent } from '../careers/containers/job-posting-list-container/job-posting-list-container.component';
import { JobPostingAdminListContainerComponent } from '../careers/containers/job-posting-admin-list-container/job-posting-admin-list-container.component';
import { JobPostingAdminManageContainerComponent } from '../careers/containers/job-posting-admin-manage-container/job-posting-admin-manage-container.component';
import { TroubleshootingAdminListContainerComponent } from '../service-support/containers/troubleshooting-admin-list-container/troubleshooting-admin-list-container.component';
import { TroubleshootingAdminManageContainerComponent } from '../service-support/containers/troubleshooting-admin-manage-container/troubleshooting-admin-manage-container.component';
import { LayoutContainerComponent } from '../landing-pages/containers/layout-container/layout-container.component';
import { LayoutAdminManageContainerComponent } from '../landing-pages/containers/layout-admin-manage-container/layout-admin-manage-container.component';
import { LandingPageAdminListContainerComponent } from '../landing-pages/containers/landing-page-admin-list-container/landing-page-admin-list-container.component';
import { LandingPageAdminManageContainerComponent } from '../landing-pages/containers/landing-page-admin-manage-container/landing-page-admin-manage-container.component';
import { LayoutAdminListContainerComponent } from '../landing-pages/containers/layout-admin-list-container/layout-admin-list-container.component';
import { CategoryAdminListContainerComponent } from '../products/containers/category-admin-list-container/category-admin-list-container.component';
import { CategoryAdminManageContainerComponent } from '../products/containers/category-admin-manage-container/category-admin-manage-container.component';
import { ProductAttributeValueAdminManageComponent } from '../products/components/product-attribute-value-admin-manage/product-attribute-value-admin-manage.component';

const routes: Routes = [
    {
      path: 'posts',
      component: PostAdminListContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ["role-tenant-admin", "role-manage-blogs"],
        permissions: ["blog_create", "blog_manage"],
        rolesOp: "OR",
        permissionsOp: "OR",
        operator: "OR"
      },
    },
    {
      path: 'post/manage/:slug',
      component: PostManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ["role-tenant-admin", "role-manage-blogs"],
        permissions: ["blog_create", "blog_manage"],
        rolesOp: "OR",
        permissionsOp: "OR",
        operator: "OR"
      },
    },
    {
      path: 'post/create', // For creating
      component: PostManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ["role-tenant-admin", "role-manage-blogs"],
        permissions: ["blog_create", "blog_manage"],
        rolesOp: "OR",
        permissionsOp: "OR",
        operator: "OR"
      },
    },
    {
      path: 'products/categories',
      component: CategoryAdminListContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-products"],
        operator: 'OR',
        permissions: ['product_list']
      },
    },
    {
      path: 'products/category/manage/:id',
      component: CategoryAdminManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-products"],
        operator: 'OR',
        permissions: ['product_modify']
      },
    },
    {
      path: 'products',
      component: ProductAdminListContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-products"],
        operator: 'OR',
        permissions: ['product_list']
      },
    },
    {
      path: 'product/manage/:slug',
      component: ProductAdminManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-products"],
        operator: 'OR',
        permissions: ['product_modify']
      },
    },
    {
      path: 'product/create', // For creating
      component: ProductAdminManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-products"],
        operator: 'OR',
        permissions: ['product_create']
      },
    },
    {
      path: 'product/attribute-values/manage/:code',
      component: ProductAttributeValueAdminManageComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-products"],
        operator: 'OR',
        permissions: ['product_modify']
      },
    },
    {
      path: 'jobs',
      component: JobPostingAdminListContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-careers"],
        // permissionsOp: 'AND',
        operator: 'OR',
        permissions: ['jobs_list']
      },
    },
    {
      path: 'job/create', // For creating
      component: JobPostingAdminManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-careers"],
        // permissionsOp: 'AND',
        operator: 'OR',
        permissions: ['jobs_create']
      },
    },
    {
      path: 'job/manage/:id',
      component: JobPostingAdminManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-careers"],
        // permissionsOp: 'AND',
        operator: 'OR',
        permissions: ['jobs_modify']
      },
    },
    {
      path: 'troubleshooting-guides',
      component: TroubleshootingAdminListContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-service"],
        // permissionsOp: 'AND',
        operator: 'OR',
        permissions: ['services_modify']
      },
    },
    {
      path: 'troubleshooting-guides/manage/:id',
      component: TroubleshootingAdminManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-service"],
        // permissionsOp: 'AND',
        operator: 'OR',
        permissions: ['services_modify']
      },
    },
    {
      path: 'troubleshooting-guides/create', // For creating
      component: TroubleshootingAdminManageContainerComponent,
      canActivate: [AuthenticatedGuard, RolePermissionGuard],
      data: {
        roles: ['role-tenant-admin', "role-manage-service"],
        // permissionsOp: 'AND',
        operator: 'OR',
        permissions: ['services_create', 'services_modify']
      },
    },
    {
      path: "landing-pages/layouts",
      component: LayoutAdminListContainerComponent
    },
    {
      path: "landing-pages/layout/:id",
      component: LayoutAdminManageContainerComponent
    },
    {
      path: "landing-pages/layout/create",
      component: LayoutAdminManageContainerComponent
    },
    {
      path: "landing-pages/pages",
      component: LandingPageAdminListContainerComponent
    },
    {
      path: "landing-pages/page/:id",
      component: LandingPageAdminManageContainerComponent
    }
  ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ScinomixAdminRoutingModule { }
