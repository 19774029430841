import { createAction, props } from '@ngrx/store';
import { OptionHashOption } from 'src/app/features/products/models/option-hash.interface'
export const loadOptionHash = createAction('[Option Hash] Load OptionHashs');
export const loadOptionHashSuccess = createAction(
  '[Option Hash] Load OptionHashs Success',
  props<{ optionHash: OptionHashOption[] }>()
);
export const loadOptionHashFailure = createAction(
  '[Option Hash] Load Option Hash Failure',
  props<{ error: any }>()
);
