import { Injectable, RendererFactory2, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HubSpotService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  push(args: any[]): void {
    const script = this.renderer.createElement('script');
    script.text = `
      if(!window._hsq){
        window._hsq = [];
      }
      window._hsq.push(${JSON.stringify(args)});
    `;
    this.renderer.appendChild(this.document.head, script);
  }
}
