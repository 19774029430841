import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaggingRoutingModule } from './tagging-routing.module';
// import { TaggingStoreModule } from './store/tagging-store.module';
import { SolutionTagsStoreModule } from './store/solution-tags-store.module';
import { SolutionTagsComponent } from './components/solution-tags/solution-tags.component';
import { TagsComponent } from './components/tags/tags.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductTagsComponent } from './components/product-tags/product-tags.component';
import { ProductTagsStoreModule } from './store/product-tags-store.module';

@NgModule({
  declarations: [
    SolutionTagsComponent,
    TagsComponent,
    SolutionTagsComponent,
    ProductTagsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TaggingRoutingModule,
    SolutionTagsStoreModule,
    ProductTagsStoreModule,
  ],
  exports: [
    SolutionTagsComponent,
    ProductTagsComponent
  ]
})
export class TaggingModule {}
