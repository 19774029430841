import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page-admin-list-container',
  templateUrl: './landing-page-admin-list-container.component.html',
  styleUrls: ['./landing-page-admin-list-container.component.scss']
})
export class LandingPageAdminListContainerComponent {

}
