import { createAction, props } from '@ngrx/store';

export const setImageSource = createAction(
    '[VR Viewer] Set Image Source',
    props<{ source: string }>()
);

export const clearImageSource = createAction('[VR Viewer] Clear Image Source');
export const setFallbackSource = createAction(
    '[VR Viewer] Set Fallback Source',
    props<{ source: string }>()
);

export const clearFallbackSource = createAction('[VR Viewer] Clear Fallback Image Source');
