// src/app/store/multi-step-form/multi-step-form.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as MultiStepFormActions from './multi-step-form.actions';
import { DynamicFormService } from 'src/app/core/services/dynamic-form.service';
import { Store } from '@ngrx/store';
import { selectCompletedSteps } from './multi-step-form.selectors';

@Injectable()
export class MultiStepFormEffects {
    submitForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MultiStepFormActions.submitForm),
            switchMap(({ formData, successCallback }) =>
                this.dynamicFormService.submitFormData(formData).pipe(
                    tap(() => successCallback()),
                    map(() => MultiStepFormActions.submitFormSuccess()),
                    catchError((error) =>
                        of(MultiStepFormActions.submitFormFailure({ error }))
                    )
                )
            )
        )
    );

    completeStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MultiStepFormActions.completeStep),
            withLatestFrom(this.store.select(selectCompletedSteps)),
            filter(([action, completedSteps]) => !completedSteps.includes(action.step)),
            map(([action]) => MultiStepFormActions.completeStep({
                step: action.step,
                data: action.data,
            }))
        )
    );

    constructor(
        private actions$: Actions,
        private dynamicFormService: DynamicFormService,
        private store: Store
    ) { }

    private getTotalSteps(): number {
        // Replace this with the actual number of steps in your form
        return 2;
    }
}
