<div *ngIf="products">
    <div class="blog-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card card-default">
                        <!-- Card Header with New Post Button -->
                        <div class="card-header d-flex justify-content-between align-items-center admin-list">
                            <div>Product Manager</div>
                            <button class="btn btn-secondary" (click)="create.emit()">New Product</button>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-md-12">
                                  <input type="text" class="form-control" placeholder="Search by keyword" [formControl]="keywordControl" (input)="onFilterChange()">
                                </div>
                              </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <!-- Table Head -->
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Last Updated</th>
                                            <th>Actions</th>
                                    </thead>
                                    <!-- Table Body -->
                                    <tbody>
                                        <tr *ngFor="let product of products">
                                            <td>{{ product.product_name }}</td>
                                            <td>{{ product.modified_date | date }}</td>
                                            <td>
                                                <div class="action-buttons">
                                                    <button class="btn btn-secondary btn-sm" (click)="edit.emit(product.slug)">Edit</button>
                                                    <button class="btn btn-danger btn-sm" (click)="delete.emit(product.id)">Delete</button>
                                                  </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Card Footer -->
                        <div class="card-footer">
                            <app-pagination
                                [paginationInfo]="paginationInfo"
                                (pageChanged)="onPageChange($event)"
                                (itemsPerPageChanged)="onItemsPerPageChange($event)"
                            ></app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
