// reducers/media.reducer.ts
import { createReducer, on } from "@ngrx/store";
import * as MediaActions from "./media.actions";
import { initialMediaState, adapter } from "./media.state";
import { createAction } from "@ngrx/store";

export const mediaFeatureKey = "media";

export const mediaReducer = createReducer(
  initialMediaState,
  on(MediaActions.loadMedia, (state) => ({ ...state, loading: true, error: null })),
  on(MediaActions.loadMediaSuccess, (state, { media }) =>
    adapter.setAll(media, { ...state, loading: false, error: null })
  ),
  on(MediaActions.loadMediaFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(MediaActions.loadMediaByTags, (state) => ({ ...state, loading: true, error: null })),
  on(MediaActions.loadMediaByTagsSuccess, (state, { media }) => adapter.setAll(media, { ...state, loading: false })),
  on(MediaActions.loadMediaByTagsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(MediaActions.updateMediaWeights, (state) => ({ ...state, loading: true })),
  on(MediaActions.updateMediaWeightsSuccess, (state, { updatedMediaList }) =>
    adapter.upsertMany(updatedMediaList, { ...state, loading: false })
  ),
  on(MediaActions.saveEditedMedia, (state, { media }) => adapter.updateOne({ id: media.id, changes: media }, state)),
  on(MediaActions.saveEditedMediaSuccess, (state, { media }) =>
    adapter.updateOne({ id: media.id, changes: media }, state)
  ),
  on(createAction("[Media] Clear Media"), (state) =>
    adapter.removeAll({
      ...state,
      loading: false,
      error: null,
    })
  ),
  on(MediaActions.createMediaForProduct, (state) => state),
  on(MediaActions.createMediaForProductSuccess, (state, { media }) => adapter.addOne(media, state)),
  on(MediaActions.createMediaForProductFailure, (state, { error }) => ({ ...state, error })),
  on(MediaActions.removeProductAssociation, (state) => state),
  on(MediaActions.removeProductAssociationSuccess, (state, { productSlug, mediaId }) =>
    adapter.removeOne(mediaId, state)
  ),
  on(MediaActions.deleteMedia, (state) => state),
  on(MediaActions.deleteMediaSuccess, (state, { mediaId }) => adapter.removeOne(mediaId, state)),
);
