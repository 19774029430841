declare let window: any;

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { loadPostBySlug } from '../store/post.actions';
import { isPostLoading } from '../store/post.selectors';
import { WindowService } from 'src/app/core/services/window.service';
@Injectable({
    providedIn: 'root'
  })
  export class PostResolver implements Resolve<void> {
    constructor(private store: Store, private windowService: WindowService) {}
  
    resolve(route: ActivatedRouteSnapshot): Observable<void> {
      const slug = route.paramMap.get('slug');
      this.store.dispatch(loadPostBySlug({ slug }));
  
      return this.store.select(isPostLoading).pipe(
        filter(loading => !loading), 
        tap(() => {
            this.windowService.windowRef.prerenderReady = true;
        }),
        map(() => null)
      );
    }
  }
