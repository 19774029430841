import { Component } from '@angular/core';

@Component({
  selector: 'app-product-attribute-value-admin-manage',
  templateUrl: './product-attribute-value-admin-manage.component.html',
  styleUrls: ['./product-attribute-value-admin-manage.component.scss']
})
export class ProductAttributeValueAdminManageComponent {

}
