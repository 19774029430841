import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { Testimonial } from 'src/app/shared/models/testimonial.interface';
import { loadTestimonials } from '../../store/testimonials.actions';

@Component({
  selector: 'app-testimonials-page-container',
  templateUrl: './testimonials-page-container.component.html',
  styleUrls: ['./testimonials-page-container.component.scss']
})
export class TestimonialsPageContainerComponent implements OnInit {

  constructor(private store: Store, private metaTagsService: MetaTagsService) {
  }

  ngOnInit() {
    this.store.dispatch(loadTestimonials());
    const newMetaTags = {
      title: 'Scinomix Testimonials: Just Ask Our Customers',
      description: 'We strongly value each of our customers, and exceeding customer expectations is the motivation that drives our business. Our plans to continually improve quality and offer innovative solutions play a huge role in our vision for the future.',
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }


}
