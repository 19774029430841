import { Component, Output, EventEmitter, Input } from "@angular/core";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Output() search = new EventEmitter<string>();
  @Output() queryChange = new EventEmitter<string>();
  @Output() indexChange = new EventEmitter<string[]>();

  searchIndexes = environment.algoliaSearchIndexes;
  selectedIndexes = [...this.searchIndexes.map(item => item.index)];
  indexCheckStates = this.searchIndexes.reduce((acc, cur) => ({ ...acc, [cur.index]: true }), {});
  currentQuery: string = '';

  onQueryChange(event: Event): void {
    this.currentQuery = (event.target as HTMLInputElement).value;
    this.queryChange.emit(this.currentQuery);
  }

  onSearch(): void {
    if (this.currentQuery) {
      this.search.emit(this.currentQuery);
    }
  }

  onIndexChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const checked = checkbox.checked;
    const index = checkbox.value;

    this.indexCheckStates[index] = checked;  // Update checkbox state
    this.selectedIndexes = this.searchIndexes.filter(item => this.indexCheckStates[item.index]).map(item => item.index);

    this.indexChange.emit(this.selectedIndexes);
  }
}

