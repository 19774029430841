<app-page-banner-container headingLevel="1" backgroundImage="/assets/images/default_abstract.jpg" title="Search"
    [breadcrumbs]="breadcrumbs"></app-page-banner-container>
<div class="container ptb-30">
    <div class="row">
        <div class="col-lg-8 search-results">
            <p>Please enter your search in the search box below. You can refine your search by selecting the type of
                content
                you prefer, or filter your results when they appear.</p>
            <app-search (search)="onSearch($event)" (indexChange)="onIndexChange($event)"
                (queryChange)="onQueryChange($event)"></app-search>

            <app-search-results [searchResults]="filteredSearchResults$ | async"
                [searchPerformed]="searchPerformed"></app-search-results>
        </div>
        <div class="col-lg-4">
            <div class="pb-30"> <app-search-filter (filterChange)="onFilterChange($event)"
                    [searchIndexes]="algoliaSearchIndex" [searchPerformed]="searchPerformed"></app-search-filter></div>

            <app-help-widget-container></app-help-widget-container>
        </div>

    </div>
</div>