<div class="card product-card">
    <div class="card-img-container">
        <a [routerLink]="['/product', product.slug]" class="d-block">
            <img *ngIf="product?.images?.length" class="card-img-top" [src]="product.images[0].original"
                [alt]="product.title">
        </a>
    </div>
    <div class="card-body">
        <h5 class="card-title">
            <a [routerLink]="['/product', product.slug]">{{product.title | truncateDescription:70:true}}</a>
        </h5>
        <p class="card-text" *ngIf="product.description" [innerHtml]="truncate(product.description)"></p>
        <a [routerLink]="['/product', product.slug]" class="btn btn-primary">Learn more about
            {{product.product_name | truncateDescription:50:true:'>'}}</a>
    </div>
</div>