// spinner.selectors.ts
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SpinnerState } from './spinner.state';

export const selectSpinnerState = createFeatureSelector<SpinnerState>('spinner');

export const selectIsSpinnerVisible = createSelector(
  selectSpinnerState,
  (spinnerState: SpinnerState) => spinnerState.activeRequests > 0
);
