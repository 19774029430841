import { createFeatureSelector, createSelector } from '@ngrx/store';
import { solutionsFeatureKey } from './solutions.reducer';

const selectSolutionsState = createFeatureSelector(solutionsFeatureKey);

export const selectCategoryData = createSelector(
    selectSolutionsState,
    (state: any) => state.categoryData
);

export const selectCategoryLoading = createSelector(
    selectSolutionsState,
    (state: any) => state.loading
);

export const selectCategoryError = createSelector(
    selectSolutionsState,
    (state: any) => state.error
);
