
export const environment = {
   version: '0.0.30',
   production: true,
   staging: false,
   local: false,
   hubspot_api_key: "73061ef0-4c2a-4fa9-9322-e2c84060ba60",
   gtm_id: "GTM-MJPRZ3D",
   config: {
    currency: 'USD',
    currency_symbol: '$'
   },
   encryptLocalStorage: true,
   localStorageSecretKey: '3nf@S@#9H0<=#$n~sxF|',
   scheme: 'https:',
   domain: 'scinomix.com',
   apiUrl: 'https://scinomix.com',
   localStorageKey: 'prod_local_storage_key',
   hubspotAccessToken: 'pat-na1-228adf4d-780f-473d-9ff2-4a6af5ad42a3',
   algoliaSearchApiKey: '86209b2095edece001c779d3a9933f7a',
   algoliaApplicationId: '99CJ5JF66H',
   algoliaSearchIndexes: [{"key":"product","index":"Product","display":"Products"},{"key":"supportGuide","index":"SupportGuide","display":"Support Guides"},{"key":"faq","index":"FAQ","display":"FAQs"},{"key":"post","index":"Post","display":"Blog Posts"}],
   redirectUrlAfterLogin: '/dashboard',
   redirectUrlAfterLogout: '/auth/login'
};
