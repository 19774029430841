import { Component, Input } from '@angular/core';
import { HelpContactInfo } from '../../models/help-contact-info.interface';

@Component({
  selector: 'app-help-widget',
  templateUrl: './help-widget.component.html',
  styleUrls: ['./help-widget.component.scss']
})
export class HelpWidgetComponent {
  @Input() contactInfo: HelpContactInfo;
  @Input() backgroundImage: string;
}
