import { createFeatureSelector, createSelector } from '@ngrx/store';
import { productClassFeatureKey } from './product-class.reducer';
import { adapter, ProductClassState } from './product-class.state';
import { AttributeOption } from '../models/attribute-option.interface';

export const selectProductClassState = createFeatureSelector<ProductClassState>(productClassFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectProductClassIds = createSelector(
    selectProductClassState,
    selectIds
);

export const selectProductClassEntities = createSelector(
    selectProductClassState,
    selectEntities
);

export const selectAllProductClasses = createSelector(
    selectProductClassState,
    selectAll
);

export const selectProductClassTotal = createSelector(
    selectProductClassState,
    selectTotal
);

export const selectProductClassById = (id: number) =>
    createSelector(
        selectProductClassEntities,
        (entities) => entities[id]
    );

export const selectProductClassLoading = createSelector(
    selectProductClassState,
    (state) => state.loading
);

export const selectProductClassError = createSelector(
    selectProductClassState,
    (state) => state.error
);

export const selectCurrentProductClass = createSelector(
    selectProductClassState,
    (state) => state.currentProductClass
);

export const selectAttributeOptionsByCode = (code: string) => createSelector(
    selectCurrentProductClass,
    (currentProductClass) => {
        if (!currentProductClass || !currentProductClass.attributes) {
            return [];
        }
        const attribute = currentProductClass.attributes.find(attr => attr.code === code);
        return attribute?.options ? attribute.options.attribute_options : [];
    }
);

