import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertService, AppState, PaginationInfo } from '@fluentllc/core';
import { ProductAttributeValue } from '../../models/product-attribute-value.interface';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { ModalService } from 'src/app/core/services/modal.service';
import * as ProductAttributeValueActions from '../../store/product-attribute-value.actions';

@Component({
  selector: 'app-product-attribute-value-admin-list',
  templateUrl: './product-attribute-value-admin-list.component.html',
  styleUrls: ['./product-attribute-value-admin-list.component.scss']
})
export class ProductAttributeValueAdminListComponent {

  @Input() productAttributeValues: ProductAttributeValue[];
  @Input() paginationInfo: PaginationInfo;
  // @Output() edit = new EventEmitter<string>();
  @Output() delete = new EventEmitter<number>();
  @Output() create = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();
  @Output() updateProductAttributeValues = new EventEmitter<ProductAttributeValue[]>();
  
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private metaTagsService: MetaTagsService,
    // private alertService: AlertService,
    private modalService: ModalService
  ) {}


  trackByAttribute(index: number, attribute: ProductAttributeValue): string {
    return attribute.code; // or any unique identifier of the attribute
  }

  
  onPageChange(newPage: number): void {
    this.pageChanged.emit(newPage);
  }
  
  onItemsPerPageChange(paginateBy: number): void {
    this.itemsPerPageChanged.emit(paginateBy);
  }

  onUpdate(updatedValue: ProductAttributeValue): void {
    this.productAttributeValues = this.productAttributeValues.map(attr =>
      attr.id === updatedValue.id ? updatedValue : attr
    );
    this.updateProductAttributeValues.emit(this.productAttributeValues);
  }

  onDelete(id: number): void {
    this.openCustomModal(id)
  }


  async openCustomModal(id: number) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Remove Product Attribute', cssClass: 'btn btn-danger', action: 'confirm' }
    ];

    const result = await this.modalService.openModal(
      'Confirm Removal', 
      'Are you sure you want to remove this attribute from this product?', 
      buttons
    );

    // Handle the result based on action
    switch (result) {
      case 'cancel':
        // Handle cancel
        break;
      case 'confirm':
        this.store.dispatch(ProductAttributeValueActions.deleteProductAttributeValue({ id }));
        break;
    }
  }

}
