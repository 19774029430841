<div #topNavbar class="navbar-area" stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <app-navbar-brand-container></app-navbar-brand-container>
            <app-burger-menu-container (toggle)="toggleClass()" [class.active]="classApplied"></app-burger-menu-container>
            <div class="collapse navbar-collapse" id="navbarNavDropdown"><app-menu-container [classApplied]="classApplied"
                    class="full-width-container"></app-menu-container>
            </div>
            <div class="others-option d-flex align-items-center">
                <div class="option-item"><app-search-btn-container
                        (searchToggle)="searchToggleClass()"></app-search-btn-container></div>
                <div class="option-item"><app-appointment-scheduler-container></app-appointment-scheduler-container>
                </div>
            </div>
        </nav>
    </div>
</div>

<!-- Search Overlay -->
<div class="search-overlay" [class.search-overlay-active]="searchClassApplied">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="searchToggleClass()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <app-search-navbar-widget (search)="onSearch($event)"></app-search-navbar-widget>
                </form>
            </div>
        </div>
    </div>
</div>