<div class="dashboard-area pt-75 pb-100">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 col-md-12">
            <h1 class="mb-4">Scinomix Admin</h1>
          </div>
      </div>
      <div class="dashboard">
  

        <div class="row">
          <!-- Dashboard card example -->
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <h5 class="card-title">Navigation</h5>
                <p class="card-text">
                 <a routerLink="/admin/posts">Manage Blog Posts</a>
                </p>
              </div>
            </div>
          </div>
          <!-- Add more dashboard cards or content here -->
        </div>
      </div>
    </div>
    </div>

