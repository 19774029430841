import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { BsModalService } from "ngx-bootstrap/modal";
import { debounceTime } from "rxjs";
import { ModalService } from "src/app/core/services/modal.service";
import { Media } from "src/app/features/products/models/media.interface";
import { deleteMedia, removeProductAssociation } from "src/app/features/products/store/media.actions";

@Component({
  selector: "app-media-item",
  templateUrl: "./media-item.component.html",
  styleUrls: ["./media-item.component.scss"],
})
export class MediaItemComponent {
  @Input() media: Media;
  @Output() weightChanged: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() priorityChanged: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() changes: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() disassociateRequested: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() editRequested = new EventEmitter<Media>();
  file: File;
  thumbnail: File;
  mediaForm: FormGroup;
  loadingThumbnail: boolean = true;

  ngOnInit(): void {
    this.mediaForm = new FormGroup({
      file: new FormControl(null),
      thumbnail: new FormControl(null),
      meta_title: new FormControl(this.media.meta_title),
      weight: new FormControl(this.media.weight),
      priority: new FormControl(this.media.priority),
      caption: new FormControl(this.media.caption),
      meta_alt: new FormControl(this.media.meta_alt),
      type: new FormControl(this.media.type),
      external_video_url: new FormControl(this.media.external_video_url),
      external_video_alternative_text: new FormControl(this.media.external_video_alternative_text),
      external_video_caption: new FormControl(this.media.external_video_caption),
    });


    // Initialize form control with media weight
    this.mediaForm.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => {
      const newMedia = { ...this.media, ...newValue };
      this.changes.emit({ ...this.media, ...newValue });
    });
  }

  constructor(private store: Store, private modalService: BsModalService, private confirmModalService: ModalService ) {}

  editItem(media: Media): void {
    this.editRequested.emit(media);
  }

  onFileChange(event: Event, field: string): void {
    const element = event.target as HTMLInputElement;
    if (element.files?.length) {
      const file: File = element.files[0];
      // this.mediaForm.setValue({ [field]: file });

      // Emit an event with the file object
      field === 'file' ? this.file = file : this.thumbnail = file;
    }
  }

  handleFileInput(event: Event): void {
    const element = event.target as HTMLInputElement;
    if (element.files?.length) {
      const file:File = element.files[0];
      // console.log(file)
      // Clone the media object before modifying it
      this.media = { ...this.media, file: file };
    }
  }

  handleThumbnailInput(event: Event): void {
    const element = event.target as HTMLInputElement;
    if (element.files?.length) {
      const thumbnail = element.files[0];
      this.media = { ...this.media, thumbnail: thumbnail };
    }
  }

  get isExternalType(): boolean {
    return this.mediaForm.get("type").value === "external";
  }

  async openCustomModal(mediaId: number) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Delete Media', cssClass: 'btn btn-danger', action: 'confirm' }
    ];

    const result = await this.confirmModalService.openModal(
      'Delete Media Item', 
      'Are you sure you want to permanently delete this media item? This action cannot be undone. Please confirm if you wish to permanently remove this media item from the server.', 
      buttons
    );

    // Handle the result based on action
    switch (result) {
      case 'cancel':
        // Handle cancel
        break;
      case 'confirm':
        this.store.dispatch(deleteMedia({ mediaId: mediaId }));
        break;
    }
  }

  onDelete(mediaId: number): void {
    this.openCustomModal(mediaId);
  }

  disassociate(media: Media): void {
    this.disassociateRequested.emit(media);
    // this.store.dispatch(removeProductAssociation({ productSlug: productSlug, mediaId: media.id }));
  }

  onThumbnailLoad(): void {
    this.loadingThumbnail = false;
  }

  onThumbnailError(): void {
    this.loadingThumbnail = false;
  }
}
