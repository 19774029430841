import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lander-a2-plus-eol20230815',
  templateUrl: './lander-a2-plus-eol20230815.component.html',
  styleUrls: ['./lander-a2-plus-eol20230815.component.scss']
})
export class LanderA2PlusEOL20230815Component {
  @Input() subtitle: string;
  @Input() title: string;
  @Input() titleHighlight: string;
  @Input() description: string;
  @Input() heroImage: string;
}
