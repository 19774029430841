<app-meta-tags-container></app-meta-tags-container>
<app-alert-container></app-alert-container>
<lib-alert-container></lib-alert-container>
<app-spinner-container></app-spinner-container>
<app-offsidebar-container></app-offsidebar-container>
<div class="wrapper">
  <app-aside-container class="aside-container"></app-aside-container>
  <div class="section-container">
    <div class="content-wrapper">
      <app-header-container></app-header-container>
      <app-schema-container></app-schema-container>
      <router-outlet></router-outlet>
      <app-footer-container></app-footer-container>
    </div> 
  </div>
  
</div>
<app-offsidebar></app-offsidebar>
