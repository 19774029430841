import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { FAQEffects } from './faq.effects';
import * as faqs from './faq.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(faqs.FAQFeatureKey, faqs.FAQReducer),
        EffectsModule.forFeature([FAQEffects]),
    ],
})
export class FAQsStoreModule { }