<div [formGroup]="editForm">
  <ng-container [ngSwitch]="value.type">
    <input *ngSwitchCase="'text'" class="large-input" formControlName="formValue" type="text" />
    <input *ngSwitchCase="'integer'" formControlName="formValue" type="number" class="large-input"/>
    <input *ngSwitchCase="'float'" formControlName="formValue" type="number" step="any" />
    <input *ngSwitchCase="'boolean'" formControlName="formValue" type="checkbox" />
    <textarea *ngSwitchCase="'richtext'" formControlName="formValue"></textarea>
    <input *ngSwitchCase="'date'" formControlName="formValue" type="date" />
    <input *ngSwitchCase="'datetime'" formControlName="formValue" type="datetime-local" />
    <div *ngIf="attributeOption$ | async as attributeOptions">
      <select *ngSwitchCase="'option'" class="large-input" formControlName="formValue">
        <option *ngFor="let option of productAttributeOptions" [value]="option.option_slug">{{ option.option }}</option>
      </select>
      <select *ngSwitchCase="'multi_option'" formControlName="formValue" multiple>
        <option *ngFor="let option of productAttributeOptions" [value]="option.option_slug">{{ option.option }}</option>
      </select>
    </div>
  </ng-container>
</div>
