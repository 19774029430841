import { createAction, props } from '@ngrx/store';
import { ProductAttributeValue} from 'src/app/features/products/models/product-attribute-value.interface';

export const loadProductAttributeValues = createAction('[ProductAttributeValue] Load ProductAttributeValues');
export const loadProductAttributeValuesSuccess = createAction(
  '[ProductAttributeValue] Load ProductAttributeValues Success',
  props < { ProductAttributeValues: ProductAttributeValue[] }> ()
);
export const loadProductAttributeValuesFailure = createAction(
  '[ProductAttributeValue] Load ProductAttributeValues Failure',
  props<{ error: any }>()
);

export const updateProductAttributeValue = createAction(
  '[ProductAttributeValue] Update ProductAttributeValue',
  props<{ id: number, data: ProductAttributeValue }>()
);

export const updateProductAttributeValueSuccess = createAction(
  '[ProductAttributeValue] Update ProductAttributeValue Success',
  props<{ ProductAttributeValue: ProductAttributeValue }>()
);

export const updateProductAttributeValueFailure = createAction(
  '[ProductAttributeValue] Update ProductAttributeValue Failure',
  props<{ error: any }>()
);

export const deleteProductAttributeValue = createAction(
  '[ProductAttributeValue] Delete ProductAttributeValue',
  props<{ id: number }>()
);

export const deleteProductAttributeValueSuccess = createAction(
  '[ProductAttributeValue] Delete ProductAttributeValue Success',
  props<{ id: number }>()
);

export const deleteProductAttributeValueFailure = createAction(
  '[ProductAttributeValue] Delete ProductAttributeValue Failure',
  props<{ error: any }>()
);

