import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DynamicFormStep } from "src/app/shared/models/dynamic-form-step.interface";
import { FormStepComponent } from "src/app/shared/models/form-step.interface";
import { BaseFormStepComponent } from "./base-form-step/base-form-step.component";

@Component({
    selector: 'app-step-two',
    template: `
    <h2>{{ title }}</h2>
    <form [formGroup]="formGroup">
      <formly-form [fields]="fields" [form]="formGroup"></formly-form>
    </form>
  `,
})
export class StepTwoComponent extends BaseFormStepComponent {
    key = 'stepTwo';
    title = 'Step Two from Component';
    formGroup: FormGroup;
    fields: FormlyFieldConfig[];

    constructor(cd: ChangeDetectorRef, store: Store) {
        super(cd, store);
        this.initializeFields();
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    initializeFields() {
        this.fields = [
            {
                key: 'email',
                type: 'input',
                templateOptions: {
                    label: 'Email',
                    required: true,
                    type: 'email',
                },
            },
            {
                key: 'phone',
                type: 'input',
                templateOptions: {
                    label: 'Phone Number',
                    required: true,
                    type: 'tel',
                },
            },
        ];
    }


    initialize(): DynamicFormStep {
        this.formGroup = this.createForm();
        return {
            title: this.title,
            formStep: {
                key: this.key,
                title: this.title,
                formGroup: this.formGroup,
                fields: this.fields,
                initialize: () => this.initialize(),
                createForm: () => this.createForm(),
                model: this.model // Inherit model property from BaseFormStepComponent
            }
        };
    }

    createForm(): FormGroup {
        return new FormGroup({
            email: new FormControl(this.model?.email, [Validators.required, Validators.email]),
            phone: new FormControl(this.model?.phone, Validators.required),
        });
    }

    getFields(): FormlyFieldConfig[] { // Implement the abstract method
        return this.fields;
    }
}
