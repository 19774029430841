import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../../models/product.interface';
import { PaginationInfo } from '@fluentllc/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-product-admin-list',
  templateUrl: './product-admin-list.component.html',
  styleUrls: ['./product-admin-list.component.scss']
})
export class ProductAdminListComponent {
  @Input() products: Product[];
  @Input() paginationInfo: PaginationInfo;
  @Output() edit = new EventEmitter<string>();
  @Output() delete = new EventEmitter<number>();
  @Output() create = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();
  @Output() filterChanged = new EventEmitter<string>(); // Add this output to emit filter changes

  keywordControl = new FormControl('');

  onPageChange(newPage: number): void {
    this.pageChanged.emit(newPage);
  }

  onItemsPerPageChange(paginateBy: number): void {
    this.itemsPerPageChanged.emit(paginateBy);
  }

  onFilterChange(): void {
    this.filterChanged.emit(this.keywordControl.value);
  }

  
}
