import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeStoreModule } from './store/home-store.module';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutModule } from '../layout/layout.module';
import { ScinomixStoryContainerComponent } from './containers/scinomix-story-container/scinomix-story-container.component';
import { ScinomixStoryComponent } from './components/scinomix-story/scinomix-story.component';
import { TestimonialsModule } from '../testimonials/testimonials.module';
import { VrViewerModule } from '../vr-viewer/vr-viewer.module';
import { MikeComponent } from './components/mike/mike.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ScinomixExperienceComponent } from './components/scinomix-experience/scinomix-experience.component';
import { DemonstrationsComponent } from './components/demonstrations/demonstrations.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
@NgModule({
  declarations: [
    HomeComponent,
    ScinomixStoryContainerComponent,
    ScinomixStoryComponent,
    MikeComponent,
    PrivacyPolicyComponent,
    ScinomixExperienceComponent,
    DemonstrationsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    HomeRoutingModule,
    HomeStoreModule,
    TestimonialsModule,
    VrViewerModule,
    CarouselModule
  ],
  exports: [
    MikeComponent
  ]
})
export class HomeModule { }
