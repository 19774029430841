import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { ProductSchema } from 'src/app/shared/models/schema-types.interface';
import { Media } from '../../models/media.interface';
import { OptionHashOption } from '../../models/option-hash.interface';
import { Product } from '../../models/product.interface';
declare var hbspt: any;
@Component({
  selector: 'app-tube-detail',
  templateUrl: './tube-detail.component.html',
  styleUrls: ['./tube-detail.component.scss']
})
export class TubeDetailComponent implements AfterViewInit  {
  @Input() product: Product;
  @Input() media: Media[];
  @Input() resources: Media[];
  @Input() filteredProduct: Product[];
  @Input() selectedOptions: { [optionCode: string]: string };
  @Input() isOptionAvailable$: (optionCode: string, optionValue: string) => Observable<boolean>;
  @Input() isOptionSelected$: (optionCode: string, optionValue: string) => Observable<boolean>;
  @Input() isOptionDisabled$: (optionCode: string, optionValue: string) => Observable<boolean>;
  @Input() optionHash: Dictionary<OptionHashOption>;
  @Output() onOptionChange = new EventEmitter<{ optionCode: string, optionValueSlug: string }>();

  @Input() filteredOptions: { [optionCode: string]: string[] };
  @Input() variationOptions: { [optionCode: string]: string[] };
  @Input() filteredProductVariations: Product[];

  breadcrumbs: Breadcrumb[] = [];
  public pageUri: string;
  public pageName: string;

  constructor(
    private metaTagsService: MetaTagsService,
    private schemaService: SchemaService,
    private _elementRef: ElementRef
  ) {
  }
  ngOnInit(): void {
    const newMetaTags = {
      title: this.product.meta_title,
      description: this.product.meta_description,
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  ngAfterViewInit(): void {
    // Embed the HubSpot form script
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        portalId: '4896724',
        formId: '72256ffb-c53f-4791-911b-b21ffa4fbabf',
        target: '#hubspotForm',
        region: "na1",
      });
    } else {
      const scriptTag = document.createElement('script');
      scriptTag.src = '//js.hsforms.net/forms/shell.js';
      scriptTag.addEventListener('load', () => {
        hbspt.forms.create({
          portalId: '4896724',
          formId: '72256ffb-c53f-4791-911b-b21ffa4fbabf',
          target: '#hubspotForm',
          region: "na1",
        });
      });
      this._elementRef.nativeElement.appendChild(scriptTag);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionHash && changes.optionHash.currentValue) {
      // console.log('optionHash: ', changes.optionHash.currentValue);
    }

    if (changes.product && this.product) {
      const newMetaTags = {
        title: this.product.meta_title,
        description: this.product.meta_description,
        keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      };
      this.pageName = this.product.meta_title;
      this.pageUri = window.location.href;
      this.metaTagsService.updateMetaTags(newMetaTags);
      this.breadcrumbs = [
        { label: 'Home', url: '/' },
        { label: this.product.product_name, url: '/product/' + this.product.slug },
      ];
    }

    if (changes.product && this.product) {
      const productSchema: ProductSchema = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        'name': this.product.title,
        'description': this.product.meta_description,
        'sku': this.product.upc,
        'brand': {
          '@type': 'Brand',
          'name': 'Scinomix',
        }
      };

      this.schemaService.updateSchema(productSchema);
    }
  }

  handleOptionChange(event: { optionCode: string, optionValueSlug: string }) {
    this.onOptionChange.emit(event);
  }
}
