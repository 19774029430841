import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { AlertService } from "src/app/core/services/alert.service";
import { ModalService } from "src/app/core/services/modal.service";
import { AppState } from "src/app/store/app.state";
import * as fromLayoutActions from "../../store/layout.actions";
import { selectAllLayouts } from "../../store/layout.selectors";
import { Layout } from 'src/app/shared/models/layout.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-layout-admin-list-container',
  templateUrl: './layout-admin-list-container.component.html',
  styleUrls: ['./layout-admin-list-container.component.scss']
})
export class LayoutAdminListContainerComponent implements OnInit {
  layouts$: Observable<Layout[]> = this.store.select(selectAllLayouts);
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private alertService: AlertService,
    private modalService: ModalService
  ) {
  }

  async openCustomModal(id: number) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Delete Layout', cssClass: 'btn btn-danger', action: 'confirm' }
    ];

    const result = await this.modalService.openModal(
      'Confirm Deletion', 
      'Are you sure you want to delete this layout? This action cannot be undone. Please confirm if you wish to permanently remove this layout.', 
      buttons
    );

    // Handle the result based on action
    switch (result) {
      case 'cancel':
        // Handle cancel
        break;
      case 'confirm':
        this.store.dispatch(fromLayoutActions.deleteLayout({ id }));
        break;
    }
  }

  onCreate(): void {
    this.router.navigate(["/admin/landing-pages/layout/create"]);
  }

  onDelete(id: number): void {
    this.openCustomModal(id)
  }

  onEdit(id: number): void {
    // Navigation logic for editing a post
    this.router.navigate(["/admin/landing-pages/layout", id]);
    
  }

  ngOnInit(): void {
    this.store.dispatch(fromLayoutActions.loadLayouts());
  }

}
