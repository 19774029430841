<div *ngIf="post">
    <app-page-banner-container backgroundImage="/assets/images/default_abstract.jpg" title="Lab Insights Blog"
        [breadcrumbs]="breadcrumbs"></app-page-banner-container>
    <!-- <h2>{{ categoryData.name }}</h2>
    <p>{{ categoryData.description }}</p>
    <img *ngIf="categoryData.page_title_image" [src]="categoryData.page_title_image" alt="{{ categoryData.name }}"> -->
</div>
<div class="blog-details-area pt-75 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image"><img [src]="post.image" [alt]="post.title" *ngIf="post.image">
                        <div *ngIf="post.published_at; else modifiedDateBlock">
                            <div class="date">{{post.published_at | date:'mediumDate'}}</div>
                        </div>
                        <ng-template #modifiedDateBlock>
                            <div class="date">{{post.modified_date | date:'mediumDate'}}</div>
                        </ng-template>
                        <div class="tag-wrapper">
                            <div class="tag" *ngFor="let tag of post.keywords">
                                {{tag.name}}
                            </div>
                        </div>
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul class="entry-meta">
                                <li>
                                    <div class="blue-icon" [innerHTML]="bxsUser"></div>
                                    <span>By {{post.author.user.first_name}}
                                        {{post.author.user.last_name}}</span>
                                </li>
                            </ul>
                        </div>
                        <h1>{{post.title}}</h1>
                        <div [innerHTML]="post.content"></div>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <app-post-list-widget-container [currentPost]="post" [maxArticles]=10
                        placement="sidebar"></app-post-list-widget-container>
                </div>
            </div>
        </div>
    </div>
</div>