import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Specification } from '../../models/specification.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-specifications-admin',
  templateUrl: './specifications-admin.component.html',
  styleUrls: ['./specifications-admin.component.scss']
})
export class SpecificationsAdminComponent implements OnInit {
  @Input() specifications: Specification[];
  @Output() updateSpecifications = new EventEmitter<Specification[]>();

  specificationsForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.specificationsForm = this.fb.group({
      specifications: this.fb.array(this.specifications.map(spec => this.createSpecificationFormGroup(spec)))
    });
  }

  // Create a form group for a single specification
  private createSpecificationFormGroup(spec: Specification): FormGroup {
    return this.fb.group({
      id: [spec.id],
      name: [spec.name],
      value: [spec.value],
      display_order: [spec.display_order]
    });
  }

  get specificationsArray(): FormArray {
    return this.specificationsForm.get('specifications') as FormArray;
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.specificationsArray.controls, event.previousIndex, event.currentIndex);
    this.specificationsArray.controls.forEach((ctrl, idx) => {
      ctrl.patchValue({ display_order: idx });
    });
    this.emitChanges();
  }
  

  // Method to trigger when specifications are updated
  onSave(): void {
    this.updateSpecifications.emit(this.specificationsArray.value);
  }

  removeSpecification(index: number): void {
    // Remove the FormGroup from the specifications FormArray
    this.specificationsArray.removeAt(index);
  
    // Reorder the remaining specifications to reflect the new order
    this.specificationsArray.controls.forEach((control, idx) => {
      control.patchValue({ display_order: idx });
    });
  
    this.emitChanges();
  
    // Emit an update event here if necessary, to immediately reflect the change in the parent component's data model.
    // this.updateSpecifications.emit(this.specificationsArray.value);
  }
  

  addSpecification(): void {
    // Calculate the display_order based on the current length of the specificationsArray
    const displayOrder = this.specificationsArray.length;
  
    // Create a new specification FormGroup with the calculated display_order
    const newSpec = this.fb.group({
      id: [null], // Assuming 'id' is not mandatory when creating a new spec
      name: ['', Validators.required], // Add validators as needed
      value: ['', Validators.required],
      display_order: [displayOrder] // Set to the end of the list
    });
  
    // Add the new specification FormGroup to the FormArray
    this.specificationsArray.push(newSpec);
    this.emitChanges();
  }
  

  emitChanges(): void {
    this.updateSpecifications.emit(this.specificationsArray.value);
  }
  
  
}
