import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GtmService } from "src/app/core/services/gtm.service";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {
  @Input() media: any[] = [];
  public activeIndex = 0;
  public sliderId = `carousel-${Math.random().toString(36).substr(2, 10)}`;
  private interval: any;

  constructor(private gtmService: GtmService) { }

  ngOnInit(): void {
    // this.startInterval(); Removed so it won't automatically advance to the next slide
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  public selectSlide(index: number): void {
    this.gtmService.push({event: 'carousel_thumbnail_clicked'})
    this.activeIndex = index;
  }

  public next(): void {
    this.gtmService.push({event: 'carousel_next_clicked'})
    this.activeIndex = (this.activeIndex + 1) % this.media.length;
  }

  public prev(): void {
    this.gtmService.push({event: 'carousel_prev_clicked'})
    this.activeIndex = (this.activeIndex - 1 + this.media.length) % this.media.length;
  }

  private startInterval(): void {
    this.interval = setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.media.length;
    }, 5000);
  }

}
