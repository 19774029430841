export interface CustomItem {
    name: string;
    icon?: string;
    description?: string;
}

export class CustomItemClass implements CustomItem {
    name: string;
    icon?: string;
    description?: string;

    constructor({ name, icon = 'flaticon-163-draw-check-mark', description }: { name: string; icon?: string; description?: string }) {
        this.name = name;
        this.icon = icon;
        this.description = description;
    }
}