import { createReducer, on } from '@ngrx/store';
import * as SolutionTagsActions from './solution-tags.actions';
import { adapter, initialState } from './solution-tags.state';

export const solutionTagsFeatureKey = 'solutionTags';

export const SolutionTagsReducer = createReducer(
  initialState,
  on(SolutionTagsActions.loadSolutionTags, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SolutionTagsActions.loadSolutionTagsSuccess, (state, { SolutionTags }) =>
  adapter.setAll(SolutionTags, { ...state, loading: false })
),
  on(SolutionTagsActions.loadSolutionTagsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
);
