import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { LayoutEffects } from './layout.effects';
import * as fromLayout from './layout.reducer';



@NgModule({
    imports: [
        EffectsModule.forFeature([LayoutEffects]),
        StoreModule.forFeature(fromLayout.layoutFeatureKey, fromLayout.layoutReducer)],
})
export class LayoutStoreModule { }