import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/services/settings.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(public settings: SettingsService) { }

  currentYear: number;

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
}
}
