import { Component, HostListener, PLATFORM_ID, Inject, HostBinding, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { GtmService } from "./core/services/gtm.service";
import { resetSpinner } from "./shared/store/spinner-store/spinner.actions";
import { isPlatformBrowser } from "@angular/common";
import { RouterService } from "./core/services/router.service";
import { CanonicalService } from "./core/services/seo/canonical.service";
import { SettingsService } from "./core/services/settings.service";
import { AuthService, RolesPermissionsService } from "@fluentllc/core";
import { isLoggedIn } from "@fluentllc/core"
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit{
  title = "Scinomix";


  @HostBinding("class.layout-fixed") get isFixed() {
    return this.settings.getLayoutSetting("isFixed");
  }
  @HostBinding("class.aside-collapsed") get isCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed")
  }
  @HostBinding("class.layout-boxed") get isBoxed() {
    return this.settings.getLayoutSetting("isBoxed");
  }
  @HostBinding("class.layout-fs") get useFullLayout() {
    return this.settings.getLayoutSetting("useFullLayout");
  }
  @HostBinding("class.hidden-footer") get hiddenFooter() {
    return this.settings.getLayoutSetting("hiddenFooter");
  }
  @HostBinding("class.layout-h") get horizontal() {
    return this.settings.getLayoutSetting("horizontal");
  }
  @HostBinding("class.aside-float") get isFloat() {
    return this.settings.getLayoutSetting("isFloat");
  }
  @HostBinding("class.offsidebar-open") get offsidebarOpen() {
    return this.settings.getLayoutSetting("offsidebarOpen");
  }
  @HostBinding("class.aside-toggled") get asideToggled() {
    return this.settings.getLayoutSetting("asideToggled");
  }
  @HostBinding("class.aside-collapsed-text") get isCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }

  asideCollapsed = true;

  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: Object,
    private routerService: RouterService,
    private canonicalService: CanonicalService,
    public settings: SettingsService,
    private rolesPermissionsService: RolesPermissionsService,
    private authService: AuthService,
  ) {
    // this.initializeRoleBasedSettings()
  }

  ngOnInit(): void {
    this.initializeRoleBasedSettings();
  }

  private initializeRoleBasedSettings(): void {
    this.settings.setLayoutSetting("isCollapsed", true);
    this.store.select(isLoggedIn).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        const roles = ['role-tenant-admin']; 
        const permissions = []; 
        this.rolesPermissionsService.checkRolesAndPermissions(roles, permissions).subscribe(isAuthorized => {
          // Set isCollapsed based on the authorization
          this.settings.setLayoutSetting("isCollapsed", false);
        });
      }
    });
  }

  @HostListener("window:beforeunload")
  onBeforeUnload() {
    if (isPlatformBrowser(this.platformId)) {
      this.store.dispatch(resetSpinner()); // Dispatch the resetSpinner action when the page is about to unload
    }
  }
}
