import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { HtmlToTextPipe } from 'src/app/shared/pipes/html-to-text.pipe';

@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent implements OnChanges {
  breadcrumbs: Breadcrumb[] = [];
  private htmlToTextPipe: HtmlToTextPipe;
  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService, private router: Router, private route: ActivatedRoute) {
    this.htmlToTextPipe = new HtmlToTextPipe();
    this.breadcrumbs = [
      { label: 'Home', url: '/' },
      { label: 'Partners & Alliances', url: '/distributors' }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {

    const newMetaTags = {
      title: 'Partners & Alliances',
      description: "Explore our network of trusted international distributors for your lab automation needs. As a leading US-based manufacturer, we provide high-quality, innovative lab automation solutions outside the US and Canada. Discover the distributor nearest to you and elevate your lab's efficiency with our cutting-edge technology.",
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };

    this.metaTagsService.updateMetaTags(newMetaTags);

  }
}
