import { AfterViewInit, Component, ComponentFactoryResolver, Inject, Input, OnChanges, OnInit, QueryList, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { DynamicAnchorDirective } from 'src/app/shared/directives/dynamic-anchor.directive';
import { ProductSearchResultComponent } from '../product-search-result/product-search-result.component';
import { SupportGuideSearchResultComponent } from '../support-guide-search-result/support-guide-search-result.component';
import { SearchItem } from 'src/app/shared/models/search-item.interface';
import { environment } from 'src/environments/environment';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { Link } from 'src/app/shared/models/link.interface';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() searchResults: any[];
    @Input() searchPerformed = false;
    @ViewChildren(DynamicAnchorDirective) anchors: QueryList<DynamicAnchorDirective>;
    @Input() breadcrumbs: Breadcrumb[] = [];
    @Input() bannerTitle: string;
    @Input() links: Link[] = [];
    private componentMap = new Map<string, any>([
        [PRODUCT_SEARCH_RESULT, ProductSearchResultComponent],
        [SUPPORT_GUIDE_SEARCH_RESULT, SupportGuideSearchResultComponent],
    ]);

    ngOnChanges(changes: SimpleChanges): void {


    }


    constructor(private componentFactoryResolver: ComponentFactoryResolver, private metaTagsService: MetaTagsService, private schemaService: SchemaService) {

    }

    ngAfterViewInit() {
        this.loadComponents();
    }

    loadComponents() {
        this.anchors.toArray().forEach((anchor, index) => {
            const result = this.searchResults[index];
            const componentType = this.componentMap.get(result.type);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
            const viewContainerRef = anchor.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
            (<any>componentRef.instance).result = result;
        });
    }

    getResultType(result: SearchItem): string {
        return this.getKeyByIndex(result.index);
    }

    getKeyByIndex(index: string): string | undefined {
        const searchIndex = environment.algoliaSearchIndexes.find(item => item.index === index);
        return searchIndex ? searchIndex.key : undefined;
    }

    getIndexByKey(key: string): string | undefined {
        const searchIndex = environment.algoliaSearchIndexes.find(item => item.key === key);
        return searchIndex ? searchIndex.index : undefined;
    }

    ngOnInit(): void {
    }
}

export const PRODUCT_SEARCH_RESULT = 'PRODUCT_SEARCH_RESULT';
export const SUPPORT_GUIDE_SEARCH_RESULT = 'SUPPORT_GUIDE_SEARCH_RESULT';

