import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./features/layout/layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { AccountSelectionAuthGuard } from "./core/guards/account-selection.guard";
import { Error404Component } from "./features/pages/error404/error404.component";
import { AuthenticatedGuard, RolePermissionGuard, TenantsRoutingModule } from "@fluentllc/core"

const routes: Routes = [
  //   {
  //   path: "auth",
  //   // canActivate: [AuthGuardFunction()],
  //   loadChildren: () => import("./features/auth/auth.module").then((m) => m.AuthModule),
  // },
  {
    path: "auth",
    // canActivate: [AuthGuardFunction()],
    loadChildren: () => import('@fluentllc/core').then((m) => m.AuthModule),
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      // { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "",
        loadChildren: () => import("./features/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "admin",
        loadChildren: () => import("./features/scinomix-admin/scinomix-admin.module").then((m) => m.ScinomixAdminModule),
        canActivate: [AuthenticatedGuard, RolePermissionGuard],
        data: {
          roles: ['role-tenant-admin'],
          // permissionsOp: 'AND',
          // operator: 'AND',
          // permissions: ['user_create']
        },
      },
      {
        path: "dashboard",
        canActivate: [AuthenticatedGuard, RolePermissionGuard],
        data: {
          roles: ['role-tenant-admin'],
          // permissionsOp: 'AND',
          // operator: 'AND',
          // permissions: ['user_create']
        },
        loadChildren: () => import("./features/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: 'management',
        loadChildren: () =>
        import('@fluentllc/core').then((m)=> m.AdminModule)
      },
      {
        path: "product",
        loadChildren: () => import("./features/products/products.module").then((m) => m.ProductsModule),
      },
      {
        path: 'product-category',
        loadChildren: () => import("./features/products-by-solution/products-by-solution.module").then(m => m.ProductsBySolutionModule)
      },
      {
        path: 'consumables',
        loadChildren: () => import("./features/products-by-keyword/products-by-keyword.module").then(m => m.ProductsByKeywordModule)
      },

      {
        path: 'testimonials',
        loadChildren: () => import("./features/testimonials/testimonials.module").then((m) => m.TestimonialsModule),
      },
      {
        path: 'roi',
        loadChildren: () => import("./features/roi-calculator/roi-calculator.module").then((m) => m.ROICalculatorModule),
      },
      {
        path: 'services-support',
        loadChildren: () => import("./features/service-support/service-support.module").then((m) => m.ServiceSupportModule),
      },
      {
        path: 'about/employment',
        loadChildren: () => import("./features/careers/careers.module").then((m) => m.CareersModule),
      },
      {
        path: 'distributors',
        loadChildren: () => import("./features/distributors/distributors.module").then((m) => m.DistributorsModule),
      },
      {
        path: 'news-events',
        loadChildren: () => import("./features/blog/blog.module").then((m) => m.BlogModule),
      },
      {
        path: 'search',
        loadChildren: () => import("./features/search/search.module").then((m) => m.SearchModule),
      },
      {
        path: 'discover',
        loadChildren: () => import("./features/landing-pages/landing-pages.module").then((m) => m.LandingPagesModule),
      }
    ],
  },
  {
    path: '**', component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', useHash: false,
  anchorScrolling: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
