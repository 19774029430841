<div class="container">
    <div class="row">
        <div class="col-lg-7">
            <h1>{{job.title}}</h1>
            <button class="sci-default-btn" [routerLink]="['/about/employment/job-application/', job.slug]">Apply for
                the
                {{job.title}} Position</button>
            <h5 *ngIf="job.description">Job Description</h5>
            <div *ngIf="job.description" [innerHtml]="job.description"></div>
            <h5 *ngIf="job.responsibilities">Key Responsibilities</h5>
            <div *ngIf="job.responsibilities" [innerHtml]="job.responsibilities"></div>
            <h5 *ngIf="job.qualifications">Skills/Qualifications</h5>
            <div *ngIf="job.qualifications" [innerHtml]="job.qualifications"></div>
            <h5 *ngIf="job.company_overview">About Scinomix</h5>
            <div *ngIf="job.company_overview" [innerHtml]="job.company_overview"></div>
            <div class="ptb-30"><button class="sci-default-btn"
                    [routerLink]="['/about/employment/job-application/', job.slug]">Apply for
                    the
                    {{job.title}} Position</button></div>
        </div>
        <div class="col-lg-5">
            <app-job-postings-sidebar-navigation-container
                [currentJobPosting]="job"></app-job-postings-sidebar-navigation-container>
        </div>
    </div>
</div>