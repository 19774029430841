import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { DynamicFormStep } from "src/app/shared/models/dynamic-form-step.interface";

export interface MultiStepFormState {
    currentStep: number;
    completedSteps: number[];
    formData: { [key: string]: any };
    steps: DynamicFormStep[];
    model: EntityState<{ [key: string]: any }>;
}

export const adapter = createEntityAdapter<{ [key: string]: any }>({
    selectId: (item) => item.id,
});

export const initialMultiStepFormState: MultiStepFormState = {
    currentStep: 0,
    completedSteps: [],
    formData: {},
    steps: [],
    model: adapter.getInitialState(),
};
