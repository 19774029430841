<div class="multi-step-form d-flex flex-column h-100">
    <div class="header bg-primary text-white p-2">
        <h5 class="mb-0">{{ steps[currentStep]?.title }}</h5>
    </div>
    <form [formGroup]="formGroup" class="flex-grow-1 d-flex flex-column">
        <div class="form-content flex-grow-1 p-3">
            <div *ngFor="let step of steps; let i = index">
                <div *ngIf="currentStep === i" [ngClass]="{ 'step-active': currentStep === i }">
                    <formly-form [fields]="step.formStep.fields" [form]="step.formStep.formGroup"
                        [model]="step.formStep.model"></formly-form>
                </div>
            </div>
        </div>
        <div class="footer bg-light p-2">
            <div class="button-group d-flex justify-content-between">
                <button (click)="previousStep()" [disabled]="currentStep === 0"
                    class="btn btn-primary">Previous</button>
                <button (click)="nextStep()" [disabled]="currentStep === steps.length - 1"
                    class="btn btn-primary">Next</button>
                <button (click)="onSubmit()" *ngIf="currentStep === steps.length - 1"
                    class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
</div>