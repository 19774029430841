import { Component, Input } from '@angular/core';
import { MenuItem } from 'src/app/core/models/menu-item.interface';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent {
  @Input() menuItems: MenuItem[] = [];

}
