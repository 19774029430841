import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SettingsService } from "src/app/core/services/settings.service";

@Component({
  selector: "app-aside-container",
  templateUrl: "./aside-container.component.html",
  styleUrls: ["./aside-container.component.scss"],
})
export class AsideContainerComponent implements OnInit, OnDestroy {
  router: Router;
  constructor(public settings: SettingsService, public injector: Injector) {}

  ngOnInit() {
    this.router = this.injector.get(Router);

    this.router.events.subscribe((val) => {
      // close any submenu opened when route changes
      // scroll view to top
      window.scrollTo(0, 0);
      // close sidebar on route change
      this.settings.setLayoutSetting("asideToggled", false);
    });

    // enable sidebar autoclose from extenal clicks
  }
  ngOnDestroy() {}
}
