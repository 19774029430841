import { Injectable } from '@angular/core';
import { ProductRequestService } from './product-request.service';
import { environment } from 'src/environments/environment';
import { ProductRequest } from 'src/app/features/products/models/product-request.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { setProviderToken } from '@fluentllc/core';
import { Observable, catchError } from 'rxjs';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { HubSpotService } from '../hub-spot.service';

@Injectable()
export class HubspotAdapterService extends ProductRequestService {
    private formId = '08bb6553-e148-4ae4-848d-818ee70c0f65'; // Replace with your HubSpot form ID
    private portalId = '4896724'; // Replace with your HubSpot portal ID
    private apiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`;
    // private headers = new HttpHeaders().set('Content-Type', 'application/json');
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    private contextData?: ContextData;

    constructor(private http: HttpClient, private store: Store, private hubSpotService: HubSpotService) {
        super();
        this.store.dispatch(setProviderToken({ providerName: 'hubspot', providerToken: environment.hubspotAccessToken }));
    }

    setContextData(contextData?: ContextData): void {
        this.contextData = contextData;
    }

    submitRequest(productRequest: ProductRequest): Observable<any> {
        const hubspotData = this.transformData(productRequest);

          return this.http.post(this.apiUrl, hubspotData, { headers: this.headers })
          .pipe(
            catchError((error) => {
              console.error('Error submitting form to HubSpot', error);
              throw error;
            })
          );
    }

    private transformData(productRequest: ProductRequest): any {
        this.hubSpotService.push(['identify', { email: productRequest.email}])
        const hubspotData = {
            fields: [
                { name: 'firstname', value: productRequest.firstname },
                { name: 'lastname', value: productRequest.lastname },
                { name: 'email', value: productRequest.email },
                { name: 'phone', value: productRequest.phone },
                { name: 'company', value: productRequest.company },
                { name: 'country_iso_alpha2', value: productRequest.country_iso_alpha2 },
                { name: 'state_us', value: productRequest.state_us },
                { name: 'message', value: productRequest.message },
                { name: 'products_interested', value: productRequest.products_interested }
            ],
            context: this.contextData || {},
            legalConsentOptions: {
                consent: {
                    consentToProcess: true,
                    text: "I agree to allow Scinomix to store and process my personal data.",
                    communications: [
                        {
                            value: true,
                            subscriptionTypeId: 999,
                            text: "I agree to receive marketing communications from Scinomix."
                        }
                    ]
                }
            }
        };

        // return JSON.stringify(hubspotData);
        return hubspotData
    }

}
