<div *ngIf="categories && categories.length > 0">
    <div class="blog-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="card card-default">
                        <!-- Card Header with New Category Button -->
                        <div class="card-header d-flex justify-content-between align-items-center admin-list">
                            <div>Category Manager</div>
                            <!-- <button class="btn btn-secondary" (click)="createCategory()">New Category</button> -->
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <!-- Table Head -->
                                    <thead>
                                        <tr>
                                            <th>Category Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <!-- Table Body -->
                                    <tbody>
                                        <!-- Recursive template declaration -->
                                        <ng-template #recursiveList let-categories let-depth="depth">
                                            <ng-container *ngFor="let category of categories">
                                                <tr (click)="editCategory(category)">
                                                    <!-- Apply padding-left to the first td directly for indentation -->
                                                    <td [style.padding-left]="computeIndent(depth)">{{ category.name }}</td>
                                                    <td>
                                                        <div class="action-buttons">
                                                            <button class="btn btn-secondary btn-sm" (click)="editCategory(category, $event)">Edit</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <!-- Recursive inclusion for children if they exist -->
                                                <ng-container *ngIf="category.children && category.children.length">
                                                    <ng-container *ngTemplateOutlet="recursiveList; context: {$implicit: category.children, depth: depth + 1}">
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-template>

                                        <!-- Initial call to the recursive template with depth 0 -->
                                        <ng-container *ngTemplateOutlet="recursiveList; context: {$implicit: categories, depth: 0}"></ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- Card Footer -->
                        <div class="card-footer">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
