import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Category } from '../../models/category.interface';

@Component({
  selector: 'app-category-admin-list',
  templateUrl: './category-admin-list.component.html',
  styleUrls: ['./category-admin-list.component.scss']
})
export class CategoryAdminListComponent {
  @Input() categories: Category[];
  @Output() create = new EventEmitter();
  @Output() edit = new EventEmitter<string>();

  createCategory() {
    this.create.emit();
  }

  editCategory(category: Category, event?: MouseEvent) {
    if (event) {
      event.stopPropagation(); // Stops the propagation of the click event
    }
    this.edit.emit(category.id);
  }

  computeIndent(depth: number): string {
    let indent = '15px';

    if (depth > 0) {
      indent = `${depth * 30}px`
    }
    // Indentation starts from 0px for depth 0 and increases by 30px per depth level
    return indent;
  }
}
