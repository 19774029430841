<app-page-banner-container backgroundImage="/assets/images/default_abstract.jpg" headingLevel="1" title="Partners & Alliances"
    [breadcrumbs]="breadcrumbs"></app-page-banner-container>

<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="ptb-30">
                <p>In the science industry, you recognize the value of high-quality lab automation. That's why Scinomix
                    is proud
                    to collaborate with top-tier companies globally. Listed below are our exclusive international
                    distributors, carefully selected to provide our state-of-the-art lab automation solutions. For our
                    colleagues in North America, please note that we provide direct sales to ensure optimal service.</p>
            </div>
        </div>
    </div>
</div>
<app-distributors-list-container></app-distributors-list-container>