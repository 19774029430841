import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { SearchEffects } from './search.effects';
import { searchReducer, searchFeatureKey } from './search.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(searchFeatureKey, searchReducer),
    EffectsModule.forFeature([SearchEffects]),
  ],
})
export class SearchStoreModule { }
