
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { JobApplication } from 'src/app/shared/models/job-application.interface';


export interface JobApplicationState extends EntityState<JobApplication> {
  loading: boolean;
  error: any;
  successMessage: string | null;
}

export const adapter: EntityAdapter<JobApplication> = createEntityAdapter<JobApplication>();


export const initialState: JobApplicationState = adapter.getInitialState({
  loading: false,
  error: null,
  successMessage: null
});

