import { Observable, combineLatest, map, of } from "rxjs";
import { MenuItem } from "../models/menu-item.interface";
import { PermissionService } from "@fluentllc/core";
import { RoleService } from "@fluentllc/core";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class MenuUtility {

  constructor(
    private roleService: RoleService,
    private permissionService: PermissionService
  ) {}
  

  // Determine if a menu item should be visible based on the user's roles and permissions
  public isMenuItemVisible(menuItem: MenuItem): Observable<boolean> {
    const roleObservables = menuItem.roles?.map((role) => this.roleService.hasRole(role)) || [];
    const permissionObservables = menuItem.permissions?.map((permission) => this.permissionService.hasPermission(permission)) || [];

    const hasRequiredRole$ = roleObservables.length
      ? menuItem.rolesOp === "AND"
        ? combineLatest(roleObservables).pipe(map((results) => results.every(Boolean)))
        : combineLatest(roleObservables).pipe(map((results) => results.some(Boolean)))
      : of(true);

    const hasRequiredPermission$ = permissionObservables.length
      ? menuItem.permissionsOp === "AND"
        ? combineLatest(permissionObservables).pipe(map((results) => results.every(Boolean)))
        : combineLatest(permissionObservables).pipe(map((results) => results.some(Boolean)))
      : of(true);

    return combineLatest([hasRequiredRole$, hasRequiredPermission$]).pipe(
      map(([hasRequiredRole, hasRequiredPermission]) => {
        let accessCheck = true;

        if (menuItem.operator === "OR") {
          accessCheck = hasRequiredRole || hasRequiredPermission;
        } else if (menuItem.operator === "AND") {
          accessCheck = hasRequiredRole && hasRequiredPermission;
        } else {
          accessCheck = hasRequiredRole || hasRequiredPermission;
        }
        return accessCheck;
      })
    );
  }

  // Sort the menu items based on your criteria
  public sortMenuItems(menuItems: MenuItem[]): MenuItem[] {
    return menuItems
    .sort((a, b) => {
        const aOrder = a.order ?? Number.MAX_VALUE;
        const bOrder = b.order ?? Number.MAX_VALUE;

        if (aOrder !== Number.MAX_VALUE || bOrder !== Number.MAX_VALUE) {
            if (aOrder !== bOrder) {
                return aOrder - bOrder;
            }
            return a.label.localeCompare(b.label);
        }
        return 0;
    })
      .map((item) => {
        if (item.children) {
          return { ...item, children: this.sortMenuItems(item.children) };
        }
        return item;
      });
  }
}
