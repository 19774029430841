import { Category } from "../models/category.interface";


export interface SolutionsState {
    categoryData: Category | null;
    loading: boolean;
    error: string | null;
}

export const initialState: SolutionsState = {
    categoryData: null,
    loading: false,
    error: null
};
