import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss'],
})
export class PageBannerComponent implements OnInit {
  @Input() backgroundImage: string | null = null;
  @Input() title: string | null = null;
  @Input() breadcrumbs: Array<{ label: string; url: string }> = [];
  @Input() headingLevel: string = '2';

  ngOnInit(): void {
    if (!this.backgroundImage) {
      this.backgroundImage = '/assets/images/default_abstract.jpg';
    }
  }
}
