<aside class="widget widget-contact p-0">
    <div
        class="sci-col-bgcolor-yes sci-bgcolor-darkgrey col-bg-img-one sci-col-bgimage-yes sci-bg pt-20 pl-20 pr-20 pb-20">
        <div class="sci-col-wrapper-bg-layer sci-bg-layer">
            <div class="sci-col-wrapper-bg-layer-inner"></div>
        </div>
        <div class="layer-content">
            <div class="sci-bg sci-col-bgcolor-yes sci-bgcolor-grey pt-1 pb-1 pl-1 pr-1">
                <div class="sci-col-wrapper-bg-layer sci-bg-layer"></div>
                <div class="sci-col-bgcolor-yes sci-bgcolor-darkgrey sci-bg pt-50 pl-30 pr-30 pb-40">
                    <div class="sci-col-wrapper-bg-layer sci-bg-layer">
                        <div class="sci-col-wrapper-bg-layer-inner"></div>
                    </div>
                    <div class="layer-content">
                        <div class="sci-answer-faq-icon xlarge-icon white-icon mb-15">
                            <i class="flaticon-pigment"></i>
                        </div>
                        <h4>How Can We Help?</h4>
                        <p>Find the answers you need. Our product specialists are happy to answer your questions.</p>
                        <ul class="sci-textcolor-white">
                            <li><i class="flaticon-call mr-2"></i><a href="tel:+{{contactInfo.phoneClean}}">{{contactInfo.phone}}</a></li>
                            <li><i class="flaticon-email mr-2"></i><a
                                    href="mailto:{{contactInfo.email}}">{{contactInfo.email}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</aside>