import { createReducer, on } from '@ngrx/store';
import { FAQ } from 'src/app/shared/models/faq.interface';
import * as FAQActions from './faq.actions';
import { adapter, initialState } from './faq.state';

export const FAQFeatureKey = 'faqs';

export const FAQReducer = createReducer(
    initialState,
    on(FAQActions.loadFAQs, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(FAQActions.loadFAQsSuccess, (state, { FAQs }) =>
        adapter.setAll(FAQs, { ...state, loading: false })
    ),
    on(FAQActions.loadFAQsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })),
    on(FAQActions.loadFAQsByTag, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(FAQActions.loadFAQsByTagSuccess, (state, { FAQs }) =>
        adapter.setAll(FAQs, { ...state, loading: false })
    ),
    on(FAQActions.loadFAQsByTagFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }))
);
