<form [formGroup]="featuresForm" class="mt-3">
    <!-- Header Row -->
    <div class="row mb-2">
      <div class="col">
        <strong>Capability Name</strong>
      </div>
      <div class="col">
        <strong>Capability Description</strong>
      </div>
      <div class="col-auto">
        <strong>Actions</strong>
      </div>
    </div>
  
    <!-- Features Rows -->
    <div formArrayName="features" cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let specGroup of featuresArray.controls; let i = index" [formGroupName]="i" cdkDrag>
        <div class="card mb-3">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto text-center">
                <i class="fas fa-grip-vertical" cdkDragHandle></i> <!-- Font Awesome icon for drag handle -->
              </div>
              <!-- Feature Name -->
              <div class="col">
                <textarea formControlName="title" (change)="emitChanges()" placeholder="Capability Name" class="form-control" aria-label="Feature Name" rows="2"></textarea>
              </div>
              <!-- Feature Value -->
              <div class="col">
                <textarea formControlName="description" (change)="emitChanges()" placeholder="Capability Description" class="form-control" aria-label="Feature Value" rows="2"></textarea>
              </div>
              <!-- Remove Button -->
              <div class="col-auto">
                <button type="button" (click)="removeFeature(i)" class="btn btn-danger btn-sm">
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Footer Buttons -->
    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
      <button type="button" (click)="addFeature()" class="btn btn-outline-secondary">Add Feature</button>
    </div>
  </form>
  