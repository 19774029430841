export interface MetaTagsState {
    tags: { [key: string]: string };  // This dictionary holds any type of meta tags
}

export const initialMetaTagsState: MetaTagsState = {
    tags: {
        title: '',  // Default value for title
        description: '',  // Default value for description
        keywords: '',  // Default value for keywords
        prerenderStatusCode: ''  // Default value for prerender status code
    }
};