import { Component } from '@angular/core';
import { HelpContactInfo } from '../../models/help-contact-info.interface';
@Component({
  selector: 'app-help-widget-container',
  templateUrl: './help-widget-container.component.html',
  styleUrls: ['./help-widget-container.component.scss']
})
export class HelpWidgetContainerComponent {

  contactInfo: HelpContactInfo = {
    phone: '+1 314.298.9800',
    phoneClean: '13142989800',
    email: 'sales@scinomix.com',
  };

  backgroundImage = '/assets/images/scinomix-can-help-lab-automation.jpg';
}
