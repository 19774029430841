import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { CustomItem, CustomItemClass } from 'src/app/shared/models/custom-item.interface';
@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent  {

  constructor() {

  }

  // @Input() subtitle: string;
  // @Input() title: string;
  // @Input() titleHighlight: string;
  // @Input() description: string;
  // @Input() heroImage: string;
  heroImage: string = 'assets/images/careers-hero2.jpg';
  description = "At Scinomix, we know that work is all about people. That's why we prioritize our employees and foster an incredible culture. Established in 2001, we've expanded to over 27 countries with our headquarters in Earth City, MO. As we grow, we remain committed to maintaining our small business mindset while making waves in the corporate world. We're a dynamic company dedicated to offering top-notch benefits as we advance in our industry. Our exceptional package includes 100% paid benefits for our employees, a Flexible Paid Time Off (FPTO) plan, and the flexibility needed in a post-pandemic world. Our work environment is filled with excitement, providing ample opportunities for our team to learn and grow. As leaders in laboratory automation systems for the Life Science Industry, we rely on fresh talent to continue delivering innovative solutions, unparalleled quality, reliability, and exceptional customer service. Join us on this exhilarating journey and help shape the future! #JoinScinomix"
  public customItems: CustomItem[] = [
    new CustomItemClass({ name: 'Endless Growth Opportunities', description: 'Join us to learn and grow in an exciting work environment.' }),
    new CustomItemClass({ name: 'Exceptional Benefits Package', description: "Enjoy 100% paid benefits and flexible time off. Join our team today!" }),
    new CustomItemClass({ name: 'Innovate with Us', description: 'Shape the future in the Life Science Industry. Join our dynamic team!' }),
  ];

}
