import { createReducer, on } from '@ngrx/store';
import { initialState } from './solutions.state';
import * as SolutionsActions from './solutions.actions';
export const solutionsFeatureKey = 'solutions';


export const solutionsReducer = createReducer(
    initialState,
    on(SolutionsActions.loadCategory, state => ({ ...state, loading: true, error: null })),
    on(SolutionsActions.loadCategorySuccess, (state, { categoryData }) => ({ ...state, categoryData, loading: false })),
    on(SolutionsActions.loadCategoryFailure, (state, { error }) => ({ ...state, error, loading: false }))
);