import { createReducer, on } from '@ngrx/store';
import { initialState } from './vr-viewer.state';


import * as VrActions from './vr-viewer.actions';
export const vrViewerFeatureKey = 'vrViewer';
export const vrReducer = createReducer(
    initialState,
    on(VrActions.setImageSource, (state, action) => ({ ...state, imageSource: action.source })),
    on(VrActions.clearImageSource, state => ({ ...state, imageSource: null })),
    on(VrActions.setFallbackSource, (state, action) => ({ ...state, fallbackSource: action.source })),
    on(VrActions.clearFallbackSource, state => ({ ...state, fallbackSource: null })),
);