import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { Membership } from 'src/app/shared/models/membership.interface';
import { User } from '../../shared/models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly apiUrl = 'api/v1/users'; // Replace this with your API endpoint
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  constructor(private http: HttpClient) { }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUrl);
  }

  getUserById(id: number): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/${id}`);
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(this.apiUrl, user);
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/${user.id}`, user);
  }

  deleteUser(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getMemberships(userId: number): Observable<Membership[]> {
    const url = `${this.apiUrl}/users/${userId}/memberships`;

    return this.http.get<Membership[]>(url).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error getting memberships:', error);
        return of([]);
      })
    );
  }

  updateUserProfile(profileData: any): Observable<User> {
    // Replace this with your API call
    return this.http.patch<User>(
      `${this.apiUrl}/${profileData.id}`,
      profileData
    );
  }

  changePassword(passwordData: any): Observable<void> {
    // Replace this with your API call
    const body = {
      password: passwordData.password,
    };
    // console.log(body);
    return this.http.post<void>(
      `${this.apiUrl}/change_password_existing`,
      body
    );
  }

  getMyUserInfo(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/me`);
  }
}
