import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { MetaTag } from 'src/app/shared/models/meta-tag.interface';
import * as MetaTagsActions from './meta-tags.actions';

import { Action } from '@ngrx/store';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';

@Injectable()
export class MetaTagsEffects {
    constructor(
        private actions$: Actions,
        private titleService: Title,
        private metaService: Meta,
        private metaTagsService: MetaTagsService
    ) { }

    getMetaTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MetaTagsActions.getMetaTags),
            switchMap(() =>
                this.metaTagsService.getMetaTags().pipe(
                    map((metaTags: MetaTag[]) =>
                        MetaTagsActions.setMetaTags({ metaTags }) as Action
                    ),
                    catchError(() => EMPTY)
                )
            )
        )
    );

    updateTitle$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MetaTagsActions.updateTitle),
                tap(({ title }) => this.titleService.setTitle(title))
            ),
        { dispatch: false }
    );

    updateDescription$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MetaTagsActions.updateDescription),
                tap(({ description }) =>
                    this.metaService.updateTag({ name: 'description', content: description })
                )
            ),
        { dispatch: false }
    );

    updateKeywords$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MetaTagsActions.updateKeywords),
                tap(({ keywords }) =>
                    this.metaService.updateTag({ name: 'keywords', content: keywords })
                )
            ),
        { dispatch: false }
    );

    updatePrerenderStatusCode$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MetaTagsActions.updatePrerenderStatusCode),
                tap(({ code }) =>
                    this.metaService.updateTag({ name: 'prerender-status-code', content: code })
                )
            ),
        { dispatch: false }
    )
}
