import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DemoRequest } from 'src/app/shared/models/demo-request.interface';


export interface DemoRequestState extends EntityState<DemoRequest> {
    loading: boolean;
    error: any;
    successMessage: string | null;
}

export const adapter: EntityAdapter<DemoRequest> = createEntityAdapter<DemoRequest>();


export const initialState: DemoRequestState = adapter.getInitialState({
    loading: false,
    error: null,
    successMessage: null
});
