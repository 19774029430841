import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { IPService } from 'src/app/core/services/ip.service';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { ProductRequest } from '../../models/product-request.interface';
import * as ProductRequestActions from '../../store/product-request.actions';
import { getSuccessMessage } from '../../store/product-request.selectors';

@Component({
  selector: 'app-request-product-information-container',
  template: `
    <app-request-product-information (formSubmit)="onFormSubmit($event)" [productName]="productName" [pageName]="pageName" [pageUri]="pageUri" [successMessage]="successMessage$ | async" [submitted]="submitted"></app-request-product-information>
  `,
  styleUrls: ['./request-product-information-container.component.scss']
})
export class RequestProductInformationContainerComponent implements OnInit {
  @Input() productName: string;
  @Input() pageName: string;
  @Input() pageUri: string;
  successMessage$: Observable<string | null>;
  submitted: boolean = false;
  ipAddress:string; 
  constructor(private store: Store, private cookieService: CookieService, private ip:IPService) {
    this.successMessage$ = store.select(getSuccessMessage);
  }

  ngOnInit(): void {
    this.getIP(); 
  }

  getHubSpotUTK() {
    return this.cookieService.get('hubspotutk');
  }
  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  } 

  onFormSubmit(productRequest: ProductRequest): void {
    const hutkValue = this.getHubSpotUTK();
    const contextData: ContextData = {
      ipAddress: this.ipAddress,
      pageName: this.pageName,
      pageUri: this.pageUri
    };

    if (hutkValue) {
      contextData.hutk = hutkValue;
    }
    this.store.dispatch(ProductRequestActions.addProductRequest({ productRequest, contextData }));
    this.submitted = true;
  }
}
