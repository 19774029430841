import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaRoutingModule } from './media-routing.module';
// import { MediaStoreModule } from './store/media-store.module';
import { MediaAdminListContainerComponent } from './containers/media-admin-list-container/media-admin-list-container.component';
import { MediaAdminManageContainerComponent } from './containers/media-admin-manage-container/media-admin-manage-container.component';
import { MediaAdminManageComponent } from './components/media-admin-manage/media-admin-manage.component';
import { MediaAdminListComponent } from './components/media-admin-list/media-admin-list.component';
import { MediaItemComponent } from './components/media-item/media-item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaEditorComponent } from './components/media-editor/media-editor.component';


@NgModule({
  declarations: [
    MediaAdminListContainerComponent,
    MediaAdminManageContainerComponent,
    MediaAdminManageComponent,
    MediaAdminListComponent,
    MediaItemComponent,
    MediaEditorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MediaRoutingModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    MediaAdminListContainerComponent
  ]
})
export class MediaModule {}
