<div class="not-found-area ptb-100">
    <div class="container">
        <div class="not-found-content">

            <h1>Oops, We Can't Find That Page</h1>
            <p class="ptb-10">Well, that's embarrasing. We're unable to locate the page you're looking for. Our team has
                embarked on a
                spirited quest to locate this elusive page as soon as possible, employing ingenious
                contraptions and mystical incantations in hopes of summoning its return. But fret not, for science never
                rests! Why not try a new search below.
            </p>
            <div class="row">
                <div class="col-lg-12 search-results">
                    <app-search (search)="onSearch($event)" (indexChange)="onIndexChange($event)"></app-search>

                    <app-search-results [searchResults]="filteredSearchResults$ | async"
                        [searchPerformed]="searchPerformed"></app-search-results>
                </div>
            </div>
        </div>
    </div>
</div>