import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, switchMap, take, takeUntil } from "rxjs";
import { Media } from "src/app/features/products/models/media.interface";
import { Product } from "src/app/features/products/models/product.interface";
import { createMediaForProduct, createMediaForProductSuccess, loadMedia, removeProductAssociation, saveEditedMedia, saveEditedMediaSuccess } from "src/app/features/products/store/media.actions";
import { selectCurrentProduct } from "src/app/features/products/store/products.selectors";

@Component({
  selector: "app-media-editor",
  templateUrl: "./media-editor.component.html",
  styleUrls: ["./media-editor.component.scss"],
})
export class MediaEditorComponent implements OnInit, OnDestroy {
  @ViewChild("template") template: TemplateRef<any>;
  @Output() save = new EventEmitter<Media[]>();
  @Input() objectType: string = 'media';
  isEditMode: boolean = false;
  private destroy$ = new Subject<void>();
  product$: Observable<Product>;
  media$: Observable<Media[]>;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered modal-xl",
  };
  mediaForm: FormGroup;
  file: File | null = null;
  thumbnail: File | null = null;
  slug: string;
  media: Media;
  constructor(
    private modalService: BsModalService,
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    this.initializeForm();
    this.route.params.subscribe((params) => {
      const slug = params["slug"];
      this.slug = slug;
    });
  }

  initializeForm(): void {
    this.mediaForm = this.fb.group({
      file: [null],
      meta_alt: ['', Validators.required],
      type: ['', Validators.required],
      external_video_url: [''],
      external_video_alternative_text: [''],
      external_video_caption: [''],
      thumbnail: [null],
      caption: ['', Validators.required],
      weight: [0, Validators.required],
      priority: ['no-priority', Validators.required]
    });
  }

  onFileChange(event: any, type: string): void {
    const file = event.target.files[0];
    if (type === 'file') {
      this.file = file;
    } else if (type === 'thumbnail') {
      this.thumbnail = file;
    }
  }

  ngOnInit(): void {
    this.file = null;
    this.thumbnail = null;
    this.initializeForm();
    if (this.media && this.media !== null) {
      this.isEditMode = true;
    }
    
    this.actions$
      .pipe(
        ofType(saveEditedMediaSuccess, createMediaForProductSuccess),
        takeUntil(this.destroy$) // Ensure subscription is properly cleaned up
      )
      .subscribe(() => {
        // This will be executed when saveEditedMediaSuccess action is dispatched
        if (this.modalRef) {
          this.modalRef.hide();
          // this.save.emit(); // Optionally emit an event if needed
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveChanges(): void {
    let characteristicsSlug = null;
    if (this.objectType == 'specifications') {
      characteristicsSlug = 'specifications';
    }
    const { ...formValues } = this.mediaForm.getRawValue();
    const updatedMedia = {
      ...formValues,
      id: this.media?.id,
    };

    if (this.isEditMode === true) {
      // console.log('Is Edit Mode Updated Media: ', updatedMedia);
      this.store.dispatch(saveEditedMedia({ media: updatedMedia, file: this.file, thumbnail: this.thumbnail }));
    } else {
      // Include characteristicsSlug if it's provided and the item is a specification
      const dispatchPayload = characteristicsSlug
        ? { media: updatedMedia, file: this.file, thumbnail: this.thumbnail, productSlug: this.slug, characteristicsSlug: characteristicsSlug }
        : { media: updatedMedia, file: this.file, thumbnail: this.thumbnail, productSlug: this.slug };
      // console.log('Dispatch Payload: ', dispatchPayload);
      this.store.dispatch(createMediaForProduct(dispatchPayload));
    }
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  openEditorModal(media: Media) {
    this.file = null;
    this.thumbnail = null;
    this.isEditMode = true;
    this.initializeForm();
    this.mediaForm.patchValue(media);
    this.media = media;
    this.modalRef = this.modalService.show(this.template, this.config);
  }

  newMedia(): void {
    this.media = null;
    this.mediaForm.reset();
    this.isEditMode = false;
    this.modalRef = this.modalService.show(this.template, this.config);
  }

  get isExternalType(): boolean {
    return this.mediaForm.get("type").value === "external";
  }
}
