import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { PaginationInfo } from '@fluentllc/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() paginationInfo: PaginationInfo;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();

  totalPages: number[] = [];
  currentPageNumber: number = 1;
  visiblePages: number[] = [];
  pageSize: number = 10;

  ngOnChanges(): void {
    if (this.paginationInfo) {
      this.pageSize = Number(this.paginationInfo.paginatedBy);
      this.currentPageNumber = Number(this.paginationInfo.currentPage);
      const totalItems = Number(this.paginationInfo.count);
      const itemsPerPage = Number(this.paginationInfo.paginatedBy);
      const pageCount = Math.ceil(totalItems / itemsPerPage);
      this.totalPages = Array.from({ length: pageCount }, (_, i) => i + 1);
      this.calculateVisiblePages();
    }
  }

  calculateVisiblePages() {
    const maxPagesToShow = 5;
    let startPage = Math.max(this.currentPageNumber - 2, 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, this.totalPages.length);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    this.visiblePages = this.totalPages.slice(startPage - 1, endPage);
  }

  onPageChange(page: number, event: Event) {
    event.preventDefault();
    this.currentPageNumber = page;
    this.calculateVisiblePages();
    this.pageChanged.emit(page);
  }

  onItemsPerPageChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.itemsPerPageChanged.emit(Number(selectedValue));
  }

  incrementPage(event: Event) {
    if (this.currentPageNumber < this.totalPages.length) {
      this.onPageChange(this.currentPageNumber + 1, event);
    }
  }
}
