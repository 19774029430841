import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TenantTemplate } from "src/app/shared/models/tenant-template.interface";

@Injectable({
  providedIn: "root",
})
export class TenantTemplateService {
  private readonly apiUrl = "api/v1/tenant-templates"; // Replace this with your API endpoint
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });

  constructor(private http: HttpClient) {}

  getTenantTemplates(): Observable<TenantTemplate[]> {
    return this.http.get<TenantTemplate[]>(this.apiUrl);
  }

  getTenantTemplateById(id: number): Observable<TenantTemplate> {
    return this.http.get<TenantTemplate>(`${this.apiUrl}/${id}`);
  }

  createTenantTemplate(tenantTemplate: TenantTemplate): Observable<TenantTemplate> {
    return this.http.post<TenantTemplate>(this.apiUrl, tenantTemplate);
  }

  updateTenantTemplate(tenantTemplate: TenantTemplate): Observable<TenantTemplate> {
    return this.http.put<TenantTemplate>(`${this.apiUrl}/${tenantTemplate.id}`, tenantTemplate);
  }

  deleteTenantTemplate(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
