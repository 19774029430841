import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { ErrorHandlerService } from "./services/error-handler.service";
import { CommonModule } from "@angular/common";
import { AuthService } from "./services/auth.service";
import { MenuService } from "./services/menu.service";
import { UserService } from "./services/user.service";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { AlertService } from "./services/alert.service";
import { RolesPermissionsService } from "./services/roles-permissions.service";
import { TenantService } from "./services/tenant.service";
import { TenantTemplateService } from "./services/tenant-template.service";
import { OffsidebarService } from "./services/offsidebar.service";
import { DynamicComponentService } from "./services/dynamic-component.service";
import { MultiStepFormContainerComponent } from "../shared/modules/multi-step-form/containers/multi-step-form-container/multi-step-form-container.component";
import { DYNAMIC_COMPONENTS_MAP } from "./tokens/dynamic-component.tokens";
import { MetaTagsService } from "./services/seo/meta-tags.service";
import { SchemaService } from "./services/seo/schema.service";
import { ProductsApiService } from "./services/products/products-api.service";
import { SettingsService } from "./services/settings.service";
import { LoadingInterceptor } from "./interceptors/loading.interceptor";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./strategies/custom-route-reuse-strategy";
import { HubspotAuthInterceptor } from "./interceptors/hubspot-auth.interceptor";
import { SearchService, SEARCH_SERVICE } from "src/app/core/services/search/abstract-search.service";
import { AlgoliaSearchService } from "src/app/core/services/search/algolia-search.service";
import { GtmService } from "./services/gtm.service";
import { RouterService } from "./services/router.service";
import { CanonicalService } from "./services/seo/canonical.service";
import { WindowService } from "./services/window.service";
import { DataLayerService } from "./services/data-layer.service";
import { HubSpotService } from "./services/hub-spot.service";
import { ModalService } from "./services/modal.service";
import { CacheService } from "./services/caching.service";
import { CacheInterceptor } from "./interceptors/cache.intercepter";
import { HeaderLoggingInterceptor } from "./interceptors/header-logging.interceptor";
import { CacheBusterInterceptor } from "./interceptors/cache-buster.interceptor";
@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    AuthService,
    AlertService,
    ErrorHandlerService,
    MenuService,
    UserService,
    TenantService,
    TenantTemplateService,
    RolesPermissionsService,
    OffsidebarService,
    DynamicComponentService,
    MetaTagsService,
    SchemaService,
    ProductsApiService,
    SettingsService,
    // GtmService,
    RouterService,
    CanonicalService,
    WindowService,
    DataLayerService,
    HubSpotService,
    CacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HubspotAuthInterceptor, // Add this provider
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoadingInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheBusterInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    DynamicComponentService,
    ModalService,
  ],
})
export class CoreModule {}
