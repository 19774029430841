import { Injectable, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { ComponentConfig } from 'src/app/features/layout/interfaces/component-config.interface';
import * as OffsidebarActions from 'src/app/features/layout/store/offsidebar/offsidebar.actions'
@Injectable({
  providedIn: 'root',
})
export class OffsidebarService {
  constructor(private store: Store) { }

  open(componentConfig: ComponentConfig) {
    // console.log("open")
    this.store.dispatch(
      OffsidebarActions.showOffsidebar({ config: componentConfig })
    );
  }


  close() {
    // console.log("close")
    this.store.dispatch(OffsidebarActions.hideOffsidebar());
  }
}
