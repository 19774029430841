import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Distributor } from 'src/app/shared/models/distributor.interface';
import { loadDistributors } from '../../store/distributors.actions';
import { selectAllDistributors } from '../../store/distributors.selectors';

@Component({
  selector: 'app-distributors-list-container',
  templateUrl: './distributors-list-container.component.html',
  styleUrls: ['./distributors-list-container.component.scss']
})
export class DistributorsListContainerComponent implements OnInit {
  distributors$: Observable<Distributor[]>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadDistributors());
    this.distributors$ = this.store.select(selectAllDistributors);
  }

}
