
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProductClass } from '../models/product-class.interface';



export interface ProductClassState extends EntityState<ProductClass> {
  currentProductClass: ProductClass | null;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<ProductClass> = createEntityAdapter<ProductClass>({
  selectId: (productClass: ProductClass) => productClass.slug,
});


export const initialState: ProductClassState = adapter.getInitialState({
  currentProductClass: null,
  loading: false,
  error: null,
});
