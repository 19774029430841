import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Layout } from "src/app/shared/models/layout.interface";

@Injectable({
    providedIn: "root",
})
export class LayoutService {
    private readonly apiUrl = "api/v1/layouts"; // Replace this with your API endpoint
    headers = new HttpHeaders({
        "Content-Type": "application/json",
    });

    constructor(private http: HttpClient) { }

    getLayouts(): Observable<Layout[]> {
        return this.http.get<Layout[]>(this.apiUrl);
    }

    getLayoutById(id: number): Observable<Layout> {
        return this.http.get<Layout>(`${this.apiUrl}/${id}`);
    }

    createLayout(layout: Layout): Observable<Layout> {
        return this.http.post<Layout>(this.apiUrl, layout);
    }

    updateLayout(layout: Layout): Observable<Layout> {
        return this.http.put<Layout>(`${this.apiUrl}/${layout.id}`, layout);
    }

    deleteLayout(id: number): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${id}`);
    }

}