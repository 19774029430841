import { Component, EventEmitter, Output } from '@angular/core';
import { SearchWindowToggleService } from 'src/app/core/services/search/search-window-toggle.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-search-navbar-widget',
  templateUrl: './search-navbar-widget.component.html',
  styleUrls: ['./search-navbar-widget.component.scss']
})
export class SearchNavbarWidgetComponent {
  @Output() search = new EventEmitter<string>();
  @Output() queryChange = new EventEmitter<string>();
  @Output() indexChange = new EventEmitter<string[]>();

  constructor(private searchWindowToggleService: SearchWindowToggleService) { }

  searchIndexes = environment.algoliaSearchIndexes;
  selectedIndexes = [...this.searchIndexes.map(item => item.index)];
  indexCheckStates = this.searchIndexes.reduce((acc, cur) => ({ ...acc, [cur.index]: true }), {});
  currentQuery = '';
  onQueryChange(event: Event): void {
    this.currentQuery = (event.target as HTMLInputElement).value;
    this.queryChange.emit(this.currentQuery);
  }

  onSearch(): void {

    if (this.currentQuery) {
      this.searchWindowToggleService.searchPerformed();
      this.search.emit(this.currentQuery);
    }
  }
}
