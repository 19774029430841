import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import { JobPosting } from "src/app/shared/models/job-posting.interface";
import { AppState } from "src/app/store/app.state";
import * as JobPostingActions from "../../store/job-posting.actions";
import { selectJobPostingById } from "../../store/job-posting.selectors";

@Component({
  selector: "app-job-posting-admin-manage-container",
  templateUrl: "./job-posting-admin-manage-container.component.html",
  styleUrls: ["./job-posting-admin-manage-container.component.scss"],
})
export class JobPostingAdminManageContainerComponent implements OnInit {
  jobPosting$: Observable<JobPosting>;
  isEditMode: boolean = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private metaTagsService: MetaTagsService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL is '/admin/post/create'
        if (this.router.url === "/admin/job/create") {
          // Dispatch the clearPostFormState action
          this.store.dispatch(JobPostingActions.clearJobPostingFormState());
        }
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const postId = params['id'];
      // console.log(postId)
      let pageTitle = 'Create Job Posting';
      if (postId) {
        this.isEditMode = true;
        pageTitle = 'Edit Job Post';
        this.jobPosting$ = this.store.select(selectJobPostingById(postId));
      } else {
        this.isEditMode = false;
        this.jobPosting$ = new Observable<JobPosting>(); // Initialize with a new post
      }
      const newMetaTags = {
        title: pageTitle,
        description: '',
        keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      };
      this.metaTagsService.updateMetaTags(newMetaTags);
    });
  }

  onSave(event: any): void {
    // console.log(this.isEditMode)
    this.store.dispatch(JobPostingActions.saveJobPostingFormState({ jobPosting: event.jobPosting }));
    if (this.isEditMode) {
      this.store.dispatch(JobPostingActions.updateJobPosting({ jobPosting: event.jobPosting }));
    } else {
      this.store.dispatch(JobPostingActions.createJobPosting({ jobPosting: event.jobPosting }));
      // this.store.dispatch(PostActions.updatePost({ post: event.post, imageFile: event.imageFile }));
    }
    this.store.dispatch(JobPostingActions.clearJobPostingFormState());
  }

  onCancel(): void {
    this.router.navigate(["/admin/jobs"]);
    this.store.dispatch(JobPostingActions.clearJobPostingFormState());
  }
}
