import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanderA2PlusEOL20230815ContainerComponent } from './containers/lander-a2-plus-eol20230815-container/lander-a2-plus-eol20230815-container.component';
import { LanderA2PlusEOL20230815Component } from './components/lander-a2-plus-eol20230815/lander-a2-plus-eol20230815.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LandingPagesRoutingModule } from './landing-pages-routing.module';
import { ScinomixAQ20240306Component } from './components/scinomix-aq20240306/scinomix-aq20240306.component';
import { ProductsModule } from '../products/products.module';
import { LayoutContainerComponent } from './containers/layout-container/layout-container.component';
import { LandingPageContainerComponent } from './containers/landing-page-container/landing-page-container.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ContentBlockComponent } from './components/content-block/content-block.component';
import { LayoutPositionComponent } from './components/layout-position/layout-position.component';
import { LandingPageAdminListComponent } from './components/landing-page-admin-list/landing-page-admin-list.component';
import { LandingPageAdminManageComponent } from './components/landing-page-admin-manage/landing-page-admin-manage.component';
import { LandingPageAdminListContainerComponent } from './containers/landing-page-admin-list-container/landing-page-admin-list-container.component';
import { LandingPageAdminManageContainerComponent } from './containers/landing-page-admin-manage-container/landing-page-admin-manage-container.component';
import { LayoutAdminListContainerComponent } from './containers/layout-admin-list-container/layout-admin-list-container.component';
import { LayoutAdminManageContainerComponent } from './containers/layout-admin-manage-container/layout-admin-manage-container.component';
import { LayoutAdminListComponent } from './components/layout-admin-list/layout-admin-list.component';
import { LayoutAdminManageComponent } from './components/layout-admin-manage/layout-admin-manage.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { LanderB2bTubes20240320Component } from './components/lander-b2b-tubes20240320/lander-b2b-tubes20240320.component';
import { LayoutStoreModule } from './store/layout-store.module';



@NgModule({
  declarations: [
    LanderA2PlusEOL20230815ContainerComponent,
    LanderA2PlusEOL20230815Component,
    ScinomixAQ20240306Component,
    LayoutContainerComponent,
    LandingPageContainerComponent,
    LandingPageComponent,
    LayoutComponent,
    ContentBlockComponent,
    LayoutPositionComponent,
    LandingPageAdminListComponent,
    LandingPageAdminManageComponent,
    LandingPageAdminListContainerComponent,
    LandingPageAdminManageContainerComponent,
    LayoutAdminListContainerComponent,
    LayoutAdminManageContainerComponent,
    LayoutAdminListComponent,
    LayoutAdminManageComponent,
    LanderB2bTubes20240320Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutStoreModule,
    LandingPagesRoutingModule,
    ProductsModule,
    TabsModule.forRoot(),
  ]
})
export class LandingPagesModule { }
