import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { delay, Observable, of, take } from 'rxjs';
import { DynamicFormStep } from 'src/app/shared/models/dynamic-form-step.interface';
import { StepOneComponent } from 'src/app/shared/modules/multi-step-form/components/step1.component';
import { StepTwoComponent } from 'src/app/shared/modules/multi-step-form/components/step2.component';
import { StepThreeComponent } from 'src/app/shared/modules/multi-step-form/components/step3.component';
import { selectFormData } from 'src/app/shared/modules/multi-step-form/store/multi-step-form.selectors';


@Injectable({
    providedIn: 'root'
})
export class DynamicFormService {
    constructor(private injector: Injector, private store: Store) { }


    initFormValues(): Observable<any> {
        return this.store.select(selectFormData).pipe(take(1));
    }

    getSteps(): Observable<DynamicFormStep[]> {
        const stepOne = this.createStepOneComponent();
        const stepTwo = this.createStepTwoComponent();
        const stepThree = this.createStepThreeComponent();
        return of([
            stepOne.initialize(),
            stepTwo.initialize(),
            stepThree.initialize()
        ]);
    }

    submitFormData(formData: any) {
        // Simulate submitting the form data
        // console.log('Submitting form data:', formData);
        return of({ success: true }).pipe(delay(1000));
    }

    private createStepOneComponent(): StepOneComponent {
        const factory = this.injector.get(ComponentFactoryResolver).resolveComponentFactory(StepOneComponent);
        const componentRef = factory.create(this.injector);
        return componentRef.instance;
    }

    private createStepTwoComponent(): StepTwoComponent {
        const factory = this.injector.get(ComponentFactoryResolver).resolveComponentFactory(StepTwoComponent);
        const componentRef = factory.create(this.injector);
        return componentRef.instance;
    }

    private createStepThreeComponent(): StepThreeComponent {
        const factory = this.injector.get(ComponentFactoryResolver).resolveComponentFactory(StepThreeComponent);
        const componentRef = factory.create(this.injector);
        return componentRef.instance;
    }
}
