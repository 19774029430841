// src/app/core/services/post.service.ts

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Distributor } from "src/app/shared/models/distributor.interface";

@Injectable({
    providedIn: "root",
})
export class DistributorService {
    private readonly apiUrl = "api/v1/distributors"; // Replace this with your API endpoint

    constructor(private http: HttpClient) { }

    getDistributors(): Observable<Distributor[]> {
        return this.http.get<Distributor[]>(this.apiUrl);
    }

    getDistributorById(id: number): Observable<Distributor> {
        return this.http.get<Distributor>(`${this.apiUrl}/${id}`);
    }

    createDistributor(distributor: Distributor): Observable<Distributor> {
        return this.http.post<Distributor>(this.apiUrl, distributor);
    }

    updateDistributor(distributor: Distributor): Observable<Distributor> {
        return this.http.put<Distributor>(`${this.apiUrl}/${distributor.id}`, distributor);
    }

    deleteDistributor(id: number): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${id}`);
    }
}
