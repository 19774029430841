// offsidebar.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as OffsidebarActions from './offsidebar.actions';
import { OffsidebarService } from 'src/app/core/services/offsidebar.service';
// import { YourDataService } from '../your-data.service';

@Injectable()
export class OffsidebarEffects {
    constructor(private actions$: Actions, private offsidebarService: OffsidebarService) { }

    showOffsidebar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(OffsidebarActions.showOffsidebar),
                tap((action) => {
                    // console.log('Offsidebar opened');
                })
            ),
        { dispatch: false }
    );

    closeOffsidebar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(OffsidebarActions.hideOffsidebar),
                tap(() => {
                    // Perform any side effect when closing the offsidebar
                    // console.log('Offsidebar closed');
                })
            ),
        { dispatch: false } // Set dispatch to false since we don't need to dispatch any new action
    );
}
