import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ModalButtonConfig } from 'src/app/shared/models/modal-button-config.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  bsModalRef?: BsModalRef;

  constructor(private modalService: BsModalService) {}

  openModal(title: string, content: string, buttons: ModalButtonConfig[]): Promise<string> {
    return new Promise(resolve => {
      this.bsModalRef = this.modalService.show(ModalComponent, {
        initialState: { title, content, buttons }
      });

      this.bsModalRef.content.action.subscribe(result => {
        this.bsModalRef?.hide();
        resolve(result);
      });
    });
  }
}
