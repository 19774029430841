import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { JobApplication } from 'src/app/shared/models/job-application.interface';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';
import { selectJobPostingBySlugFactory } from '../../store/job-posting.selectors';
import * as JobApplicationActions from '../../store/job-application.actions';
import { getSuccessMessage } from '../../store/job-application.selectors';
import { CookieService } from 'ngx-cookie-service';
import { IPService } from 'src/app/core/services/ip.service';
@Component({
  selector: 'app-job-application-container',
  templateUrl: './job-application-container.component.html',
  styleUrls: ['./job-application-container.component.scss'],
})
export class JobApplicationContainerComponent {
  @Input() pageName: string;
  @Input() pageUri: string;
  successMessage$: Observable<string | null>;
  submitted: boolean = false;
  applicationForm: FormGroup;
  job$: Observable<JobPosting>;
  ipAddress:string;  
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private ip:IPService
  ) {
    const slug = this.route.snapshot.paramMap.get('slug');
    if (!slug) {
      this.router.navigate(['/about/employment']);
    } else {
      this.job$ = this.store.select(selectJobPostingBySlugFactory(slug));
      this.successMessage$ = store.select(getSuccessMessage);
    }
  }

  getHubSpotUTK() {
    return this.cookieService.get('hubspotutk');
  }

  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  }  

  ngOnInit() {
    this.applicationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      jobPosition: ['', Validators.required],
      resume: [null, Validators.required],
      coverLetter: [null, Validators.required],
    });
    this.getIP(); 
    this.job$.subscribe((job) => {
      this.pageName = job.title;
      this.pageUri = window.location.href;
    });
  }

  onFormSubmit(jobApplication: JobApplication): void {
    const hutkValue = this.getHubSpotUTK();
    const contextData: ContextData = {
      ipAddress: this.ipAddress,
      pageName: this.pageName,
      pageUri: this.pageUri,
    };

    if (hutkValue) {
      contextData.hutk = hutkValue;
    }
    this.store.dispatch(
      JobApplicationActions.addJobApplication({ jobApplication, contextData })
    );
    this.submitted = true;
  }
}
