import { format, formatISO, parse, parseISO } from "date-fns";

export class DateUtils {
  static toISO8601(dateInput) {
    let date;

    if (typeof dateInput === "string") {
      // Parse the MM-DD-YYYY string to a Date object
      date = parse(dateInput, "MM-dd-yyyy", new Date());
    } else if (dateInput instanceof Date) {
      // If it's already a Date object, use it directly
      date = dateInput;
    } else {
      // Handle invalid input, maybe return null or current date
      return null; // or formatISO(new Date());
    }

    // Convert the Date object to ISO 8601 format
    return formatISO(date);
  }

  static toMMDDYYYY(isoDateString) {
    // Parse the ISO 8601 string to a Date object
    const date = parseISO(isoDateString);
    // Format the Date object to MM-DD-YYYY string
    return format(date, "MM-dd-yyyy");
  }
}
