<div class="container mt-3">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="layout-edit-tab" data-bs-toggle="tab" data-bs-target="#layoutEdit" type="button" role="tab" aria-controls="layoutEdit" aria-selected="true">Layout Edit</button>
      </li>
      <!-- Additional tabs can be added here -->
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="layoutEdit" role="tabpanel" aria-labelledby="layout-edit-tab">
        <form [formGroup]="layoutForm" class="needs-validation" novalidate>
          <div class="row g-3 mt-3">
            <div class="col-md-6">
              <label for="name" class="form-label">Name</label>
              <input formControlName="name" id="name" placeholder="Name" required class="form-control">
              <div class="invalid-feedback">
                Please provide a valid name.
              </div>
            </div>
            <div class="col-md-6">
              <label for="columns" class="form-label">Columns</label>
              <input formControlName="columns" id="columns" type="number" placeholder="Columns" required class="form-control">
              <div class="invalid-feedback">
                Please provide a valid number of columns.
              </div>
            </div>
            <div class="col-md-6">
              <label for="rows" class="form-label">Rows</label>
              <input formControlName="rows" id="rows" type="number" placeholder="Rows" required class="form-control">
              <div class="invalid-feedback">
                Please provide a valid number of rows.
              </div>
            </div>
          </div>
          <div formArrayName="positions" class="mt-4">
            <label class="form-label d-block mb-2">Positions</label>
            <div *ngFor="let position of positions.controls; let i = index" [formGroupName]="i" class="border p-3 mb-3">
              <div class="row g-3">
                <div class="col-md-4">
                  <label for="positionName{{i}}" class="form-label">Position Name</label>
                  <input formControlName="position_name" id="positionName{{i}}" placeholder="Position Name" required class="form-control">
                </div>
                <div class="col-md-4">
                  <label for="allowedBlockTypes{{i}}" class="form-label">Allowed Block Types</label>
                  <input formControlName="allowed_block_types" id="allowedBlockTypes{{i}}" placeholder="Allowed Block Types" required class="form-control">
                </div>
                <div class="col-md-4">
                  <label for="order{{i}}" class="form-label">Order</label>
                  <input formControlName="order" id="order{{i}}" type="number" placeholder="Order" required class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="row{{i}}" class="form-label">Row</label>
                  <input formControlName="row" id="row{{i}}" type="number" placeholder="Row" required class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="col{{i}}" class="form-label">Column</label>
                  <input formControlName="col" id="col{{i}}" type="number" placeholder="Column" required class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="colSpan{{i}}" class="form-label">Column Span</label>
                  <input formControlName="col_span" id="colSpan{{i}}" type="number" placeholder="Column Span" required class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="rowSpan{{i}}" class="form-label">Row Span</label>
                  <input formControlName="row_span" id="rowSpan{{i}}" type="number" placeholder="Row Span" required class="form-control">
                </div>
                <div class="col-12 d-flex justify-content-end">
                  <button type="button" class="btn btn-danger btn-sm" (click)="removePosition(i)">Remove Position</button>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary btn-sm" (click)="addPosition()">Add Position</button>
          </div>
  
          <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
            <button class="btn btn-secondary me-2" type="button" (click)="onCancel()">Cancel</button>
            <button class="btn btn-primary" type="button" (click)="onSave()" >
              Save Layout
            </button>
          </div>
        </form>
      </div>
      <!-- Additional tab panes can be added here -->
    </div>
  </div>
  