<div class="pt-5" *ngIf="resources?.length">
    <div class="row">
        <div class="col-lg-12">
            <h5>Helpful Resources</h5>
            <div class="widget widget-download" *ngFor="let file of resources;">
                <app-call-to-action-small-with-icon1-container [ctaTitle]="file.caption" [ctaUrl]="file.file_preview">
                </app-call-to-action-small-with-icon1-container>
            </div>
        </div>
    </div>
</div>