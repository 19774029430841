// product-filter.state.ts

export interface ProductFilterState {
  selectedOptions: { [key: string]: string };
  filteredOptions: { [key: string]: string[] };
  variationOptions: { [key: string]: string[] };
}

export const initialProductFilterState: ProductFilterState = {
  selectedOptions: {},
  filteredOptions: {},
  variationOptions: {},
};
