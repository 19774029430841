<app-job-posting-admin-list
  [jobPostings]="posts$ | async"
  [paginationInfo]="paginationInfo$ | async"
  (edit)="onEdit($event)" 
  (delete)="onDelete($event)" 
  (create)="onCreate()"
  (pageChanged)="onPageChange($event)"
  (itemsPerPageChanged)="onChangePageSize($event)">
</app-job-posting-admin-list>

