// media.state.ts
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Media } from '../models/media.interface';

export interface MediaState extends EntityState<Media> {
  loading: boolean;
  error: string | null;
}

export const adapter: EntityAdapter<Media> = createEntityAdapter<Media>();

export const initialMediaState: MediaState = adapter.getInitialState({
  loading: false,
  error: null,
});
