import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContextData } from '../../models/context-data.interface';
import { DemoRequest } from '../../models/demo-request.interface';
import * as DemoRequestActions from 'src/app/features/appointment-scheduler/store/appointment-scheduler.actions'
import { getSuccessMessage } from 'src/app/features/appointment-scheduler/store/appointment-scheduler.selectors';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { IPService } from 'src/app/core/services/ip.service';
@Component({
  selector: 'app-virtual-demonstration-form-popover-container',
  templateUrl: './virtual-demonstration-form-popover-container.component.html',
  styleUrls: ['./virtual-demonstration-form-popover-container.component.scss']
})
export class VirtualDemonstrationFormPopoverContainerComponent implements OnInit {
  pageName: string;
  pageUri: string;
  successMessage$: Observable<string | null>;
  submitted: boolean = false;
  ipAddress:string; 
  onFormSubmit(demoRequest: DemoRequest): void {
    const hutkValue = this.getHubSpotUTK();

    const contextData: ContextData = {
      ipAddress: this.ipAddress,
      pageName: this.document.title,
      pageUri: this.location.href,
      sourceComponent: 'VirtualDemonstrationFormPopover'
    };

    if (hutkValue) {
      contextData.hutk = hutkValue;
    }
    
    this.store.dispatch(
      DemoRequestActions.addDemoRequest({ demoRequest, contextData })
    );
    this.submitted = true;
  }

  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  } 

  getHubSpotUTK() {
    return this.cookieService.get('hubspotutk');
  }

  constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: any,
    private location: PlatformLocation,
    private cookieService: CookieService,
    private ip:IPService
  ) {
    this.successMessage$ = store.select(getSuccessMessage);
  }
  ngOnInit(): void {
    this.getIP(); 
  }
}
