import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Testimonial } from 'src/app/shared/models/testimonial.interface';

@Component({
  selector: 'app-testimonials-list',
  templateUrl: './testimonials-list.component.html',
  styleUrls: ['./testimonials-list.component.scss']
})
export class TestimonialsListComponent implements OnInit {
  @Input() testimonials: Testimonial[];
  @Input() title: string = 'Customer';
  @Input() subhead: string = 'Testimonials';
  @Input() customOwlOptions: OwlOptions = {}

  constructor(
  ) { }


  ngOnInit(): void {



  }

}
