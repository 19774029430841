import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState } from './search.state';

export const selectSearchState = createFeatureSelector<SearchState>('search');

export const getSearchResults = createSelector(
    selectSearchState,
    (state: SearchState) => state.results
);

