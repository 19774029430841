import { Component, Input } from '@angular/core';
import { faCheckCircle, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';
import { Link } from 'src/app/shared/models/link.interface';
import { loadJobPostings } from '../../store/job-posting.actions';
import { selectAllJobPostings } from '../../store/job-posting.selectors';
@Component({
  selector: 'app-job-postings-sidebar-navigation-container',
  templateUrl: './job-postings-sidebar-navigation-container.component.html',
  styleUrls: ['./job-postings-sidebar-navigation-container.component.scss']
})
export class JobPostingsSidebarNavigationContainerComponent {
  menuTitle: string = 'Services & Support Links';
  jobPostings$: Observable<JobPosting[]>;
  @Input() currentJobPosting: JobPosting;
  links$: Observable<Link[]>;
  // icon: IconDefinition = faCheckCircle;
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadJobPostings());
    this.jobPostings$ = this.store.select(selectAllJobPostings).pipe(
      // filter out the currentPost by slug
      map(jobPostings => {
        if (this.currentJobPosting) {
          return jobPostings.filter(jobPosting => jobPosting.slug !== this.currentJobPosting.slug);
        }
        return jobPostings;
      })
    );

    this.links$ = this.jobPostings$.pipe(
      map(jobPostings => jobPostings.map(jobPosting => ({
        name: jobPosting.title,
        url: `/about/employment/job/${jobPosting.slug}`,
        target: '_self',
        icon: 'fa-checkbox'  // Replace with your actual icon
      })))
    );
  }
}
