import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/core/services/settings.service';
import { selectIsLoggedIn } from '@fluentllc/core';
import { MenuService } from 'src/app/core/services/menu.service';
import { MenuItem } from 'src/app/core/models/menu-item.interface';
@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit{
  isOpen: boolean;
  hovering: boolean = false;
  tooltipMessage = 'Toggle Sidebar';
  isLoggedIn$: Observable<boolean>;
  menuItems$: Observable<MenuItem[]>;
  context: string = 'admin';

  constructor(private settings: SettingsService, private store: Store,  private menuService: MenuService) {
    this.isOpen = !this.settings.getLayoutSetting("isCollapsed");
    this.isLoggedIn$ = this.store.select(selectIsLoggedIn);
  }

  ngOnInit(): void {
    this.isOpen = !this.settings.getLayoutSetting("isCollapsed")
    this.menuItems$ = this.menuService.getMenuItems(this.context);
  }

  toggleSidebar() {
    this.settings.toggleLayoutSetting("isCollapsed");
    this.isOpen = !this.isOpen;
    // this.cdr.detectChanges();
  }

  onMouseOver() {
    this.hovering = true;
    this.tooltipMessage = this.isOpen ? 'Collapse Sidebar' : 'Expand Sidebar';
}

onMouseOut() {
    this.hovering = false;
    this.tooltipMessage = 'Toggle Sidebar';
}


}
