
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Category } from '../models/category.interface';


export interface CategoryState extends EntityState<Category> {
  loading: boolean;
  error: any;
  formState: Category | null;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>();

export const initialState: CategoryState = adapter.getInitialState({
  loading: false,
  error: null,
  formState: null,
});
