import { Component, Input } from '@angular/core';
import { truncateDescription } from 'src/app/core/utils/string-utils';
import { Product } from '../../models/product.interface';

@Component({
  selector: 'app-tube-card',
  templateUrl: './tube-card.component.html',
  styleUrls: ['./tube-card.component.scss']
})
export class TubeCardComponent {
  @Input() product: Product | null = null;

  truncate(text: string): string {
    return truncateDescription(text);
  }
}
