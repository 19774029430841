import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductRequest } from 'src/app/features/products/models/product-request.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { setProviderToken } from '@fluentllc/core';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { v4 as uuidv4 } from 'uuid';
import { DemoRequestService } from './demo-request.service';
import { DemoRequest } from 'src/app/shared/models/demo-request.interface';
import * as moment from 'moment';
import { HubSpotService } from '../hub-spot.service';

@Injectable()
export class DemoRequestHubspotAdapterService extends DemoRequestService {
    private formId = 'd24e67a6-82a2-4ef4-adc0-5511358f771c'; // Replace with your HubSpot form ID
    private portalId = '4896724'; // Replace with your HubSpot portal ID
    private apiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`;
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    private contextData?: ContextData;

    constructor(private http: HttpClient, private store: Store, private hubSpotService: HubSpotService) {
        super();
        this.store.dispatch(setProviderToken({ providerName: 'hubspot', providerToken: environment.hubspotAccessToken }));
    }

    setContextData(contextData?: ContextData): void {
        this.contextData = contextData;
    }

    submitRequest(jobApplication: DemoRequest): Observable<any> {
        const hubspotData = this.transformData(jobApplication);
        return this.http.post(this.apiUrl, hubspotData, { headers: this.headers });
    }

    private transformData(demoRequest: DemoRequest): any {
        let request_date = moment(demoRequest.requested_date_time).utc();
        request_date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        this.hubSpotService.push(['identify', { email: demoRequest.email}])
        const hubspotData = {
            fields: [
                { name: 'firstname', value: demoRequest.firstname },
                { name: 'lastname', value: demoRequest.lastname },
                { name: 'email', value: demoRequest.email },
                { name: 'phone', value: demoRequest.phone },
                { name: 'country_iso_alpha2', value: demoRequest.country_iso_alpha2 },
                { name: 'state_us', value: demoRequest.state_us },
                { name: 'company', value: demoRequest.company },
                { name: 'requested_date_time', value: request_date.toISOString() },
                { name: 'message', value: demoRequest.message }
            ],
            context: { 'pageName': this.contextData.pageName, 'pageUri': this.contextData.pageUri } || {},
            legalConsentOptions: {
                consent: {
                    consentToProcess: true,
                    text: "I agree to allow Scinomix to store and process my personal data.",
                    communications: [
                        {
                            value: true,
                            subscriptionTypeId: 999,
                            text: "I agree to receive marketing communications from Scinomix."
                        }
                    ]
                }
            }
        };
        // console.log(JSON.stringify(hubspotData))
        return hubspotData;
    }

}
