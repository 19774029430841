import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, delay, map, mergeMap, tap } from "rxjs/operators";
import { from, of } from "rxjs";
import { Injectable } from "@angular/core";
import * as DemoRequestActions from "./appointment-scheduler.actions";
import { Store } from "@ngrx/store";
import { concat } from "rxjs";
import { DemoRequestService } from "src/app/core/services/demo-request/demo-request.service";
import { GtmService } from "src/app/core/services/gtm.service";

@Injectable()
export class DemoRequestEffects {
  addDemoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DemoRequestActions.addDemoRequest),
      mergeMap((action) => {
        this.demoRequestService.setContextData(action.contextData);
        return this.demoRequestService.submitRequest(action.demoRequest).pipe(
          mergeMap((response: any) => {
            const gtmPayload = {
                event: 'form_submission',
                form_type: 'demo_request',
                demoRequestId: response.id, // Assuming the response contains an id or some identifier
                demoRequestRequestedDate: action.demoRequest.requested_date_time,
                demoRequestMessage: action.demoRequest.message,
                demoRequestSourceComponent: null,
                pageName: action.contextData.pageName,
                pageUrl: action.contextData.pageUri,
            };
            
            if (action.contextData && action.contextData.sourceComponent) {
                gtmPayload.demoRequestSourceComponent = action.contextData.sourceComponent;
            }
            
            this.gtmService.push(gtmPayload);

            return concat(
              of(
                DemoRequestActions.setSuccessMessage({
                  message: response.inlineMessage,
                })
              ),
              of(
                DemoRequestActions.addDemoRequestSuccess({
                  demoRequest: action.demoRequest,
                })
              )
            );
          }),
          catchError((error) => of(DemoRequestActions.addDemoRequestFailure({ error })))
        );
      })
    )
  );

  resetDemoRequestSlice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DemoRequestActions.addDemoRequestSuccess),
        delay(30000), // Adjust the delay (in milliseconds) as needed
        tap(() => this.store.dispatch(DemoRequestActions.resetDemoRequestSlice()))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private demoRequestService: DemoRequestService,
    private gtmService: GtmService,
    private store: Store
  ) {}
}
