import { createReducer, on } from '@ngrx/store';
import { adapter, initialState } from './product-tags.state';
import * as ProductTagsActions from './product-tags.actions';
export const productTagsFeatureKey = 'productTags';

export const ProductTagsReducer = createReducer(
    initialState,
    on(ProductTagsActions.loadProductTags, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(ProductTagsActions.loadProductTagsSuccess, (state, { productTags }) =>
        adapter.setAll(productTags, { ...state, loading: false })
    ),
    on(ProductTagsActions.loadProductTagsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })),
    );

