import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import * as ProductFilters from './product-filter.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ProductFilters.ProductFilterFeatureKey, ProductFilters.productFilterReducer),
    ],
})
export class ProductFiltersStoreModule { }
