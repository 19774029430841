import { Component, Input } from '@angular/core';
import { faQuestionCircle, IconDefinition, faCircle, faFolderOpen, faHandshake } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo, faFolderTree, faHandsHelping, faInfo, } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'src/app/shared/models/link.interface';

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss']
})
export class SectionListComponent {
  faqIcon: IconDefinition = faQuestionCircle;
  infoIcon: IconDefinition = faFolderTree;
  serviceAgreementIcon: IconDefinition = faHandshake;
  @Input() links: Link[] = [];
}
