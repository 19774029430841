import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
import { ConfigService } from '@fluentllc/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

export function loadConfigData(http: HttpClient, configService: ConfigService): () => Observable<any> {
  return () => {
    return http.get('assets/i18n/en.json').pipe(
      map(data => {
        const mergedConfig = {
          ...data, // Spread the content of en.json
          production: environment.production,
          staging: environment.staging,
          version: environment.version
        };
        configService.setConfig(mergedConfig);
        return mergedConfig;
      })
    );
  };
}

