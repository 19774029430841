import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from 'src/app/features/products/models/category.interface';

@Injectable({
  providedIn: 'root'
})
export class CategoryApiService {
  private readonly apiUrl = "/api/v1/categories"; // Updated to point directly to the categories endpoint
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });

  constructor(private http: HttpClient) { }

  getCategory(slug: string): Observable<Category> {
    return this.http.get<Category>(`${this.apiUrl}/${slug}`, { headers: this.headers });
  }

  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(this.apiUrl, { headers: this.headers });
  }

  getCategoryBySlug(slug: string): Observable<Category> {
    return this.http.get<Category>(`${this.apiUrl}/${slug}`, { headers: this.headers });
  }

  updateCategoryBySlug(slug: string, category: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/${slug}`, category, { headers: this.headers });
  }

  deleteCategory(slug: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${slug}`, { headers: this.headers });
  }

  // Method to get child categories using a relative URL (childrenUrl)
  getChildrenByUrl(childrenUrl: string): Observable<Category[]> {
    // Assuming childrenUrl might be a full URL or a relative path, we handle both cases:
    if (childrenUrl.startsWith('http')) {
      return this.http.get<Category[]>(childrenUrl, { headers: this.headers });
    } else {
      return this.http.get<Category[]>(`${this.apiUrl}${childrenUrl}`, { headers: this.headers });
    }
  }
}
