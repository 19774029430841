import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { JobPostingEffects } from './job-posting.effects';
import * as jobPostings from './job-posting.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(jobPostings.JobPostingFeatureKey, jobPostings.JobPostingReducer),
        EffectsModule.forFeature([JobPostingEffects]),
    ],
})
export class JobPostingsStoreModule { }
