<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <div class="pt-30">
                <h1>Frequently Asked Questions</h1>
                <p>Welcome to our FAQ section, where you'll find quick and informative answers to commonly asked
                    questions about lab automation, products, services, and support. Get the information you need to
                    make informed decisions and troubleshoot any issues. Explore our FAQs for guidance and
                    valuable insights.</p>
            </div>
            <div class="ptb-30">
                <div class="accordion" id="faqAccordion">
                    <div *ngFor="let faq of faqs" class="accordion-item">
                        <h2 class="accordion-header" id="faqHeader{{faq.id}}">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#faqCollapse' + faq.id" aria-expanded="false"
                                [attr.aria-controls]="'faqCollapse' + faq.id">
                                {{ faq.question }}
                            </button>
                        </h2>
                        <div [attr.id]="'faqCollapse' + faq.id" class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'faqHeader' + faq.id" data-bs-parent="#faqAccordion">
                            <div class="accordion-body" [innerHtml]="faq.answer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <app-services-support-sidebar-navigation-container></app-services-support-sidebar-navigation-container>
        </div>
    </div>
</div>