import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { spinnerFeatureKey, spinnerReducer } from './spinner-store/spinner.reducer';
import { buttonFeatureKey, buttonReducer } from './button/button.reducer';
import { ButtonEffects } from './button/button.effects';
import { SpinnerEffects } from './spinner-store/spinner.effects';

@NgModule({
  imports: [CommonModule,
    StoreModule.forFeature(buttonFeatureKey, buttonReducer),
    StoreModule.forFeature(spinnerFeatureKey, spinnerReducer),
    EffectsModule.forFeature([ButtonEffects, SpinnerEffects]),],
})
export class SharedStoreModule { }
