import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { ToggleAction } from "@ngrx/store-devtools/src/actions";
import { GtmService } from "src/app/core/services/gtm.service";
import { closeHamburgerMenu, toggleHamburgerMenu } from "src/app/features/layout/store/navbar.actions";
import { MenuItem } from "../../../core/models/menu-item.interface";

@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent {
  @Input() menuItem!: MenuItem;
  @Input() topmostParent: any;
  @Input() menuType: "top" | "footer" = "top";
  constructor(private store: Store, private breakpointObserver: BreakpointObserver, private gtmService: GtmService) {}
  generateId(): string {
    return `divider-${Date.now()}`;
  }

  onLinkClick(event: Event): void {
    // Get the clicked element
    let targetElement = event.target as HTMLElement;
    // console.log(event);
    // If the clicked element has the 'is-divider' class, stop the event propagation
    if (targetElement.classList.contains("is-divider")) {
      event.stopPropagation();
      return; // Exit the method
    }
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      if (result.matches) {
        this.store.dispatch(toggleHamburgerMenu());
      }
    });
  }

  toggleSubmenu(event: Event, menuItem: any, topmostParent: any): void {
    let topmostParentLabel = null
    if (!menuItem.children || menuItem.children.length === 0) {
      topmostParentLabel = topmostParent.label
    }

    this.gtmService.push({
      event: "menu_click",
      menu_parent: menuItem.children?.length > 0 || false,
      menu_label: menuItem.label,
      menu_path: menuItem.path,
      menu_parent_label: topmostParentLabel
    });
    event.stopPropagation();
  }
}
