import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Input() currentImageUrl: string | null = null;
  @Input() multiple: boolean = false;
  @Output() onImageSelected: EventEmitter<File | File[]> = new EventEmitter();
  @Output() onImageSizeError: EventEmitter<string> = new EventEmitter();
  errorMessage: string | null = null;

  handleFileInput(event: any) {
    const files = event.target.files;
    const maxSize = 300 * 1024; // 300KB in bytes
    const validFiles: File[] = [];
    this.errorMessage = null;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= maxSize) {
        validFiles.push(files[i]);
      } else {
        this.errorMessage = `File ${files[i].name} exceeds the 300KB size limit.`;
        this.onImageSizeError.emit(`File ${files[i].name} exceeds the 300KB size limit.`);
      }
    }

    if (validFiles.length > 0) {
      if (this.multiple) {
        this.onImageSelected.emit(validFiles);
      } else {
        this.onImageSelected.emit(validFiles[0]);
      }
    }
  }
}
