import { Component, Input } from '@angular/core';
import { Link } from '../../models/link.interface';

@Component({
  selector: 'app-sidebar-navigation-container',
  templateUrl: './sidebar-navigation-container.component.html',
  styleUrls: ['./sidebar-navigation-container.component.scss']
})
export class SidebarNavigationContainerComponent {
  @Input() menuTitle: string = 'Links';
  @Input() links: Link[] = [];
}
