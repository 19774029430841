import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SupportGuide } from "src/app/shared/models/support-guide.interface";
import { PaginationInfo } from "@fluentllc/core";
import { Store } from "@ngrx/store";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import { AlertService } from "src/app/core/services/alert.service";
import { ModalService } from "src/app/core/services/modal.service";
import { AppState } from "src/app/store/app.state";
import { Router } from "@angular/router";

@Component({
  selector: "app-troubleshooting-admin-list",
  templateUrl: "./troubleshooting-admin-list.component.html",
  styleUrls: ["./troubleshooting-admin-list.component.scss"],
})
export class TroubleshootingAdminListComponent {
  @Input() guides: SupportGuide[];
  @Input() paginationInfo: PaginationInfo;
  @Output() edit = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();
  @Output() create = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();
  
  onPageChange(newPage: number): void {
    this.pageChanged.emit(newPage);
  }

  onItemsPerPageChange(paginateBy: number): void {
    this.itemsPerPageChanged.emit(paginateBy);
  }
}
