import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DistributorState, adapter } from './distributors.state';

export const selectDistributorState = createFeatureSelector<DistributorState>('distributors');

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectDistributorIds = createSelector(
    selectDistributorState,
    selectIds
);

export const selectDistributorEntities = createSelector(
    selectDistributorState,
    selectEntities
);

export const selectAllDistributors = createSelector(
    selectDistributorState,
    selectAll
);

export const selectDistributorTotal = createSelector(
    selectDistributorState,
    selectTotal
);

export const selectDistributorById = (id: number) =>
    createSelector(
        selectDistributorEntities,
        (entities) => entities[id]
    );

export const selectDistributorLoading = createSelector(
    selectDistributorState,
    (state) => state.loading
);

export const selectDistributorError = createSelector(
    selectDistributorState,
    (state) => state.error
);
