import { Component, OnInit } from '@angular/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { CustomItem, CustomItemClass } from '../../../../shared/models/custom-item.interface';

@Component({
  selector: 'app-roicalculator',
  templateUrl: './roicalculator.component.html',
  styleUrls: ['./roicalculator.component.scss']
})
export class ROICalculatorComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  customItems: CustomItem[] = [];
  subtitle: string = 'Return On Investment';
  title: string = 'Interactive';
  titleHighlight: string = 'ROI Calculator';
  description: string = 'Custom Description';
  heroImage: string = '/assets/images/lab_automation_return_on_investment_550x514.jpg';
  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService) {

  }

  ngOnInit(): void {
    this.breadcrumbs = [
      { label: 'Home', url: '/' },
      { label: 'ROI Calculator', url: '/roi' },
    ];

    this.description = `Every lab is uniquely operated and, therefore, return on investment calculators are only as good as the information entered. For a more comprehensive analysis, one of our account managers will be happy to provide a more detailed view that suits your individual needs.`;

    this.customItems = [
      new CustomItemClass({ name: 'Fast ROI Realization', description: 'Accelerate payback period through significant productivity gains.' }),
      new CustomItemClass({ name: 'Savings on Reduced Staff Hours', description: "Decrease labor costs by minimizing manual tasks, freeing up resources." }),
      // new CustomItemClass({ name: 'Improved Scientific Accuracy', description: 'Enhance data reliability and experimental outcomes with precise automation.' }),

    ]
  }

}
