import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-container',
  templateUrl: './logo-container.component.html',
  styleUrls: ['./logo-container.component.scss']
})
export class LogoContainerComponent {
  @Input() logoUrl: string = 'assets/images/scinomix.png';
  @Input() alt: string = 'Scinomix - Innovating Automation';
  @Input() srcset?: string = "assets/images/scinomix@2x.png, assets/images/scinomix@2x.png 2x, assets/images/scinomix@2x.png 3x, assets";
  @Input() maxWidth?: string = '200px'; // Add this line
  @Input() width: string = '200px';
  @Input() height: string = '64px';
}
