// src/app/shared/components/spinner-container/spinner-container.component.ts
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsSpinnerVisible } from '../../store/spinner-store/spinner.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-spinner-container',
  templateUrl: './spinner-container.component.html',
  styleUrls: ['./spinner-container.component.scss'],
})
export class SpinnerContainerComponent implements OnInit {
  isVisible$: Observable<boolean>;

  constructor(private store: Store) {
    this.isVisible$ = this.store.select(selectIsSpinnerVisible);
  }

  ngOnInit() {
    this.store.select(selectIsSpinnerVisible).subscribe((value) => {
      // console.log('Spinner visible:', value);
    });
  }
}
