import { Component, Input } from '@angular/core';
import { UspTag } from '../../models/usp-tag.interface';
import { Benefit } from '../../models/benefit.interface';

@Component({
  selector: 'app-benefits-container',
  templateUrl: './benefits-container.component.html',
  styleUrls: ['./benefits-container.component.scss']
})
export class BenefitsContainerComponent {
  @Input() benefits: UspTag[] = [];
  @Input() benefitsArray: Benefit[] = [];
}
