import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page-admin-manage-container',
  templateUrl: './landing-page-admin-manage-container.component.html',
  styleUrls: ['./landing-page-admin-manage-container.component.scss']
})
export class LandingPageAdminManageContainerComponent {

}
