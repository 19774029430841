// src/app/services/router.service.ts

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GtmService } from './gtm.service';
import { isPlatformBrowser } from '@angular/common';
import { HubSpotService } from './hub-spot.service';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(
    private router: Router,
    private gtmService: GtmService,
    private hubSpotService: HubSpotService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.gtmService.push({ event: 'page_view', page: event.urlAfterRedirects });
        this.hubSpotService.push(['setPath', event.urlAfterRedirects]);
        this.hubSpotService.push(['trackPageView']);
      });
    }
  }
}
