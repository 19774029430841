import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Distributor } from 'src/app/shared/models/distributor.interface';

export interface DistributorState extends EntityState<Distributor> {
    loading: boolean;
    error: any;
}

export const adapter: EntityAdapter<Distributor> = createEntityAdapter<Distributor>();

export const initialState: DistributorState = adapter.getInitialState({
    loading: false,
    error: null,
});
