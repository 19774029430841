import { Component, Input, OnInit } from '@angular/core';
import { faFilePdf, IconDefinition } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-call-to-action-small-with-icon1',
  templateUrl: './call-to-action-small-with-icon1.component.html',
  styleUrls: ['./call-to-action-small-with-icon1.component.scss']
})
export class CallToActionSmallWithIcon1Component implements OnInit {
  @Input('ctaTitle') ctaTitle: String = "About Scinomix"
  @Input('ctaDescription') ctaDescription: String = "Download"
  @Input('ctaUrl') ctaUrl: string = "#"
  @Input('ctaIcon') ctaIcon: IconDefinition = faFilePdf;
  faFilePdf = faFilePdf
  constructor() { }

  ngOnInit(): void {
  }

}
