import { ComponentConfig } from "../../interfaces/component-config.interface";

export interface OffsidebarState {
    isVisible: boolean;
    componentConfig: ComponentConfig | null;
}

export const initialState: OffsidebarState = {
    isVisible: false,
    componentConfig: null,
};