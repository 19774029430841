
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SolutionTag } from 'src/app/shared/models/solution-tag.interface';
import { Tag } from 'src/app/shared/models/tag.interface';


export interface SolutionTagsState extends EntityState<Tag> {
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<Tag> = createEntityAdapter<Tag>();

export const initialState: SolutionTagsState = adapter.getInitialState({
  loading: false,
  error: null,
});
