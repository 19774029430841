import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductRequest } from 'src/app/features/products/models/product-request.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { setProviderToken } from '@fluentllc/core';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { JobApplication } from 'src/app/shared/models/job-application.interface';
import { JobApplicationService } from './job-application.service';
import { v4 as uuidv4 } from 'uuid';
import { CookieService } from 'ngx-cookie-service';
import { HubSpotService } from '../hub-spot.service';
@Injectable()
export class JobApplicationHubspotAdapterService extends JobApplicationService {
    private formId = '56876ee1-c3d0-4894-85f5-026ba5d79d33'; // Replace with your HubSpot form ID
    private portalId = '4896724'; // Replace with your HubSpot portal ID
    private apiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`;
    private headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${environment.hubspotAccessToken}`
      });
    private contextData?: ContextData;

    constructor(private http: HttpClient, private store: Store, private hubSpotService: HubSpotService) {
        super();
        this.store.dispatch(setProviderToken({ providerName: 'hubspot', providerToken: environment.hubspotAccessToken }));
    }

    setContextData(contextData?: ContextData): void {
        this.contextData = contextData;
    }

    submitApplication(jobApplication: JobApplication): Observable<any> {
        const hubspotData = this.transformData(jobApplication);
        const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
        const sanitizedJobPosition = jobApplication.jobPosition.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        const resumeExtension = jobApplication.resume.name.split('.').pop();
        const coverLetterExtension = jobApplication.coverLetter.name.split('.').pop();
        const resumeFileName = `${date}_${jobApplication.firstName}_${jobApplication.lastName}_${sanitizedJobPosition}_resume_${uuidv4()}.${resumeExtension}`;
        const coverLetterFileName = `${date}_${jobApplication.firstName}_${jobApplication.lastName}_${sanitizedJobPosition}_cover_letter_${uuidv4()}.${coverLetterExtension}`;

        const resumeUpload$ = this.uploadFile(jobApplication.resume, resumeFileName);
        const coverLetterUpload$ = this.uploadFile(jobApplication.coverLetter, coverLetterFileName);


        // Once both files are uploaded, submit the form
        return forkJoin({ resume: resumeUpload$, coverLetter: coverLetterUpload$ }).pipe(
            switchMap(({ resume, coverLetter }) => {
                // console.log(resume.url)
                // Replace the File objects with the URLs of the uploaded files
                const applicationWithFileUrls = {
                    ...jobApplication,
                    resume: resume.url,
                    coverLetter: coverLetter.url,
                    resumeLink: resume.url,
                    coverLetterLink: coverLetter.url
                };

                // Transform the application data for HubSpot
                const hubspotData = this.transformData(applicationWithFileUrls);

                // Submit the form
                return this.http.post(this.apiUrl, hubspotData, { headers: this.headers });
            })
        );
        // return this.http.post(this.apiUrl, hubspotData, { headers: this.headers });
        // return of({ success: true, message: 'Mock response' });
    }

    uploadFile(file: File, fileName: string): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folderId', '116064400747');  // Replace with your desired folder path

        // Create a filename using the date, first name, last name, and job position

        formData.append('fileName', fileName);

        const options = {
            access: 'PUBLIC_NOT_INDEXABLE',
            overwrite: true
        };

        // Convert the options object to a JSON string
        const optionsJson = JSON.stringify(options);
        formData.append('options', optionsJson);

        // Use the proxied URL
        return this.http.post('https://api.hubapi.com/filemanager/api/v3/files/upload', formData, { headers: this.headers});
    }


    private transformData(jobApplication: JobApplication): any {
        this.hubSpotService.push(['identify', { email: jobApplication.email}])
        const hubspotData = {
            fields: [
                { name: 'firstname', value: jobApplication.firstName },
                { name: 'lastname', value: jobApplication.lastName },
                { name: 'email', value: jobApplication.email },
                { name: 'phone', value: jobApplication.phoneNumber },
                { name: 'resume_link', value: jobApplication.resumeLink },
                { name: 'cover_letter_link', value: jobApplication.coverLetterLink },
                { name: 'application_job_title', value: jobApplication.jobPosition },
            ],
            context: this.contextData || {},
            legalConsentOptions: {
                consent: {
                    consentToProcess: true,
                    text: "I agree to allow Scinomix to store and process my personal data.",
                    communications: [
                        {
                            value: true,
                            subscriptionTypeId: 999,
                            text: "I agree to receive marketing communications from Scinomix."
                        }
                    ]
                }
            }
        };
        return hubspotData
    }

}
