import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'htmlToText' })
export class HtmlToTextPipe implements PipeTransform {
    transform(html: string): string {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    }
}
