import { Component, Input, OnInit } from '@angular/core';
import { TagsComponent } from '../tags/tags.component';
import { Store, select } from '@ngrx/store';
import { AppState } from '@fluentllc/core';
import * as fromSolutionTags from '../../store/solution-tags.actions';
import { selectAllSolutionTags } from '../../store/solution-tags.selectors';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-solution-tags',
  templateUrl: '../tags/tags.component.html',
  styleUrls: ['../tags/tags.component.scss']
})
export class SolutionTagsComponent extends TagsComponent implements OnInit {
  @Input() tags: number[] = [];
  @Input() useSlugs: boolean = false
  constructor(private solutionTagStore: Store<AppState>, fb: FormBuilder) {
    super(fb, solutionTagStore);
  }

  ngOnInit(): void {
    this.initialTags = this.tags
    // Dispatch action to load solution tags
    this.solutionTagStore.dispatch(fromSolutionTags.loadSolutionTags());
    this.title = "Solution Tags";
    // Subscribe to the solution tags slice and update the available tags
    this.solutionTagStore.pipe(select(selectAllSolutionTags)).subscribe(allTags => {
      this.availableTags = allTags;
    });
    super.ngOnInit();
    
    
    
  }

}
