// src/app/core/services/post.service.ts

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FAQ } from "src/app/shared/models/faq.interface";
import { GenericService } from "./generic.service";

@Injectable({
    providedIn: "root",
})
export class FAQService {
    private readonly apiUrl = "api/v1/faqs"; // Replace this with your API endpoint

    constructor(private http: HttpClient, private genericService: GenericService) { }

    getFAQs(): Observable<FAQ[]> {
        return this.http.get<FAQ[]>(this.apiUrl);
    }

    getFAQById(id: number): Observable<FAQ> {
        return this.http.get<FAQ>(`${this.apiUrl}/${id}`);
    }

    createFAQ(FAQ: FAQ): Observable<FAQ> {
        return this.http.post<FAQ>(this.apiUrl, FAQ);
    }

    updateFAQ(FAQ: FAQ): Observable<FAQ> {
        return this.http.put<FAQ>(`${this.apiUrl}/${FAQ.id}`, FAQ);
    }

    deleteFAQ(id: number): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${id}`);
    }

    getFAQsByTag(keyword_slug?: string, product_slug?: string, solution_slug?: string, operator: 'AND' | 'OR' = 'OR'): Observable<FAQ[]> {
        const url = `${this.apiUrl}/by_tag`;
        const params = { keyword_slug, product_slug, solution_slug, operator };

        return this.genericService.getByParams(url, params);
    }
}
