import { createReducer, on } from '@ngrx/store';
import * as ButtonActions from './button.actions';
import { ButtonState, initialState } from './button.state';
export const buttonFeatureKey = "button";

export const buttonReducer = createReducer(
    initialState,
    on(ButtonActions.buttonClicked, (state, { buttonType }) => {
        const updatedClicks = { ...state.clicks, [buttonType]: state.clicks[buttonType] + 1 };
        return { ...state, clicks: updatedClicks };
    })
);

export const selectButtonClicks = (state: ButtonState) => state.clicks;