import { createReducer, on } from '@ngrx/store';
import * as SchemaActions from './schema.actions';
import { initialSchemaState } from './schema.state';

export const schemaFeatureKey = 'schema';



export const schemaReducer = createReducer(
    initialSchemaState,
    on(SchemaActions.loadSchema, state => ({ ...state, loading: true })),
    on(SchemaActions.loadSchemaSuccess, (state, { schema }) => ({ ...state, schema, loading: false })),
    on(SchemaActions.loadSchemaFailure, (state, { error }) => ({ ...state, error, loading: false })),
    on(SchemaActions.updateSchema, (state, { schema }) => ({ ...state, schema: schema }))
);
