import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SchemaState } from 'src/app/features/seo/store/schema.state';
import { Schema } from 'src/app/shared/models/schema.interface';
import * as SchemaActions from 'src/app/features/seo/store/schema.actions'
import { Observable } from 'rxjs';
import { selectSchema } from 'src/app/features/seo/store/schema.selectors';
@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  constructor(private store: Store<{ schema: SchemaState }>) { }

  updateSchema(schema: Schema) {
    this.store.dispatch(
      SchemaActions.updateSchema({ schema })
    );
  }

  getSchema(): Observable<Schema> {
    return this.store.select(selectSchema);
  }
}
