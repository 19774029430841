import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-hashtag',
  templateUrl: './title-hashtag.component.html',
  styleUrls: ['./title-hashtag.component.scss']
})
export class TitleHashtagComponent {
  @Input() hashtagSubtitle: string;
}
