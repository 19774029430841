import { Component } from '@angular/core';

@Component({
  selector: 'app-service-agreements-container',
  templateUrl: './service-agreements-container.component.html',
  styleUrls: ['./service-agreements-container.component.scss']
})
export class ServiceAgreementsContainerComponent {

}
