import { Component, OnInit } from '@angular/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';

@Component({
  selector: 'app-service-agreements',
  templateUrl: './service-agreements.component.html',
  styleUrls: ['./service-agreements.component.scss']
})
export class ServiceAgreementsComponent implements OnInit {
  constructor(private metaTagsService: MetaTagsService) {

  }
  ngOnInit(): void {
    const newMetaTags = {
      title: 'Service Agreements',
      description: "Scinomix offers expert installation and tailored Service Agreements for your equipment. Benefit from preventive maintenance checks and specialized support. Reach out to sales@scinomix.com for custom quotes and more information.",
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };

    this.metaTagsService.updateMetaTags(newMetaTags);
  }

}
