import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { resetSearchSlice, search } from "../../store/search.actions";
import { getSearchResults } from "../../store/search.selectors";
import { environment } from "src/environments/environment";
import { map, tap } from "rxjs";
import { Breadcrumb } from "src/app/shared/models/breadcrumb.interface";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import { GtmService } from "src/app/core/services/gtm.service";

@Component({
  selector: "app-search-results-container",
  templateUrl: "./search-results-container.component.html",
  styleUrls: ["./search-results-container.component.scss"],
})
export class SearchResultsContainerComponent implements OnInit {
  searchResults$ = this.store.select(getSearchResults);
  filteredSearchResults$ = this.searchResults$;
  algoliaSearchIndex = environment.algoliaSearchIndexes;
  defaultSearchIndexes = this.algoliaSearchIndex.map((item) => item.index);
  searchIndexes = [...this.defaultSearchIndexes];
  query: string = null;
  searchPerformed = false;
  currentFilter: { index: string; date?: Date } = null;
  breadcrumbs: Breadcrumb[] = [];
  constructor(private store: Store, private metaTagsService: MetaTagsService, private gtmService: GtmService) {
    this.store.dispatch(resetSearchSlice());
    this.breadcrumbs = [
      { label: "Home", url: "/" },
      { label: "Search", url: "/search" },
    ];
  }

  onSearch(query: string): void {
    if (!query) {
      return; // don't perform search if no query
    }
    this.searchPerformed = true;
    this.query = query;
    this.store.dispatch(resetSearchSlice());
    this.store.dispatch(search(this.query, this.searchIndexes));
  }

  onIndexChange(indexes: string[]): void {
    if (!indexes || indexes.length === 0) {
      // If no indexes provided or they are blank, default to all indexes
      this.searchIndexes = [...this.defaultSearchIndexes];
    } else {
      this.searchIndexes = indexes;
    }
  }

  onFilterChange(filter: { index: string; date?: Date }): void {
    this.currentFilter = filter;
    this.gtmService.push({
      event: "filter_change",
      filter_index: this.currentFilter.index,
      filter_date: this.currentFilter.date ? this.currentFilter.date.toISOString() : null,
      original_search_query: this.query
    });

    const indexes = this.currentFilter.index.split(",");
    indexes.forEach(index => {
        this.gtmService.push({
            event: 'filter_index',
            product_index_type: index.trim()
        });
    });


    // console.log(filter)
    this.filterSearchResults();
  }

  filterSearchResults(): void {
    if (this.currentFilter && this.currentFilter.index) {
      let filterIndexes = this.currentFilter.index.split(",");
      this.filteredSearchResults$ = this.searchResults$.pipe(
        map((results) => results.filter((result) => filterIndexes.includes(result.index)))
      );
    } else {
      this.filteredSearchResults$ = this.searchResults$;
    }
  }

  ngOnInit(): void {
    this.searchResults$.subscribe((data) => {
      this.filterSearchResults();
    });

    const newMetaTags = {
      title: "Search Lab Automation Products and Solutions | Scinomix",
      description:
        "Optimize your laboratory operations with cutting-edge tube and microplate labelers from our renowned lab automation solutions. Streamline sample tracking and labeling processes, boost productivity, and achieve unparalleled efficiency. Discover our comprehensive range of advanced lab automation solutions designed for your lab's unique needs.",
      keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };

    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  onQueryChange(query: string): void {
    // console.log(query)
  }
}
