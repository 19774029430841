<div class="form-group">
    <input class="form-control mb-2" type="text" (input)="onQueryChange($event)" (keyup.enter)="onSearch()" #searchInput
        placeholder="Enter your keywords...">
    <div class="d-flex align-items-center">
        <button class="btn btn-secondary" type="button" (click)="onSearch()">Search</button>
        <div class="ms-auto">
            <label class="c-checkbox" *ngFor="let item of searchIndexes">
                <input type="checkbox" [(ngModel)]="indexCheckStates[item.index]" [value]="item.index"
                    (change)="onIndexChange($event)">
                <span class="fas fa-check"></span>{{item.display}}
            </label>
        </div>
    </div>
</div>