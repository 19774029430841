import { Component, Input } from '@angular/core';
import { UspTag } from '../../models/usp-tag.interface';
import { Benefit } from '../../models/benefit.interface';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent {
  @Input() benefits: UspTag[] = [];
  @Input() benefitsArray: Benefit[] = [];
}
