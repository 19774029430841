import { Component } from '@angular/core';

@Component({
  selector: 'app-appointment-scheduler-container',
  templateUrl: './appointment-scheduler-container.component.html',
  styleUrls: ['./appointment-scheduler-container.component.scss']
})
export class AppointmentSchedulerContainerComponent {

}
