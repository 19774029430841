// product-request.state.ts

import { ProductRequest } from "../models/product-request.interface";

export interface ProductRequestState {
    productRequests: ProductRequest[];
    successMessage: string | null;
}

export const initialProductRequestState: ProductRequestState = {
    productRequests: [],
    successMessage: null
};
