import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, Renderer2, ElementRef, Inject } from '@angular/core';
import { Schema } from 'src/app/shared/models/schema.interface';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss'],
})
export class SchemaComponent implements OnChanges {
  @Input() schema: Schema | null = null;
  @Input() loading: boolean = false;
  @Input() error: any = null;

  @Output() updateSchema = new EventEmitter<Schema>();

  constructor(private meta: Meta, private renderer: Renderer2, private el: ElementRef, @Inject(DOCUMENT) private document: Document) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.schema && this.schema) {
      this.updateJsonLd(this.schema);
    }
  }

  updateJsonLd(schema: Schema) {
    // console.log(schema)
    // Remove the existing JSON-LD script element if it exists
    const existingJsonLdElement = this.el.nativeElement.ownerDocument.head.querySelector('script[type="application/ld+json"]');
    if (existingJsonLdElement) {
      this.renderer.removeChild(this.document.head, existingJsonLdElement);
    }

    // Create a new script element and set its content to the schema JSON
    const jsonLdElement = this.renderer.createElement('script');
    jsonLdElement.type = 'application/ld+json';
    jsonLdElement.text = JSON.stringify(schema);
    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, jsonLdElement);
  }

  onUpdateSchema(schema: Schema) {
    this.updateSchema.emit(schema);
  }
}
