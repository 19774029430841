<div class="review-area pb-75">
    <div class="container">
        <h3>
            {{title}}
            <span> {{subhead}}</span>
        </h3>
        <!-- <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let testimonial of testimonials">
                <app-testimonial-card-container [testimonial]="testimonial"></app-testimonial-card-container>
            </div>
        </div> -->
        <!-- <owl-carousel-o [options]="customOwlOptions">
            <ng-container *ngFor="let testimonial of testimonials">
                <ng-template carouselSlide>
                    <div class="carousel-item">
                        {{ testimonial.name }}
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o> -->
        <div class="review-slides">
            <owl-carousel-o [options]="customOwlOptions">
                <ng-container *ngFor="let testimonial of testimonials">
                    <ng-template carouselSlide>
                        <div class="single-review-item">
                            <div class="icon">
                                <i class="flaticon-034-left-quote-1"></i>
                            </div>
                            <h3 class="teaser">{{testimonial.teaser}}</h3>
                            <p>{{testimonial.quote}}</p>
                            <div *ngIf="testimonial.product_tags">
                                <h6>Products: </h6>
                                <ul>
                                    <li *ngFor="let product_tag of testimonial.product_tags">{{product_tag.name}}</li>
                                </ul>
                            </div>

                            <div class="review-info">
                                
                                <img *ngIf="testimonial.profile_image_url" [src]="testimonial.profile_image_url"
                                    [alt]="testimonial.name">
                                <h3>{{testimonial.name}}</h3>
                                <span *ngIf="testimonial.company">{{testimonial.company}}</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>