import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';
import * as JobPostingsActions from './job-posting.actions';
import { CareersService } from 'src/app/core/services/careers.service';
import { Router } from '@angular/router';
import { AlertService } from "@fluentllc/core";

@Injectable()
export class JobPostingEffects {

  createJobPosting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPostingsActions.createJobPosting),
      switchMap((action) => {
        return this.jobPostingService.createJobPosting(action.jobPosting).pipe(
          map((jobPosting) => JobPostingsActions.createJobPostingSuccess({ jobPosting })),
          catchError((error) => of(JobPostingsActions.createJobPostingFailure({ error })))
        )
      }),
      tap(() => {
        this.router.navigate(["/admin/jobs"]); // Adjust the URL as needed
        this.alertService.show({ message: 'Create successful!', type: 'info' });
      })
    )
  );

  loadJobPostings$ = createEffect(() => this.actions$.pipe(
    ofType(JobPostingsActions.loadJobPostings),
    mergeMap(() => this.jobPostingService.getJobPostings()
      .pipe(
        map((jobPostings: JobPosting[]) => JobPostingsActions.loadJobPostingsSuccess({ jobPostings }) as Action),
        catchError((error: any) => of(JobPostingsActions.loadJobPostingsFailure({ error }) as Action))
      ))
  ));

  loadJobPostingBySlug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPostingsActions.loadJobPostingBySlug),
      switchMap((action) =>
        this.jobPostingService.getJobPostingBySlug(action.slug).pipe(
          map((jobPosting) => JobPostingsActions.loadJobPostingBySlugSuccess({ jobPosting })),
          catchError((error) => of(JobPostingsActions.loadJobPostingBySlugFailure({ error }))),
        ),
      ),
    ),
  );

  loadPaginatedJobPostings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPostingsActions.loadPaginatedJobPostings),
      switchMap((action) =>
        this.jobPostingService.getPaginatedJobPostings(action.page, action.limit).pipe(
          map((response) =>
            JobPostingsActions.loadPaginatedJobPostingsSuccess({
              jobPostings: response.jobPostings,
              total: parseInt(response.pagination?.count || '0', 10),
              paginationInfo: response.pagination,
            })
          ),
          catchError((error) => of(JobPostingsActions.loadPaginatedJobPostingsFailure({ error })))
        )
      )
    )
  );

  updateJobPosting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPostingsActions.updateJobPosting),
      switchMap((action) => {
        return this.jobPostingService.updateJobPosting(action.jobPosting).pipe(
          map((jobPosting) => JobPostingsActions.updateJobPostingSuccess({ jobPosting })),
          catchError((error) => of(JobPostingsActions.updateJobPostingFailure({ error })))
        )
        }),
        tap(() => {
          this.router.navigate(["/admin/jobs"]); // Adjust the URL as needed
          this.alertService.show({ message: 'Update successful!', type: 'info' });
      })
    )
  );  

  deleteJobPosting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPostingsActions.deleteJobPosting),
      switchMap((action) => {
        return this.jobPostingService.deleteJobPosting(action.id).pipe(
          map(() => JobPostingsActions.deleteJobPostingSuccess({ id: action.id })),
          catchError((error) => of(JobPostingsActions.deleteJobPostingFailure({ error })))
        )
      })
    )
  );

    

  constructor(
    private actions$: Actions,
    private jobPostingService: CareersService,
    private router: Router,
    private alertService: AlertService
  ) { }
}

