import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as JobApplicationActions from './job-application.actions';
import { JobApplicationService } from 'src/app/core/services/careers/job-application.service';
import { Store } from '@ngrx/store';
import { concat } from 'rxjs';

@Injectable()
export class JobApplicationEffects {



  addJobApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobApplicationActions.addJobApplication),
      mergeMap((action) => {
        this.jobApplicationService.setContextData(action.contextData);
        return this.jobApplicationService.submitApplication(action.jobApplication).pipe(
          mergeMap((response: any) => concat(
            of(JobApplicationActions.setSuccessMessage({ message: response.inlineMessage })),
            of(JobApplicationActions.addJobApplicationSuccess({ jobApplication: action.jobApplication }))
          )),
          catchError((error) =>
            of(JobApplicationActions.addJobApplicationFailure({ error }))
          )
        );
      })
    )
  );

  resetProductRequestSlice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobApplicationActions.addJobApplicationSuccess),
      delay(30000), // Adjust the delay (in milliseconds) as needed
      tap(() => this.store.dispatch(JobApplicationActions.resetJobApplicationSlice()))
    ), { dispatch: false }
  );


  //   addProductRequest$ = createEffect(() =>
  //   this.actions$.pipe(
  //       ofType(ProductRequestActions.addProductRequest),
  //       mergeMap((action) => {
  //           this.productRequestService.setContextData(action.contextData);
  //           return from(this.productRequestService.submitRequest(action.productRequest)).pipe(
  //               map((response: any) => {
  //                   this.store.dispatch(ProductRequestActions.setSuccessMessage({ message: response.inlineMessage }));
  //                   return ProductRequestActions.addProductRequestSuccess({
  //                       productRequest: action.productRequest,
  //                   });
  //               }),
  //               catchError((error) =>
  //                   of(ProductRequestActions.addProductRequestFailure({ error }))
  //               )
  //           );
  //       })
  //   )
  // );

  constructor(
    private actions$: Actions,
    private jobApplicationService: JobApplicationService,
    private store: Store
  ) { }
}
