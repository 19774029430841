import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GtmService } from 'src/app/core/services/gtm.service';
import { getSearchResults } from '../../search/store/search.selectors';
import { environment } from 'src/environments/environment';
import { resetSearchSlice, search } from '../../search/store/search.actions';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit, AfterViewInit {
  searchResults$ = this.store.select(getSearchResults);
  filteredSearchResults$ = this.searchResults$;
  algoliaSearchIndex = environment.algoliaSearchIndexes;
  defaultSearchIndexes = this.algoliaSearchIndex.map(item => item.index);
  searchIndexes = [...this.defaultSearchIndexes];
  query: string = null;
  searchPerformed = false;
  currentFilter: { index: string, date?: Date } = null;

  constructor(
    private gtmService: GtmService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private metaTagsService: MetaTagsService,
  ) {
    this.store.dispatch(resetSearchSlice());
    const newMetaTags = {
      title: "Oops! Page Not Found",
      description: 'We extend our apologies for the temporary unavailability of the page you are seeking. It appears that the path to scientific discovery has encountered a momentary detour. Rest assured, however, that our commitment to facilitating your exploration of lab automation equipment remains unwavering.',
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      prerenderStatusCode: '404',
    };
    this.metaTagsService.updateMetaTags(newMetaTags);

    // if (isPlatformBrowser(this.platformId)) {
    //   console.log('Dispatching 404 error event');
    //   this.gtmService.push({
    //     event: '404error',
    //     'virtualPageURL': this.router.url
    //   });
    // }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.gtmService.push({
        event: '404error',
        'virtualPageURL': this.router.url
      });
    }
  }

  ngOnInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   setTimeout(() => {
    //     this.gtmService.push({
    //       event: '404error',
    //       'virtualPageURL': this.router.url
    //     });
    //   }, 2000); // This will delay the dispatch of the event by 2000 milliseconds, or 2 seconds
    // }
  }

  onSearch(query: string): void {
    if (!query) {
      return; // don't perform search if no query
    }
    this.searchPerformed = true;
    this.query = query;
    this.store.dispatch(resetSearchSlice());
    this.store.dispatch(search(this.query, this.searchIndexes));
  }


  onIndexChange(indexes: string[]): void {
    if (!indexes || indexes.length === 0) {
      // If no indexes provided or they are blank, default to all indexes
      this.searchIndexes = [...this.defaultSearchIndexes];
    } else {
      this.searchIndexes = indexes;
    }
  }

}
