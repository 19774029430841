import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Post } from 'src/app/shared/models/post.interface';
import { loadPostBySlug, loadPosts } from '../../store/post.actions';
import { selectPostBySlugFactory } from '../../store/post.selectors';
declare let window: any;
@Component({
  selector: 'app-post-detail-container',
  templateUrl: './post-detail-container.component.html',
  styleUrls: ['./post-detail-container.component.scss']
})
export class PostDetailContainerComponent {
  post$: Observable<Post>;
  
  constructor(private store: Store, private route: ActivatedRoute) {
    const slug = this.route.snapshot.paramMap.get('slug');
    this.store.dispatch(loadPostBySlug({ slug }));
    this.post$ = this.store.select(selectPostBySlugFactory(slug));
  }
}