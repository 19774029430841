import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-play-embedded-video-button',
  templateUrl: './play-embedded-video-button.component.html',
  styleUrls: ['./play-embedded-video-button.component.scss']
})
export class PlayEmbeddedVideoButtonComponent {
  @Input() vimeoLink: string;
  @Input() queryStrings: string;
  @Input() title: string;
  @Input() buttonCta: string = "Watch Video";
}
