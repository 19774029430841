<div class="scinomix-theme-sidebar">
    <div class="widget-area sidebar-right">
        <aside class="sidebar-box">
            <h3 class="widget-title">{{ menuTitle }}</h3>
            <ul class="list">
                <li *ngFor="let link of links" [routerLinkActive]="'active'">
                    <a [routerLink]="link.url" [target]="link.target">{{ link.name }}</a>
                </li>
            </ul>
        </aside>
    </div>
</div>