// core/services/alert.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { Alert } from '../models/alert.interface';
import { createAlert } from '../models/alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertsSubject: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>([]);
  public alerts$ = this.alertsSubject.asObservable();

  private alertId = 0;

  show(alert: Partial<Alert>): void {
    const newAlert: Alert = createAlert({ ...alert, id: this.alertId++ });
    this.alertsSubject.next([...this.alertsSubject.getValue(), newAlert]);

    if (newAlert.autoClose) {
      const duration = newAlert.duration || 5000;
      timer(duration).subscribe(() => {
        this.close(newAlert.id);
      });
    }
  }

  close(alertId: number): void {
    const alerts = this.alertsSubject.getValue().filter((alert) => alert.id !== alertId);
    this.alertsSubject.next(alerts);
  }
}
