import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SolutionsActions from './solutions.actions';
import { ProductsApiService } from 'src/app/core/services/products/products-api.service';


@Injectable()
export class SolutionsEffects {
    loadCategory$ = createEffect(() => this.actions$.pipe(
        ofType(SolutionsActions.loadCategory),
        mergeMap(action => this.productsApiService.getCategory(action.categorySlug).pipe(
            map(categoryData => SolutionsActions.loadCategorySuccess({ categoryData })),
            catchError(error => of(SolutionsActions.loadCategoryFailure({ error })))
        ))
    ));

    constructor(private actions$: Actions, private productsApiService: ProductsApiService) { }
}
