import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { Membership } from "src/app/shared/models/membership.interface";
import { removeFromLocalStorage } from "../utils/local-storage.utils";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.state";
import { setToken } from "@fluentllc/core";
import { Permission } from "src/app/shared/models/permission.interface";
import { Role } from "src/app/shared/models/role.interface";

export interface AuthResponseData {
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  my_roles: string[] | null;
  my_permissions: string[] | null;
  total_memberships: number;
  auth_token: string;
  id: number;
  memberships: Membership[];
  is_superuser: boolean;
  force_reset_password: boolean;
  can_login: boolean;
}

@Injectable({ providedIn: "root" })
export class AuthService {
  token: string | null = null;
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });

  constructor(private http: HttpClient, private store: Store<AppState>) { }

  login(username: string, password: string, invitation_token?: string): Observable<AuthResponseData> {
    const headers = new HttpHeaders({});
    const data = {
      type: "normal",
      username,
      password,
      invitation_token,
    };
    // console.log(data);
    return this.http
      .post<AuthResponseData>("/api/v1/auth", data, {
        headers,
      })
      .pipe(
        tap((response: AuthResponseData) => {
          // console.log(response);
          this.token = response.auth_token;
          this.store.dispatch(setToken({ token: this.token }));
        })
      );
  }

  // Add the reauthenticate method
  reauthenticate(username: string, password: string): Observable<AuthResponseData> {
    // console.log(username, password);
    return this.login(username, password);
  }

  clearAuthToken(): void {
    removeFromLocalStorage(environment.localStorageKey);
  }

  isLoggedIn(): boolean {
    return !!this.token;
  }
}
