import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input()
  loggedIn: boolean | null = false;
  
  isNavSearchVisible: boolean = false;

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }
}
