import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as SearchActions from "src/app/features/search/store/search.actions";
import { environment } from "src/environments/environment";
import { SearchWindowToggleService } from "src/app/core/services/search/search-window-toggle.service";
import { Subscription } from "rxjs";
import { NavbarRefService } from "src/app/core/services/navbar-ref.service";
import { closeHamburgerMenu, toggleHamburgerMenu } from "../../../store/navbar.actions";
import { selectIsHamburgerMenuOpen } from "../../../store/navbar.selectors";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { GtmService } from "src/app/core/services/gtm.service";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  classApplied = false;
  searchClassApplied = false;
  algoliaSearchIndex = environment.algoliaSearchIndexes;
  defaultSearchIndexes = this.algoliaSearchIndex.map((item) => item.index);
  private subscriptions: Subscription[] = [];
  private searchPerformedSubscription: Subscription;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private navbarService: NavbarRefService,
    public router: Router,
    private store: Store,
    private searchWindowToggleService: SearchWindowToggleService,
    private gtmService: GtmService
  ) {
    this.searchPerformedSubscription = this.searchWindowToggleService.searchPerformed$.subscribe(() => {
      this.searchToggleClass();
    });
  }

  @ViewChild("topNavbar") navbar: ElementRef;

  ngOnDestroy(): void {
    this.searchPerformedSubscription.unsubscribe();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    const sub = this.store.select(selectIsHamburgerMenuOpen).subscribe((isOpen) => {
      this.classApplied = isOpen;
    });
    this.subscriptions.push(sub);

    // Add this code block
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]);
    this.subscriptions.push(
      layoutChanges.subscribe((result) => {
        if (result.matches) {
          this.store.dispatch(closeHamburgerMenu());
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.navbarService.setNavbarElementRef(this.navbar);
  }

  toggleClass() {
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      if (result.matches) {
        this.store.dispatch(toggleHamburgerMenu());
        // console.log(this.classApplied);
      }
    });
  }

  searchToggleClass() {
    this.searchClassApplied = !this.searchClassApplied;
    this.gtmService.push({
      event: "click_search_icon",
    });
    // console.log('Toggling searchClassApplied:', this.searchClassApplied);
  }

  onSearch(query: string): void {
    this.store.dispatch(SearchActions.search(query, this.defaultSearchIndexes));
    this.searchPerformedSubscription = this.searchWindowToggleService.searchPerformed$.subscribe(() => {
      this.searchToggleClass();
    });
    this.router.navigate(["/search"]);
  }

  onSubmit(event: Event): void {
    event.preventDefault();
  }
}
