import { createAction, props } from '@ngrx/store';

export const loadCategory = createAction(
    '[Solutions] Load Category',
    props<{ categorySlug: string }>()
);

export const loadCategorySuccess = createAction(
    '[Solutions] Load Category Success',
    props<{ categoryData: any }>()
);

export const loadCategoryFailure = createAction(
    '[Solutions] Load Category Failure',
    props<{ error: any }>()
);
