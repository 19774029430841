import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MetaTag } from 'src/app/shared/models/meta-tag.interface';
import { getMetaTags } from '../../store/meta-tags.selectors';
import { MetaTagsState } from '../../store/meta-tags.state';

@Component({
  selector: 'app-meta-tags-container',
  template: `
    <ng-container *ngIf="metaTags$ | async as metaTags">
      <app-meta-tags [metaTags]="metaTags"></app-meta-tags>
    </ng-container>
  `,
})
export class MetaTagsContainerComponent {
  metaTags$: Observable<MetaTag[]> = this.store.select(getMetaTags);

  constructor(private store: Store<MetaTagsState>) { }
}
