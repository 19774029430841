import { Component, OnInit } from '@angular/core';
import { faClock, faClockFour, faIdBadge, IconDefinition, faCheckSquare, faStar, faSmileBeam, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faClockRotateLeft, faPhone, faPhoneAlt, faPhoneSquare, faShield, faShieldAlt, faShieldHeart, faTrophy } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-middle-header',
  templateUrl: './middle-header.component.html',
  styleUrls: ['./middle-header.component.scss']
})
export class MiddleHeaderComponent implements OnInit {
  callIcon: IconDefinition = faPhoneSquare;
  hoursIcon: IconDefinition = faClockFour;
  trustIcon: IconDefinition = faThumbsUp;
  constructor() { }

  ngOnInit(): void {
  }

}
