import { createFeatureSelector, createSelector } from '@ngrx/store';
import { JobApplicationState, adapter } from './job-application.state';

const getJobApplicationState = createFeatureSelector<JobApplicationState>('jobApplications');

// Use the entity adapter to create selector functions
const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const getJobApplicationsLoading = createSelector(
    getJobApplicationState,
    (state: JobApplicationState) => state.loading
);

export const getJobApplicationsError = createSelector(
    getJobApplicationState,
    (state: JobApplicationState) => state.error
);

export const selectJobApplicationIds = createSelector(
    getJobApplicationState,
    selectIds // shorthand for state => selectIds(state)
);

export const selectJobApplicationEntities = createSelector(
    getJobApplicationState,
    selectEntities // shorthand for state => selectEntities(state)
);

export const selectAllJobApplications = createSelector(
    getJobApplicationState,
    selectAll // shorthand for state => selectAll(state)
);

export const selectJobApplicationTotal = createSelector(
    getJobApplicationState,
    selectTotal // shorthand for state => selectTotal(state)
);

export const getSuccessMessage = createSelector(
    getJobApplicationState,
    (state: JobApplicationState) => state.successMessage
);