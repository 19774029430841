import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Post } from '../../../../shared/models/post.interface';
import { AppState } from "src/app/store/app.state";
import * as PostActions from '../../store/post.actions';
import { selectPostBySlugFactory } from '../../store/post.selectors';
import { AlertService } from '@fluentllc/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';

@Component({
  selector: 'app-post-manage-container',
  templateUrl: './post-manage-container.component.html',
  styleUrls: ['./post-manage-container.component.scss']
})
export class PostManageContainerComponent implements OnInit {
  post$: Observable<Post>;
  isEditMode: boolean = false;


  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private metaTagsService: MetaTagsService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL is '/admin/post/create'
        if (this.router.url === '/admin/post/create') {
          // Dispatch the clearPostFormState action
          this.store.dispatch(PostActions.clearPostFormState());
        }
      }
    });
  }

ngOnInit(): void {
    this.route.params.subscribe(params => {
      const postSlug = params['slug'];
      let blogTitle = 'Create Blog Post';
      if (postSlug) {
        this.isEditMode = true;
        blogTitle = 'Edit Blog Post';
        this.store.dispatch(PostActions.loadPostBySlug({ slug: postSlug }));
        this.post$ = this.store.select(selectPostBySlugFactory(postSlug));
      } else {
        this.isEditMode = false;
        this.post$ = new Observable<Post>(); // Initialize with a new post
      }
      const newMetaTags = {
        title: blogTitle,
        description: '',
        keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      };
      this.metaTagsService.updateMetaTags(newMetaTags);
    });

  }

  onSave(event: any): void {
    // console.log(this.isEditMode)
    this.store.dispatch(PostActions.savePostFormState({ post: event.post }));
    if (this.isEditMode) {
      this.store.dispatch(PostActions.updatePostWithKeywords({ post: event.post, keywords: event.keywords }));
      this.store.dispatch(PostActions.updatePost({ post: event.post, imageFile: event.imageFile }));
    } else {
      this.store.dispatch(PostActions.createPostWithKeywords({ post: event.post, keywords: event.keywords, imageFile: event.imageFile }));
      // this.store.dispatch(PostActions.updatePost({ post: event.post, imageFile: event.imageFile }));
    }
    this.store.dispatch(PostActions.clearPostFormState());
  }

  onCancel(): void {
    this.router.navigate(["/admin/posts"]);
    this.store.dispatch(PostActions.clearPostFormState());
  }
}
