import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-brand-container',
  templateUrl: './navbar-brand-container.component.html',
  styleUrls: ['./navbar-brand-container.component.scss']
})
export class NavbarBrandContainerComponent {
  classApplied = false;

  toggleClass() {
    this.classApplied = !this.classApplied;
  }
}
