import * as CryptoJS from "crypto-js";

export function saveToLocalStorage(state: any, key: string, encrypt: boolean, secretKey?: string): void {
  let data = JSON.stringify(state);
  // console.log(encrypt);
  // console.log(secretKey);
  if (encrypt && secretKey) {
    data = CryptoJS.AES.encrypt(data, secretKey).toString();
  }

  localStorage.setItem(key, data);
}

export function loadFromLocalStorage(key: string, decrypt: boolean, secretKey?: string): any {
  const storedData = localStorage.getItem(key);

  if (!storedData) {
    return undefined;
  }

  let data = storedData;
  if (decrypt && secretKey) {
    data = CryptoJS.AES.decrypt(storedData, secretKey).toString(CryptoJS.enc.Utf8);
  }

  return JSON.parse(data);
}

export function removeFromLocalStorage(localStorageKey: string): void {
  localStorage.removeItem(localStorageKey);
}
