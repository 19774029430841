import { Injectable } from '@angular/core';

interface Country {
    name: string;
    code: string;
}

interface State {
    name: string;
    code: string;
    countryCode: string;
}

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    private countries: Country[] = [
        { name: 'Afghanistan', code: 'AF' },
        { name: 'Albania', code: 'AL' },
        { name: 'Algeria', code: 'DZ' },
        { name: 'Andorra', code: 'AD' },
        { name: 'Angola', code: 'AO' },
        { name: 'Antigua and Barbuda', code: 'AG' },
        { name: 'Argentina', code: 'AR' },
        { name: 'Armenia', code: 'AM' },
        { name: 'Australia', code: 'AU' },
        { name: 'Austria', code: 'AT' },
        { name: 'Azerbaijan', code: 'AZ' },
        { name: 'Bahamas', code: 'BS' },
        { name: 'Bahrain', code: 'BH' },
        { name: 'Bangladesh', code: 'BD' },
        { name: 'Barbados', code: 'BB' },
        { name: 'Belarus', code: 'BY' },
        { name: 'Belgium', code: 'BE' },
        { name: 'Belize', code: 'BZ' },
        { name: 'Benin', code: 'BJ' },
        { name: 'Bhutan', code: 'BT' },
        { name: 'Bolivia', code: 'BO' },
        { name: 'Bosnia and Herzegovina', code: 'BA' },
        { name: 'Botswana', code: 'BW' },
        { name: 'Brazil', code: 'BR' },
        { name: 'Brunei Darussalam', code: 'BN' },
        { name: 'Bulgaria', code: 'BG' },
        { name: 'Burkina Faso', code: 'BF' },
        { name: 'Burundi', code: 'BI' },
        { name: 'Cambodia', code: 'KH' },
        { name: 'Cameroon', code: 'CM' },
        { name: 'Canada', code: 'CA' },
        { name: 'Cape Verde', code: 'CV' },
        { name: 'Central African Republic', code: 'CF' },
        { name: 'Chad', code: 'TD' },
        { name: 'Chile', code: 'CL' },
        { name: 'China', code: 'CN' },
        { name: 'Colombia', code: 'CO' },
        { name: 'Comoros', code: 'KM' },
        { name: 'Congo', code: 'CG' },
        { name: 'Costa Rica', code: 'CR' },
        { name: "Côte d'Ivoire", code: 'CI' },
        { name: 'Croatia', code: 'HR' },
        { name: 'Cuba', code: 'CU' },
        { name: 'Cyprus', code: 'CY' },
        { name: 'Czech Republic', code: 'CZ' },
        { name: "Democratic People's Republic of Korea", code: 'KP' },
        { name: 'Democratic Republic of the Congo', code: 'CD' },
        { name: 'Denmark', code: 'DK' },
        { name: 'Djibouti', code: 'DJ' },
        { name: 'Dominica', code: 'DM' },
        { name: 'Dominican Republic', code: 'DO' },
        { name: 'Ecuador', code: 'EC' },
        { name: 'Egypt', code: 'EG' },
        { name: 'El Salvador', code: 'SV' },
        { name: 'Equatorial Guinea', code: 'GQ' },
        { name: 'Eritrea', code: 'ER' },
        { name: 'Estonia', code: 'EE' },
        { name: 'Eswatini', code: 'SZ' },
        { name: 'Ethiopia', code: 'ET' },
        { name: 'Fiji', code: 'FJ' },
        { name: 'Finland', code: 'FI' },
        { name: 'France', code: 'FR' },
        { name: 'Gabon', code: 'GA' },
        { name: 'Gambia', code: 'GM' },
        { name: 'Georgia', code: 'GE' },
        { name: 'Germany', code: 'DE' },
        { name: 'Ghana', code: 'GH' },
        { name: 'Greece', code: 'GR' },
        { name: 'Grenada', code: 'GD' },
        { name: 'Guatemala', code: 'GT' },
        { name: 'Guinea', code: 'GN' },
        { name: 'Guinea-Bissau', code: 'GW' },
        { name: 'Guyana', code: 'GY' },
        { name: 'Haiti', code: 'HT' },
        { name: 'Honduras', code: 'HN' },
        { name: 'Hungary', code: 'HU' },
        { name: 'Iceland', code: 'IS' },
        { name: 'India', code: 'IN' },
        { name: 'Indonesia', code: 'ID' },
        { name: 'Iran (Islamic Republic of)', code: 'IR' },
        { name: 'Iraq', code: 'IQ' },
        { name: 'Ireland', code: 'IE' },
        { name: 'Israel', code: 'IL' },
        { name: 'Italy', code: 'IT' },
        { name: 'Jamaica', code: 'JM' },
        { name: 'Japan', code: 'JP' },
        { name: 'Jordan', code: 'JO' },
        { name: 'Kazakhstan', code: 'KZ' },
        { name: 'Kenya', code: 'KE' },
        { name: 'Kiribati', code: 'KI' },
        { name: 'Kuwait', code: 'KW' },
        { name: 'Kyrgyzstan', code: 'KG' },
        { name: "Lao People's Democratic Republic", code: 'LA' },
        { name: 'Latvia', code: 'LV' },
        { name: 'Lebanon', code: 'LB' },
        { name: 'Lesotho', code: 'LS' },
        { name: 'Liberia', code: 'LR' },
        { name: 'Libya', code: 'LY' },
        { name: 'Liechtenstein', code: 'LI' },
        { name: 'Lithuania', code: 'LT' },
        { name: 'Luxembourg', code: 'LU' },
        { name: 'Madagascar', code: 'MG' },
        { name: 'Malawi', code: 'MW' },
        { name: 'Malaysia', code: 'MY' },
        { name: 'Maldives', code: 'MV' },
        { name: 'Mali', code: 'ML' },
        { name: 'Malta', code: 'MT' },
        { name: 'Marshall Islands', code: 'MH' },
        { name: 'Mauritania', code: 'MR' },
        { name: 'Mauritius', code: 'MU' },
        { name: 'Mexico', code: 'MX' },
        { name: 'Micronesia (Federated States of)', code: 'FM' },
        { name: 'Monaco', code: 'MC' },
        { name: 'Mongolia', code: 'MN' },
        { name: 'Montenegro', code: 'ME' },
        { name: 'Morocco', code: 'MA' },
        { name: 'Mozambique', code: 'MZ' },
        { name: 'Myanmar', code: 'MM' },
        { name: 'Namibia', code: 'NA' },
        { name: 'Nauru', code: 'NR' },
        { name: 'Nepal', code: 'NP' },
        { name: 'Netherlands', code: 'NL' },
        { name: 'New Zealand', code: 'NZ' },
        { name: 'Nicaragua', code: 'NI' },
        { name: 'Niger', code: 'NE' },
        { name: 'Nigeria', code: 'NG' },
        { name: 'Niue', code: 'NU' },
        { name: 'North Macedonia', code: 'MK' },
        { name: 'Norway', code: 'NO' },
        { name: 'Oman', code: 'OM' },
        { name: 'Pakistan', code: 'PK' },
        { name: 'Palau', code: 'PW' },
        { name: 'Panama', code: 'PA' },
        { name: 'Papua New Guinea', code: 'PG' },
        { name: 'Paraguay', code: 'PY' },
        { name: 'Peru', code: 'PE' },
        { name: 'Philippines', code: 'PH' },
        { name: 'Poland', code: 'PL' },
        { name: 'Portugal', code: 'PT' },
        { name: 'Qatar', code: 'QA' },
        { name: 'Republic of Korea', code: 'KR' },
        { name: 'Republic of Moldova', code: 'MD' },
        { name: 'Romania', code: 'RO' },
        { name: 'Russian Federation', code: 'RU' },
        { name: 'Rwanda', code: 'RW' },
        { name: 'Saint Kitts and Nevis', code: 'KN' },
        { name: 'Saint Lucia', code: 'LC' },
        { name: 'Saint Vincent and the Grenadines', code: 'VC' },
        { name: 'Samoa', code: 'WS' },
        { name: 'San Marino', code: 'SM' },
        { name: 'Sao Tome and Principe', code: 'ST' },
        { name: 'Saudi Arabia', code: 'SA' },
        { name: 'Senegal', code: 'SN' },
        { name: 'Serbia', code: 'RS' },
        { name: 'Seychelles', code: 'SC' },
        { name: 'Sierra Leone', code: 'SL' },
        { name: 'Singapore', code: 'SG' },
        { name: 'Slovakia', code: 'SK' },
        { name: 'Slovenia', code: 'SI' },
        { name: 'Solomon Islands', code: 'SB' },
        { name: 'Somalia', code: 'SO' },
        { name: 'South Africa', code: 'ZA' },
        { name: 'South Sudan', code: 'SS' },
        { name: 'Spain', code: 'ES' },
        { name: 'Sri Lanka', code: 'LK' },
        { name: 'Sudan', code: 'SD' },
        { name: 'Suriname', code: 'SR' },
        { name: 'Sweden', code: 'SE' },
        { name: 'Switzerland', code: 'CH' },
        { name: 'Syrian Arab Republic', code: 'SY' },
        { name: 'Tajikistan', code: 'TJ' },
        { name: 'Thailand', code: 'TH' },
        { name: 'Timor-Leste', code: 'TL' },
        { name: 'Togo', code: 'TG' },
        { name: 'Tonga', code: 'TO' },
        { name: 'Trinidad and Tobago', code: 'TT' },
        { name: 'Tunisia', code: 'TN' },
        { name: 'Turkey', code: 'TR' },
        { name: 'Turkmenistan', code: 'TM' },
        { name: 'Tuvalu', code: 'TV' },
        { name: 'Uganda', code: 'UG' },
        { name: 'Ukraine', code: 'UA' },
        { name: 'United Arab Emirates', code: 'AE' },
        {
            name: 'United Kingdom of Great Britain and Northern Ireland',
            code: 'GB',
        },
        { name: 'United Republic of Tanzania', code: 'TZ' },
        { name: 'United States of America', code: 'US' },
        { name: 'Uruguay', code: 'UY' },
        { name: 'Uzbekistan', code: 'UZ' },
        { name: 'Vanuatu', code: 'VU' },
        { name: 'Venezuela (Bolivarian Republic of)', code: 'VE' },
        { name: 'Viet Nam', code: 'VN' },
        { name: 'Yemen', code: 'YE' },
        { name: 'Zambia', code: 'ZM' },
        { name: 'Zimbabwe', code: 'ZW' },
    ];

    private states: State[] = [
        { name: 'Alabama', code: 'AL', countryCode: 'US' },
        { name: 'Alaska', code: 'AK', countryCode: 'US' },
        { name: 'Arizona', code: 'AZ', countryCode: 'US' },
        { name: 'Arkansas', code: 'AR', countryCode: 'US' },
        { name: 'California', code: 'CA', countryCode: 'US' },
        { name: 'Colorado', code: 'CO', countryCode: 'US' },
        { name: 'Connecticut', code: 'CT', countryCode: 'US' },
        { name: 'Delaware', code: 'DE', countryCode: 'US' },
        { name: 'Florida', code: 'FL', countryCode: 'US' },
        { name: 'Georgia', code: 'GA', countryCode: 'US' },
        { name: 'Hawaii', code: 'HI', countryCode: 'US' },
        { name: 'Idaho', code: 'ID', countryCode: 'US' },
        { name: 'Illinois', code: 'IL', countryCode: 'US' },
        { name: 'Indiana', code: 'IN', countryCode: 'US' },
        { name: 'Iowa', code: 'IA', countryCode: 'US' },
        { name: 'Kansas', code: 'KS', countryCode: 'US' },
        { name: 'Kentucky', code: 'KY', countryCode: 'US' },
        { name: 'Louisiana', code: 'LA', countryCode: 'US' },
        { name: 'Maine', code: 'ME', countryCode: 'US' },
        { name: 'Maryland', code: 'MD', countryCode: 'US' },
        { name: 'Massachusetts', code: 'MA', countryCode: 'US' },
        { name: 'Michigan', code: 'MI', countryCode: 'US' },
        { name: 'Minnesota', code: 'MN', countryCode: 'US' },
        { name: 'Mississippi', code: 'MS', countryCode: 'US' },
        { name: 'Missouri', code: 'MO', countryCode: 'US' },
        { name: 'Montana', code: 'MT', countryCode: 'US' },
        { name: 'Nebraska', code: 'NE', countryCode: 'US' },
        { name: 'Nevada', code: 'NV', countryCode: 'US' },
        { name: 'New Hampshire', code: 'NH', countryCode: 'US' },
        { name: 'New Jersey', code: 'NJ', countryCode: 'US' },
        { name: 'New Mexico', code: 'NM', countryCode: 'US' },
        { name: 'New York', code: 'NY', countryCode: 'US' },
        { name: 'North Carolina', code: 'NC', countryCode: 'US' },
        { name: 'North Dakota', code: 'ND', countryCode: 'US' },
        { name: 'Ohio', code: 'OH', countryCode: 'US' },
        { name: 'Oklahoma', code: 'OK', countryCode: 'US' },
        { name: 'Oregon', code: 'OR', countryCode: 'US' },
        { name: 'Pennsylvania', code: 'PA', countryCode: 'US' },
        { name: 'Rhode Island', code: 'RI', countryCode: 'US' },
        { name: 'South Carolina', code: 'SC', countryCode: 'US' },
        { name: 'South Dakota', code: 'SD', countryCode: 'US' },
        { name: 'Tennessee', code: 'TN', countryCode: 'US' },
        { name: 'Texas', code: 'TX', countryCode: 'US' },
        { name: 'Utah', code: 'UT', countryCode: 'US' },
        { name: 'Vermont', code: 'VT', countryCode: 'US' },
        { name: 'Virginia', code: 'VA', countryCode: 'US' },
        { name: 'Washington', code: 'WA', countryCode: 'US' },
        { name: 'West Virginia', code: 'WV', countryCode: 'US' },
        { name: 'Wisconsin', code: 'WI', countryCode: 'US' },
        { name: 'Wyoming', code: 'WY', countryCode: 'US' },
        { name: 'Alberta', code: 'AB', countryCode: 'CA' },
        { name: 'British Columbia', code: 'BC', countryCode: 'CA' },
        { name: 'Manitoba', code: 'MB', countryCode: 'CA' },
        { name: 'New Brunswick', code: 'NB', countryCode: 'CA' },
        { name: 'Newfoundland and Labrador', code: 'NL', countryCode: 'CA' },
        { name: 'Northwest Territories', code: 'NT', countryCode: 'CA' },
        { name: 'Nova Scotia', code: 'NS', countryCode: 'CA' },
        { name: 'Nunavut', code: 'NU', countryCode: 'CA' },
        { name: 'Ontario', code: 'ON', countryCode: 'CA' },
        { name: 'Prince Edward Island', code: 'PE', countryCode: 'CA' },
        { name: 'Quebec', code: 'QC', countryCode: 'CA' },
        { name: 'Saskatchewan', code: 'SK', countryCode: 'CA' },
        { name: 'Yukon', code: 'YT', countryCode: 'CA' },
    ];

    constructor() { }

    getCountries(): Country[] {
        const usIndex = this.countries.findIndex(country => country.code === 'US');
        const usCountry = this.countries.splice(usIndex, 1)[0];
        this.countries.sort((a, b) => {
            if (this.isPrioritized(a) && !this.isPrioritized(b)) {
                return -1;
            }
            if (!this.isPrioritized(a) && this.isPrioritized(b)) {
                return 1;
            }
            return a.name.localeCompare(b.name);
        });
        this.countries.unshift(usCountry);
        return this.countries;
    }


    getStatesByCountry(countryCode: string): State[] {
        return this.states.filter((state) => state.countryCode === countryCode);
    }

    private isPrioritized(country: Country): boolean {
        const prioritizedCountryCodes = ['US', 'CA', 'GB', 'FR', 'DE', 'NL'];
        return prioritizedCountryCodes.includes(country.code);
    }
}
