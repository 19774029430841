// store/actions/testimonials.actions.ts
import { createAction, props } from '@ngrx/store';
import { Testimonial } from 'src/app/shared/models/testimonial.interface';

export const loadTestimonials = createAction('[Testimonials] Load Testimonials');

export const loadTestimonialsSuccess = createAction(
    '[Testimonials] Load Testimonials Success',
    props<{ testimonials: Testimonial[] }>()
);

export const loadTestimonialsFailure = createAction(
    '[Testimonials] Load Testimonials Failure',
    props<{ error: any }>()
);

export const loadTestimonialsByProductSlug = createAction(
    '[Testimonials] Load Testimonials By Product Slug',
    props<{ slug: string }>()
);

export const loadTestimonialsByProductSlugSuccess = createAction(
    '[Testimonials] Load Testimonials By Product Slug Success',
    props<{ testimonials: Testimonial[] }>()
);

export const loadTestimonialsByProductSlugFailure = createAction(
    '[Testimonials] Load Testimonials By Product Slug Failure',
    props<{ error: any }>()
);

export const resetTestimonials = createAction('[Testimonials] Reset Testimonials');
