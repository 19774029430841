import { Component, Input } from '@angular/core';
import { Feature } from '../../models/feature.interface';

@Component({
  selector: 'app-features-container',
  templateUrl: './features-container.component.html',
  styleUrls: ['./features-container.component.scss']
})
export class FeaturesContainerComponent {
  @Input() features: Feature[] = [];
}
