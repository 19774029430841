import { createFeatureSelector, createSelector } from '@ngrx/store';
import { multiStepFormFeatureKey } from './multi-step-form.reducer';
import { MultiStepFormState } from './multi-step-form.state';

export const selectMultiStepFormState = createFeatureSelector<MultiStepFormState>(multiStepFormFeatureKey);

export const selectCurrentStep = createSelector(
    selectMultiStepFormState,
    (state: MultiStepFormState) => state.currentStep
);

export const selectCompletedSteps = createSelector(
    selectMultiStepFormState,
    (state: MultiStepFormState) => state ? state.completedSteps : []
);

export const selectFormData = createSelector(
    selectMultiStepFormState,
    (state: MultiStepFormState) => state.formData
);

export const selectSteps = createSelector(
    selectMultiStepFormState,
    (state: MultiStepFormState) => {
        const completedSteps = state.completedSteps;
        return state.steps.filter((step, index) => completedSteps.includes(index));
    }
);

export const selectModel = createSelector(
    selectMultiStepFormState,
    (state: MultiStepFormState) => state.model
);