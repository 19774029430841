import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import anime from 'animejs/lib/anime.es.js';
@Component({
  selector: 'app-background-video-hero',
  templateUrl: './background-video-hero.component.html',
  styleUrls: ['./background-video-hero.component.scss']
})
export class BackgroundVideoHeroComponent {

  @Input() videoSource: string;

  @Output() ctaButtonClick = new EventEmitter<void>();

  constructor(private store: Store<any>) { }

  onCtaButtonClick() {
    this.ctaButtonClick.emit();
  }

}
