<!-- page-title end-->
<div class="site-main">
    <section class="sci-row pb-25 res-991-pb-0 clearfix">
        <div class="container">
            <!-- row -->
            <div class="row">
                <div class="col-lg-12">
                    <!-- sci-pf-single-content-wrapper-innerbox -->
                    <div class="sci-pf-single-content-wrapper-innerbox sci-pf-view-top-image">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1>See How Automation Can Work In Your Lab</h1>
                                <p>
                                    Schedule a convenient, no-pressure 30-minute virtual
                                    demonstration of our lab automation solutions using Zoom video
                                    conferencing. Our expert account managers can tailor the
                                    interactive demonstration specifically to your unique lab
                                    environment, answer all of your questions in real-time, and
                                    show how automation can help make your lab more efficient and
                                    successful.
                                </p>
                                <div class="row">
                                    <app-bullet-features-container [customItems]="customItems"></app-bullet-features-container>
                                </div>
                                <app-virtual-demo-form (formSubmit)="onFormSubmit($event)" [successMessage]="successMessage$ | async"
                                    [submitted]="submitted"></app-virtual-demo-form>
                            </div>
                            <div class="col-lg-6">
                                <owl-carousel-o [options]="customOwlOptions">
                                    <ng-template carouselSlide>
                                        <img src="assets/images/virtualdemo1.jpg" alt="">
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <img src="assets/images/virtualdemo2.jpg" alt="">
                                    </ng-template>
                                </owl-carousel-o>
                                
                            </div>
                        </div>
                    </div>
                    <!-- sci-pf-single-content-wrapper-innerbox end-->
                </div>
            </div>
        </div>
    </section>
</div>
<!--site-main end-->