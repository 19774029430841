import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { ProductRequestEffects } from './product-request.effects';
import * as fromProductRequest from './product-request.reducer';



@NgModule({
    imports: [
        EffectsModule.forFeature([ProductRequestEffects]),
        StoreModule.forFeature(fromProductRequest.productRequestFeatureKey, fromProductRequest.productRequestReducer)],
})
export class ProductRequestStoreModule { }