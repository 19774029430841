import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsBySolutionContainerComponent } from './containers/products-by-solution-container/products-by-solution-container.component';

const routes: Routes = [
  { path: 'solutions/:solution', component: ProductsBySolutionContainerComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsBySolutionRoutingModule { }