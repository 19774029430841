import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Media } from "src/app/features/products/models/media.interface";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { removeProductAssociation } from "src/app/features/products/store/media.actions";
import { ModalService } from "src/app/core/services/modal.service";

@Component({
  selector: "app-media-admin-list",
  templateUrl: "./media-admin-list.component.html",
  styleUrls: ["./media-admin-list.component.scss"],
})
export class MediaAdminListComponent implements OnInit{
  @Input() mediaItems: Media[];
  @Input() uploadButtonText: string;
  @Input() objectType: string = 'media';
  @Output() orderChanged: EventEmitter<Media[]> = new EventEmitter<Media[]>();
  @Output() disassociateRequested: EventEmitter<Media> = new EventEmitter<Media>();
  @Output() save = new EventEmitter<Media[]>();
  @Output() cancel = new EventEmitter<void>();
  @Input() isFormValid: boolean = true;
  onChanges(updatedMedia: Media): void {
    // Find the index of the media item that was updated
    const index = this.mediaItems.findIndex(item => item.id === updatedMedia.id);
    if (index !== -1) {
      this.mediaItems[index] = updatedMedia;
    }
  }

  constructor(private store: Store, private route: ActivatedRoute, private modalService: ModalService) {}

  drop(event: CdkDragDrop<Media[]>): void {
    // Move item in the array based on the drag-and-drop operation
    moveItemInArray(this.mediaItems, event.previousIndex, event.currentIndex);

    // Update the weight of each mediaItem to match its current index
    this.mediaItems.forEach((item, index) => {
      // Shallow clone the item to a new object
      const updatedItem = { ...item, weight: index };
      
      // Update the array with the cloned object
      this.mediaItems[index] = updatedItem;
    });

    // console.log(this.mediaItems);

    // Emit the updated mediaItems array to the parent component, if needed
    this.orderChanged.emit(this.mediaItems);
}

  onFileChange(file: File): void {
    this.mediaItems['file'] = file;
  }

  ngOnInit(): void {
    
    // console.log(this.mediaItems) 
  }

  onCancel() {
    this.cancel.emit();
  }

  onDisassociateRequested(media: Media) {
   this.openCustomModal(media);
  }

  async openCustomModal(media: Media) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Disassociate Media', cssClass: 'btn btn-danger', action: 'confirm' }
    ];

    const result = await this.modalService.openModal(
      'Disassociate Media Item', 
      'Are you sure you want to disassociate this media item from the selected product? This action cannot be undone. Please confirm if you wish to permanently remove this media item from your product.', 
      buttons
    );

    // Handle the result based on action
    switch (result) {
      case 'cancel':
        // Handle cancel
        break;
      case 'confirm':
        this.disassociateRequested.emit(media);
        break;
    }
  }
  
}
