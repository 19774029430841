// src/app/core/services/post.service.ts

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Post } from "src/app/shared/models/post.interface";
import { HttpHeaders } from "@angular/common/http";
import { PaginationInfo } from "@fluentllc/core";

@Injectable({
    providedIn: "root",
})
export class PostService {
    private readonly apiUrl = "api/v1/posts"; // Replace this with your API endpoint

    constructor(private http: HttpClient) { }

    getPostsByBlogId(blogId: number): Observable<Post[]> {
        const params = new HttpParams().set("blog_id", blogId.toString());
        return this.http.get<Post[]>(this.apiUrl, { params });
    }

    getPostBySlug(slug: string): Observable<Post> {
        // Replace this URL with the correct API endpoint for fetching a single post by its slug
        const params = new HttpParams().set("slug", slug.toString());
        return this.http.get<Post>(`${this.apiUrl}/by_slug`, { params });
    }

    translatePostForPosting(post: Post) {
        const postForPosting: any = {}; // Use 'any' for flexibility

        for (const key in post) {
            if (post.hasOwnProperty(key) && post[key] !== undefined && post[key] !== null) {
                // Check if the property is 'author'
                if (key === "author" && post[key]?.id !== undefined && post[key]?.id !== null) {
                    // If 'author' exists and has an 'id', use the 'id' as the value
                    postForPosting[key] = post[key].id;
                } else if (key !== "image") {
                    // Exclude the 'image' property, but include all other properties
                    postForPosting[key] = post[key];
                }
            }
        }

        return postForPosting;
    }

    getPaginatedPosts(page: number, pageSize: number): Observable<{ posts: Post[]; pagination: PaginationInfo }> {
        const params = new HttpParams().set("page", page.toString()).set("page_size", pageSize.toString());

        return this.http.get<Post[]>(this.apiUrl, { params, observe: "response" }).pipe(
            map((response) => {
                const posts = response.body;
                const pagination: PaginationInfo = {
                    paginated: response.headers.get("x-paginated") === "true",
                    paginatedBy: response.headers.get("x-paginated-by"),
                    currentPage: response.headers.get("x-pagination-current"),
                    nextPage: response.headers.get("x-pagination-next"),
                    count: response.headers.get("x-pagination-count"),
                };
                return { posts, pagination };
            })
        );
    }

    getPostById(id: number): Observable<Post> {
        return this.http.get<Post>(`${this.apiUrl}/${id}`);
    }

    createPost(post: Post, imageFile?: File): Observable<Post> {
        const formData = new FormData();
        // console.log(post);
        Object.keys(post).forEach((key) => {
            if (post[key] != null) {
                if (key === "author" && post[key] && typeof post[key] === "object") {
                    // Append only the author's ID if the author field is an object
                    formData.append(key, post[key].id.toString());
                } else if (key !== "image") {
                    // Handle other fields normally
                    formData.append(key, post[key].toString());
                }
            }
        });
        if (imageFile) {
            formData.append("image", imageFile, imageFile.name);
        }
        // console.log(imageFile)
        return this.http.post<Post>(`${this.apiUrl}`, formData);
    }

    //   updatePost(post: Post): Observable<Post> {
    //     const postForPosting = this.translatePostForPosting(post);
    //     console.log(postForPosting)
    //     return this.http.patch<Post>(`${this.apiUrl}/${post.id}`, postForPosting);
    //   }

    updatePost(post: Post, imageFile?: File): Observable<Post> {
        const formData = new FormData();
        // console.log(post);
        Object.keys(post).forEach((key) => {
            if (post[key] != null) {
                if (key === "author" && post[key] && typeof post[key] === "object") {
                    // Append only the author's ID if the author field is an object
                    formData.append(key, post[key].id.toString());
                } else if (key !== "image") {
                    // Handle other fields normally
                    formData.append(key, post[key].toString());
                }
            }
        });
        if (imageFile) {
            formData.append("image", imageFile, imageFile.name);
        }
        return this.http.patch<Post>(`${this.apiUrl}/${post.id}`, formData);
    }

    uploadImage(postId: number, imageFile: File): Observable<any> {
        const formData = new FormData();
        formData.append("image", imageFile, imageFile.name);
        return this.http.patch(`${this.apiUrl}/${postId}`, formData);
    }

    deletePost(postId: number): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${postId}`);
    }

    createKeyword(postId: number, keyword: { name: string; slug: string }): Observable<any> {
        return this.http.post(`${this.apiUrl}/${postId}/create_keyword`, keyword);
    }

    editKeyword(postId: number, keyword: { name: string; slug: string }): Observable<any> {
        return this.http.post(`${this.apiUrl}/${postId}/edit_keyword`, keyword);
    }

    deleteKeyword(postId: number, keyword: { name: string; slug: string }): Observable<any> {
        return this.http.post(`${this.apiUrl}/${postId}/delete_keyword`, keyword);
    }
}
