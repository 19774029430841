import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as SchemaActions from './schema.actions';
import { SchemaService } from 'src/app/core/services/seo/schema.service';

@Injectable()
export class SchemaEffects {
    loadSchema$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SchemaActions.loadSchema),
            mergeMap(() =>
                this.schemaService.getSchema().pipe(
                    map(schema => SchemaActions.loadSchemaSuccess({ schema })),
                    catchError(error => of(SchemaActions.loadSchemaFailure({ error })))
                )
            )
        )
    );

    constructor(private actions$: Actions, private schemaService: SchemaService) { }
}
