import { createAction, props } from '@ngrx/store';
import { Layout } from 'src/app/shared/models/layout.interface';

export const loadLayouts = createAction('[Layout] Load Layouts');

export const loadLayoutsSuccess = createAction(
  '[Layout] Load Layouts Success',
  props<{ layouts: Layout[] }>()
);

export const loadLayoutsFailure = createAction(
  '[Layout] Load Layouts Failure',
  props<{ error: string }>()
);

export const addLayout = createAction(
    '[Layout] Add Layout',
    props<{ layout: Layout }>()
  );
  
  export const addLayoutSuccess = createAction(
    '[Layout] Add Layout Success',
    props<{ layout: Layout }>()
  );
  
  export const addLayoutFailure = createAction(
    '[Layout] Add Layout Failure',
    props<{ error: string }>()
  );
  
  export const updateLayout = createAction(
    '[Layout] Update Layout',
    props<{ layout: Layout }>()
  );
  
  export const updateLayoutSuccess = createAction(
    '[Layout] Update Layout Success',
    props<{ layout: Layout }>()
  );
  
  export const updateLayoutFailure = createAction(
    '[Layout] Update Layout Failure',
    props<{ error: string }>()
  );
  
  export const deleteLayout = createAction(
    '[Layout] Delete Layout',
    props<{ id: number }>()
  );
  
  export const deleteLayoutSuccess = createAction(
    '[Layout] Delete Layout Success',
    props<{ id: number }>()
  );
  
  export const deleteLayoutFailure = createAction(
    '[Layout] Delete Layout Failure',
    props<{ error: string }>()
  );
