<button (click)="videoPlayer.openModal('https://player.vimeo.com/video/642072774')" class="
    sci-btn
    sci-btn-size-lg
    sci-btn-shape-rounded
    sci-btn-style-fill
    btn-cta-bg,
    sci-textcolor-white
    " type="button">
    {{buttonCta}}

</button>

<app-embedded-video-player [vimeoLink]="'https://player.vimeo.com/video/642072774'" [title]="title"
    [queryStrings]="queryStrings" #videoPlayer></app-embedded-video-player>