<div class="container">
    <div class="row">
      <!-- Article Content-->
      <div class="col-xl-9 pb-100">
        <div class="card card-default">
          <div class="card-body">
            <div class="post-edit">
              <app-content-editor (save)="onSave()" (cancel)="onCancel()" [isFormValid]="supportGuideForm.valid">
                <form [formGroup]="supportGuideForm">
                  <div class="form-group">
                    <p>Title</p>
                    <input id="title" class="mb-3 form-control form-control-lg" formControlName="title" />
                    <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="alert alert-danger">
                      Title is required.
                    </div>
                  </div>
                  <div class="form-group">
                      <p>Part Number</p>
                      <input id="part_number" class="mb-3 form-control form-control-lg" formControlName="part_number"  />
                    </div>
                    <div class="form-group">
                        <div *ngIf="supportGuide?.attached_pdf" class="mb-3">
                        <p>Support Guide PDF</p>
                        <a href="{{supportGuide?.attached_pdf}}" target="_blank" *ngIf="supportGuide?.attached_pdf">{{supportGuide?.attached_pdf}}</a>
                    </div>
                        <p>Upload New Support Guide PDF</p>
                        <input type="file" class="form-control mb-3" id="attached_pdf" (change)="onFileChange($event, 'attached_pdf')">
                        <div *ngIf="f.attached_pdf.errors?.notPdf" class="alert alert-danger">
                            Only PDF files are allowed.
                          </div>
                    </div>
                </form>
              </app-content-editor>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <app-product-tags [tags]="productTagSlugs"
        (tagsChange)="onProductTagsChange($event)" [useSlugs]="useSlugs"></app-product-tags>
    </div>
  </div>
  