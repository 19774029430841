import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Layout } from 'src/app/shared/models/layout.interface';

export interface LayoutState extends EntityState<Layout> {
  // additional state property indicators could be added here
  loading: boolean;
  error: string | null;
}

export const adapter: EntityAdapter<Layout> = createEntityAdapter<Layout>({
  selectId: (layout: Layout) => layout.id,
});

export const initialState: LayoutState = adapter.getInitialState({
  // additional initial state properties
  loading: false,
  error: null,
});
