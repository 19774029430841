// product-filter.reducer.ts

import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { initialProductFilterState, ProductFilterState } from './product-filter.state';
import { updateSelectedOptions } from './product-filter.actions';

export const ProductFilterFeatureKey = 'productFilter';

export const productFilterReducer = createReducer(
  initialProductFilterState,
  on(updateSelectedOptions, (state, { optionCode, optionValueSlug }) => ({
    ...state,
    selectedOptions: {
      ...state.selectedOptions,
      [optionCode]: optionValueSlug,
    },
  }))
);
