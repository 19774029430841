// src/app/store/effects/faq.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as FAQActions from './faq.actions';
import { FAQService } from 'src/app/core/services/faq.service';

@Injectable()
export class FAQEffects {

    loadFAQs$ = createEffect(() => this.actions$.pipe(
        ofType(FAQActions.loadFAQs),
        mergeMap(() => this.faqService.getFAQs()
            .pipe(
                map(FAQs => FAQActions.loadFAQsSuccess({ FAQs })),
                catchError(error => of(FAQActions.loadFAQsFailure({ error })))
            ))
    )
    );

    loadFAQsByTag$ = createEffect(() => this.actions$.pipe(
        ofType(FAQActions.loadFAQsByTag),
        mergeMap((action) => this.faqService.getFAQsByTag(action.keyword_slug, action.product_slug, action.solution_slug, action.operator)
            .pipe(
                map(FAQs => FAQActions.loadFAQsByTagSuccess({ FAQs })),
                catchError(error => of(FAQActions.loadFAQsByTagFailure({ error })))
            ))
    )
    );

    constructor(
        private actions$: Actions,
        private faqService: FAQService
    ) { }
}
