import { createAction, props } from '@ngrx/store';
import { FAQ } from 'src/app/shared/models/faq.interface';

export const loadFAQs = createAction('[FAQ] Load FAQs');
export const loadFAQsSuccess = createAction(
    '[FAQ] Load FAQs Success',
    props<{ FAQs: FAQ[] }>()
);
export const loadFAQsFailure = createAction(
    '[FAQ] Load FAQs Failure',
    props<{ error: any }>()
);

export const loadFAQsByTag = createAction(
    '[FAQ] Load FAQs By Tag',
    props<{
        keyword_slug?: string;
        product_slug?: string;
        solution_slug?: string;
        operator?: 'AND' | 'OR';
    }>()
);
export const loadFAQsByTagSuccess = createAction(
    '[FAQ] Load FAQs By Tag Success',
    props<{ FAQs: FAQ[] }>()
);
export const loadFAQsByTagFailure = createAction(
    '[FAQ] Load FAQs By Tag Failure',
    props<{ error: any }>()
);
