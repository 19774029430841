<!-- START Sidebar (left)-->

<div class="aside-inner" *ngIf="isLoggedIn$ | async">
    <div class="sidebar-content">
        <app-admin-menu [menuItems]="menuItems$ | async"></app-admin-menu>
    </div>
    <div class="button-container" *ngIf="isLoggedIn$ | async">
        <i (click)="toggleSidebar()" 
           (mouseover)="onMouseOver()" 
           (mouseout)="onMouseOut()" 
           class="fa fa-2x" 
           [class.fa-ellipsis-v]="isOpen && !hovering" 
           [class.fa-angle-left]="isOpen && hovering" 
           [class.fa-angle-right]="!isOpen" 
           [title]="tooltipMessage"
           aria-hidden="true">
        </i>
    </div>
</div>

