import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../core/services/menu.service';
import { MenuItem } from '../../../core/models/menu-item.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-container',
  template: `
    <app-menu
      [menuItems]="menuItems$ | async"
      [isVertical]="isVertical"
      [menuType]="menuType"
      (switcherToggleClass)="switcherToggleClass()"
      [classApplied]="classApplied"
      [customClass]="customClass"
      (linkClick)="handleLinkClick()"
    ></app-menu>
  `,
  styles: [],
})
export class MenuContainerComponent implements OnInit {
  @Input() context: string = 'global';
  @Input() isVertical: boolean = false;
  @Input() menuType: 'top' | 'footer' = 'top';
  @Input() classApplied: boolean = false;
  @Input() customClass: string = '';
  @Output() linkClick = new EventEmitter<void>();
  menuItems$: Observable<MenuItem[]>;

  switcherClassApplied = false;

  constructor(private menuService: MenuService, public router: Router) {
  }

  ngOnInit() {
    this.menuItems$ = this.menuService.getMenuItems(this.context);
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  handleLinkClick() {
    this.linkClick.emit();
  }

  switcherToggleClass() {
    this.switcherClassApplied = !this.switcherClassApplied;
  }
}
