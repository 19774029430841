import { createReducer, on } from "@ngrx/store";
import { Product } from '../models/product.interface';
import { initialState, ProductsState, adapter} from './products.state';
import * as ProductsActions from './products.actions';
export const productsFeatureKey = 'products';

export const productsReducer = createReducer(
  initialState,
  on(ProductsActions.loadProducts, (state) => ({ ...state, loading: true })),
  on(ProductsActions.loadProductsSuccess, (state, { products }) => 
      adapter.setAll(products, { ...state, loading: false })
  ),
  on(ProductsActions.loadProductsFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(ProductsActions.loadProduct, (state) => ({ ...state, loading: true })),
  on(ProductsActions.loadProductSuccess, (state, { currentProduct }) => ({ ...state, currentProduct, loading: false })),
  on(ProductsActions.loadProductFailure, (state, { error }) => ({ ...state, error, loading: false })),
  on(ProductsActions.loadProductsBySolution, (state) => ({
      ...state,
      loading: true,
      error: null
  })),
  on(ProductsActions.loadProductsBySolutionSuccess, (state, { products }) => 
      adapter.setAll(products, { ...state, loading: false, error: null })
  ),
  on(ProductsActions.loadProductsBySolutionFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error
  })),
  on(ProductsActions.loadProductsByKeyword, (state) => ({
      ...state,
      loading: true,
      error: null
  })),
  on(ProductsActions.loadProductsByKeywordSuccess, (state, { products }) => 
      adapter.setAll(products, { ...state, loading: false, error: null })
  ),
  on(ProductsActions.loadProductsByKeywordFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error
  })),
  on(ProductsActions.loadPaginatedProductsSuccess, (state, { products, total, paginationInfo }) => ({
      ...state,
      ...adapter.setAll(products, state),
      total: total,
      paginationInfo: paginationInfo,
      loading: false,
  })),
  on(ProductsActions.createProductSuccess, (state, { product }) => 
      adapter.addOne(product, { ...state, loading: false })
  ),
  on(ProductsActions.createProductFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProductsActions.updateProductSuccess, (state, { product }) => { 
      const existingProduct = state.entities[product.id];
      const updatedProduct = {
          ...existingProduct,
          ...product
      };
      return adapter.upsertOne(updatedProduct, { ...state, loading: false });
  }),
  on(ProductsActions.updateProductFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProductsActions.deleteProductSuccess, (state, { productId }) => 
      adapter.removeOne(productId, { ...state, loading: false })
  ),
  on(ProductsActions.deleteProductFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ProductsActions.saveProductFormState, (state, { product }) => ({ ...state, formState: product })),
  on(ProductsActions.loadProductFormState, (state) => state),
  on(ProductsActions.clearProductFormState, (state) => ({ ...state, formState: null })),
  on(ProductsActions.clearSelectedProduct, (state) => ({ ...state, currentProduct: null })),
  on(ProductsActions.updatePageSize, (state, { pageSize }) => ({ ...state, pageSize })), 
);
