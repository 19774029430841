// src/app/core/utils/string-utils.ts

import { Renderer2 } from '@angular/core';
import { Permission } from 'src/app/shared/models/permission.interface';
import { Role } from 'src/app/shared/models/role.interface';
import { RolesPermissionsService } from '../services/roles-permissions.service';

interface AccessControlAPIResponse {
  name: string;
  slug: string;
}

export function camelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function convertKeysToSnakeCase(obj: any): any {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    newObj[camelToSnakeCase(key)] = obj[key];
  });
  return newObj;
}

export function replaceUnderscoreWithHyphen(str: string): string {
  // console.log(str)
  return str.replace(/_/g, '-');
}

export function convertKeysToHyphenCase(obj: any): any {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    newObj[replaceUnderscoreWithHyphen(key)] = obj[key];
  });
  return newObj;
}

export function convertStringArrayToRoleArray(rolesArray?: Role[]): Role[] {
  if (!rolesArray) {
    return [];
  }
  return rolesArray
    .map((role: Role) => {
      if (typeof role.slug !== 'string') {
        console.error('Invalid slug:', role.slug);
        return null;
      }
      // console.log(role.slug)
      return {
        name: role.name,
        slug: replaceUnderscoreWithHyphen(role.slug),
        id: role.id,
        order: role.order,
        tenant: role.tenant,
      };
    })
    .filter((permission) => permission !== null);
}

export function cleanRolesArray(rolesArray?: Role[]): Role[] {
  if (!rolesArray) {
    return [];
  }
  return rolesArray.map((role: Role) => {
    return {
      name: role.name,
      slug: replaceUnderscoreWithHyphen(role.slug),
      id: role.id,
      tenant: role.tenant,
      order: role.order,
      permissions: role.permissions
    };
  });
}

export function cleanPermissionsArray(
  permissionsArray?: Permission[]
): Permission[] {
  if (!permissionsArray) {
    return [];
  }
  return permissionsArray.map((permissionModel: Permission) => {
    return {
      name: permissionModel.name,
      slug: replaceUnderscoreWithHyphen(permissionModel.slug),
    };
  });
}

export function convertStringArrayToPermissionArray(
  permissionArray?: Permission[]
): Permission[] {
  if (!permissionArray) {
    return [];
  }
  return permissionArray
    .map((permissionObj: Permission) => {
      if (typeof permissionObj.slug !== 'string') {
        console.error('Invalid slug:', permissionObj.slug);
        return null;
      }
      return {
        name: permissionObj.name,
        slug: replaceUnderscoreWithHyphen(permissionObj.slug),
      };
    })
    .filter((permission) => permission !== null);
}

export function truncateDescription(
  description: string,
  limit: number = 100,
  completeWords: boolean = true,
  ellipsis: string = '...'
): string {
  if (description.length <= limit) {
    return description;
  }

  let trimmedValue = description.slice(0, limit);

  if (completeWords) {
    trimmedValue = trimmedValue.slice(0, trimmedValue.lastIndexOf(' '));
  }

  return `${trimmedValue}${ellipsis}`;
}


export function generateSlug(name: string): string {
  return name
    .toLowerCase()
    .replace(/[\s_]+/g, '-')  // Replace spaces and underscores with hyphens
    .replace(/[^\w-]+/g, '')  // Remove all non-word chars
    .replace(/--+/g, '-');    // Replace multiple hyphens with a single hyphen
}

// export function extractHighlightedResult(description: string): string {
//   const MAX_WORDS_BEFORE = 10;
//   const MAX_WORDS_AFTER = 40;
//   const ELLIPSIS = '...';

//   const container = document.createElement('div');
//   container.innerHTML = description;

//   // Find the first em tag in the string.
//   const emTag = container.querySelector('em');
//   if (!emTag) {
//     return description;
//   }

//   // Split the text before and after the em tag into words.
//   const beforeEm = emTag.previousSibling.textContent.trim().split(/\s+/);
//   const afterEm = emTag.nextSibling.textContent.trim().split(/\s+/);

//   // Get the MAX_WORDS_BEFORE from beforeEm and MAX_WORDS_AFTER from afterEm
//   const wordsBeforeEm = beforeEm.slice(Math.max(beforeEm.length - MAX_WORDS_BEFORE, 0));
//   const wordsAfterEm = afterEm.slice(0, MAX_WORDS_AFTER);

//   // Reconstruct the string with the em tag and the surrounding words.
//   const reconstructedString = [
//     wordsBeforeEm.length === MAX_WORDS_BEFORE ? ELLIPSIS : '',
//     ...wordsBeforeEm,
//     '<em>',
//     emTag.textContent,
//     '</em>',
//     ...wordsAfterEm,
//     wordsAfterEm.length === MAX_WORDS_AFTER ? ELLIPSIS : ''
//   ].join(' ');

//   return reconstructedString;
// }




export function extractHighlightedResult(description: string): string {
  const MAX_WORDS_BEFORE = 10;
  const MAX_WORDS_AFTER = 40;
  const ELLIPSIS = '...';

  const emTagRegex = /<em\b[^>]*>(.*?)<\/em>/;
  const match = description.match(emTagRegex);

  if (!match) {
    return description;
  }

  const highlightedText = match[1];
  const startIndex = Math.max(description.indexOf(match[0]), 0);

  const textBefore = description.slice(0, startIndex).trim();
  const textAfter = description.slice(startIndex + match[0].length).trim();

  const wordsBefore = textBefore.split(/\s+/).slice(-MAX_WORDS_BEFORE);
  const wordsAfter = textAfter.split(/\s+/).slice(0, MAX_WORDS_AFTER);

  const reconstructedString = [
    wordsBefore.length === MAX_WORDS_BEFORE ? ELLIPSIS : '',
    ...wordsBefore,
    `<em>${highlightedText}</em>`,
    ...wordsAfter,
    wordsAfter.length === MAX_WORDS_AFTER ? ELLIPSIS : ''
  ].join(' ');

  return reconstructedString;
}
