<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-8 col-sm-10 m-auto title-style-center-text mb-30">
            <div class="title-header">
                <h5>{{teaser}}</h5>
                <h3 class="title">{{title}}</h3>
            </div>
            <div class="title-desc">
                <p>{{description | truncateDescription:80:true}}</p>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12" *ngFor="let product of relatedProducts">
            <app-product-card-container *ngIf="product.product_class_details?.slug !=='tube'" [product]="product"></app-product-card-container>
            <app-tube-card-container *ngIf="product.product_class_details?.slug === 'tube'" [product]="product"></app-tube-card-container>
        </div>
    </div>
</div>