import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchWindowToggleService {
  private searchPerformedSubject = new Subject<void>();
  searchPerformed$ = this.searchPerformedSubject.asObservable();

  searchPerformed(): void {
    this.searchPerformedSubject.next();
  }
}