import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
// import { HomeEffects } from './home.effects';
// import * as home from './home.reducer';

@NgModule({
  imports: [
    // StoreModule.forFeature(home.featureKey, home.reducer),
    // EffectsModule.forFeature([HomeEffects]),
  ],
})
export class HomeStoreModule { }