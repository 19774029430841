import { AfterViewInit, OnInit, ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormStepComponent } from 'src/app/shared/models/form-step.interface';
import { DynamicFormStep } from 'src/app/shared/models/dynamic-form-step.interface';
import * as MultiStepFormActions from 'src/app/shared/modules/multi-step-form/store/multi-step-form.actions';
import { Store } from '@ngrx/store';
import { selectFormData } from '../../store/multi-step-form.selectors';
import { Subject, takeUntil } from 'rxjs';

@Directive()
export abstract class BaseFormStepComponent implements FormStepComponent {
    key: string;
    title: string;
    formGroup: FormGroup;
    fields: FormlyFieldConfig[];
    model?: any; // Add the model property

    constructor(protected cd: ChangeDetectorRef, protected store: Store) { }

    initialize(): DynamicFormStep {
        this.formGroup = this.createForm();
        this.fields = this.getFields();
        return {
            title: this.title,
            formStep: {
                key: this.key,
                title: this.title,
                formGroup: this.formGroup,
                fields: this.fields,
                model: this.model, // Assign the model property
                initialize: () => this.initialize(),
                createForm: () => this.createForm()
            }
        };
    }

    ngAfterViewInit() {
        // Detect changes after the view has been initialized
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        // Clean up subscriptions when the component is destroyed
        // this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    abstract getFields(): FormlyFieldConfig[];
    abstract createForm(): FormGroup;
}
