import { createReducer, on } from "@ngrx/store";
import * as PostActions from "./post.actions";
import { initialState, adapter } from "./post.state";
import { DateUtils } from "src/app/core/utils/date-utils";
export const postFeatureKey = "posts";
/**
 * Reducer function for managing the state of posts in the application.
 *
 * @param state - The current state of the posts.
 * @param action - The action to be performed on the state.
 * @returns The updated state after applying the action.
 */
export const postReducer = createReducer(
  initialState,
  on(PostActions.loadPosts, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(PostActions.loadPostsSuccess, (state, { posts }) => {
    return adapter.setAll(posts, { ...state, loading: false });
  }),
  on(PostActions.loadPostsFailure, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
  on(PostActions.loadPostsByBlogIdSuccess, (state, { posts }) => {
    const updatedState = adapter.setAll(posts, { ...state, loading: false });
    // console.log('Updated state:', updatedState);
    return updatedState;
  }),
  on(PostActions.loadPostBySlug, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(PostActions.loadPostBySlugSuccess, (state, { post }) => {
    return adapter.upsertOne(post, { ...state, loading: false });
  }),
  on(PostActions.loadPostBySlugFailure, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
  // Create post
  on(PostActions.createPostSuccess, (state, { post }) => adapter.addOne(post, { ...state, loading: false })),
  on(PostActions.createPostFailure, (state, { error }) => ({ ...state, loading: false, error })),

  // Update post
  on(PostActions.updatePostSuccess, (state, { post }) => adapter.upsertOne(post, { ...state, loading: false })),
  on(PostActions.updatePostFailure, (state, { error }) => {
    return { ...state, loading: false, error };
  }),

  // Delete post
  on(PostActions.deletePostSuccess, (state, { postId }) => adapter.removeOne(postId, { ...state, loading: false })),
  on(PostActions.deletePostFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(PostActions.loadPaginatedPostsSuccess, (state, { posts, total, paginationInfo }) => {
    // Update the state with the paginated posts and the total number of posts
    return {
      ...state,
      ...adapter.setAll(posts, state),
      total: total, // Update the total number of posts (if provided by the API)
      paginationInfo: paginationInfo,
      loading: false,
    };
  }),
  on(PostActions.updatePostWithKeywordsSuccess, (state, { post }) =>
    adapter.upsertOne(post, { ...state, loading: false })
  ),
  on(PostActions.updatePostWithKeywordsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(PostActions.uploadPostImage, (state) => {
    return { ...state, uploadingImage: true };
  }),
  on(PostActions.uploadPostImageSuccess, (state) => {
    return { ...state, uploadingImage: false };
  }),
  on(PostActions.uploadPostImageFailure, (state, { error }) => {
    return { ...state, uploadingImage: false, error };
  }),
  on(PostActions.createPostWithKeywordsSuccess, (state, { post }) =>
    adapter.addOne(post, { ...state, loading: false })
  ),
  on(PostActions.createPostWithKeywordsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(PostActions.savePostFormState, (state, { post }) => {
    return { ...state, formState: post };
  }),
  on(PostActions.loadPostFormState, (state) => {
    return state;
  }),
  on(PostActions.clearPostFormState, (state) => {
    return { ...state, formState: null };
  }),
);
