import { Component, Input } from '@angular/core';
import { CustomItem } from '../../models/custom-item.interface';

@Component({
  selector: 'app-bullet-features-list',
  templateUrl: './bullet-features-list.component.html',
  styleUrls: ['./bullet-features-list.component.scss']
})
export class BulletFeaturesListComponent {
  @Input() customItems: CustomItem[] = [];
  @Input() numColumns: number = 3;
}
