import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as MultiStepFormActions from './../../store/multi-step-form.actions';
import { DynamicFormStep } from '../../../../models/dynamic-form-step.interface';
import { selectCurrentStep, selectFormData, selectModel } from '../../store/multi-step-form.selectors';
import * as MultiStepFormSelectors from '../../store/multi-step-form.selectors';

@Component({
  selector: 'app-multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.scss'],
})
export class MultiStepFormComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() steps: DynamicFormStep[];

  currentStep = 0;
  formData: { [key: string]: any } = {};
  private formValueChangesSubscription: Subscription;
  private formDataSubscription: Subscription;
  private currentStepSubscription: Subscription;
  private modelSubscription: Subscription;

  constructor(private store: Store, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.formDataSubscription = this.store.select(selectFormData).subscribe((formData) => {
      this.updateFormWithFormData(formData);
    });

    this.currentStepSubscription = this.store.select(selectCurrentStep).subscribe((currentStep) => {
      this.currentStep = currentStep;
    });

    this.modelSubscription = this.store.select(selectModel).subscribe((model) => {
      this.store.dispatch(MultiStepFormActions.updateModel({ model }));
    });

    this.formValueChangesSubscription = this.formGroup.valueChanges.subscribe(formData => {
      this.store.dispatch(MultiStepFormActions.updateFormData({ step: this.currentStep, data: formData }));
    });
  }

  ngOnDestroy(): void {
    this.formDataSubscription.unsubscribe();
    this.currentStepSubscription.unsubscribe();
    this.modelSubscription.unsubscribe();
    this.formValueChangesSubscription.unsubscribe();
  }

  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.store.dispatch(MultiStepFormActions.setCurrentStep({ step: this.currentStep }));
      this.store.dispatch(MultiStepFormActions.uncompleteStep({ step: this.currentStep + 1 }));
    }
  }

  nextStep(): void {
    if (this.currentStep < this.steps.length - 1) {
      this.store.dispatch(MultiStepFormActions.completeStep({
        step: this.currentStep,
        data: this.formGroup.value,
      }));
      this.currentStep++;
      this.store.dispatch(MultiStepFormActions.setCurrentStep({ step: this.currentStep }));
    }
  }

  updateFormWithFormData(formData: any): void {
    Object.keys(formData).forEach((key) => {
      if (this.formGroup.controls[key]) {
        this.formGroup.controls[key].patchValue(formData[key]);
        this.formGroup.controls[key].updateValueAndValidity();
      }
    });
  }

  onSubmit(): void {
    this.store.dispatch(MultiStepFormActions.completeStep({
      step: this.currentStep,
      data: this.formGroup.value,
    }));
    const successCallback = () => {
      // console.log('Form submitted successfully');
      // this.store.dispatch(MultiStepFormActions.resetMultiStepForm());
    };
    this.store.dispatch(MultiStepFormActions.submitForm({ formData: this.formData, successCallback }));
  }
}
