
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SupportGuide } from 'src/app/shared/models/support-guide.interface';
import { PaginationState } from 'src/app/core/state/pagination.state';

export interface SupportGuideState extends EntityState<SupportGuide>, PaginationState {
  loading: boolean;
  error: any;
  formState: SupportGuide | null;
}

export const adapter: EntityAdapter<SupportGuide> = createEntityAdapter<SupportGuide>();

export const initialState: SupportGuideState = adapter.getInitialState({
  loading: false,
  error: null,
  total: 0,
  currentPage: 1,
  pageSize: 10, // Default page size
  paginationInfo: null,
  formState: null, 
});
