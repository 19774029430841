import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LayoutPosition } from 'src/app/shared/models/layout-position.interface';
import * as fromLayoutActions from '../../store/layout.actions';
import * as fromLayoutSelectors from '../../store/layout.selectors';
import { Layout } from 'src/app/shared/models/layout.interface' // Adjust this import path to your actual model location
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, filter } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout-admin-manage',
  templateUrl: './layout-admin-manage.component.html',
  styleUrls: ['./layout-admin-manage.component.scss'],
})
export class LayoutAdminManageComponent implements OnChanges, OnInit, OnDestroy {
  layout$: Observable<Layout | undefined>;
  @Output() save = new EventEmitter<Layout>();
  @Output() cancel = new EventEmitter<void>();
  private subscription: Subscription = new Subscription();
  layoutForm: FormGroup;
  isEditMode: boolean = false;

  constructor(private fb: FormBuilder, private store: Store, private route: ActivatedRoute,) {
    this.layoutForm = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      is_predefined: [false],
      columns: [1, Validators.required],
      rows: [1],
      positions: this.fb.array([]),
    });
  }

  refreshLayouts(): void {
    this.store.dispatch(fromLayoutActions.loadLayouts());
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const layoutId = params['id'];
      this.layout$ = this.store.pipe(select(fromLayoutSelectors.selectLayoutById(layoutId)));
      this.subscription.add(
        this.layout$.pipe(filter((layout): layout is Layout => layout !== undefined))
          .subscribe((layout) => {
            if (layout) {
              this.isEditMode = true;
              console.log(layout.positions)
              this.layoutForm.patchValue({
                id: layout.id,
                name: layout.name,
                is_predefined: layout.is_predefined,
                columns: layout.columns,
                rows: layout.rows,
              });

              this.positions.clear();

            // If the layout has positions, create a FormGroup for each and push it into the FormArray
            layout.positions?.forEach((position) => {
              this.positions.push(this.fb.group({
                id: [position.id],
                position_name: [position.position_name, Validators.required],
                allowed_block_types: [position.allowed_block_types],
                order: [position.order, Validators.required],
                row: [position.row, Validators.required],
                col: [position.col, Validators.required],
                col_span: [position.col_span, Validators.required],
                row_span: [position.row_span, Validators.required],
              }));
            });
            }
          })

      );
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

  }

  ngOnChanges(): void {
    console.log(this.layoutForm.valid);
  }

  get positions(): FormArray {
    return this.layoutForm.get('positions') as FormArray;
  }

  addPosition(): void {
    const positionForm = this.fb.group({
      id: [null],
      position_name: ['', Validators.required], // Assuming it's a required string
      allowed_block_types: [''], // Assuming it's an optional string
      order: [0, Validators.required], // Assuming it's a required number
      row: [0, Validators.required], // Assuming it's a required number
      col: [0, Validators.required], // Assuming it's a required number
      col_span: [1, Validators.required], // Assuming it's a required number with a default value
      row_span: [1, Validators.required], // Assuming it's a required number with a default value
    });
    this.positions.push(positionForm);
  }

  removePosition(index: number): void {
    this.positions.removeAt(index);
  }

  onSave(): void {
    if (this.layoutForm.valid) {
      const formValue = this.layoutForm.value;
      const layout: Layout = {
        id: formValue.id,
        name: formValue.name,
        is_predefined: formValue.is_predefined,
        columns: formValue.columns,
        rows: formValue.rows,
        positions: formValue.positions.map((pos: any): LayoutPosition => ({
          id: pos.id, // To be set server-side
          position_name: pos.position_name,
          allowed_block_types: pos.allowed_block_types,
          order: pos.order,
          row: pos.row,
          col: pos.col,
          col_span: pos.col_span,
          row_span: pos.row_span,
        })),
      };
      if (this.isEditMode) {
        console.log(layout)
        this.store.dispatch(fromLayoutActions.updateLayout({ layout }));
      } else {
        console.log(layout)
        this.store.dispatch(fromLayoutActions.addLayout({ layout }));
        // this.store.dispatch(PostActions.updatePost({ post: event.post, imageFile: event.imageFile }));
      }
      // this.save.emit(layout);
    } else {
      console.error("Form is not valid");
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }
}
