import {
  Component,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-vr-tour',
  templateUrl: './vr-tour.component.html',
  styleUrls: ['./vr-tour.component.scss'],
})
export class VrTourComponent {

  iframeRef: ElementRef<HTMLIFrameElement>;
  containerRef: ElementRef<HTMLElement>;
  tooltip: string;
  constructor() {
    this.tooltip =
      'Experience groundbreaking VR and AR technologies. VR immerses you in a virtual world, while AR blends the digital with your reality. Use a VR headset or your smartphone for VR, and simply press a button for AR. No special equipment needed. Discover captivating and interactive ways to interact with our products. Dive into new environments or merge digital content with your space. Prepare to be amazed!';
  }

}
