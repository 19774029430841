import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductSlug } from 'src/app/shared/models/product-slug.interface';

@Injectable({
    providedIn: 'root'
})
export class ProductSlugService {
    constructor(private http: HttpClient) { }
    private productSlugConfig = "assets/product-slugs.json";

    getProducts(): Observable<ProductSlug[]> {
        return this.http.get<ProductSlug[]>(this.productSlugConfig).pipe(
            map(products => products.sort((a, b) => a.sortOrder - b.sortOrder))
        );
    }
}
