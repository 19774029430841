import { createAction, props } from '@ngrx/store';
import { ProductClass } from '../models/product-class.interface';


export const loadProductClasses = createAction(
  '[Product Class] Load Product Classes'
);

export const loadProductClassesSuccess = createAction(
  '[Product Class] Load Product Classes Success',
  props<{ productClasses: ProductClass[] }>()
);

export const loadProductClassesFailure = createAction(
  '[Product Class] Load Product Classes Failure',
  props<{ error: any }>()
);

// Actions for loading a single ProductClass by slug
export const loadProductClass = createAction(
  '[Product Class] Load Product Class',
  props<{ slug: string }>()
);

export const loadProductClassSuccess = createAction(
  '[Product Class] Load Product Class Success',
  props<{ currentProductClass: ProductClass }>()
);

export const loadProductClassFailure = createAction(
  '[Product Class] Load Product Class Failure',
  props<{ error: any }>()
);