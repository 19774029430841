import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Post } from '../../../shared/models/post.interface';
import { PaginationState } from 'src/app/core/state/pagination.state';
export interface PostState extends EntityState<Post>, PaginationState {
    loading: boolean;
    error: any;
    formState: Post | null;
}

export const adapter: EntityAdapter<Post> = createEntityAdapter<Post>();

export const initialState: PostState = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0,
    currentPage: 1,
    pageSize: 10, // Default page size
    paginationInfo: null,
    formState: null, 
});
