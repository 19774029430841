import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadMedia } from 'src/app/features/products/store/media.actions';
import { DemoRequest } from 'src/app/shared/models/demo-request.interface';
import { CookieService } from 'ngx-cookie-service';
import { IPService } from 'src/app/core/services/ip.service';
import { getSuccessMessage } from 'src/app/features/appointment-scheduler/store/appointment-scheduler.selectors';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import * as DemoRequestActions from 'src/app/features/appointment-scheduler/store/appointment-scheduler.actions'
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';

@Component({
  selector: 'app-scinomix-aq20240306',
  templateUrl: './scinomix-aq20240306.component.html',
  styleUrls: ['./scinomix-aq20240306.component.scss']
})
export class ScinomixAQ20240306Component implements OnInit{
  @Input() subtitle: string;
  @Input() title: string  = 'Ensure accuracy and gain';
  @Input() titleHighlight: string = 'valuable walk-away time with the Scinomix AQ';
  @Input() description: string = 'Eliminate manual tasks in the lab with our all-in-one benchtop solution for tube aliquoting and labeling workflows.';
  @Input() heroImage: string;
  pageName: string;
  pageUri: string;
  successMessage$: Observable<string | null>;
  submitted: boolean = false;
  ipAddress:string; 
  slug: string = 'scinomix-aq-system';

  constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: any,
    private location: PlatformLocation,
    private cookieService: CookieService,
    private ip:IPService, 
    private metaTagsService: MetaTagsService
  ) {
    this.successMessage$ = store.select(getSuccessMessage);
  }

  ngOnInit(): void {
    this.getIP(); 
    this.store.dispatch(loadMedia({ slug: this.slug }));
    const newMetaTags = {
      title: 'The Scinoimx AQ Aliquoting System',
      description: 'Schedule a virtual demonstration. The AQ Aliquoting System is the next innovative automation system from Scinomix designed to aliquot reagents or samples with labeling, validation, and sample tracking. Workflows supported include sample aliquoting, sample pooling, sample dilution, and reagent dispensing.',
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  getHubSpotUTK() {
    return this.cookieService.get('hubspotutk');
  }

  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  } 

  onFormSubmit(demoRequest: DemoRequest): void {
    const hutkValue = this.getHubSpotUTK();

    const contextData: ContextData = {
      ipAddress: this.ipAddress,
      pageName: this.document.title,
      pageUri: this.location.href,
      sourceComponent: 'DemonstrationsComponentForm'
    };

    if (hutkValue) {
      contextData.hutk = hutkValue;
    }
    this.store.dispatch(
      DemoRequestActions.addDemoRequest({ demoRequest, contextData })
    );
    this.submitted = true;
  }
}
