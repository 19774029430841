import { createAction, props } from "@ngrx/store";
import { Product } from "../models/product.interface";
import { PaginationInfo } from "@fluentllc/core";
export const loadProducts = createAction("[Products] Load Products");

export const loadProductsSuccess = createAction("[Products] Load Products Success", props<{ products: Product[] }>());

export const loadProductsFailure = createAction("[Products] Load Products Failure", props<{ error: string }>());

export const loadProduct = createAction("[Products] Load Product", props<{ slug: string }>());

export const loadProductSuccess = createAction(
  "[Products] Load Product Success",
  props<{ currentProduct: Product }>()
);

export const loadProductFailure = createAction("[Products] Load Product Failure", props<{ error: string }>());

export const loadProductsBySolution = createAction(
  "[Products] Load Products by Solution",
  props<{ solution: string }>()
);
export const loadProductsBySolutionSuccess = createAction(
  "[Products] Load Products by Solution Success",
  props<{ products: Product[] }>()
);
export const loadProductsBySolutionFailure = createAction(
  "[Products] Load Products by Solution Failure",
  props<{ error: string }>()
);

export const loadProductsByKeyword = createAction("[Products] Load Products by Keyword", props<{ keyword: string }>());
export const loadProductsByKeywordSuccess = createAction(
  "[Products] Load Products by Keyword Success",
  props<{ products: Product[] }>()
);
export const loadProductsByKeywordFailure = createAction(
  "[Products] Load Products by Keyword Failure",
  props<{ error: string }>()
);

// Actions for loading paginated posts
export const loadPaginatedProducts = createAction(
  "[Products] Load Paginated Products",
  props<{ page: number; limit: number }>()
);
export const loadPaginatedProductsSuccess = createAction(
  "[Products] Load Paginated Products Success",
  props<{ products: Product[]; total: number; paginationInfo: PaginationInfo }>() // Assuming the API returns total count
);
export const loadPaginatedProductsFailure = createAction(
  "[Products] Load Paginated Products Failure",
  props<{ error: any }>()
);

export const updateProduct = createAction("[Products] Update Products", props<{ product: Partial<Product>, imageFiles?: File[] }>());
export const updateProductSuccess = createAction("[Products] Update Products Success", props<{ product: Partial<Product> }>());
export const updateProductFailure = createAction("[Products] Update Products Failure", props<{ error: any }>());

export const deleteProduct = createAction("[Products] Delete Post", props<{ productId: number }>());
export const deleteProductSuccess = createAction("[Products] Delete Products Success", props<{ productId: number }>());
export const deleteProductFailure = createAction("[Products] Delete Products Failure", props<{ error: any }>());

export const updateCurrentPage = createAction("[Products] Update Current Page", props<{ page: number }>());
export const updatePageSize = createAction("[Products] Update Page Size", props<{ pageSize: number }>());

export const createProduct = createAction("[Products] Create Products", props<{ product: Product, imageFiles?: File[] }>());
export const createProductSuccess = createAction("[Products] Create Products Success", props<{ product: Product }>());
export const createProductFailure = createAction("[Products] Create Products Failure", props<{ error: any }>());

export const saveProductFormState = createAction("[Products] Save Product Form State", props<{ product: Partial<Product> }>());
export const loadProductFormState = createAction("[Products] Load Product Form State");
export const clearProductFormState = createAction("[Products] Clear Product Form State");
export const clearSelectedProduct = createAction("[Products] Clear Selected Product");