import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnChanges {
  @Input() logoUrl: string;
  @Input() alt: string;
  @Input() srcset?: string;
  @Input() maxWidth?: string;
  @Input() width?: string;
  @Input() height?: string;

  ngOnChanges() {
    // Now these values should be updated before the component is initialized
  }
}
