<div [formGroup]="keywordsForm">
    <div formArrayName="keywords">
      <div *ngFor="let keywordGroup of keywords.controls; let i = index" [formGroupName]="i">
        <div class="input-group mb-2">
          <input type="text" class="form-control mb-2" formControlName="name" placeholder="Keyword" />
          <div class="input-group-append">
            <button class="btn btn-outline-danger" (click)="removeKeyword(i)">Remove</button>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" (click)="addKeyword()">Add Keyword</button>
  </div>
  