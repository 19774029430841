import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { SupportGuideEffects } from './support-guide.effects';
import * as supportGuides from './support-guide.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(supportGuides.supportGuideFeatureKey, supportGuides.SupportGuideReducer),
        EffectsModule.forFeature([SupportGuideEffects]),
    ],
})
export class SupportGuidesStoreModule { }
