import { Component } from '@angular/core';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';
import { loadJobPostings } from '../../store/job-posting.actions';
import { selectAllJobPostings } from '../../store/job-posting.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-job-posting-list-container',
  templateUrl: './job-posting-list-container.component.html',
  styleUrls: ['./job-posting-list-container.component.scss']
})
export class JobPostingListContainerComponent {
  jobPostings$: Observable<JobPosting[]>;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadJobPostings());
    this.jobPostings$ = this.store.select(selectAllJobPostings);
  }
}
