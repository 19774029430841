import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductAttributeValue } from '../../models/product-attribute-value.interface';
import { AttributeOption } from '../../models/attribute-option.interface';
import { Observable, debounceTime } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductClassState } from '../../store/product-class.state';
import { selectAttributeOptionsByCode } from '../../store/product-class.selectors';

@Component({
  selector: 'app-product-attribute-value-editor',
  templateUrl: './product-attribute-value-editor.component.html',
  styleUrls: ['./product-attribute-value-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductAttributeValueEditorComponent implements OnInit {
  @Input() value: ProductAttributeValue;
  productAttributeOptions: AttributeOption[];
  @Output() update = new EventEmitter<ProductAttributeValue>();
  attributeOption$: Observable<AttributeOption[]>;
  editForm: FormGroup;

  constructor(private store: Store<ProductClassState>, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    this.getAttributeOptionsByCode(this.value.code);
    this.editForm.valueChanges.subscribe(() => this.onChange());
  }

  initForm() {
    let adjValue: any = this.value.value;
    if (this.value.type === 'multi_option' || this.value.type === 'option') {
      adjValue = this.value.value_slug;
    } else if (this.value.type === 'boolean') {
      adjValue = this.value.value.toLowerCase() === 'true';
    }
    this.editForm = this.fb.group({
      formValue: [adjValue, this.getValidators()]
    });
  }

  getValidators() {
    const validators = [];
    validators.push(Validators.required);
    return validators;
  }

  getAttributeOptionsByCode(code: string) {
    this.attributeOption$ = this.store.select(selectAttributeOptionsByCode(code));
    this.attributeOption$.subscribe((options) => {
      this.productAttributeOptions = options;
      if (this.value.type === 'option' || this.value.type === 'multi_option') {
        const currentValue = this.value.type === 'multi_option' ? (this.value.value_slug || []) : this.value.value_slug;
        this.editForm.controls['formValue'].setValue(currentValue);
      }
    });
  }

  onChange() {
    if (this.editForm.valid) {
      let updatedValueSlug = this.editForm.value.formValue;
      let updatedValue: any = updatedValueSlug;

      if (this.value.type === 'boolean') {
        updatedValue = updatedValueSlug ? 'True' : 'False';
        updatedValueSlug = updatedValue;
      } else if (this.value.type === 'option' || this.value.type === 'multi_option') {
        updatedValue = this.productAttributeOptions.find(option => option.option_slug === updatedValueSlug)?.option;
        if (this.value.type === 'multi_option') {
          updatedValue = updatedValueSlug.map(slug => this.productAttributeOptions.find(option => option.option_slug === slug)?.option);
        }
      }

      const updatedAttributeValue: ProductAttributeValue = { ...this.value, value_slug: updatedValueSlug, value: updatedValue };
      this.update.emit(updatedAttributeValue);
    }
  }
}
