<div class="hospital-services-card">
    <div class="title">
        <img [src]="distributor.logo" alt="{{distributor.name}}">
        <h3>
            {{distributor.name}}
        </h3>
        <h6>{{distributor.location}}</h6>
    </div>
    <p>{{distributor.description}}</p>
    <div class="distributor-details">
        <div class="distributor-address">
            <div *ngIf="distributor.address.line1">{{distributor.address.line1}}</div>
            <div *ngIf="distributor.address.line2">{{distributor.address.line2}}</div>
            <div *ngIf="distributor.address.line3">{{distributor.address.line3}}</div>
            <div *ngIf="distributor.address.line4">{{distributor.address.line4}}</div>
            <div *ngIf="distributor.address.country.printable_name">{{distributor.address.country.printable_name}}</div>
        </div>
        <div class="distributor-contact">
            <div *ngIf="distributor.telephone">P: <a href="tel:{{distributor.telephone}}">{{distributor.telephone}}</a>
            </div>
            <div *ngIf="distributor.fax">F: {{distributor.fax}}</div>
            <div *ngIf="distributor.email">E: <a href="mailto:{{distributor.email}}">{{distributor.email}}</a></div>
            <div *ngIf="distributor.website">W: <a href="{{distributor.website}}">{{distributor.website}}</a></div>
        </div>
    </div>
</div>