import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { getSuccessMessage } from 'src/app/features/appointment-scheduler/store/appointment-scheduler.selectors';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { CustomItem, CustomItemClass } from 'src/app/shared/models/custom-item.interface';
import { DemoRequest } from 'src/app/shared/models/demo-request.interface';
import * as DemoRequestActions from 'src/app/features/appointment-scheduler/store/appointment-scheduler.actions'
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { IPService } from 'src/app/core/services/ip.service';

@Component({
  selector: 'app-demonstrations',
  templateUrl: './demonstrations.component.html',
  styleUrls: ['./demonstrations.component.scss']
})
export class DemonstrationsComponent implements OnInit {
  public customItems: CustomItem[];
  customOwlOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: false
  }

  pageName: string;
  pageUri: string;
  successMessage$: Observable<string | null>;
  submitted: boolean = false;
  ipAddress:string; 
  getHubSpotUTK() {
    return this.cookieService.get('hubspotutk');
  }

  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  } 

  onFormSubmit(demoRequest: DemoRequest): void {
    const hutkValue = this.getHubSpotUTK();

    const contextData: ContextData = {
      ipAddress: this.ipAddress,
      pageName: this.document.title,
      pageUri: this.location.href,
      sourceComponent: 'DemonstrationsComponentForm'
    };

    if (hutkValue) {
      contextData.hutk = hutkValue;
    }
    this.store.dispatch(
      DemoRequestActions.addDemoRequest({ demoRequest, contextData })
    );
    this.submitted = true;
  }
  constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: any,
    private location: PlatformLocation,
    private cookieService: CookieService,
    private ip:IPService
  ) {
    this.successMessage$ = store.select(getSuccessMessage);
  }

  private getDefaultCustomItems(): CustomItem[] {
    return [
      new CustomItemClass({ name: 'Test Drive It', description: 'Test drive Scinomix automation in a pressure-free environment to see if it is a true fit for your needs' }),
      new CustomItemClass({ name: 'Convenient', description: "Our virtual demonstration lab contains multiple HD cameras positioned at just the right angles and close-ups for a perfect viewing experience." }),
      new CustomItemClass({ name: 'No Pressure', description: 'All demonstrations designed to be informational, free of charge, and with no pushy sales.' }),
    ];
  }


  ngOnInit() {
    this.getIP(); 
    if (!this.customItems) {
      this.customItems = this.getDefaultCustomItems()
    }
  }
}
