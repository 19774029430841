import { createReducer, on } from "@ngrx/store";
import * as SupportGuideActions from "./support-guide.actions";
import { adapter, initialState } from "./support-guide.state";

export const supportGuideFeatureKey = "supportGuides";

/**
 * Reducer function for the SupportGuide feature state.
 * @param state The current state of the SupportGuide feature.
 * @param action The action being dispatched.
 * @returns The new state of the SupportGuide feature.
 */
export const SupportGuideReducer = createReducer(
  initialState,
  on(SupportGuideActions.loadSupportGuides, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SupportGuideActions.loadSupportGuidesSuccess, (state, { supportGuides }) =>
    adapter.setAll(supportGuides, { ...state, loading: false })
  ),
  on(SupportGuideActions.loadSupportGuidesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(SupportGuideActions.loadSupportGuideById, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SupportGuideActions.loadSupportGuideByIdSuccess, (state, { supportGuide }) =>
    adapter.upsertOne(supportGuide, { ...state, loading: false })
  ),
  on(SupportGuideActions.loadSupportGuideByIdFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(SupportGuideActions.createSupportGuide, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SupportGuideActions.createSupportGuideSuccess, (state, { supportGuide }) =>
    adapter.addOne(supportGuide, { ...state, loading: false })
  ),
  on(SupportGuideActions.createSupportGuideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(SupportGuideActions.updateSupportGuide, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SupportGuideActions.updateSupportGuideSuccess, (state, { supportGuide }) =>
    adapter.upsertOne(supportGuide, { ...state, loading: false })
  ),
  on(SupportGuideActions.updateSupportGuideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(SupportGuideActions.deleteSupportGuide, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SupportGuideActions.deleteSupportGuideSuccess, (state, { id }) =>
    adapter.removeOne(id, { ...state, loading: false })
  ),
  on(SupportGuideActions.deleteSupportGuideFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(SupportGuideActions.saveSupportGuideFormState, (state, { supportGuide }) => ({
    ...state,
    formState: supportGuide,
  })), 
  on(SupportGuideActions.loadSupportGuideFormState, (state) => state),
  on(SupportGuideActions.clearSupportGuideFormState, (state) => ({
    ...state,
    formState: null,
  }))
);
