<div class="row align-items-center">
  <!-- Column for Drag Icon -->
  <div class="col-md-1 text-center">
    <i class="fas fa-grip-vertical" cdkDragHandle></i> <!-- Font Awesome icon for drag handle -->
  </div>

  <!-- Column for Thumbnail -->
  <div class="col-md-3" *ngIf="media?.file_preview && media.type !== 'pdf'">
    <div class="thumbnail-container">
      <div *ngIf="loadingThumbnail" class="spinner-container">
        Generating Thumbnail
        <app-spinner [isVisible]="loadingThumbnail"></app-spinner>
      </div>
      <img [src]="media?.file_preview" class="img-thumbnail" alt="Thumbnail" (load)="onThumbnailLoad()"
        (error)="onThumbnailError()" [class.loaded]="!loadingThumbnail" />
    </div>
  </div>

  <!-- Column for Image Name -->
  <div class="col-md-8">
    {{ media.caption }} <!-- Assuming 'name' is the property holding the image name -->
    <div class="mt-2"> <!-- Margin top for spacing -->
      <div class="mb-2 align-center"><button type="button" class="btn btn-primary btn-sm me-1"
          (click)="editItem(media)">Edit</button> <!-- me-1 for margin end -->
        <button type="button" class="btn btn-warning btn-sm me-1" (click)="disassociate(media)">Disassociate</button>
      </div>
      <div><button type="button" class="btn btn-link text-center" (click)="onDelete(media.id)">Remove
          Permanently</button></div>
    </div>
  </div>
</div>