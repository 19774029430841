// File: services/support-guide.service.ts

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PaginationInfo } from "@fluentllc/core";
import { Observable, map } from "rxjs";
import { SupportGuide } from "src/app/shared/models/support-guide.interface";

@Injectable({
  providedIn: "root",
})
export class SupportGuideService {
  private readonly apiUrl = "/api/v1/support-guides";

  constructor(private http: HttpClient) {}

  getSupportGuides(): Observable<SupportGuide[]> {
    const headers = new HttpHeaders().set("Cache-Control", "no-cache").set("Pragma", "no-cache");
    return this.http.get<SupportGuide[]>(this.apiUrl, { headers });
  }

  getSupportGuideById(id: number): Observable<SupportGuide> {
    return this.http.get<SupportGuide>(`${this.apiUrl}/${id}`);
  }
  createSupportGuide(supportGuide: SupportGuide, productSlugs?: string[], attachedPdf?: File): Observable<SupportGuide> {
    const formData = new FormData();

    // Append file if provided
    if (attachedPdf) {
      formData.append("attached_pdf", attachedPdf);
    }

    if (productSlugs) {
        formData.append("product_slugs", JSON.stringify(productSlugs));
    }

    // Append media object properties
    // Note: This is a simplistic approach. You might need to handle different data types or structures.
    Object.keys(supportGuide).forEach((key) => {
      if (supportGuide.hasOwnProperty(key) && key !== "attached_pdf" && supportGuide[key] !== null) {
        formData.append(key, supportGuide[key]);
      }
    });

    return this.http.post<SupportGuide>(this.apiUrl, formData);
  }
  updateSupportGuide(supportGuide: SupportGuide, productSlugs?: string[], attachedPdf?: File): Observable<SupportGuide> {
    const formData = new FormData();

    // Append file if provided
    if (attachedPdf) {
      formData.append("attached_pdf", attachedPdf);
    }

    if (productSlugs) {
        formData.append("product_slugs", JSON.stringify(productSlugs));
    }

    // Append media object properties
    // Note: This is a simplistic approach. You might need to handle different data types or structures.
    Object.keys(supportGuide).forEach((key) => {
      if (supportGuide.hasOwnProperty(key) && key !== "attached_pdf" && supportGuide[key] !== null) {
        formData.append(key, supportGuide[key]);
      }
    });
    return this.http.patch<SupportGuide>(`${this.apiUrl}/${supportGuide.id}`, formData);
  }
  deleteSupportGuide(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${id}`);
  }

  getPaginatorSupportGuides(
    page: number,
    pageSize: number
  ): Observable<{ supportGuides: SupportGuide[]; pagination: PaginationInfo }> {
    const params = new HttpParams().set("page", page.toString()).set("page_size", pageSize.toString());

    return this.http.get<SupportGuide[]>(this.apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const supportGuides = response.body;
        const pagination: PaginationInfo = {
          paginated: response.headers.get("x-paginated") === "true",
          paginatedBy: response.headers.get("x-paginated-by"),
          currentPage: response.headers.get("x-pagination-current"),
          nextPage: response.headers.get("x-pagination-next"),
          count: response.headers.get("x-pagination-count"),
        };
        return { supportGuides, pagination };
      })
    );
  }
}
