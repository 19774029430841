import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../models/product.interface';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit{
  ngOnInit(): void {
    // for(let product of this.relatedProducts){
    //   console.log(product)
    // }
  }
  @Input() teaser: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() relatedProducts: Product[] = [];
}
