import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { GtmService } from 'src/app/core/services/gtm.service';
import { OffsidebarService } from 'src/app/core/services/offsidebar.service';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { MetaTagsState } from 'src/app/features/seo/store/meta-tags.state';
import { ButtonClickEvent } from 'src/app/shared/store/button/button.actions';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  title = 'Scinomix Is Your Trusted Partner in ';
  subTitle = 'Lab Automation';
  description = 'Description';
  vrSource: string;
  fallbackSource: string;
  onButtonClicked(event: ButtonClickEvent) {
    // console.log('Button clicked:', event);
  }

  constructor(
    public offsidebarService: OffsidebarService,
    private metaTagsService: MetaTagsService,
    private schemaService: SchemaService,
    private gtmService: GtmService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.vrSource = '/assets/vr/vxl-vr.jpg';
    this.fallbackSource = '/assets/images/vxl_hero.jpg';
    this.description = `<p>At Scinomix, we are pioneers in providing innovative lab solutions tailored to meet your lab's unique requirements. With years of experience in lab automation, we have emerged as industry leaders in automated tube and microplate labeling technology.</p>
    <p>Driven by our commitment to excellence, we constantly strive to revolutionize lab work, enabling scientists and researchers to focus on what matters most - their groundbreaking discoveries. Our cutting-edge equipment, combined with our expertise in cell biology and liquid handling, allows us to deliver benchtop automation solutions that enhance efficiency, accuracy, and productivity in your lab.</p>
    <p>Partner with us and experience the power of automation in your lab. Let us empower you to push boundaries, unlock new possibilities, and make a lasting impact in your field.</p>`;
    const newMetaTags = {
      title: 'Scinomix | Laboratory Automation Solutions ',
      description:
        'Scinomix provides laboratory automation solutions to customers in the Life Science Industry all over the world. We strive to be a leading provider in our field by committing to quality, reliability, value, and customer service. Call us today at 314-298-9800.',
      keywords:
        'laboratory automation solutions, lab equipment, lab productivity',
    };
    this.metaTagsService.updateMetaTags(newMetaTags);

    const schemaData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Scinomix',
      url: 'https://www.scinomix.com',
      logo: 'https://www.scinomix.com/logo.png',
    };
    this.schemaService.updateSchema(schemaData);
  }

  onCtaButtonClick() {
    // console.log("Hero clicked")
  }

  openOffsidebar() {
    // console.log("OPEN");
    const componentConfig = {
      componentType: 'multiStepForm',
      data: {
        // Pass any data required by the dynamic component
        // For example, pass the initial form data
        formData: {
          name: '',
          email: '',
          phoneNumber: '',
        },
      },
    };

    this.offsidebarService.open(componentConfig);
  }

  closeOffsidebar() {
    // console.log("CLOSE")
    this.offsidebarService.close();
  }
}
