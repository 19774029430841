import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ROICalculatorRoutingModule } from './roi-calculator-routing.module';
import { ROICalculatorContainerComponent } from './containers/roicalculator-container/roicalculator-container.component';
import { ROICalculatorComponent } from './components/roicalculator/roicalculator.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ROICalculatorContainerComponent,
    ROICalculatorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ROICalculatorRoutingModule,
  ],
})
export class ROICalculatorModule { }
