import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoryFeatureKey } from './category.reducer';
import { adapter, CategoryState } from './category.state';
import { Category } from '../models/category.interface';

export const selectCategoryState = createFeatureSelector <CategoryState > (CategoryFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectCategoryIds = createSelector(
    selectCategoryState,
    selectIds
);

export const selectCategoryEntities = createSelector(
    selectCategoryState,
    selectEntities
);

export const selectAllCategories = createSelector(
    selectCategoryState,
    selectAll
);

export const selectCategoryTotal = createSelector(
    selectCategoryState,
    selectTotal
);

export const selectCategoryById = (id: number) =>
    createSelector(
        selectCategoryEntities,
        (entities) => entities[id]
    );

export const selectCategoryLoading = createSelector(
    selectCategoryState,
    (state) => state.loading
);

export const selectCategoryError = createSelector(
    selectCategoryState,
    (state) => state.error
);

export const selectHierarchicalCategories = createSelector(
    selectAll,
    (categories) => buildHierarchy(categories)
  );

function buildHierarchy(categories: Category[]): Category[] {
    let categoryMap = new Map<string, Category & { children: Category[] }>();
  
    // Initialize every category with an empty children array
    categories.forEach(category => {
      categoryMap.set(category.path, { ...category, children: [] });
    });
  
    // Build the tree based on path deduction
    categories.forEach(category => {
        const parentPath = getParentPath(category.path);
        console.log(`Category: ${category.id}, Path: ${category.path}, ParentPath: ${parentPath}`);
        if (parentPath) {
          const parentCategory = categoryMap.get(parentPath);
          if (parentCategory) {
            parentCategory.children.push(categoryMap.get(category.path));
          }
        }
      });
      
    // Extract the roots (categories whose path matches the minimum length of top-level category paths)
    const rootPathLength = Math.min(...Array.from(categoryMap.keys()).map(key => key.length));
    return [...categoryMap.values()].filter(cat => cat.path.length === rootPathLength);
  }
  
  function getParentPath(path) {
    // Example assuming each segment is always 4 characters, but this can be adjusted
    if (path.length > 4) {
      console.log(path.substring(0, path.lastIndexOf(path.substring(0, path.length - 4))))
      return path.substring(0, path.lastIndexOf(path.substring(0, path.length - 4)));
    }
    return null;  // This is the root level
  }

  export const selectFlattenedCategories = createSelector(selectAllCategories, (categories) => {
    function flattenCategories(categories: Category[]): Category[] {
      return categories.reduce((acc, category) => {
        acc.push(category);
        if (category.children) {
          acc.push(...flattenCategories(category.children));
        }
        return acc;
      }, [] as Category[]);
    }
  
    return flattenCategories(categories);
  });

  export const selectFormState = createSelector(selectCategoryState, (state: CategoryState) => state.formState);

  export const selectCategoryForEditingById = (id: number | string) => createSelector(
    selectFlattenedCategories,
    (categories) => {
    //   console.log("All categories (flattened):", categories);  // Log all categories to inspect their structure and IDs
    //   console.log("Searching for ID:", typeof(id));                    // Log the ID being searched for
  
      const foundCategory = categories.find(category => {
        const categoryIDMatch = category.id === id;
        // console.log(`Checking category ID: ${category.id} (type: ${typeof category.id}) against ${id} (type: ${typeof id}) -> Match: ${categoryIDMatch}`);
        return categoryIDMatch;
      });
  
    //   console.log("Found category:", foundCategory);           // Log the found category, if any
      return foundCategory;
    }
  );
