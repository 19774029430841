<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <app-title-hashtag [hashtagSubtitle]="subtitle"></app-title-hashtag>
                <app-title [title]="title" [titleHighlight]="titleHighlight"></app-title>
                <p [innerHTML]="description"></p>
                <div class="row">
                    <app-bullet-features-container [customItems]="customItems"></app-bullet-features-container>
                </div>
            </div>
        </div> <!-- This is the correct location for the closing div tag -->
        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img [src]="heroImage" [alt]="title" class="img-fluid">
            </div>
        </div>
    </div>
</div>