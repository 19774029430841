<div class="card card-default">
    <div class="card-body">
        <p class="lead">{{title}}</p>
        <div [formGroup]="tagsForm">
            <div formArrayName="selectedTags" class="tag-cloud">
                <!-- Iterating over each control in the FormArray -->
                <div *ngFor="let tagControl of selectedTags.controls; let i = index" class="tag-item badge bg-primary me-2 mb-2">
                    <!-- Displaying the tag name using the ID from tagControl.value -->
                    <span>{{ findTagName(tagControl.value) }}</span>
                    <!-- Button to remove a tag from the selection -->
                    <button class="btn-close btn-close-white ms-2" type="button" (click)="removeTag(i)"></button>
                </div>
            </div>
            <!-- Dropdown to add new tags to the selection -->
            <select class="form-select mt-3" (change)="addTagFromEvent($event)">
                <option value="">Select from dropdown</option>
                <!-- Option for each available tag, showing tag name and using tag ID as the value -->
                <option *ngFor="let tag of availableTags" [value]="tag.id">{{ tag.name }}</option>
            </select>
        </div>
    </div>
</div>
