// src/app/shared/modules/multi-step-form/store/multi-step-form.reducer.ts

import { createReducer, on } from '@ngrx/store';
import * as MultiStepFormActions from './multi-step-form.actions';
import { initialMultiStepFormState, MultiStepFormState } from './multi-step-form.state';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const adapter = createEntityAdapter<any>();
export const multiStepFormFeatureKey = "multiStepForm";

export const multiStepFormReducer = createReducer(
    initialMultiStepFormState,
    on(MultiStepFormActions.setCurrentStep, (state, { step }) => {
        return { ...state, currentStep: step };
    }),
    on(MultiStepFormActions.completeStep, (state, { step, data }) => {
        // Check if the step is already completed; if so, return the current state
        if (state.completedSteps.includes(step)) {
            return state;
        }

        const updatedFormData = { ...state.formData, ...data };

        // Create a new completedSteps array without duplicates
        const updatedCompletedSteps = [...new Set([...state.completedSteps, step])];

        return {
            ...state,
            completedSteps: updatedCompletedSteps,
            formData: updatedFormData,
        };
    }),
    on(MultiStepFormActions.updateFormData, (state, { step, data }) => {
        const updatedFormData = { ...state.formData };
        updatedFormData[step] = { ...updatedFormData[step], ...data };

        return { ...state, formData: updatedFormData };
    }),
    on(MultiStepFormActions.resetMultiStepForm, () => {
        return initialMultiStepFormState;
    }),
    on(MultiStepFormActions.uncompleteStep, (state, { step }) => {
        const completedSteps = [...state.completedSteps];
        const index = completedSteps.indexOf(step);
        if (index > -1) {
            completedSteps.splice(index, 1);
        }

        // Remove the following lines:
        // const formData = { ...state.formData };
        // const stepKey = Object.keys(formData)[step - 1];
        // if (stepKey) {
        //     delete formData[stepKey];
        // }

        // Update this line to use the existing formData without modifications
        return { ...state, completedSteps, formData: state.formData };
    }),
    on(MultiStepFormActions.setSteps, (state, { steps }) => ({ ...state, steps })),
    // on(MultiStepFormActions.updateModel, (state, action) => {
    //     const updatedModel = state.model
    //         ? adapter.updateOne({ id: 'model', changes: action.model }, state.model)
    //         : state.model;
    //     return { ...state, model: updatedModel };
    // })

);
