import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomItem } from '../../models/custom-item.interface';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnChanges {
  @Input() subtitle: string;
  @Input() title: string;
  @Input() titleHighlight: string;
  @Input() description: string;
  @Input() customItems: CustomItem[] = [];
  @Input() heroImage: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.subtitle) {
      this.subtitle = 'Our Commitment to Service';
    }

    if (!this.title) {
      this.title = 'Just Ask Our ';
    }

    if (!this.titleHighlight) {
      this.titleHighlight = 'Customers';
    }

    if (!this.description) {
      this.description = 'We strongly value each of our customers, and exceeding customer expectations is the motivation that drives our business. Our plans to continually improve quality and offer innovative solutions play a huge role in our vision for the future.';
    }

    if (!this.heroImage) {
      this.heroImage = '/assets/images/scinomix_customer_service_interaction.jpg';

    }
  }
}