import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() title: string;
  @Input() titleHighlight: string;
  private _headerType: string = 'h1';

  @Input()
  set headerType(type: string) {
    if (['h1', 'h2', 'h3'].includes(type)) {
      this._headerType = type;
    } else {
      console.warn(`Invalid header type "${type}". Defaulting to "h1".`);
    }
  }

  get headerType(): string {
    return this._headerType;
  }
}
