// store/effects/testimonials.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TestimonialsService } from 'src/app/core/services/testimonials.service';
import * as TestimonialsAction from './testimonials.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class TestimonialsEffects {
    loadTestimonials$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TestimonialsAction.loadTestimonials),
            switchMap(() =>
                this.testimonialsService.getTestimonials().pipe(
                    map((testimonials) => TestimonialsAction.loadTestimonialsSuccess({ testimonials })),
                    catchError((error) => of(TestimonialsAction.loadTestimonialsFailure({ error })))
                )
            )
        )
    );

    loadTestimonialsByProductSlug$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TestimonialsAction.loadTestimonialsByProductSlug),
            switchMap(({ slug }) =>
                this.testimonialsService.getTestimonialsByProductSlug(slug).pipe(
                    map((testimonials) => TestimonialsAction.loadTestimonialsByProductSlugSuccess({ testimonials })),
                    catchError((error) => of(TestimonialsAction.loadTestimonialsByProductSlugFailure({ error })))
                )
            )
        )
    );

    constructor(private actions$: Actions, private testimonialsService: TestimonialsService) { }
}
