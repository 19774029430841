<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <div class="pt-30">
                <h1>Troubleshooting</h1>
            </div>
            <div class="ptb-30">
                <p>If you cannot find what you are looking for, please do not hesitate to contact our service
                    department.</p>
            </div>
        </div>
        <div class="col-lg-4">
            <app-services-support-sidebar-navigation-container></app-services-support-sidebar-navigation-container>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="ptb-30">
                <table class="table table-striped table-small">
                    <thead>
                        <tr>
                            <th>Part Number</th>
                            <th>Title</th>
                            <th>Available Formats</th>
                            <th *ngFor="let slug of productSlugs">{{ slug.name }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let guide of supportGuides">
                            <td>{{ guide.part_number }}</td>
                            <td>{{ guide.title }}</td>
                            <td><a [href]="guide.attached_pdf" target="_blank">
                                    <fa-icon [icon]="pdfIcon"></fa-icon> PDF
                                </a></td>
                            <td *ngFor="let product of productSlugs">
                                <fa-icon *ngIf="guide.products.includes(product.slug)" [icon]="checkIcon"
                                    [class.checked]="guide.products.includes(product.slug)"
                                    [class.unchecked]="!guide.products.includes(product.slug)"></fa-icon>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>