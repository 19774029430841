import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Feature } from '../../models/feature.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-feature-admin',
  templateUrl: './feature-admin.component.html',
  styleUrls: ['./feature-admin.component.scss']
})
export class FeatureAdminComponent implements OnInit{

  @Input() features: Feature[];
  @Output() updateFeatures = new EventEmitter<Feature[]>();

  featuresForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.featuresForm = this.fb.group({
      features: this.fb.array(this.features.map(feature => this.createFeaturesFormGroup(feature)))
    });
  }

  private createFeaturesFormGroup(feature: Feature): FormGroup {
    return this.fb.group({
      id: [feature.id],
      title: [feature.title],
      description: [feature.description],
      display_order: [feature.display_order]
    });
  }

  get featuresArray(): FormArray {
    return this.featuresForm.get('features') as FormArray;
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.featuresArray.controls, event.previousIndex, event.currentIndex);
    this.featuresArray.controls.forEach((ctrl, idx) => {
      ctrl.patchValue({ display_order: idx });
    });
    this.emitChanges();
  }

  
  removeFeature(index: number): void {
    this.featuresArray.removeAt(index);
  
    this.featuresArray.controls.forEach((control, idx) => {
      control.patchValue({ display_order: idx });
    });
  
    this.emitChanges();
  
  }

  addFeature(): void {
    // Calculate the display_order based on the current length of the specificationsArray
    const displayOrder = this.featuresArray.length;
  
    // Create a new specification FormGroup with the calculated display_order
    const newFeat = this.fb.group({
      id: [null], // Assuming 'id' is not mandatory when creating a new spec
      title: ['', Validators.required], // Add validators as needed
      description: ['', Validators.required],
      display_order: [displayOrder] // Set to the end of the list
    });
  
    // Add the new specification FormGroup to the FormArray
    this.featuresArray.push(newFeat);
    this.emitChanges();
  }


  emitChanges(): void {
    this.updateFeatures.emit(this.featuresArray.value);
  }
  

  // Method to trigger when specifications are updated
  onSave(): void {
    this.updateFeatures.emit(this.featuresArray.value);
  }

}
