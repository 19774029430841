import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
// import { VrViewerEffects } from './vr-viewer.effects';
import * as vrViewer from './vr-viewer.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(vrViewer.vrViewerFeatureKey, vrViewer.vrReducer),
    // EffectsModule.forFeature([VrViewerEffects]),
  ],
})
export class VrViewerStoreModule { }