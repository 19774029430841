import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SeoData } from '../../models/seo-data.interface';


@Component({
  selector: 'app-meta-data',
  templateUrl: './meta-data.component.html',
  styleUrls: ['./meta-data.component.scss']
})
export class MetaDataComponent {
  @Input() set seoData(value: Partial<SeoData>) {
    if(value) {
      this.seoForm.patchValue(value);
    }
  }
  @Output() seoDataChange = new EventEmitter<Partial<SeoData>>();

  seoForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.seoForm = this.fb.group({
      meta_title: '',
      meta_description: ''
    });

    this.seoForm.valueChanges.subscribe(value => {
      this.seoDataChange.emit(value);
    });
  }
}
