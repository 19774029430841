import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, exhaustMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromAuth from '@fluentllc/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    const providerName = this.getProviderName(req);

    if (!providerName) {
      return this.store.select(fromAuth.selectAuthToken).pipe(
        take(1),
        exhaustMap((token) => {
          let authReq = req;
          if (token) {
            authReq = req.clone({
              setHeaders: { 
                Authorization: `Bearer ${token}`,
                'Cache-Control': headers.get('Cache-Control') || '',
                'Pragma': headers.get('Pragma') || '',
              },
            });
          } else {
            // Clone the request to add the cache control headers if there's no token
            authReq = req.clone({
              setHeaders: {
                'Cache-Control': headers.get('Cache-Control') || '',
                'Pragma': headers.get('Pragma') || '',
              }
            });
          }
          return next.handle(authReq);
        })
      );
    }

    // Use the new factory function to create the selector for provider-specific tokens
    const providerTokenSelector = fromAuth.selectProviderTokenFactory(providerName);

    return this.store.select(providerTokenSelector).pipe(
      take(1),
      exhaustMap((token) => {
        let authReq = req;
        if (token) {
          authReq = req.clone({
            setHeaders: { 
              Authorization: `Bearer ${token}`,
              'Cache-Control': headers.get('Cache-Control') || '',
              'Pragma': headers.get('Pragma') || '',
            },
          });
        } else {
          // Clone the request to add the cache control headers if there's no provider-specific token
          authReq = req.clone({
            setHeaders: {
              'Cache-Control': headers.get('Cache-Control') || '',
              'Pragma': headers.get('Pragma') || '',
            }
          });
        }
        return next.handle(authReq);
      })
    );
  }

  private getProviderName(req: HttpRequest<any>): string | null {
    if (req.url.startsWith('/hubspotapi') || req.url.startsWith('https://api.hubapi.com')) {
      return 'hubspot';
    }

    // Add more conditions here for other providers, if necessary

    return null;
  }
}
