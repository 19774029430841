<ng-template #dynamicContent>
    <!-- Add the dynamic content here -->

</ng-template>

<!-- <button (click)="openOffsidebar()">Open Offsidebar</button> -->
<!-- <app-hero-container></app-hero-container> -->
<app-background-video-hero-container class="background-video-container"
    [videoSource]="'assets/videos/background_20230626.mp4'"
    (ctaButtonClick)="onCtaButtonClick()"></app-background-video-hero-container>
<div class="pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-sm-12">
                <app-title-description-hashtag-container [title]="title" [titleHighlight]="subTitle" headerType="h3"
                    [description]="description"></app-title-description-hashtag-container>

            </div>
            <div class="col-lg-7 col-sm-12">
                <a href="scinomix-experience"><img src="/assets/images/scinomix_preview.jpg" class="img-fluid" width="914"
                        height="558" /></a>
            </div>
        </div>
        <div class="row pt-100">
            <div class="col-lg-12">
                <app-testimonials-list-container title="Scinomix"
                    subhead="Testimonials"></app-testimonials-list-container>
            </div>
        </div>
    </div>
</div>