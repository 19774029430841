// offsidebar.component.ts
import {
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { DynamicComponentService } from 'src/app/core/services/dynamic-component.service';
import { OffsidebarContentDirective } from '../../directives/offsidebar-content.directive';
import { OffsidebarState } from '../../store/offsidebar/offsidebar.state';
import * as OffsidebarActions from './../../store/offsidebar/offsidebar.actions';

@Component({
  selector: 'app-offsidebar',
  templateUrl: './offsidebar.component.html',
  styleUrls: ['./offsidebar.component.scss'],
})
export class OffsidebarComponent implements OnInit, OnDestroy {
  @Input() isVisible: boolean;
  @Input() contentType: string;
  @Input() data: any;

  @ViewChild(OffsidebarContentDirective, { static: true })
  offsidebarContentHost: OffsidebarContentDirective;

  private destroy$ = new Subject<void>();
  private componentRef: any;

  constructor(private injector: Injector, private store: Store<{ offsidebar: OffsidebarState }>, private dynamicComponentService: DynamicComponentService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isVisible && this.isVisible || changes.contentType) {
      this.loadComponent();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadComponent(): void {
    if (!this.contentType) {
      return;
    }

    const componentType = this.getComponentType(this.contentType);

    const viewContainerRef = this.offsidebarContentHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = viewContainerRef.createComponent(componentType, { injector: this.injector });

    // Store the component reference
    this.componentRef = componentFactory;

    // Pass data to the dynamically loaded component if necessary
    if (this.data) {
      this.componentRef.instance.data = this.data;
    }
  }

  getComponentType(type: string): any {
    return this.dynamicComponentService.getComponentType(type);
  }

  closeOffsidebar(): void {
    this.store.dispatch(OffsidebarActions.hideOffsidebar());
  }
}
