import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductRequestState } from './product-request.state';

const getProductRequestState = createFeatureSelector<ProductRequestState>('productRequests');

export const getProductRequests = createSelector(
    getProductRequestState,
    (state: ProductRequestState) => state.productRequests
);

export const getSuccessMessage = createSelector(
    getProductRequestState,
    (state: ProductRequestState) => state.successMessage
);