import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadProduct, loadProducts } from '../../store/products.actions';
import { selectCurrentProduct } from '../../store/products.selectors';
import { tap, takeUntil, switchMap, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { loadMedia, loadMediaByTags } from '../../store/media.actions';
import { Media } from '../../models/media.interface';
import { selectCarouselMediaList, selectMediaListByType } from '../../store/media.selectors';
import { Product } from '../../models/product.interface';
import { OptionHashOption } from 'src/app/features/products/models/option-hash.interface';
import { selectOptionHash } from '../../store/option-hash.selectors';

import {
  selectFilteredProduct,
  selectSelectedOptions,
  selectIsOptionAvailable,
  selectIsOptionSelected,
  selectIsOptionDisabled,
  selectFilteredOptions,
  selectVariationOptions,
  selectFilteredProductVariations,
} from '../../store/product-filter.selectors';
import { updateSelectedOptions } from '../../store/product-filter.actions';
import { Dictionary } from '@ngrx/entity';

@Component({
  selector: 'app-product-detail-container',
  templateUrl: './product-detail-container.component.html',
  styleUrls: ['./product-detail-container.component.scss']
})
export class ProductDetailContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  product$: Observable<Product>;
  media$: Observable<Media[]>;
  resources$: Observable<Media[]>;
  slug: string;
  optionHash$: Observable<Dictionary<OptionHashOption>>;
  filteredProduct$: Observable<Product[]>;
  selectedOptions$: Observable<{ [optionCode: string]: string }>;
  isOptionAvailable$: (optionCode: string, optionValue: string) => Observable<boolean>;
  isOptionSelected$: (optionCode: string, optionValue: string) => Observable<boolean>;
  isOptionDisabled$: (optionCode: string, optionValue: string) => Observable<boolean>;
  filteredOptions$: Observable<{ [optionCode: string]: string[] }>;
  variationOptions$: Observable<{ [optionCode: string]: string[] }>;
  filteredProductVariations$: Observable<Product[]>;
  isTube: boolean = false;
  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.slug = this.route.snapshot.paramMap.get('slug');

  }

  ngOnInit(): void {
    this.store.dispatch(loadProduct({ slug: this.slug }))
    this.product$ = this.store.select(selectCurrentProduct).pipe(takeUntil(this.destroy$));
    this.store.dispatch(loadMedia({ slug: this.slug }));
    this.media$ = this.store.select(selectCarouselMediaList);
    // this.product$.subscribe(product => console.log(product))
    this.resources$ = this.store.select(selectMediaListByType, { mediaType: 'pdf' });
    this.optionHash$ = this.store.select(selectOptionHash);

    this.filteredProduct$ = this.store.select(selectFilteredProduct);
    this.selectedOptions$ = this.store.select(selectSelectedOptions);
    this.product$.subscribe(product => {
      this.isTube = product?.product_class_details?.slug === 'tube';
      // console.log(this.isTube)
    })
    this.isOptionAvailable$ = (optionCode: string, optionValue: string) =>
      this.store.select(selectIsOptionAvailable(optionCode, optionValue));

    this.isOptionSelected$ = (optionCode: string, optionValue: string) =>
      this.store.select(selectIsOptionSelected(optionCode, optionValue));

    this.isOptionDisabled$ = (optionCode: string, optionValue: string) =>
      this.store.select(selectIsOptionDisabled(optionCode, optionValue));
    
    this.filteredOptions$ = this.store.select(selectFilteredOptions);
    this.variationOptions$ = this.store.select(selectVariationOptions);
    this.filteredProductVariations$ = this.store.select(selectFilteredProductVariations);
    // console.log(this.slug)


    // this.store.select(selectMediaList).pipe(
    //   take(1),
    //   tap(() => {
    //     this.store.dispatch(loadMedia({ slug: this.slug }));
    //   }),
    //   switchMap(() => this.store.select(selectMediaList).pipe(take(1))),
    //   tap(() => {
    //     const params = `characteristics=specification&product_tags=${this.slug}`;
    //     this.store.dispatch(loadMediaByTags({ params }));
    //   })
    // ).subscribe();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOptionChange(option: { optionCode: string, optionValueSlug: string }): void {
    // console.log(option)
    // this.store.dispatch(updateSelectedOptions({ optionCode: option.optionCode, optionValueSlug: option.optionValueSlug }));
  }


  // Additional methods and logic related to the product variation filter can be implemented here
}
