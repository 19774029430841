import { createReducer, on } from "@ngrx/store";
import * as JobPostingActions from "./job-posting.actions";
import { adapter, initialState } from "./job-posting.state";

export const JobPostingFeatureKey = "jobPostings";

export const JobPostingReducer = createReducer(
  initialState,
  on(JobPostingActions.loadJobPostings, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(JobPostingActions.loadJobPostingsSuccess, (state, { jobPostings }) =>
    adapter.setAll(jobPostings, { ...state, loading: false })
  ),
  on(JobPostingActions.loadJobPostingsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(JobPostingActions.loadJobPostingBySlug, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(JobPostingActions.loadJobPostingBySlugSuccess, (state, { jobPosting }) => {
    return adapter.upsertOne(jobPosting, { ...state, loading: false });
  }),
  on(JobPostingActions.loadJobPostingBySlugFailure, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
  on(JobPostingActions.loadPaginatedJobPostingsSuccess, (state, { jobPostings, total, paginationInfo }) => {
    return {
      ...state,
      ...adapter.setAll(jobPostings, state),
      total: total, // Update the total number of posts (if provided by the API)
      paginationInfo: paginationInfo,
      loading: false,
    };
  }),
  on(JobPostingActions.createJobPostingSuccess, (state, { jobPosting }) =>
    adapter.addOne(jobPosting, { ...state, loading: false })
  ),
  on(JobPostingActions.createJobPostingFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(JobPostingActions.updateJobPostingSuccess, (state, { jobPosting }) => adapter.upsertOne(jobPosting, { ...state, loading: false })),
  on(JobPostingActions.updateJobPostingFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(JobPostingActions.deleteJobPostingSuccess, (state, { id }) => adapter.removeOne(id, { ...state, loading: false })),
  on(JobPostingActions.deleteJobPostingFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(JobPostingActions.saveJobPostingFormState, (state, { jobPosting }) => ({
    ...state,
    jobPostingFormState: jobPosting,
  })),
  on(JobPostingActions.clearJobPostingFormState, (state) => ({
    ...state,
    jobPostingFormState: null,
  })),
  on(JobPostingActions.loadJobPostingFormState, (state) => ({
    ...state
  })),
  on(JobPostingActions.loadJobPostingByIdSuccess, (state, { jobPosting }) => ({
    ...state,
    jobPostingFormState: jobPosting
  })),
);
