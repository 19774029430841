<div id="hero_wrapper">
    <video autoplay muted loop playsinline preload="auto" onloadedmetadata="this.muted = true" id="heroVideo">
        <source [src]="videoSource" type="video/mp4">
        Your browser does not support HTML5 video.
    </video>
    <div class="hero_container">
        <div class="separator"></div>
        <h3>Gain Valuable Time in Your Lab With Automation</h3>
        <div class="spacer"></div>
        <h1 class="ml11">
            Benchtop Automation Solutions to Improve Productivity in Your Lab
        </h1>
        <div id="videoCTA" class="d-flex row align-items-center">
            <div id="watchVideoBtn" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pl-0 widget-area">
                <!-- <app-play-embedded-video-button></app-play-embedded-video-button> -->
                <app-play-embedded-video-button-container
                    title="Scinomix Sci-Print VX Series with VXQ Bulk Tube Feeder and Sci-Pump Peristaltic Pump"
                    vimeoLink="https://player.vimeo.com/video/616122545?h=85a6cbc73d"
                    buttonCta="Watch Our Video"></app-play-embedded-video-button-container>
            </div>
        </div>
    </div>
</div>