import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Distributor } from 'src/app/shared/models/distributor.interface';
import * as DistributorActions from './distributors.actions';
import { adapter, initialState } from './distributors.state';
export const distributorFeatureKey = 'distributors';

export const distributorReducer = createReducer(
    initialState,
    on(DistributorActions.loadDistributors, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(DistributorActions.loadDistributorsSuccess, (state, { distributors }) =>
        adapter.setAll(distributors, { ...state, loading: false })
    ),
    on(DistributorActions.loadDistributorsFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })),
    on(DistributorActions.loadDistributorById, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(DistributorActions.loadDistributorByIdSuccess, (state, { distributor }) =>
        adapter.upsertOne(distributor, { ...state, loading: false })
    ),
    on(DistributorActions.loadDistributorByIdFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }))
);
