import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { MediaEffects } from './media.effects';
import * as fromMedia from './media.reducer';



@NgModule({
    imports: [
        EffectsModule.forFeature([MediaEffects]),
        StoreModule.forFeature(fromMedia.mediaFeatureKey, fromMedia.mediaReducer)],
})
export class MediaStoreModule { }