// src/app/store/post/post.actions.ts
import { createAction, props } from "@ngrx/store";
import { PaginationInfo } from "@fluentllc/core";
import { Post } from "src/app/shared/models/post.interface";

export const loadPosts = createAction("[Post] Load Posts");
export const loadPostsSuccess = createAction("[Post] Load Posts Success", props<{ posts: Post[] }>());
export const loadPostsFailure = createAction("[Post] Load Posts Failure", props<{ error: any }>());

export const loadPostsByBlogId = createAction("[Post] Load Posts By Blog ID", props<{ blogId: number }>());
export const loadPostsByBlogIdSuccess = createAction(
  "[Post] Load Posts By Blog ID Success",
  props<{ posts: Post[] }>()
);
export const loadPostsByBlogIdFailure = createAction("[Post] Load Posts By Blog ID Failure", props<{ error: any }>());

export const loadPostBySlug = createAction("[Post] Load Post By Slug", props<{ slug: string }>());
export const loadPostBySlugSuccess = createAction("[Post] Load Post By Slug Success", props<{ post: Post }>());
export const loadPostBySlugFailure = createAction("[Post] Load Post By Slug Failure", props<{ error: any }>());

export const createPost = createAction("[Post Admin] Create Post", props<{ post: Post, imageFile?: File }>());
export const createPostSuccess = createAction("[Post Admin] Create Post Success", props<{ post: Post, imageFile?: File }>());
export const createPostFailure = createAction("[Post Admin] Create Post Failure", props<{ error: any }>());

export const savePostFormState = createAction("[Post Manage Component] Save Post Form State", props<{ post: Post }>());
export const loadPostFormState = createAction("[Post Manage Component] Load Post Form State");
export const clearPostFormState = createAction("[Post Manage Component] Clear Post Form State");

export const createPostWithKeywords = createAction(
    "[Post Admin] Create Post with Keywords",
    props<{ post: Post; keywords: { create: any[]; edit: any[]; delete: any[] }, imageFile?: File }>()
  );
  
  export const createPostWithKeywordsSuccess = createAction(
    "[Post Admin] Create Post with Keywords Success",
    props<{ post: Post, imageFile?: File }>()
  );
  
  export const createPostWithKeywordsFailure = createAction(
    "[Post Admin] Create Post with Keywords Failure",
    props<{ error: any }>()
  );

export const updatePost = createAction("[Post Admin] Update Post", props<{ post: Post, imageFile?: File}>());
export const updatePostSuccess = createAction("[Post Admin] Update Post Success", props<{ post: Post, imageFile?: File }>());
export const updatePostFailure = createAction("[Post Admin] Update Post Failure", props<{ error: any }>());

export const deletePost = createAction("[Post Admin] Delete Post", props<{ postId: number }>());
export const deletePostSuccess = createAction("[Post Admin] Delete Post Success", props<{ postId: number }>());
export const deletePostFailure = createAction("[Post Admin] Delete Post Failure", props<{ error: any }>());

// Actions for loading paginated posts
export const loadPaginatedPosts = createAction("[Post] Load Paginated Posts", props<{ page: number; limit: number }>());
export const loadPaginatedPostsSuccess = createAction(
  "[Post] Load Paginated Posts Success",
  props<{ posts: Post[]; total: number; paginationInfo: PaginationInfo }>() // Assuming the API returns total count
);
export const loadPaginatedPostsFailure = createAction("[Post] Load Paginated Posts Failure", props<{ error: any }>());

// Actions for updating pagination state
export const updateCurrentPage = createAction("[Post] Update Current Page", props<{ page: number }>());
export const updatePageSize = createAction("[Post] Update Page Size", props<{ size: number }>());

export const updatePostWithKeywords = createAction(
  "[Post Admin] Update Post with Keywords",
  props<{ post: Post; keywords: { create: any[]; edit: any[]; delete: any[] } }>()
);

export const updatePostWithKeywordsSuccess = createAction(
  "[Post Admin] Update Post with Keywords Success",
  props<{ post: Post }>()
);

export const updatePostWithKeywordsFailure = createAction(
  "[Post Admin] Update Post with Keywords Failure",
  props<{ error: any }>()
);

export const uploadPostImage = createAction(
    '[Post Admin] Upload Post Image', 
    props<{ postId: number, imageFile: File}>()
);

export const uploadPostImageSuccess = createAction(
    '[Post Admin] Upload Post Image Success'
);

export const uploadPostImageFailure = createAction(
    '[Post Admin] Upload Post Image Failure', 
    props<{ error: any }>()
);
