<div class="container">
  <div class="row align-items-top pb-100">
    <div class="col-lg-6 col-md-12">
      <div class="about-content">
        <app-title-hashtag [hashtagSubtitle]="subtitle"></app-title-hashtag>
        <app-title [title]="title" [titleHighlight]="titleHighlight"></app-title>
        <div [innerHTML]="description"></div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
        <div class="about-image">
            <img [src]="heroImage" [alt]="title" class="img-fluid">
        </div>
    </div>
  </div>
</div>
