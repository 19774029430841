import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLayout from './navbar.reducer';

export const selectLayoutState = createFeatureSelector<fromLayout.State>(
    fromLayout.layoutFeatureKey
);

export const selectIsHamburgerMenuOpen = createSelector(
    selectLayoutState,
    (state) => state.isHamburgerMenuOpen
);
