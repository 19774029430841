import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Product } from "src/app/features/products/models/product.interface";
import { Media } from "src/app/features/products/models/media.interface";
import { ProductClass } from "src/app/features/products/models/product-class.interface";
import { PaginationInfo } from "@fluentllc/core";
import { deleteMedia } from "src/app/features/products/store/media.actions";
export interface OAuthLoginResponse {
  auth_token: string;
}

@Injectable({
  providedIn: "root",
})
export class ProductsApiService {
  public loggedIn = false;
  private accessToken: string;
  private readonly apiUrl = "api/v1/product";
  headers = new HttpHeaders({
    "Content-Type": "application/json",
  });
  constructor(private http: HttpClient) { }

  getProductClassBySlug(slug: string) {
    return this.http.get<ProductClass>(`/api/v1/product-class/${slug}`);
  }

  getProductClasses() {
    return this.http.get<ProductClass[]>("/api/v1/product-class");
  }

  getCategory(productcategory: string) {
    return this.http.get(`/api/v1/categories/${productcategory}`);
  }

  getProduct(slug: string): Observable<Product> {
    return this.http.get<Product>(`/api/v1/product/${slug}`);
  }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>("/api/v1/product");
  }

  getProductsBySolution(solution: string): Observable<Product[]> {
    return this.http.get<Product[]>(`/api/v1/product/by_solution?solution_tags=${solution}`);
  }

  getProductsByKeywords(params: string): Observable<Product[]> {
    return this.http.get<Product[]>(`/api/v1/product/by_keywords?slug=${params}`);
  }

  getPaginatedProducts(
    page: number,
    pageSize: number
  ): Observable<{ products: Product[]; pagination: PaginationInfo }> {
    const params = new HttpParams().set("page", page.toString()).set("page_size", pageSize.toString());

    return this.http.get<Product[]>(this.apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const products = response.body;
        const pagination: PaginationInfo = {
          paginated: response.headers.get("x-paginated") === "true",
          paginatedBy: response.headers.get("x-paginated-by"),
          currentPage: response.headers.get("x-pagination-current"),
          nextPage: response.headers.get("x-pagination-next"),
          count: response.headers.get("x-pagination-count"),
        };
        return { products, pagination };
      })
    );
  }

  getMedia(slug: string): Observable<Media[]> {
    // console.log("GET MEDIA BY SLUG", slug);
    return this.http.get<Media[]>(`/api/v1/media/by_product?slug=${slug}`);
  }

  getMediaByTags(params: string): Observable<Media[]> {
    return this.http.get<Media[]>(`/api/v1/media/by_tags?${params}`);
  }

  updateMediaWeights(media: Media[]): Observable<Media[]> {
    const updates = media.map(m => ({ id: m.id, fields: { weight: m.weight } }));
    const formData = new FormData();
    formData.append('updates', JSON.stringify(updates));
    return this.http.post<Media[]>(`/api/v1/media/bulk_update`, formData);
  }

  updateMedia(media: Media, file?: File, thumbnail?: File): Observable<Media> {
    const formData = new FormData();

    // Append file if provided
    if (file) {
      formData.append("file", file);
    }

    // Append thumbnail if provided
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    // Append media object properties, excluding 'id'
    Object.keys(media).forEach((key) => {
      if (media.hasOwnProperty(key) && key !== "file" && key !== "thumbnail" && key !== "id" && media[key] !== null) {
        formData.append(key, media[key]);
      }
    });

    return this.http.patch<Media>(`/api/v1/media/${media.id}`, formData, { headers: this.headers });
}

  getConfigurationGroups(slug: string) {
    return this.http.get(`/api/v1/configurationgroups/by_product?product=${slug}`);
  }

  getConfigurationsByOption(slug: string, options: any) {
    let params = new HttpParams();
    params = params.append("product", slug);
    options.forEach(function (value, key) {
      params = params.append("option", value);
    });
    return this.http.get(`/api/v1/configurations/by_options?${params.toString()}`);
  }

  createProduct(product: Product): Observable<Product> {
    const formData = new FormData();
    return this.http.post<Product>(`${this.apiUrl}`, formData);
  }

  deleteProduct(productId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${productId}`);
  }

  updateProduct(product: Partial<Product>, imageFiles: File[],): Observable<Product> {
    const formData = new FormData();
    if (imageFiles) {
      imageFiles.forEach(file => {
        // Check if 'file' is not null or undefined before trying to access its 'name' property
        if (file && file.name && file.name.length > 0) {
          formData.append('product_images', file, file.name);
        }
      });
    }
    Object.keys(product).forEach(key => {
      const value = product[key];
      if (Array.isArray(value)) {
        value.forEach(item => formData.append(`${key}[]`, typeof item === 'object' ? JSON.stringify(item) : item));
      } else if (typeof value === 'object'  && value !== null) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    // formData.forEach((value, key) => {
    //   console.log(key, value);
    // });
    return this.http.patch<Product>(`${this.apiUrl}/${product.slug}`, formData);
  }

  uploadMediaToProduct(files: File[], productSlug: string,): Observable<Media> {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file, file.name);
    });
    formData.append('product_slug', productSlug);
    return this.http.post<Media>(`/api/v1/media/create`, formData);
  }

  createMediaForProduct(media: Media, file: File, thumbnail: File, productSlug: string, characteristicsSlug?: string): Observable<Media> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('thumbnail', file, file.name);
    formData.append('product_slug', productSlug);
    if (characteristicsSlug) {
      formData.append('characteristics_slug', characteristicsSlug);
    }
    Object.keys(media).forEach((key) => {
      if (media.hasOwnProperty(key) && key !== "file" && key !== "thumbnail" && media[key] !== null) {
        formData.append(key, media[key]);
      }
    });
    formData.delete('id');
    return this.http.post<Media>(`/api/v1/media/create`, formData);
  }

  removeProductAssociation(productSlug: string, mediaId: number): Observable<Media> {
    return this.http.post<Media>(`/api/v1/media/${mediaId}/remove_product_association`, { product_slug: productSlug });
  }

  deleteMedia(mediaId: number): Observable<void> {
    return this.http.delete<void>(`/api/v1/media/${mediaId}`);
  }
}
