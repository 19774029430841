import { Injectable } from '@angular/core';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { Observable } from 'rxjs';
import { SearchService } from './abstract-search.service';
import { environment } from 'src/environments/environment';

import { SearchItem } from 'src/app/shared/models/search-item.interface';
import { SearchMetadata } from 'src/app/shared/models/search-metadata.interface';
import { ProductSearchResult } from 'src/app/shared/models/product-search-result.interface';
import { SupportGuideSearchResult } from 'src/app/shared/models/support-guide-search-result.interface';
import { FAQSearchResult } from 'src/app/shared/models/faq-search-result.interface';
import { PostSearchResult } from 'src/app/shared/models/post-search-result.interface';



@Injectable({
    providedIn: 'root',
})
export class AlgoliaSearchService implements SearchService {
    private client: SearchClient;

    constructor() {
        const applicationId = environment.algoliaApplicationId;
        const searchApiKey = environment.algoliaSearchApiKey;
        this.client = algoliasearch(applicationId, searchApiKey);
    }



    search(
        indexName: string,
        query: string
    ): Observable<{ items: SearchItem[]; metadata: SearchMetadata }> {
        return new Observable((observer) => {
            const index = this.client.initIndex(indexName);
            index
                .search(query)
                .then(
                    ({
                        hits,
                        page,
                        nbPages,
                        hitsPerPage,
                        nbHits,
                        query,
                        params,
                        message,
                        index,
                        renderingContent

                    }) => {
                        const results = {
                            items: hits.map((hit) => this.transformResult(hit, indexName)),
                            metadata: {
                                nbHits,
                                page,
                                hitsPerPage,
                                nbPages,
                                query,
                                params,
                                message,
                                index,
                                renderingContent
                            },
                        };
                        observer.next(results);
                        observer.complete();
                    }
                );
        });
    }

    multiSearch(
        queries: { indexName: string; query: string }[]
    ): Observable<{ items: SearchItem[]; metadata: SearchMetadata }[]> {
        return new Observable((observer) => {
            const requests = queries.map(({ indexName, query }) => ({
                indexName,
                params: {
                    query,
                },
            }));
            this.client.search(requests).then(({ results }) => {
                const multiSearchResults = results.map(
                    ({
                        hits,
                        page,
                        nbPages,
                        hitsPerPage,
                        nbHits,
                        query,
                        params,
                        message,
                        index,
                        renderingContent
                    }) => {
                        const metadata = {
                            nbHits,
                            page,
                            hitsPerPage,
                            nbPages,
                            query,
                            params,
                            message,
                            index,
                            renderingContent
                        }
                        return {
                            items: hits.map((hit) => this.transformResult(hit, index)),
                            metadata: metadata,
                        };
                    }
                );
                observer.next(multiSearchResults);
                observer.complete();
            });
        });
    }

    private getResultType(result: SearchItem): string {
        return this.getKeyByIndex(result.index);
    }

    private getKeyByIndex(index: string): string | undefined {
        const searchIndex = environment.algoliaSearchIndexes.find(item => item.index === index);
        return searchIndex ? searchIndex.key : undefined;
    }

    private getIndexByKey(key: string): string | undefined {
        const searchIndex = environment.algoliaSearchIndexes.find(item => item.key === key);
        return searchIndex ? searchIndex.index : undefined;
    }

    private transformResult(hit: any, indexName: string): SearchItem {
        const key = this.getKeyByIndex(indexName);
        switch (key) {
            case 'product':
                return this.transformProductSearchResult(hit, indexName);
            case 'supportGuide':
                return this.transformSupportGuideSearchResult(hit, indexName);
            case 'faq':
                return this.transformFAQSearchResult(hit, indexName);
            case 'post':
                return this.transformPostSearchResult(hit, indexName);
            default:
                // Handle case when none of the above are matched, if necessary
                break;
        }
        throw new Error(`Unrecognized index name: ${indexName}`);
    }

    private transformPostSearchResult(hit: any, indexName?: any): PostSearchResult {
        // console.log(hit)
        return {
            content: hit.content,
            title: hit.title,
            slug: hit.slug,
            objectID: hit.objectID,
            index: indexName,
            _highlightResult: {
                content: hit._highlightResult.content,
                title: hit._highlightResult.title,
                slug: hit._highlightResult.slug,
            },
        };
    }

    private transformProductSearchResult(hit: any, indexName?: any): ProductSearchResult {
        return {
            description: hit.description,
            title: hit.title,
            slug: hit.slug,
            objectID: hit.objectID,
            index: indexName,
            _highlightResult: {
                description: hit._highlightResult.description,
                title: hit._highlightResult.title,
                slug: hit._highlightResult.slug,
            },
        };
    }

    private transformFAQSearchResult(
        hit: any, indexName?: any
    ): FAQSearchResult {
        return {
            question: hit.question,
            answer: hit.answer,
            index: indexName,
            objectID: hit.objectID,
            _highlightResult: {
                question: hit._highlightResult.question,
                answer: hit._highlightResult.answer,
            },
        };
    }

    private transformSupportGuideSearchResult(
        hit: any, indexName?: any
    ): SupportGuideSearchResult {
        return {
            title: hit.title,
            part_number: hit.part_number,
            indexed_attached_pdf: hit.indexed_attached_pdf,
            purpose: hit.purpose,
            intended_use: hit.intended_use,
            attached_pdf_absolute_url: hit.attached_pdf_absolute_url,
            objectID: hit.objectID,
            index: indexName,
            _highlightResult: {
                title: hit._highlightResult.title,
                description: hit._highlightResult.description,
                part_number: hit._highlightResult.part_number,
                indexed_attached_pdf: hit._highlightResult.indexed_attached_pdf,
                purpose: hit._highlightResult.purpose,
            },
        };
    }




}
