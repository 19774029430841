<div class="box-shadow" *ngIf="!submitted; else requestProductInfoResp">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="sci-contactform-2 wrap-form clearfix needs-validation"
        novalidate>
        <div class="section-title with-desc-clearfix">
            <div class="title-header">
                <h5>Request Product Information</h5>
                <h4 class="title">Ask Our Experts About the {{productName}} </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 form-group mb-3">
                <input type="text" class="form-control text-input" id="firstName" formControlName="firstName" required
                    [class.is-invalid]="form.controls['firstName'].invalid && form.controls['firstName'].touched"
                    placeholder="First Name *">
                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['firstName'].invalid && form.controls['firstName'].touched">
                    Please enter your first name.
                </div>
            </div>
            <div class="col-lg-6 form-group mb-3">
                <input type="text" class="form-control text-input" id="lastName" formControlName="lastName" required
                    [class.is-invalid]="form.controls['lastName'].invalid && form.controls['lastName'].touched"
                    placeholder="Last Name *">
                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['lastName'].invalid && form.controls['lastName'].touched">
                    Please enter your last name.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 form-group mb-3">
                <input type="text" class="form-control text-input" id="company" formControlName="company"
                    placeholder="Organization">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 form-group mb-3">
                <input type="email" class="form-control text-input" id="email" formControlName="email" required
                    [class.is-invalid]="form.controls['email'].invalid && form.controls['email'].touched"
                    placeholder="Email *">
                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['email'].invalid && form.controls['email'].touched">
                    Please enter a valid email address.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 form-group mb-3">
                <ng-select [items]="countries" bindLabel="name" bindValue="code" formControlName="country"
                    (change)="onCountryChange($event)">
                </ng-select>
                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['country'].invalid && form.controls['country'].touched">
                    Please select your country.
                </div>
            </div>
            <div class="col-lg-6 form-group mb-3" *ngIf="showStateDropdown">
                <ng-select *ngIf="showStateDropdown" [items]="states" bindLabel="name" bindValue="code"
                    placeholder="Select a state" formControlName="stateUs" [clearable]="true">
                </ng-select>

                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['stateUs'].invalid && form.controls['stateUs'].touched">
                    Please select your state/province.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 form-group mb-3">
                <input type="tel" class="form-control text-input" id="phone" formControlName="phone" required
                    (input)="onPhoneInputChange($event)" [value]="formattedPhoneNumber"
                    [class.is-invalid]="form.controls['phone'].invalid && form.controls['phone'].touched"
                    placeholder="Phone">
                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['phone'].invalid && form.controls['phone'].touched">
                    Please enter a valid phone number.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 form-group mb-3">
                <textarea class="form-control text-input" id="message" formControlName="message" rows="5" required
                    [class.is-invalid]="form.controls['message'].invalid && form.controls['message'].touched"
                    placeholder="How may we help you? *"></textarea>
                <div class="invalid-feedback text-danger"
                    *ngIf="form.controls['message'].invalid && form.controls['message'].touched">
                    Please tell us how we can help you.
                </div>
            </div>
        </div>
        <div class="form-group mb-3">
            <button type="submit" class="btn btn-primary">Send Your Request</button>
        </div>
    </form>
</div>
<ng-template #requestProductInfoResp>
    <div class="box-shadow" *ngIf="successMessage">
        <div [innerHTML]="successMessage" class="p-30"></div>
    </div>
</ng-template>