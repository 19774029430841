<ul class="social-links" [ngClass]="additionalClasses">
    <li>
        <a href="https://www.linkedin.com/company/scinomix/" target="_blank">
            <div class="social-media-icons" [innerHTML]="bxlLinkedInIcon"></div>
        </a>
        <a href="https://www.facebook.com/scinomix/" target="_blank">
            <div class="social-media-icons" [innerHTML]="bxlFacebookIcon"></div>
        </a>
        <a href="https://twitter.com/scinomix" target="_blank">
            <div class="social-media-icons" [innerHTML]="bxlTwitterIcon"></div>
        </a>
        <a href="https://youtube.com/@scinomix2571?si=1h07ufhdF7xGLnvQ" target="_blank">
            <div class="social-media-icons" [innerHTML]="bxlYoutubeIcon"></div>
        </a>
    </li>
</ul>