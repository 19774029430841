import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SupportGuideSearchResult } from 'src/app/shared/models/support-guide-search-result.interface';

@Component({
  selector: 'app-support-guide-search-result',
  templateUrl: './support-guide-search-result.component.html',
  styleUrls: ['./support-guide-search-result.component.scss']
})
export class SupportGuideSearchResultComponent implements OnInit, OnChanges {
  @Input() result!: SupportGuideSearchResult;

  ngOnInit() {
    // console.log(this.result);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.result) {
      // console.log(this.result);
    }
  }
}
