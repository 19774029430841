import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { FAQ } from 'src/app/shared/models/faq.interface';
import { loadFAQs } from '../../../store/faq.actions';
import { selectAllFAQs } from '../../../store/faq.selectors';

@Component({
  selector: 'app-faqcontainer',
  templateUrl: './faqcontainer.component.html',
  styleUrls: ['./faqcontainer.component.scss']
})
export class FAQContainerComponent implements OnInit {

  faqs$: Observable<FAQ[]>;

  constructor(private store: Store, private metaTagsService: MetaTagsService) { }

  ngOnInit(): void {
    this.store.dispatch(loadFAQs());
    this.faqs$ = this.store.select(selectAllFAQs);
    const newMetaTags = {
      title: 'Frequently Asked Questions',
      description: "Navigate our FAQ section for clear answers on lab automation, products, and services. From decision-making to troubleshooting, discover insights to empower your lab journey.",
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };

    this.metaTagsService.updateMetaTags(newMetaTags);
  }

}
