// src/app/store/blog/blog.state.ts
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Blog } from 'src/app/shared/models/blog.interface';

export interface BlogState extends EntityState<Blog> {
    loading: boolean;
    error: any;
}

export const adapter: EntityAdapter<Blog> = createEntityAdapter<Blog>();

export const initialState: BlogState = adapter.getInitialState({
    loading: false,
    error: null,
});
