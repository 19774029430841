import { createAction, props } from '@ngrx/store';
import { MetaTag } from 'src/app/shared/models/meta-tag.interface';

export const getMetaTags = createAction('[MetaTags] Get Meta Tags');
export const setMetaTags = createAction(
    '[MetaTags] Set Meta Tags',
    props<{ metaTags: MetaTag[] }>()
);
export const updateTitle = createAction(
    '[MetaTags] Update Title',
    props<{ title: string }>()
);
export const updateDescription = createAction(
    '[MetaTags] Update Description',
    props<{ description: string }>()
);
export const updateKeywords = createAction(
    '[MetaTags] Update Keywords',
    props<{ keywords: string }>()
);

export const updatePrerenderStatusCode = createAction(
    '[MetaTags] Update Prerender Status Code',
    props<{ code: string }>()
);
export const updateGenericMetaTag = createAction(
    '[MetaTags] Update Generic Meta Tag',
    props<{ key: string; value: string }>()
);
