<app-page-banner-container backgroundImage="/assets/images/default_abstract.jpg" [title]="bannerTitle"
    [breadcrumbs]="breadcrumbs"></app-page-banner-container>
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="ptb-30">
                <h1>Service & Support</h1>
                <p>Scinomix strives to provide excellent support to all customers. Our mission is to be a leader of
                    laboratory automation systems to customers in the Life Science industry by providing innovative
                    solutions and committing to quality, reliability, value, and customer service.​</p>
            </div>
        </div>
    </div>
</div>
<app-section-list-container [links]="links"></app-section-list-container>