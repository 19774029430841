import { createAction, props } from "@ngrx/store";
import { JobPosting } from "src/app/shared/models/job-posting.interface";
import { PaginationInfo } from '@fluentllc/core';

export const loadJobPostings = createAction("[JobPosting] Load JobPostings");

export const loadJobPostingsSuccess = createAction(
  '[JobPosting] Load JobPostings Success',
  props<{ jobPostings: JobPosting[] }>()
);

export const loadJobPostingsFailure = createAction(
  "[JobPosting] Load JobPostings Failure",
  props<{ error: any }>()
);

export const loadJobPostingById = createAction(
  "[JobPosting] Load JobPosting By Id",
  props<{ id: number }>()
);

export const loadJobPostingByIdSuccess = createAction(
  "[JobPosting] Load JobPosting By Id Success",
  props<{ jobPosting: JobPosting }>()
);

export const loadJobPostingByIdFailure = createAction(
  "[JobPosting] Load JobPosting By Id Failure",
  props<{ error: any }>()
);

export const loadJobPostingBySlug = createAction(
  "[JobPosting] Load JobPosting By Slug",
  props<{ slug: string }>()
);
export const loadJobPostingBySlugSuccess = createAction(
  "[JobPosting] Load JobPosting By Slug Success",
  props<{ jobPosting: JobPosting }>()
);
export const loadJobPostingBySlugFailure = createAction(
  "[JobPosting] Load JobPosting By Slug Failure",
  props<{ error: any }>()
);

export const loadPaginatedJobPostings = createAction(
  "[JobPosting] Load Paginated JobPostings",
  props<{ page: number; limit: number }>()
);

export const loadPaginatedJobPostingsSuccess = createAction(
  "[JobPosting] Load Paginated JobPostings Success",
  props<{ jobPostings: JobPosting[]; total: number; paginationInfo: PaginationInfo}>()
);

export const loadPaginatedJobPostingsFailure = createAction(
  "[JobPosting] Load Paginated JobPostings Failure",
  props<{ error: any }>()
);

export const updateJobPosting = createAction(
  "[JobPosting] Update JobPosting",
  props<{ jobPosting: JobPosting }>()
);

export const updateJobPostingSuccess = createAction(
  "[JobPosting] Update JobPosting Success",
  props<{ jobPosting: JobPosting }>()
);

export const updateJobPostingFailure = createAction( 
  "[JobPosting] Update JobPosting Failure",
  props<{ error: any }>()
);

export const createJobPosting = createAction(
  "[JobPosting] Create JobPosting",
  props<{ jobPosting: JobPosting }>()
);

export const createJobPostingSuccess = createAction(
  "[JobPosting] Create JobPosting Success",
  props<{ jobPosting: JobPosting }>()
);

export const createJobPostingFailure = createAction(
  "[JobPosting] Create JobPosting Failure",
  props<{ error: any }>()
);

export const deleteJobPosting = createAction(
  "[JobPosting] Delete JobPosting",
  props<{ id: number }>()
);

export const deleteJobPostingSuccess = createAction(
  "[JobPosting] Delete JobPosting Success",
  props<{ id: number }>()
);

export const deleteJobPostingFailure = createAction(
  "[JobPosting] Delete JobPosting Failure",
  props<{ error: any }>()
);

export const updateCurrentPage = createAction(
  "[JobPosting] Update Current Page",
  props<{ page: number }>()
);

export const updatePageSize = createAction(
  "[JobPosting] Update Page Size",
  props<{ size: number }>()
);

export const saveJobPostingFormState = createAction(
  "[JobPosting] Save JobPosting Form State",
  props<{ jobPosting: JobPosting }>()
);

export const loadJobPostingFormState = createAction(
  "[JobPosting] Load JobPosting Form State"
);
export const clearJobPostingFormState = createAction(
  "[JobPosting] Clear JobPosting Form State"
);

