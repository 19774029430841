import { Component, OnInit } from "@angular/core";
import { Product } from "../../models/product.interface";
import { Observable, take } from "rxjs";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/store/app.state";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import * as ProductActions from "../../store/products.actions";
import { selecProductBySlugFactory } from "../../store/products.selectors";
import { loadProductClasses } from "../../store/product-class.actions";
import { ProductClass } from "../../models/product-class.interface";
import { selectAllProductClasses } from "../../store/product-class.selectors";
import { SolutionTag } from "src/app/shared/models/solution-tag.interface";
import { loadSolutionTags } from "src/app/features/tagging/store/solution-tags.actions";
import { loadMedia } from "../../store/media.actions";

@Component({
  selector: "app-product-admin-manage-container",
  templateUrl: "./product-admin-manage-container.component.html",
  styleUrls: ["./product-admin-manage-container.component.scss"],
})
export class ProductAdminManageContainerComponent implements OnInit {
  product$: Observable<Product>;
  classes$: Observable<ProductClass[]>;
  solutionTags$: Observable<SolutionTag[]>;
  isEditMode: boolean = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private metaTagsService: MetaTagsService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL is '/admin/post/create'
        if (this.router.url === "/admin/products/create") {
          // Dispatch the clearPostFormState action
          this.store.dispatch(ProductActions.clearProductFormState());
        }
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(loadProductClasses());
    this.store.dispatch(loadSolutionTags());
    this.classes$ = this.store.select(selectAllProductClasses);
    this.route.params.subscribe((params) => {
      const slug = params["slug"];
      let title = "Create Product";
      if (slug) {
        this.isEditMode = true;
        title = "Edit Product";
        this.store.dispatch(ProductActions.loadProduct({ slug: slug }));
        this.store.dispatch(loadMedia({ slug: slug }));
        this.product$ = this.store.select(selecProductBySlugFactory(slug));
        // this.product$.subscribe((product) => {
        //   if (product) {
        //     this.store.dispatch(loadMedia({ slug: product.slug }));
        //   }
        // })
      } else {
        this.isEditMode = false;
        this.product$ = new Observable<Product>(); // Initialize with a new post
      }
      const newMetaTags = {
        title: title,
        description: "",
        keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      };
      this.metaTagsService.updateMetaTags(newMetaTags);
    });
  }

  onSave(event: any): void {
    this.store.dispatch(ProductActions.saveProductFormState({ product: event.product }));
    console.log(event.product)
    if (this.isEditMode) {
      this.store.dispatch(ProductActions.updateProduct({ product: event.product, imageFiles: event.imageFiles }));
    } else {
      this.store.dispatch(ProductActions.createProduct({ product: event.product }));
    }
    this.store.dispatch(ProductActions.clearProductFormState());
  }

  onCancel(): void {
    this.router.navigate(["/admin/products"]);
    this.store.dispatch(ProductActions.clearProductFormState());
  }
}
