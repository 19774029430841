import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, exhaustMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromAuth from '@fluentllc/core';

@Injectable()
export class HubspotAuthInterceptor implements HttpInterceptor {
    constructor(private store: Store) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.startsWith('/hubspotapi') && !req.url.startsWith('https://api.hubapi.com')) {
            return next.handle(req);
        }

        // Use the new factory function to create the selector
        const hubspotTokenSelector = fromAuth.selectProviderTokenFactory('hubspot');

        return this.store.select(hubspotTokenSelector).pipe(
            take(1),
            exhaustMap((token) => {
                if (!token) {
                    return next.handle(req);
                }
                req = req.clone({
                    setHeaders: { Authorization: `Bearer ${token}` },
                });
                return next.handle(req);
            })
        );
    }
}
