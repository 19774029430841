import { Component, OnInit } from '@angular/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';

@Component({
  selector: 'app-roicalculator-container',
  templateUrl: './roicalculator-container.component.html',
  styleUrls: ['./roicalculator-container.component.scss']
})
export class ROICalculatorContainerComponent implements OnInit{

  constructor(private metaTagsService: MetaTagsService) {

  }
  
  ngOnInit(): void {
    const newMetaTags = {
      title: 'Lab Automation Return on Investment (ROI)',
      description: 'Use our lab automation ROI calculator to evaluate the potential returns of your lab automation equipment investment. Make informed decisions and maximize your lab\'s efficiency and profitability.',
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

}
