import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";

import * as ProductTagsActions from "./product-tags.actions";
import { ProductSlugService } from "src/app/core/services/product-slug.service";

@Injectable()
export class ProductTagsEffects {
    loadProductTags$ = createEffect(() =>
        this.actions$.pipe(
        ofType(ProductTagsActions.loadProductTags),
        mergeMap(() =>
            this.productSlugService.getProducts().pipe(
            map((productTags) => ProductTagsActions.loadProductTagsSuccess({ productTags })),
            catchError((error) => of(ProductTagsActions.loadProductTagsFailure({ error })))
            )
        )
        )
    );
    
    constructor(private actions$: Actions, private productSlugService: ProductSlugService) {}
}