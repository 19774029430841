import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-btn',
  templateUrl: './search-btn.component.html',
  styleUrls: ['./search-btn.component.scss']
})
export class SearchBtnComponent {
  @Input() searchClassApplied: boolean;
  @Output() searchToggle = new EventEmitter<void>();

  onSearchToggle() {
    this.searchToggle.emit();
  }
}
