import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navbar-brand',
  templateUrl: './navbar-brand.component.html',
  styleUrls: ['./navbar-brand.component.scss']
})
export class NavbarBrandComponent {
  @Input() toggleClass: Function;
}
