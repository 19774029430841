import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MetaTag } from 'src/app/shared/models/meta-tag.interface';
import * as MetaTagsActions from 'src/app/features/seo/store/meta-tags.actions';
import { MetaTagsState } from 'src/app/features/seo/store/meta-tags.state';
import { getMetaTags } from 'src/app/features/seo/store/meta-tags.selectors';

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  constructor(private store: Store<{ metaTags: MetaTagsState }>) { }

  // updateMetaTags(metaTags: Partial<MetaTagsState>) {
  //   if (metaTags.title) {
  //     this.store.dispatch(
  //       MetaTagsActions.updateTitle({ title: metaTags.title })
  //     );
  //   }
  //   if (metaTags.description) {
  //     this.store.dispatch(
  //       MetaTagsActions.updateDescription({ description: metaTags.description })
  //     );
  //   }
  //   if (metaTags.keywords) {
  //     this.store.dispatch(
  //       MetaTagsActions.updateKeywords({ keywords: metaTags.keywords })
  //     );
  //   }

  //   if ( metaTags.prerenderStatusCode) {
  //     this.store.dispatch(
  //       MetaTagsActions.updatePrerenderStatusCode({ code: metaTags.prerenderStatusCode })
  //     );
  //   }
  // }
//   updateMetaTags(metaTags: Partial<MetaTagsState>) {
//     Object.keys(metaTags.tags).forEach(key => {
//         this.store.dispatch(
//             MetaTagsActions.updateMetaTag({ key, value: metaTags.tags[key] })
//         );
//     });
// }
  updateMetaTags(metaTags: { [key: string]: string }) {
    // This object maps specific meta tags to their corresponding actions
    const metaTagKeys = {
      title: MetaTagsActions.updateTitle,
      description: MetaTagsActions.updateDescription,
      keywords: MetaTagsActions.updateKeywords,
      prerenderStatusCode: MetaTagsActions.updatePrerenderStatusCode
    };

    Object.keys(metaTags).forEach(key => {
      if (metaTagKeys[key]) {
        // Dispatch specific actions for known keys
        this.store.dispatch(metaTagKeys[key]({ [key]: metaTags[key] }));
      } else {
        // For dynamic meta tags, dispatch a generic action
        this.store.dispatch(MetaTagsActions.updateGenericMetaTag({ key, value: metaTags[key] }));
      }
    });
  }

  getMetaTags(): Observable<MetaTag[]> {
    return this.store.select(getMetaTags);
  }
}
