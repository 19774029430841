import { createAction, props } from '@ngrx/store';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { ProductRequest } from '../models/product-request.interface';

export const addProductRequest = createAction(
    '[Product Request] Add Product Request',
    props<{ productRequest: ProductRequest; contextData: ContextData }>()
);


export const addProductRequestSuccess = createAction(
    '[Product Request] Add Product Request Success',
    props<{ productRequest: ProductRequest }>()
);

export const addProductRequestFailure = createAction(
    '[Product Request] Add Product Request Failure',
    props<{ error: any }>()
);

export const setSuccessMessage = createAction(
    '[Product Request] Set Success Message',
    props<{ message: string }>()
);

export const resetProductRequestSlice = createAction('[Product Request] Reset Product Request Slice');