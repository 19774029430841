<ng-template #template>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalToggleLabel">{{media?.caption || 'New Media'}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onCancel();"></button>
    </div>
    <div class="modal-body" [formGroup]="mediaForm">
        <div class="row">
            <!-- Media Preview and File Information -->
            <div class="col-xl-4">
                <div *ngIf="media?.file_preview && media.type !== 'pdf'">
                <img [src]="media?.file_preview" class="img-thumbnail" alt="Media Thumbnail">
                </div>
                <input type="file" class="form-control mb-3" id="file" (change)="onFileChange($event, 'file')">
                <div *ngIf="media?.file_name" class="mb-1 text-sm">Filename: {{media.file_name}}</div>
                <div *ngIf="media?.file_size" class="mb-1 text-sm">Filesize: {{media.file_size}} bytes</div>
                <div *ngIf="media?.id" class="mb-1 text-sm">Image ID: {{media.id}}</div>
                <div *ngIf="media?.file_dimensions" class="text-sm">Dimensions: {{media.file_dimensions[0]}}px x {{media.file_dimensions[1]}}px</div>
                <div *ngIf="media?.file_dpi" class="mb-1 text-sm">Resolution: {{media.file_dpi[0]}} dpi</div>
            </div>
            <!-- Media Details Form -->
            <div class="col-xl-4">
                <div class="mb-3">
                    <label for="meta_alt" class="form-label">Media/Image Alt Text</label>
                    <input formControlName="meta_alt" class="form-control" id="alt" placeholder="Enter alt text">
                </div>
                <div class="mb-3">
                    <label for="type" class="form-label">Media Type</label>
                    <select formControlName="type" class="form-select" id="type">
                        <option value="external">External</option>
                        <option value="image">Image</option>
                        <option value="pdf">PDF</option>
                    </select>
                </div>
                <!-- Conditional Fields for External Type -->
                <div *ngIf="mediaForm.get('type').value === 'external'">
                    <div class="mb-3">
                        <label for="externalVideoUrl" class="form-label">External Video URL</label>
                        <input formControlName="external_video_url" class="form-control" id="externalVideoUrl" placeholder="Enter external video URL">
                    </div>
                    <div class="mb-3">
                        <label for="externalVideoAltText" class="form-label">External Video Alt Text</label>
                        <input formControlName="external_video_alternative_text" class="form-control" id="externalVideoAltText" placeholder="Enter external video alt text">
                    </div>
                    <div class="mb-3">
                        <label for="externalVideoCaption" class="form-label">External Video Caption</label>
                        <input formControlName="external_video_caption" class="form-control" id="externalVideoCaption" placeholder="Enter external video caption">
                    </div>
                </div>
            </div>
            <!-- Thumbnail and Priority Settings -->
            <div class="col-xl-4">
                <div class="row mb-3" *ngIf="media?.thumbnail_preview && media.type !== 'pdf'">
                    <div class="col-xl-12"><div class="mb-3">Media Thumbnail Preview</div></div>
                    <div class="col-xl-3">
                        <img *ngIf="media?.thumbnail_preview" [src]="media.thumbnail_preview" class="img-thumbnail" alt="Thumbnail">
                    </div>
                    <div class="col-xl-9">
                        <input type="file" class="form-control mb-3" id="thumbnail" (change)="onFileChange($event, 'thumbnail')">
                    </div>
                </div>
                <div class="mb-3">
                    <label for="caption" class="form-label">Caption</label>
                    <input formControlName="caption" class="form-control" id="caption" placeholder="Enter caption">
                </div>
                <div class="mb-3">
                    <label for="weight" class="form-label">Weight</label>
                    <input formControlName="weight" class="form-control" type="number" id="weight" placeholder="Enter weight">
                </div>
                <div class="mb-3">
                    <label for="priority" class="form-label">Sitewide Ranking Priority</label>
                    <select formControlName="priority" class="form-select" id="priority">
                        <option value="top">Top</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                        <option value="no_priority">No Priority</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-outline-secondary" type="button" (click)="onCancel()">Cancel</button>
            <button class="btn btn-primary" type="button" (click)="saveChanges()" [disabled]="!mediaForm.valid"><i class="fa fa-save me-2"></i>{{isEditMode ? 'Save' : 'Upload'}}</button>
        </div>
    </div>
</ng-template>
