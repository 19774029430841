import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { offsidebarFeatureKey, offsidebarReducer } from './offsidebar/offsidebar.reducer';
import { OffsidebarEffects } from './offsidebar/offsidebar.effects';
import { layoutFeatureKey, layoutReducer } from './navbar.reducer';

@NgModule({
    imports: [CommonModule,
        StoreModule.forFeature(offsidebarFeatureKey, offsidebarReducer),
        StoreModule.forFeature(layoutFeatureKey, layoutReducer),
        EffectsModule.forFeature([OffsidebarEffects]),],
})
export class LayoutStoreModule { }
