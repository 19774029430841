// features/user/user-routing.module.ts
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardContainerComponent } from "./containers/dashboard-container/dashboard-container.component";
import { AuthenticatedGuard, RoleGuard } from "@fluentllc/core";

const routes: Routes = [{
  path: "", component: DashboardContainerComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  // Removed 'exports' as it's not needed with lazy loading
})
export class DashboardRoutingModule { }
