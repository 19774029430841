import { createReducer, on } from '@ngrx/store';
import { initialMetaTagsState, MetaTagsState } from './meta-tags.state';
import { updateTitle, updateDescription, updateKeywords, updatePrerenderStatusCode, updateGenericMetaTag } from './meta-tags.actions';
export const metaTagsFeatureKey = 'metaTags';

export const metaTagsReducer = createReducer(
    initialMetaTagsState,
    on(updateTitle, (state, { title }) => ({
        ...state,
        tags: { ...state.tags, title }
    })),
    on(updateDescription, (state, { description }) => ({
        ...state,
        tags: { ...state.tags, description }
    })),
    on(updateKeywords, (state, { keywords }) => ({
        ...state,
        tags: { ...state.tags, keywords }
    })),
    on(updatePrerenderStatusCode, (state, { code }) => ({
        ...state,
        tags: { ...state.tags, prerenderStatusCode: code }
    })),
    on(updateGenericMetaTag, (state, { key, value }) => ({
        ...state,
        tags: { ...state.tags, [key]: value }
    }))
);