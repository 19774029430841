import { Action, createReducer, on } from '@ngrx/store';
import * as LayoutActions from './layout.actions';
import { initialState, adapter, LayoutState } from './layout.state';

export const layoutFeatureKey = "layouts";

const reducer = createReducer(
  initialState,
  on(LayoutActions.loadLayoutsSuccess, (state, { layouts }) =>
    adapter.setAll(layouts, { ...state, loading: false })
  ),
  on(LayoutActions.loadLayoutsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(LayoutActions.addLayoutSuccess, (state, { layout }) =>
    adapter.addOne(layout, state)
  ),
  on(LayoutActions.updateLayoutSuccess, (state, { layout }) =>
    adapter.updateOne({ id: layout.id, changes: layout }, state)
  ),
  on(LayoutActions.deleteLayoutSuccess, (state, { id }) =>
    adapter.removeOne(id, state)
  ),
  // Handle other actions like add, update, delete
);

export function layoutReducer(state: LayoutState | undefined, action: Action) {
  return reducer(state, action);
}
