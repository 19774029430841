import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemonstrationsComponent } from './components/demonstrations/demonstrations.component';
import { HomeComponent } from './components/home/home.component';
import { MikeComponent } from './components/mike/mike.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ScinomixExperienceComponent } from './components/scinomix-experience/scinomix-experience.component';
import { ScinomixStoryContainerComponent } from './containers/scinomix-story-container/scinomix-story-container.component';
import { AccessDeniedContainerComponent, RoleGuard } from '@fluentllc/core';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'about/story-behind-scinomix',
    component: ScinomixStoryContainerComponent,
    // canActivate: [RoleGuard],
    // data: { role: 'role-tenant-admin'}
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'scinomix-experience',
    component: ScinomixExperienceComponent
  },
  {
    path: 'virtual-demonstration',
    component: DemonstrationsComponent
  },
  {
    path: 'access-denied',
    component: AccessDeniedContainerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule { }
