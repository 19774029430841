import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { CollectionPageSchema, ReviewSchema } from 'src/app/shared/models/schema-types.interface';
import { Testimonial } from 'src/app/shared/models/testimonial.interface';
import { selectAllTestimonials } from '../../store/testimonials.selectors';

@Component({
  selector: 'app-testimonials-page',
  templateUrl: './testimonials-page.component.html',
  styleUrls: ['./testimonials-page.component.scss']
})
export class TestimonialsPageComponent implements OnInit {
  testimonials$: Observable<Testimonial[]>;
  breadcrumbs: Breadcrumb[] = [];

  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService, private store: Store) {
    this.testimonials$ = this.store.select(selectAllTestimonials);
  }

  ngOnInit(): void {
    this.breadcrumbs = [
      { label: 'Home', url: '/' },
      { label: 'Testimonials', url: '/testimonials' },
    ];

    this.testimonials$.subscribe(testimonials => {
      const itemListElement: ReviewSchema[] = testimonials.map((testimonial, index) => ({
        '@type': 'Review',
        '@id': testimonial.id.toString(),
        'author': testimonial.name,
        'description': testimonial.quote,
        'name': testimonial.teaser,
        'reviewRating': {
          '@type': 'Rating',
          'bestRating': '5',
          'ratingValue': testimonial.rating.toString(),
          'worstRating': '1',
        },
      }));

      const testimonialCollectionPageSchema: CollectionPageSchema = {
        '@context': 'https://schema.org',
        '@type': 'CollectionPage',
        'name': 'Customer Reviews for Scinomix Lab Automatino products',
        'description': 'A collection of customer reviews for Scinomix lab automation products',
        'mainEntity': {
          '@type': 'ItemList',
          itemListElement
        }
      };
      this.schemaService.updateSchema(testimonialCollectionPageSchema);
    });
  }
}
