<!-- <button type="button" class="default-btn" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-trigger="focus"
    data-bs-content="#popoverForm">
    Virtual Demo
</button> -->

<div class="header_btn">
    <a (click)="toggleAppointmentForm($event)"
        class="appointment sci-btn sci-btn-size-md sci-btn-shape-rounded sci-btn-style-fill sci-icon-btn-left sci-btn-color-grey">
        <fa-icon [icon]="ctaIcon" size="lg" class="icon-vertical-align pr-10"></fa-icon> <span
            class="text-vertical-align">Schedule a Demo</span>
    </a>
</div>