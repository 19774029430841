import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DistributorsContainerComponent } from './containers/distributors-container/distributors-container.component';


const routes: Routes = [
  {
    path: "",
    component: DistributorsContainerComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DistributorsRoutingModule { }