import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Product } from "../models/product.interface";
import { productsFeatureKey } from "./products.reducer";
import { ProductsState, adapter } from "./products.state";

export const selectProductsState = createFeatureSelector<ProductsState>(productsFeatureKey);

export const {
  selectIds: selectProductIds,
  selectEntities: selectProductEntities,
  selectAll: selectAllProducts,
  selectTotal: selectTotalProducts,
} = adapter.getSelectors(selectProductsState);

export const selectAllProductsSortedByProductName = createSelector(selectAllProducts, (products: Product[]) =>
  [...products].sort((a, b) => a.product_name.localeCompare(b.product_name))
);

export const selectCurrentProduct = createSelector(
  selectProductsState,
  (state: ProductsState) => state?.currentProduct
);

export const selectLoading = createSelector(selectProductsState, (state: ProductsState) => state.loading);

export const selectError = createSelector(selectProductsState, (state: ProductsState) => state.error);

export const selectProductsBySolution = (solution: string) =>
  createSelector(selectAllProducts, (products) =>
    products.filter((product) => product.solution_tags_slugs.includes(solution))
  );

export const selectSubproductsByKeyword = (keyword: string) => {
  return createSelector(selectAllProducts, (allProducts: Product[]) => {
    // console.log("All products:", allProducts); // Debugging
    const filteredProducts = allProducts.filter((product) => product.keywords.some((kw) => kw.slug === keyword));
    // console.log("Filtered products:", filteredProducts); // Debugging
    return filteredProducts;
  });
};

export const selectCurrentPage = createSelector(selectProductsState, (state) => state.currentPage);
export const selectPageSize = createSelector(selectProductsState, (state) => state.pageSize);
export const selectPaginatedPosts = createSelector(
  selectAllProducts,
  selectCurrentPage,
  selectPageSize,
  (allPosts, currentPage, pageSize) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return allPosts.slice(startIndex, endIndex);
  }
);

/**
 * Selects the total number of products from the post state.
 *
 * @param state - The product state.
 * @returns The total number of products.
 */
export const selectPostTotal = createSelector(selectProductsState, selectTotalProducts);

/**
 * Selects the loading state of the product.
 * @param state - The product state.
 * @returns True if the product is currently loading, false otherwise.
 */
export const isPostLoading = createSelector(selectProductsState, (state: ProductsState) => state.loading);

/**
 * Selects the pagination information from the product state.
 *
 * @returns The pagination information object.
 */
export const selectPaginationInfo = createSelector(selectProductsState, (state: ProductsState) => state.paginationInfo);

export const selectFormState = createSelector(selectProductsState, (state: ProductsState) => state.formState);

export const selecProductBySlugFactory = (slug: string) => {
  return createSelector(selectAllProducts, (entities: { [id: number]: Product }) => {
    // console.log('Entities:', entities);
    // console.log('Looking for slug:', slug);
    return Object.values(entities).find((product) => product.slug === slug);
  });
};

export const selectProductsByKeyword = (keyword: string) => createSelector(
  selectAllProducts,
  (products: Product[]) => {
    const lowerCaseKeyword = keyword.toLowerCase();
    return products.filter(product =>
      product.title.toLowerCase().includes(lowerCaseKeyword) ||
      product.product_name.toLowerCase().includes(lowerCaseKeyword)
    );
  }
);

