import { createFeatureSelector, createSelector } from '@ngrx/store';
import { supportGuideFeatureKey } from './support-guide.reducer';
import { adapter, SupportGuideState } from './support-guide.state';

export const selectSupportGuideState = createFeatureSelector <SupportGuideState> (supportGuideFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectSupportGuideIds = createSelector(
    selectSupportGuideState,
    selectIds
);

export const selectSupportGuideEntities = createSelector(
    selectSupportGuideState,
    selectEntities
);

export const selectAllSupportGuides = createSelector(
    selectSupportGuideState,
    selectAll
);

export const selectSupportGuideByIdFactory = (id: number) => {
    return createSelector(
        selectSupportGuideEntities,
        (entities) => entities[id]
    );

}

export const selectSupportGuideTotal = createSelector(
    selectSupportGuideState,
    selectTotal
);

export const selectSupportGuideById = (id: number) =>
    createSelector(
        selectSupportGuideEntities,
        (entities) => entities[id]
    );

export const selectSupportGuideLoading = createSelector(
    selectSupportGuideState,
    (state) => state.loading
);

export const selectSupportGuideError = createSelector(
    selectSupportGuideState,
    (state) => state.error
);

export const selectSupportGuidePaginationInfo = createSelector(
    selectSupportGuideState,
    (state) => state.paginationInfo
);

export const selectSupportGuideFormState = createSelector(
    selectSupportGuideState,
    (state: SupportGuideState) => state.formState
);

export const selectSupportGuideCurrentPage = createSelector(
    selectSupportGuideState,
    (state) => state.currentPage
);

export const selectSupportGuidePageSize = createSelector(
    selectSupportGuideState,
    (state) => state.pageSize
);

export const selectSupportGuideTotalItems = createSelector(
    selectSupportGuideState,
    (state) => state.total
);

export const isSupportGuideLoading = createSelector(
    selectSupportGuideLoading,
    (loading) => loading
);




