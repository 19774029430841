import { Component, Input } from '@angular/core';
import { ProductSearchResult } from 'src/app/shared/models/product-search-result.interface';

@Component({
  selector: 'app-product-search-result',
  templateUrl: './product-search-result.component.html',
  styleUrls: ['./product-search-result.component.scss']
})
export class ProductSearchResultComponent {
  @Input() result!: ProductSearchResult;
}
