import { Component, Input, OnInit } from '@angular/core';
import { faFilePdf, IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-call-to-action-small-with-icon1-container',
  templateUrl: './call-to-action-small-with-icon1-container.component.html',
  styleUrls: ['./call-to-action-small-with-icon1-container.component.scss']
})
export class CallToActionSmallWithIcon1ContainerComponent implements OnInit {
  @Input() ctaTitle: String = "About Scinomix"
  @Input() ctaDescription: String = "Download"
  @Input() ctaUrl: string = "#"
  @Input() ctaIcon: IconDefinition = faFilePdf
  constructor() { }

  ngOnInit(): void {
  }

}
