import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { ListItem, ProductCategorySchema } from 'src/app/shared/models/schema-types.interface';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { Category } from 'src/app/features/products/models/category.interface';
import { Product } from 'src/app/features/products/models/product.interface';

@Component({
  selector: 'app-products-by-solution',
  templateUrl: './products-by-solution.component.html',
  styleUrls: ['./products-by-solution.component.scss']
})
export class ProductsBySolutionComponent implements OnChanges {
  @Input() products: Product[] = [];
  @Input() loading: boolean = false;
  @Input() error: string | null = null;
  @Input() categoryData: Category | null = null;
  breadcrumbs: Breadcrumb[] = [];

  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoryData && this.categoryData) {
      const newMetaTags = {
        title: this.categoryData.meta_title,
        description: this.categoryData.meta_description,
        keywords: '', // Set this to any keywords you want to use, or add a property to the Category interface for keywords
      };
      this.metaTagsService.updateMetaTags(newMetaTags);
      this.breadcrumbs = [
        { label: 'Home', url: '/' },
        { label: this.categoryData.name, url: '/about-us' },
      ];
    }

    if (changes.categoryData && this.categoryData) {
      const itemListElement: ListItem[] = this.products.map((product, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': product.id.toString(),
          name: product.product_name,
          url: product.url,
        },
      }));

      const productCategorySchema: ProductCategorySchema = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement,
      };

      this.schemaService.updateSchema(productCategorySchema);
    }
  }
}
