<!-- admin-menu.component.html -->
<div class="accordion" id="adminMenuAccordion">
  <ng-container *ngFor="let item of menuItems; let i = index">
    <div class="accordion-item" *ngIf="item.heading">
      <h2 class="accordion-header" [id]="'heading' + i">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
          {{ item.label }}
        </button>
      </h2>
      <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" data-bs-parent="#adminMenuAccordion">
        <div class="accordion-body">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let child of item.children">
              <a [routerLink]="child.path" [attr.title]="child.label">{{ child.label }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="!item.heading">
      <a [routerLink]="item.path" class="list-group-item list-group-item-action" [attr.title]="item.label">{{ item.label }}</a>
    </div>
  </ng-container>
</div>
