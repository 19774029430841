<!-- <div *ngIf="error">
    Error: {{ error }}
</div> -->

<div *ngIf="!loading && !error">


    <div *ngIf="categoryData">
        <app-page-banner-container [backgroundImage]="categoryData.page_title_image" [title]="categoryData.name"
            pageLink="/about-us" [breadcrumbs]="breadcrumbs"></app-page-banner-container>
        <!-- <h2>{{ categoryData.name }}</h2>
        <p>{{ categoryData.description }}</p>
        <img *ngIf="categoryData.page_title_image" [src]="categoryData.page_title_image" alt="{{ categoryData.name }}"> -->
    </div>

    <div class="services-area bg-eff8fb ptb-75">
        <div class="container">
            <div class="row">
                <div class="col-md-8">

                    <h1 class="pb-20">Lab Automation Solutions for {{categoryData.name}}</h1>
                    <div *ngIf="categoryData.description" class="mb-20" [innerHtml]="categoryData.description"></div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let product of products">
                            <app-product-card-container [product]="product"></app-product-card-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <app-help-widget-container></app-help-widget-container>
                </div>
            </div>
        </div>
    </div>