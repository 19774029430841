import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-burger-menu-container',
  templateUrl: './burger-menu-container.component.html',
  styleUrls: ['./burger-menu-container.component.scss']
})
export class BurgerMenuContainerComponent {
  @Output() toggle = new EventEmitter<void>();
  classApplied = false;

  toggleClass() {
    // console.log('BurgerMenuContainerComponent - toggleClass() called');
    this.classApplied = !this.classApplied;
    this.toggle.emit();
  }
}
