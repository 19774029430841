// post-list-widget-container.component.ts
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Post } from 'src/app/shared/models/post.interface';
import { loadPostsByBlogId } from '../../store/post.actions';
import { selectAllPosts, selectPostBySlugFactory, selectPublicPosts } from '../../store/post.selectors';
import { PostState } from '../../store/post.state';

@Component({
  selector: 'app-post-list-widget-container',
  templateUrl: './post-list-widget-container.component.html',
  styleUrls: ['./post-list-widget-container.component.scss']
})
export class PostListWidgetContainerComponent {
  allPosts$: Observable<Post[]>;
  posts$: Observable<Post[]>;
  totalPosts$: Observable<number>;
  showMoreButton$: Observable<boolean>;
  @Input() currentPost: Post;
  @Input() placement: 'footer' | 'sidebar' = 'sidebar';
  @Input() shouldShowMore: boolean = true;
  @Input() maxArticles: number = 3;

  constructor(private route: ActivatedRoute, private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(loadPostsByBlogId({ blogId: 1 }));
    this.allPosts$ = this.store.select(selectPublicPosts);

    this.posts$ = this.allPosts$.pipe(
      // filter out the currentPost by slug
      map(posts => {
        if (this.currentPost) {
          return posts.filter(post => post.slug !== this.currentPost.slug);
        }
        return posts;
      })
    );

    this.totalPosts$ = this.allPosts$.pipe(
      map(posts => posts.length)
    );

    this.showMoreButton$ = combineLatest([
      this.posts$.pipe(map(posts => posts.length)),
      this.posts$.pipe(map(posts => posts.slice(0, this.maxArticles).length), startWith(0))
    ]).pipe(
      map(([totalPosts, displayedPosts]) => {
        return displayedPosts < totalPosts && this.shouldShowMore;
      })
    );
  }

  loadMorePosts() {
    this.maxArticles += 3;
  }

}
