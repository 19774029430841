<app-troubleshooting-admin-list
  [guides]="guides$ | async"
  [paginationInfo]="paginationInfo$ | async"
  (edit)="onEdit($event)" 
  (delete)="onDelete($event)" 
  (create)="onCreate()"
  (pageChanged)="onPageChange($event)"
  (itemsPerPageChanged)="onChangePageSize($event)">
  >
</app-troubleshooting-admin-list>
