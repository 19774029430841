import { createFeatureSelector, createSelector } from '@ngrx/store';
import { solutionTagsFeatureKey } from './solution-tags.reducer';
import { adapter, SolutionTagsState } from './solution-tags.state';

export const selectSolutionTagsState = createFeatureSelector <SolutionTagsState > (solutionTagsFeatureKey);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectSolutionTagsIds = createSelector(
    selectSolutionTagsState,
    selectIds
);

export const selectSolutionTagsEntities = createSelector(
    selectSolutionTagsState,
    selectEntities
);

export const selectAllSolutionTags = createSelector(
    selectSolutionTagsState,
    selectAll
);

export const selectSolutionTagsTotal = createSelector(
    selectSolutionTagsState,
    selectTotal
);

export const selectSolutionTagById = (id: number) =>
    createSelector(
        selectSolutionTagsEntities,
        (entities) => entities[id]
    );

export const selectSolutionTagsLoading = createSelector(
    selectSolutionTagsState,
    (state) => state.loading
);

export const selectSolutionTagsError = createSelector(
    selectSolutionTagsState,
    (state) => state.error
);
