// dynamic-component.tokens.ts
import { InjectionToken } from '@angular/core';
import { MultiStepFormContainerComponent } from 'src/app/shared/modules/multi-step-form/containers/multi-step-form-container/multi-step-form-container.component';

export const DYNAMIC_COMPONENTS_MAP = new InjectionToken<Map<string, any>>('DYNAMIC_COMPONENTS_MAP', {
    providedIn: 'root',
    factory: () => new Map([
        ['multiStepForm', MultiStepFormContainerComponent],
        // Add more components as necessary
    ]),
});
