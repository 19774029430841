// src/app/store/post/post.effects.ts
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, switchMap, tap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";

import * as PostActions from "./post.actions";
import { PostService } from "src/app/core/services/post.service";
import { Post } from "src/app/shared/models/post.interface";
import { Router } from "@angular/router";
import { AlertService } from "src/app/core/services/alert.service";

@Injectable()
export class PostEffects {
  loadPostsByBlogId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.loadPostsByBlogId),
      switchMap((action) =>
        this.postService.getPostsByBlogId(action.blogId).pipe(
          map((posts) => {
            // console.log('Posts received:', posts);
            return PostActions.loadPostsByBlogIdSuccess({ posts });
          }),
          catchError((error) => of(PostActions.loadPostsByBlogIdFailure({ error })))
        )
      )
    )
  );

  loadPaginatedPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.loadPaginatedPosts),
      switchMap((action) =>
        this.postService.getPaginatedPosts(action.page, action.limit).pipe(
          map((response) =>
            PostActions.loadPaginatedPostsSuccess({
              posts: response.posts,
              total: parseInt(response.pagination?.count || "0", 10),
              paginationInfo: response.pagination,
            })
          ),
          catchError((error) => of(PostActions.loadPaginatedPostsFailure({ error })))
        )
      )
    )
  );

  loadPostBySlug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.loadPostBySlug),
      switchMap((action) =>
        this.postService.getPostBySlug(action.slug).pipe(
          map((post) => PostActions.loadPostBySlugSuccess({ post })),
          catchError((error) => of(PostActions.loadPostBySlugFailure({ error })))
        )
      )
    )
  );

  createPost$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PostActions.createPost),
    switchMap((action) => {
      if (action.imageFile) {
        // Handle post creation with image upload
        return this.postService.createPost(action.post, action.imageFile).pipe(
          map((post) => PostActions.createPostSuccess({ post })),
          catchError((error) => of(PostActions.createPostFailure({ error })))
        );
      } else {
        // Handle post creation without image upload
        return this.postService.createPost(action.post).pipe(
          map((post) => PostActions.createPostSuccess({ post })),
          catchError((error) => of(PostActions.createPostFailure({ error })))
        );
      }
    }),
    tap({
      next: () => {
        this.router.navigate(["/admin/posts"]); // Adjust the URL as needed
        this.alertService.show({ message: 'Post creation successful!', type: 'success' });
      },
      error: (error) => {
        console.error('Error creating post:', error);
        this.alertService.show({ message: 'Error creating post.', type: 'danger' });
      }
    })
  )
);

createPostWithKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.createPostWithKeywords),
      switchMap(({ post, keywords, imageFile }) => {
        // First, create the post (with or without an image)
        const createPost$ = imageFile
          ? this.postService.createPost(post, imageFile)
          : this.postService.createPost(post);

        return createPost$.pipe(
          // After the post is created, handle keyword creation
          concatMap((createdPost) =>
            forkJoin([
              ...keywords.create.map((keyword) => this.postService.createKeyword(createdPost.id, keyword)),
              // Additional operations if needed
            ]).pipe(
              map(() => PostActions.createPostWithKeywordsSuccess({ post: createdPost })),
              catchError((error) => of(PostActions.createPostWithKeywordsFailure({ error })))
            )
          ),
          catchError((error) => of(PostActions.createPostWithKeywordsFailure({ error })))
        );
      }),
      tap({
        next: () => {
          this.router.navigate(["/admin/posts"]); // Adjust the URL as needed
          this.alertService.show({ message: 'Post creation successful!', type: 'success' });
        },
        error: (error) => {
          console.error('Error creating post with keywords:', error);
          this.alertService.show({ message: 'Error creating post with keywords.', type: 'danger' });
        }
      })
    )
  );

// post.effects.ts
updatePost$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PostActions.updatePost),
    switchMap((action) => {
      if (action.imageFile) {
        // If there's an image file, handle the image upload
        return this.postService.updatePost(action.post, action.imageFile).pipe(
          map((post) => PostActions.updatePostSuccess({ post })),
          catchError((error) => of(PostActions.updatePostFailure({ error })))
        );
      } else {
        // If there's no image file, just update the post without the image
        return this.postService.updatePost(action.post).pipe(
          map((post) => PostActions.updatePostSuccess({ post })),
          catchError((error) => of(PostActions.updatePostFailure({ error })))
        );
      }
    }),
    tap(() => {
      this.router.navigate(["/admin/posts"]); // Adjust the URL as needed
      this.alertService.show({ message: 'Update successful!', type: 'info' });
    })
  )
);


  uploadPostImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.uploadPostImage),
      switchMap((action) =>
        this.postService.uploadImage(action.postId, action.imageFile).pipe(
          map(() => PostActions.uploadPostImageSuccess()),
          catchError((error) => of(PostActions.uploadPostImageFailure({ error })))
        )
      )
    )
  );

  updateKeywordsAfterPostUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.updatePostWithKeywords),
      switchMap(({ post, keywords }) => {
        // Check if there are keywords to delete
        if (keywords.delete.length > 0) {
          // If there are, delete them first
          return forkJoin(keywords.delete.map((keyword) => this.postService.deleteKeyword(post.id, keyword))).pipe(
            // After delete operations complete, run create and edit operations
            concatMap(() =>
              forkJoin([
                ...keywords.create.map((keyword) => this.postService.createKeyword(post.id, keyword)),
                ...keywords.edit.map((keyword) => this.postService.editKeyword(post.id, keyword)),
              ])
            ),
            map(() => PostActions.updatePostWithKeywordsSuccess({ post })),
            catchError((error) => of(PostActions.updatePostWithKeywordsFailure({ error })))
          );
        } else {
          // If there are no keywords to delete, directly run create and edit operations
          return forkJoin([
            ...keywords.create.map((keyword) => this.postService.createKeyword(post.id, keyword)),
            ...keywords.edit.map((keyword) => this.postService.editKeyword(post.id, keyword)),
          ]).pipe(
            map(() => PostActions.updatePostWithKeywordsSuccess({ post })),
            catchError((error) => of(PostActions.updatePostWithKeywordsFailure({ error })))
          );
        }
      })
    )
  );

  deletePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.deletePost),
      switchMap((action) =>
        this.postService.deletePost(action.postId).pipe(
          map(() => PostActions.deletePostSuccess({ postId: action.postId })),
          catchError((error) => of(PostActions.deletePostFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private postService: PostService,
    private router: Router,
    private alertService: AlertService
  ) {}
}
