import { Component, Input } from '@angular/core';
import { CustomItem } from '../../models/custom-item.interface';

@Component({
  selector: 'app-bullet-features-list-horizontal-icon-above',
  templateUrl: './bullet-features-list-horizontal-icon-above.component.html',
  styleUrls: ['./bullet-features-list-horizontal-icon-above.component.scss']
})
export class BulletFeaturesListHorizontalIconAboveComponent {
  @Input() customItems: CustomItem[] = [];
  @Input() numColumns: number = 3;
}
