import { Testimonial } from "src/app/shared/models/testimonial.interface";

export interface TestimonialsState {
    testimonials: Testimonial[];
    loading: boolean;
    error: any;
}

export const initialState: TestimonialsState = {
    testimonials: [],
    loading: false,
    error: null,
};
