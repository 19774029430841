import { Component, Input, OnInit } from '@angular/core';
import { CustomItem, CustomItemClass } from '../../models/custom-item.interface';

@Component({
  selector: 'app-about-container',
  templateUrl: './about-container.component.html',
  styleUrls: ['./about-container.component.scss']
})
export class AboutContainerComponent {
  @Input()
  set customItems(value: CustomItem[]) {
    this._customItems = value || this.getDefaultCustomItems();
  }

  @Input() subtitle: string;
  @Input() title: string;
  @Input() titleHighlight: string;
  @Input() description: string;
  @Input() heroImage: string;
  private _customItems: CustomItem[];

  get customItems(): CustomItem[] {
    return this._customItems;
  }

  private getDefaultCustomItems(): CustomItem[] {
    return [
      new CustomItemClass({ name: 'On Time Delivery & On Budget', description: 'Scinomix will effectively develop automation mechanisms with a process that will control and optimize planning, provide on time delivery, and stay within budget parameters.' }),
      new CustomItemClass({ name: 'Help & Support for Your Scinomix Products', description: "Scinomix strives to provide excellent support to all customers. We're just a phone call or e-mail away. No waiting for days, weeks, and months waiting for your questions to be resolved." }),
      new CustomItemClass({ name: 'Quality Service Agreements', description: 'Scinomix offers preventive maintenance plans and service agreements on all equipment.' }),
    ];
  }


  ngOnInit() {
    if (!this.customItems) {
      this.customItems = this.getDefaultCustomItems()
    }
  }

}
