import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";

import * as SolutionTagsActions from "./solution-tags.actions";
import { SolutionTagsService } from "src/app/core/services/solution-tags.service";

@Injectable()
export class SolutionTagsEffects {
  loadSolutionTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SolutionTagsActions.loadSolutionTags),
      mergeMap(() =>
        this.SolutionTagsService.getSolutionTags().pipe(
          map((SolutionTags) => SolutionTagsActions.loadSolutionTagsSuccess({ SolutionTags })),
          catchError((error) => of(SolutionTagsActions.loadSolutionTagsFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private SolutionTagsService: SolutionTagsService) {}
}
