import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { FAQ } from 'src/app/shared/models/faq.interface';

export interface FAQState extends EntityState<FAQ> {
    loading: boolean;
    error: any;
}

export const adapter: EntityAdapter<FAQ> = createEntityAdapter<FAQ>();

export const initialState: FAQState = adapter.getInitialState({
    loading: false,
    error: null,
});
