<div class="middle-header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="middle-header">
                    <app-logo-container [maxWidth]="'200px'" height="64" width="200"></app-logo-container>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <ul class="middle-header-content">
                    <li>
                        <div class="d-flex align-items-center blue-text">
                            <div class="sci-icon award-icon xlarge-icon"></div>
                            <a style="margin-left:18px;" routerLink="/testimonials">View Our Testimonials<span>
                                    Customer Approved Excellence</span></a>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>