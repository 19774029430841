// shared/components/alert/alert.component.ts
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Alert } from '../../../core/models/alert.interface';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit{
  @Input() alert: Alert;
  @Output() close = new EventEmitter<number>();
  isClosing = false;
  onClose(): void {
    this.isClosing = true;
    // setTimeout(() => {
    //   this.close.emit(this.alert.id);
    // }, 300);
  }

  ngOnInit(): void {
    // console.log(this.alert)
  }
}
