import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Post } from '../../../../shared/models/post.interface'; // Adjust the path as needed
import { PaginationInfo } from '@fluentllc/core';
@Component({
  selector: 'app-post-admin-list',
  templateUrl: './post-admin-list.component.html',
  styleUrls: ['./post-admin-list.component.scss']
})
export class PostAdminListComponent {
  @Input() posts: Post[];
  @Input() paginationInfo: PaginationInfo;
  @Output() edit = new EventEmitter<string>();
  @Output() delete = new EventEmitter<number>();
  @Output() create = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() itemsPerPageChanged = new EventEmitter<number>();

  onPageChange(newPage: number): void {
    this.pageChanged.emit(newPage);
  }

  onItemsPerPageChange(paginateBy: number): void {
    this.itemsPerPageChanged.emit(paginateBy);
  }

  getStatusBadge(post: Post): string {
    if (!post.published_at) return 'Draft';
    const today = new Date();
    const publishDate = new Date(post.published_at);
    return publishDate <= today ? 'Published' : 'Scheduled';
  }
}
