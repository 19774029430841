import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialsRoutingModule } from './testimonials-routing.module';
import { TestimonialsStoreModule } from './store/testimonials-store.module';
import { TestimonialCardContainerComponent } from './containers/testimonial-card-container/testimonial-card-container.component';
import { TestimonialsPageContainerComponent } from './containers/testimonials-page-container/testimonials-page-container.component';
import { TestimonialsListContainerComponent } from './containers/testimonials-list-container/testimonials-list-container.component';
import { TestimonialCardComponent } from './components/testimonial-card/testimonial-card.component';
import { TestimonialsListComponent } from './components/testimonials-list/testimonials-list.component';
import { TestimonialsPageComponent } from './components/testimonials-page/testimonials-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RatingModule } from 'ngx-bootstrap/rating';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    TestimonialCardContainerComponent,
    TestimonialsPageContainerComponent,
    TestimonialsListContainerComponent,
    TestimonialCardComponent,
    TestimonialsListComponent,
    TestimonialsPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TestimonialsRoutingModule,
    TestimonialsStoreModule,
    RatingModule,
    CarouselModule,
  ],
  exports: [
    TestimonialCardContainerComponent,
    TestimonialsPageContainerComponent,
    TestimonialsListContainerComponent
  ]
})
export class TestimonialsModule { }
