import { Component, Input } from '@angular/core';
import { faCheckCircle, faCheckSquare, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faCheckToSlot } from '@fortawesome/free-solid-svg-icons';
import { JobPosting } from 'src/app/shared/models/job-posting.interface';

@Component({
  selector: 'app-job-posting-list',
  templateUrl: './job-posting-list.component.html',
  styleUrls: ['./job-posting-list.component.scss']
})
export class JobPostingListComponent {
  @Input() jobPostings: JobPosting[] = [];
  checkIcon: IconDefinition = faCheckSquare;
}
