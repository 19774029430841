import { createAction, props } from '@ngrx/store';
import { ContextData } from 'src/app/shared/models/context-data.interface';
import { DemoRequest } from 'src/app/shared/models/demo-request.interface';


export const addDemoRequest = createAction(
    '[Demo Request] Add Demo Request',
    props<{ demoRequest: DemoRequest; contextData: ContextData }>()
);

export const addDemoRequestSuccess = createAction(
    '[Demo Request] Add Demo Request Success',
    props<{ demoRequest: DemoRequest }>()
);

export const addDemoRequestFailure = createAction(
    '[Demo Request] Add Demo Request Failure',
    props<{ error: any }>()
);

export const setSuccessMessage = createAction(
    '[Demo Request] Set Success Message',
    props<{ message: string }>()
);

export const resetDemoRequestSlice = createAction('[Demo Request] Reset Demo Request Slice');