<div *ngIf="media?.length" class="product-slider-section-5">
    <div [id]="sliderId" class="carousel slide">
        <div class="carousel-inner">
            <div *ngFor="let item of media; let i = index" class="carousel-item" [ngClass]="{'active': i === activeIndex}">
                <ng-container *ngIf="item?.type === 'image' || item?.type === 'external'">
                    <img [src]="item?.file"
                        (click)="item?.type === 'image' ? imageView.openModal(item) : videoPlayer.openModal(item)"
                        class="d-block w-100" [alt]="item?.caption">
                    <div *ngIf="item?.caption" class="caption-style">{{item?.caption}}</div>
                </ng-container>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" [attr.data-target]="'#' + sliderId" (click)="prev()"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" [attr.data-target]="'#' + sliderId" (click)="next()"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
        <ol *ngIf="media?.length > 1" class="carousel-indicators position-static m-0 justify-content-start">
            <li *ngFor="let item of media; let i = index" [attr.data-target]="'#' + sliderId" [attr.data-slide-to]="i"
                (click)="selectSlide(i)" [ngClass]="{'active': i === activeIndex}">
                <div class="data-slide-image">
                    <img class="img-fluid" [src]="item?.thumbnail" [title]="item?.title" [alt]="item?.meta_alt" />
                </div>
            </li>
        </ol>
    </div>
    <app-image-viewer #imageView></app-image-viewer>
    <app-embedded-video-player #videoPlayer></app-embedded-video-player>
</div>
