<div *ngIf="searchResults || searchPerformed" class="ptb-30">
    <ng-container *ngIf="searchResults.length > 0; else noResults">
        <div class="card card-default" class="card card-default">
            <div class="card-header">
                Search Results
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-hover">
                    <tbody>
                        <tr *ngFor="let result of searchResults">
                            <td>
                                <ng-container [ngSwitch]="getResultType(result)">
                                    <app-product-search-result *ngSwitchCase="'product'"
                                        [result]="result"></app-product-search-result>
                                    <app-support-guide-search-result *ngSwitchCase="'supportGuide'"
                                        [result]="result"></app-support-guide-search-result>
                                    <app-faqsearch-result *ngSwitchCase="'faq'"
                                        [result]="result"></app-faqsearch-result>
                                    <app-post-search-result *ngSwitchCase="'post'"
                                        [result]="result"></app-post-search-result>
                                </ng-container>
                            </td>

                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </ng-container>
    <ng-template #noResults>
        <div class="card card-default" *ngIf="searchPerformed">
            <div class="alert alert-info">

                Oops! It seems we couldn't find any results matching your search. Don't worry, let's try another search
                term or <a href="tel:3142989800">call us at 314-298-9800</a> to help find what you need. We're here to
                help and answer any questions you may have.
            </div>
        </div>
    </ng-template>
</div>