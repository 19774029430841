<div class="container">
  <div class="row">
    <!-- Article Content-->
    <tabset>
      <tab heading="Product Edit">
        <div class="row">
          <div class="col-xl-9 pb-100">
            <div class="card card-default">
              <div class="card-body">
                <div class="post-edit">
                  <app-content-editor [disableSave]="true" (save)="onSave()" (cancel)="onCancel()"
                    [isFormValid]="productForm.valid">
                    <form [formGroup]="productForm">
                      <div class="form-group">
                        <p>Title (Full Description)</p>
                        <input id="title" class="mb-3 form-control form-control-lg" formControlName="title" />
                        <div *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)" class="alert alert-danger">
                          Title is required.
                        </div>
                      </div>
                      <div class="form-group">
                        <p>Item Number</p>
                        <input id="upc" class="mb-3 form-control form-control-lg" formControlName="upc" />
                        <div *ngIf="productForm.get('upc').invalid && (productForm.get('upc').dirty || productForm.get('upc').touched)" class="alert alert-danger">
                          Item number is required.
                        </div>
                      </div>
                      <div class="form-group">
                        <p>Product Name (Shortened)</p>
                        <input id="title" class="mb-3 form-control form-control-lg" formControlName="product_name" />
                        <div *ngIf="f.product_name.invalid && (f.product_name.dirty || f.product_name.touched)"
                          class="alert alert-danger">
                          Product name is required.
                        </div>
                      </div>
                      <div class="form-group">
                        <p>Slug</p>
                        <small>Please note that it is best not to change slugs once they are established.</small>
                        <input id="slug" class="mb-3 form-control form-control-lg" formControlName="slug"
                          placeholder="Leave blank to autogenerate the slug based on the title." />

                      </div>
                      <div class="form-group mb-3">
                        <p>Description</p>
                        <summernote formControlName="description" name="description" airMode [ngxSummernote]="config">
                        </summernote>
                        <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)"
                          class="alert alert-danger">
                          Product description is required.
                        </div>
                      </div>
                    </form>
                  </app-content-editor>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="card card-default mb-3">
              <div class="card-body">
                <p class="lead">Product Data</p>
                <app-meta-data [seoData]="product" (seoDataChange)="onSeoDataChange($event)"></app-meta-data>
              </div>
            </div>
            <div class="card card-default mb-3">
              <div class="card-body">
                <p class="lead">Product Class</p>
                <select [value]="productClassId" (change)="onProductClassChange($event)" class="form-control">
                  <option *ngFor="let class of classes" [value]="class.id"
                    [selected]="class.id === product.product_class">{{
                    class.name }}</option>
                </select>
              </div>
            </div>
            <div class="mb-3">
              <app-solution-tags [tags]="solutionTagIds"
                (tagsChange)="onSolutionTagsChange($event)"></app-solution-tags>
            </div>
            <div class="card card-default mb-3">
              <div class="card-body">
                <p class="lead">Main Product Image</p>
                <app-image-upload [currentImageUrl]="product?.images[0].original"
                  (onImageSelected)="handleImageSelected($event)"></app-image-upload>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Product Images">
        <div class="row">
          <div class="col-xl-12 pb-100">
            <div class="card card-default">
              <div class="card-body">
                <app-media-admin-list-container [mediaItems]="mediaItems$ | async"></app-media-admin-list-container>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Capabilities & Benefits" *ngIf="product.features?.length > 0">
        <div class="row">
          <div class="col-xl-12 pb-30">
            <div class="card card-default">
              <div class="card-header">
                Capabilities
              </div>
              <div class="card-body">
                <app-feature-admin *ngIf="product.features?.length > 0" [features]="product.features"
                  (updateFeatures)="handleFeatureUpdate($event)"></app-feature-admin>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 pb-30">
            <div class="card card-default">
              <div class="card-header">
                Benefits
              </div>
              <div class="card-body">
                <app-benefit-admin [benefits]="product.benefits"
                  (updateBenefits)="handleBenefitUpdate($event)"></app-benefit-admin>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Specifications">

        <div class="row">
          <div class="col-xl-12 pb-30">

            <div class="card card-default">
              <div class="card-header">
                Product Specifications PDF
              </div>
              <div class="card-body">
                <app-media-admin-list-container objectType='specifications' [mediaItems]="specifications$ | async"
                  uploadButtonText="Add/Upload PDF"></app-media-admin-list-container>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="card card-default">
              <div class="card-header">
                Product Specifications Table
              </div>
              <div class="card-body">
                <app-specifications-admin [specifications]="product.specifications"
                  (updateSpecifications)="handleSpecificationUpdate($event)"></app-specifications-admin>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Product Attributes" *ngIf="product.product_class_details.slug==='label'">
        <div class="row">
          <div class="col-xl-12 pb-30">
            <div class="card card-default">
              <div class="card-header">
                Product Attributes
              </div>
              <div class="card-body">
                <app-product-attribute-value-admin-list [productAttributeValues]="product.attributes"
                  (updateProductAttributeValues)="handleProductAttributeValueUpdate($event)"></app-product-attribute-value-admin-list>
              </div>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
    <div class="row">
      <div class="col-xl-12 plr-30 pb-75">
        <button class="btn btn-secondary me-3" type="button" (click)="onCancel()">
          Cancel</button>
        <button class="btn btn-primary" type="button" (click)="onSave()" [disabled]="!isFormValid">
          <em class="fa fa-check fa-fw"></em>Save Product</button>
      </div>
    </div>
  </div>