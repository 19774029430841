import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { JobApplication } from "src/app/shared/models/job-application.interface";
import { JobPosting } from "src/app/shared/models/job-posting.interface";
import { v4 as uuidv4 } from "uuid";
declare var hbspt: any;
@Component({
  selector: "app-job-application",
  templateUrl: "./job-application.component.html",
  styleUrls: ["./job-application.component.scss"],
})
export class JobApplicationComponent implements OnInit, AfterViewInit {
  @Input() applicationForm: FormGroup;
  @Input() job: JobPosting;
  @Output() formSubmit: EventEmitter<JobApplication> = new EventEmitter();
  @Input() pageName: string;
  @Input() pageUri: string;
  @Input() successMessage: string | null;
  @Input() submitted: boolean = false;
  resumeFile: File;
  coverLetterFile: File;

  constructor(private _elementRef: ElementRef) { }
  ngOnInit() {
    this.applicationForm.get("jobPosition").setValue(this.job.title);
  }

  isInvalidAndTouched(name: string): boolean {
    const control = this.applicationForm.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  onFileChange(event: any, field: string) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (field === "resume") {
        this.resumeFile = file;
      } else if (field === "coverLetter") {
        this.coverLetterFile = file;
      }
    }
  }

  ngAfterViewInit(): void {
    // Embed the HubSpot form script
    if (typeof hbspt !== "undefined") {
      hbspt.forms.create({
        portalId: "4896724",
        formId: "0da078c5-eff1-4ad2-b979-93517f2fb0a9",
        target: "#hubspotForm",
        region: "na1",
        onFormReady: ($form, ctx) => {
          // Set the value of the hidden field
          var hiddenField = $form.find('input[name="application_job_title"]');
          if (hiddenField) {
            hiddenField.val(this.job.title);
          }
        }
      });
    } else {
      const scriptTag = document.createElement("script");
      scriptTag.src = "//js.hsforms.net/forms/shell.js";
      scriptTag.addEventListener("load", () => {
        hbspt.forms.create({
          portalId: "4896724",
          formId: "0da078c5-eff1-4ad2-b979-93517f2fb0a9",
          target: "#hubspotForm",
          region: "na1",
          onFormReady: ($form, ctx) => {
            // Set the value of the hidden field
            var hiddenField = $form.find('input[name="application_job_title"]');
            if (hiddenField) {
              hiddenField.val(this.job.title);
            }
          }
        });
      });
      this._elementRef.nativeElement.appendChild(scriptTag);
    }
  }

  onSubmit() {
    if (this.applicationForm.valid) {
      const jobApplication: JobApplication = {
        id: uuidv4(),
        firstName: this.applicationForm.value.firstName,
        lastName: this.applicationForm.value.lastName,
        email: this.applicationForm.value.email,
        phoneNumber: this.applicationForm.value.phoneNumber,
        resume: this.resumeFile,
        coverLetter: this.coverLetterFile,
        jobPosition: this.applicationForm.get("jobPosition").value,
      };
      this.formSubmit.emit(jobApplication);
      this.applicationForm.reset();
    }
  }
}
