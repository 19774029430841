<!-- BulletFeatureItemComponent Template -->
<li class="feature-item">
    <div class="icon-container">
      <i class="{{customItem.icon}}"></i>
    </div>
    <div class="text-container">
      <h5>{{customItem.name}}</h5>
      <small>{{customItem.description}}</small>
    </div>
  </li>
  