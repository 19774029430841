import { Injectable } from '@angular/core';

interface CacheEntry {
  expiry: number;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, CacheEntry>();

  constructor() {
  }

  set(key: string, data: any, maxAge: number) {
    const expiry = Date.now() + maxAge * 1000; // Convert seconds to milliseconds
    this.cache.set(key, { expiry, data });
  }

  get(key: string): any | null {
    const entry = this.cache.get(key);
    if (!entry) return null;

    const now = Date.now();
    if (now < entry.expiry) {
      return entry.data;
    } else {
      this.cache.delete(key); // Remove expired cache entry
      return null;
    }
  }
}
