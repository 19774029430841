// store/reducers/testimonials.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as TestimonialsAction from './testimonials.actions';
import { initialState } from './testimonials.state';
export const testimonialsFeatureKey = "testimonials";

export const testimonialsReducer = createReducer(
    initialState,
    on(TestimonialsAction.loadTestimonials, (state) => ({ ...state, isLoading: true })),
    on(TestimonialsAction.loadTestimonialsSuccess, (state, { testimonials }) => ({
        ...state,
        isLoading: false,
        testimonials,
    })),
    on(TestimonialsAction.loadTestimonialsFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),
    on(TestimonialsAction.loadTestimonialsByProductSlug, (state) => ({ ...state, isLoading: true })),
    on(TestimonialsAction.loadTestimonialsByProductSlugSuccess, (state, { testimonials }) => ({
        ...state,
        isLoading: false,
        testimonials,
    })),
    on(TestimonialsAction.loadTestimonialsByProductSlugFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),
    on(TestimonialsAction.resetTestimonials, (state) => ({
        ...state,
        testimonials: [],
        isLoading: false,
    })),
);
