import { Component, Input } from '@angular/core';
import { Distributor } from 'src/app/shared/models/distributor.interface';

@Component({
  selector: 'app-distributors-container',
  templateUrl: './distributors-container.component.html',
  styleUrls: ['./distributors-container.component.scss']
})
export class DistributorsContainerComponent {

}
