import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceSupportRoutingModule } from './service-support-routing.module';
import { ServiceSupportStoreModule } from './store/service-support-store.module';
import { ServiceSupportContainerComponent } from './containers/service-support-container/service-support-container.component';
import { ServiceSupportComponent } from './components/service-support/service-support.component';
import { FAQsStoreModule } from './store/faq-store.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SectionCardContainerComponent } from './containers/section-card-container/section-card-container.component';
import { SectionCardComponent } from './components/section-card/section-card.component';
import { SectionListComponent } from './components/section-list/section-list.component';
import { SectionListContainerComponent } from './containers/section-list-container/section-list-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ServiceAgreementsContainerComponent } from './containers/service-agreements-container/service-agreements-container.component';
import { ServiceAgreementsComponent } from './components/service-agreements/service-agreements.component';
import { FAQContainerComponent } from './containers/faq/faqcontainer/faqcontainer.component';
import { FAQComponent } from './components/faq/faq/faq.component';
import { TroubleshootingComponent } from './components/troubleshooting/troubleshooting.component';
import { TroubleshootingContainerComponent } from './containers/troubleshooting-container/troubleshooting-container.component';
import { ServicesSupportSidebarNavigationComponent } from './components/services-support-sidebar-navigation/services-support-sidebar-navigation.component';
import { ServicesSupportSidebarNavigationContainerComponent } from './containers/services-support-sidebar-navigation-container/services-support-sidebar-navigation-container.component';
import { SupportGuidesStoreModule } from './store/support-guide-store.module';
import { TroubleshootingAdminListContainerComponent } from './containers/troubleshooting-admin-list-container/troubleshooting-admin-list-container.component';
import { TroubleshootingAdminManageContainerComponent } from './containers/troubleshooting-admin-manage-container/troubleshooting-admin-manage-container.component';
import { TroubleshootingAdminListComponent } from './components/troubleshooting-admin-list/troubleshooting-admin-list.component';
import { TroubleshootingAdminManageComponent } from './components/troubleshooting-admin-manage/troubleshooting-admin-manage.component';
import { TaggingModule } from '../tagging/tagging.module';

@NgModule({
  declarations: [
    ServiceSupportContainerComponent,
    ServiceSupportComponent,
    SectionCardContainerComponent,
    SectionCardComponent,
    SectionListComponent,
    SectionListContainerComponent,
    ServiceAgreementsContainerComponent,
    ServiceAgreementsComponent,
    FAQContainerComponent,
    FAQComponent,
    TroubleshootingComponent,
    TroubleshootingContainerComponent,
    ServicesSupportSidebarNavigationComponent,
    ServicesSupportSidebarNavigationContainerComponent,
    TroubleshootingAdminListContainerComponent,
    TroubleshootingAdminManageContainerComponent,
    TroubleshootingAdminListComponent,
    TroubleshootingAdminManageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ServiceSupportRoutingModule,
    FAQsStoreModule,
    SupportGuidesStoreModule,
    FontAwesomeModule,
    TaggingModule
  ],
  exports: [
    TroubleshootingAdminListContainerComponent,
    
  ]
})
export class ServiceSupportModule { }
