import { createAction, props } from "@ngrx/store";
import { Media } from "../models/media.interface";

export const loadMedia = createAction("[Media] Load Media", props<{ slug: string }>());
export const loadMediaSuccess = createAction("[Media] Load Media Success", props<{ media: Media[] }>());
export const loadMediaFailure = createAction("[Media] Load Media Failure", props<{ error: any }>());
export const loadMediaByTags = createAction("[Media] Load Media By Tags", props<{ params: string }>());
export const loadMediaByTagsSuccess = createAction("[Media] Load Media By Tags Success", props<{ media: Media[] }>());
export const loadMediaByTagsFailure = createAction("[Media] Load Media By Tags Failure", props<{ error: any }>());
export const updateMediaWeights = createAction(
  "[Media] Update Weights",
  props<{ updatedMediaList: Media[]; productSlug: string }>()
);
export const updateMediaWeightsSuccess = createAction(
  "[Media] Update Weights Success",
  props<{ updatedMediaList: Media[] }>()
);
export const updateMediaWeightsFailure = createAction("[Media] Update Weights Failure", props<{ error: any }>());
export const updateBulkMedia = createAction("[Media] Update Bulk Media", props<{ updatedMediaList: Media[] }>());
export const updateBulkMediaSuccess = createAction(
  "[Media] Update Bulk Media Success",
  props<{ updatedMediaList: Media[] }>()
);
export const updateBulkMediaFailure = createAction("[Media] Update Bulk Media Failure", props<{ error: any }>());

export const editMedia = createAction("[Media] Edit Media", props<{ media: Media }>());
export const saveEditedMedia = createAction(
  "[Media] Save Edited Media",
  props<{ media: Media; file: File; thumbnail: File }>()
);
export const saveEditedMediaSuccess = createAction("[Media] Save Edited Media Success", props<{ media: Media }>());
export const saveEditedMediaFailure = createAction("[Media] Save Edited Media Failure", props<{ error: any }>());
export const clearMedia = createAction("[Media] Clear Media");
export const skipMediaReload = createAction("[Media] Skip Media Reload");
export const uploadFilesToProduct = createAction("[Media] Upload Files", props<{ files: File[], productSlug: string }>());

export const uploadFilesToProductSuccess = createAction(
  "[Media] Upload Files Success",
  props<{ response: any }>() // Adjust the response type based on your backend
);

export const uploadFilesToProductFailure = createAction("[Media] Upload Files Failure", props<{ error: any }>());
export const createMediaForProduct = createAction("[Media] Create Media for Product", props<{ media: Media, file: File; thumbnail: File, productSlug: string, characteristicsSlug?: string }>());
export const createMediaForProductSuccess = createAction("[Media] Create Media for Product Success", props<{ media: Media }>());    
export const createMediaForProductFailure = createAction("[Media] Create Media for Product Failure", props<{ error: any }>());
export const removeProductAssociation = createAction("[Media] Remove Product Association", props<{ productSlug: string, mediaId: number }>());
export const removeProductAssociationSuccess = createAction("[Media] Remove Product Association Success", props<{ productSlug: string, mediaId: number }>());
export const removeProductAssociationFailure = createAction("[Media] Remove Product Association Failure", props<{ error: any }>());
export const deleteMedia = createAction("[Media] Delete Media", props<{ mediaId: number }>());
export const deleteMediaSuccess = createAction("[Media] Delete Media Success", props<{ mediaId: number }>());
export const deleteMediaFailure = createAction("[Media] Delete Media Failure", props<{ error: any }>());