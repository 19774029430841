import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { SchemaService } from 'src/app/core/services/seo/schema.service';
import { Breadcrumb } from 'src/app/shared/models/breadcrumb.interface';
import { Link } from 'src/app/shared/models/link.interface';

@Component({
  selector: 'app-service-support-container',
  templateUrl: './service-support-container.component.html',
  styleUrls: ['./service-support-container.component.scss']
})
export class ServiceSupportContainerComponent{

  breadcrumbs: Breadcrumb[] = [];
  title: string = 'Help & Support for Your Scinomix Products'
  links: Link[] = [];
  constructor(private metaTagsService: MetaTagsService, private schemaService: SchemaService, private router: Router, private route: ActivatedRoute) {
    this.breadcrumbs = [
      { label: 'Home', url: '/' },
      { label: 'Services & Support', url: '/services-support' }
    ];
  }


}
