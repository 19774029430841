import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Benefit } from '../../models/benefit.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-benefit-admin',
  templateUrl: './benefit-admin.component.html',
  styleUrls: ['./benefit-admin.component.scss']
})
export class BenefitAdminComponent implements OnInit{

  @Input() benefits: Benefit[];
  @Output() updateBenefits = new EventEmitter<Benefit[]>();

  benefitsForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.benefitsForm = this.fb.group({
      benefits: this.fb.array(this.benefits.map(benefit => this.createBenefitsFormGroup(benefit)))
    });
    
  }

  private createBenefitsFormGroup(Benefit: Benefit): FormGroup {
    return this.fb.group({
      id: [Benefit.id],
      title: [Benefit.title],
      description: [Benefit.description],
      display_order: [Benefit.display_order]
    });
  }

  get benefitsArray(): FormArray {
    return this.benefitsForm.get('benefits') as FormArray;
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.benefitsArray.controls, event.previousIndex, event.currentIndex);
    this.benefitsArray.controls.forEach((ctrl, idx) => {
      ctrl.patchValue({ display_order: idx });
    });
    this.emitChanges();
  }

  
  removeBenefit(index: number): void {
    this.benefitsArray.removeAt(index);
  
    this.benefitsArray.controls.forEach((control, idx) => {
      control.patchValue({ display_order: idx });
    });
  
    this.emitChanges();
  
  }

  addBenefit(): void {
    // Calculate the display_order based on the current length of the specificationsArray
    const displayOrder = this.benefitsArray.length;
  
    // Create a new specification FormGroup with the calculated display_order
    const newBenefit = this.fb.group({
      id: [null], // Assuming 'id' is not mandatory when creating a new spec
      title: ['', Validators.required], // Add validators as needed
      description: ['', Validators.required],
      display_order: [displayOrder] // Set to the end of the list
    });
  
    // Add the new specification FormGroup to the FormArray
    this.benefitsArray.push(newBenefit);
    this.emitChanges();
  }


  emitChanges(): void {
    this.updateBenefits.emit(this.benefitsArray.value);
  }
  

  // Method to trigger when specifications are updated
  onSave(): void {
    this.updateBenefits.emit(this.benefitsArray.value);
  }

}
