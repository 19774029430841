import { Pipe, PipeTransform } from '@angular/core';
import { truncateDescription } from 'src/app/core/utils/string-utils';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit: number = 100, completeWords: boolean = true, ellipsis: string = '...'): string {
        if (value.length <= limit) {
            return value;
        }

        let trimmedValue = value.slice(0, limit);

        if (completeWords) {
            trimmedValue = trimmedValue.slice(0, trimmedValue.lastIndexOf(' '));
        }

        return `${trimmedValue}${ellipsis}`;
    }
}


@Pipe({ name: 'truncateDescription' })
export class TruncateDescriptionPipe implements PipeTransform {
    transform(description: string, limit: number = 100, completeWords: boolean = true, ellipsis: string = '...'): string {
        return truncateDescription(description, limit, completeWords, ellipsis);
    }
}
