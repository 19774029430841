import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { ProductClassEffects } from './product-class.effects';
import * as ProductClasses from './product-class.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(ProductClasses.productClassFeatureKey, ProductClasses.productClassReducer),
        EffectsModule.forFeature([ProductClassEffects]),
    ],
})
export class ProductClassStoreModule { }
