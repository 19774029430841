import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProductSlugService } from 'src/app/core/services/product-slug.service';
import { ProductSlug } from 'src/app/shared/models/product-slug.interface';
import { SupportGuide } from 'src/app/shared/models/support-guide.interface';
import { loadSupportGuides } from '../../store/support-guide.actions';
import { selectAllSupportGuides } from '../../store/support-guide.selectors';

@Component({
  selector: 'app-troubleshooting-container',
  templateUrl: './troubleshooting-container.component.html',
  styleUrls: ['./troubleshooting-container.component.scss']
})
export class TroubleshootingContainerComponent {
  supportGuides$: Observable<SupportGuide[]>;
  productSlugs$: Observable<ProductSlug[]>;
  constructor(private store: Store, private productSlugService: ProductSlugService) { }

  ngOnInit(): void {
    this.store.dispatch(loadSupportGuides());
    this.supportGuides$ = this.store.select(selectAllSupportGuides);
    this.productSlugs$ = this.productSlugService.getProducts();
  }
}
