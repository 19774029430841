import { createReducer, on } from '@ngrx/store';
import * as ProductRequestActions from '../store/product-request.actions';
import { initialProductRequestState, ProductRequestState } from './product-request.state';

export const productRequestFeatureKey = 'productRequests';

export const productRequestReducer = createReducer(
    initialProductRequestState,

    on(ProductRequestActions.addProductRequest, (state, { productRequest }) => ({
        ...state,
        productRequests: [...state.productRequests, productRequest]
    })),

    on(ProductRequestActions.addProductRequestSuccess, (state, { productRequest }) => ({
        ...state,
        productRequests: [...state.productRequests, productRequest]
    })),

    on(ProductRequestActions.addProductRequestFailure, (state, { error }) => ({
        ...state,
        error: error
    })),
    on(ProductRequestActions.setSuccessMessage, (state, { message }) => ({
        ...state,
        successMessage: message
    })),
    on(ProductRequestActions.resetProductRequestSlice, (state) => {
        return { ...initialProductRequestState };
    }),
);
