import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { MetaTagsEffects } from './meta-tags.effects';
import { metaTagsFeatureKey, metaTagsReducer } from './meta-tags.reducer';
import { SchemaEffects } from './schema.effects';
import { schemaFeatureKey, schemaReducer } from './schema.reducer';
@NgModule({
  imports: [
    StoreModule.forFeature(metaTagsFeatureKey, metaTagsReducer),
    StoreModule.forFeature(schemaFeatureKey, schemaReducer),
    EffectsModule.forFeature([MetaTagsEffects, SchemaEffects]),
  ],
})
export class SeoStoreModule { }