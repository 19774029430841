import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';

import * as multiStepForm from './multi-step-form.reducer';
import { MultiStepFormEffects } from './multi-step-form.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(multiStepForm.multiStepFormFeatureKey, multiStepForm.multiStepFormReducer),
    EffectsModule.forFeature([MultiStepFormEffects]),
  ],
})
export class MultiStepFormStoreModule { }