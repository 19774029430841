import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { TestimonialsEffects } from './testimonials.effects';
import * as testimonials from './testimonials.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(testimonials.testimonialsFeatureKey, testimonials.testimonialsReducer),
    EffectsModule.forFeature([TestimonialsEffects]),
  ],
})
export class TestimonialsStoreModule { }