import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Post } from "../../../../shared/models/post.interface"; // Adjust the path as needed
import { AppState } from "src/app/store/app.state";
import { loadPosts, deletePost } from "../../store/post.actions"; // Adjust the path as needed
import * as PostActions from "../../store/post.actions";
import {
  selectAllPosts,
  selectPaginatedPostsAdmin,
  selectPaginationInfo,
  selectPostTotal,
} from "../../store/post.selectors"; // Adjust the path as needed
import { PaginationInfo } from "@fluentllc/core";
import { Router } from "@angular/router";
import { MetaTagsService } from "src/app/core/services/seo/meta-tags.service";
import { AlertService } from "src/app/core/services/alert.service";
import { ModalService } from "src/app/core/services/modal.service";
import { OwlLogger } from "ngx-owl-carousel-o/lib/services/logger.service";

@Component({
  selector: "app-post-admin-list-container",
  templateUrl: "./post-admin-list-container.component.html",
  styleUrls: ["./post-admin-list-container.component.scss"],
})
export class PostAdminListContainerComponent implements OnInit {
  posts$: Observable<Post[]>;
  total$: Observable<number>;
  paginationInfo$: Observable<PaginationInfo>;
  currentPage: number = 1;
  pageSize: number = 10;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private alertService: AlertService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.posts$ = this.store.select(selectAllPosts);
    this.loadPaginatedPosts(this.currentPage);
    this.paginationInfo$ = this.store.select(selectPaginationInfo);
    const newMetaTags = {
      title: "Blog Posts",
      description: "",
      keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };
    this.metaTagsService.updateMetaTags(newMetaTags);
  }

  loadPaginatedPosts(page: number): void {
    this.store.dispatch(PostActions.loadPaginatedPosts({ page: page, limit: this.pageSize }));
    this.paginationInfo$ = this.store.select(selectPaginationInfo);
  }

  async openCustomModal(postId: number) {
    const buttons = [
      { label: 'Cancel', cssClass: 'btn btn-secondary', action: 'cancel' },
      { label: 'Delete Post', cssClass: 'btn btn-danger', action: 'confirm' }
    ];

    const result = await this.modalService.openModal(
      'Confirm Deletion', 
      'Are you sure you want to delete this post? This action cannot be undone. Please confirm if you wish to permanently remove this post from your account.', 
      buttons
    );

    // Handle the result based on action
    switch (result) {
      case 'cancel':
        // Handle cancel
        break;
      case 'confirm':
        this.store.dispatch(PostActions.deletePost({ postId }));
        break;
    }
  }

  onEdit(postSlug: string): void {
    // Navigation logic for editing a post
    this.router.navigate(["/admin/post/manage", postSlug]);
  }

  onDelete(postId: number): void {
    this.openCustomModal(postId)
  }

  onCreate(): void {
    this.router.navigate(["/admin/post/create"]);
  }

  onPageChange(newPage: number): void {
    // console.log(newPage);
    // this.currentPage = newPage;
    this.loadPaginatedPosts(newPage);
  }

  onChangePageSize(newSize: number): void {
    this.pageSize = newSize;
    this.loadPaginatedPosts(this.currentPage);
  }
}
