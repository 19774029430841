<div class="single-services-item">
    <div class="services-image">
        <a [routerLink]="link" class="d-block">
            <img [src]="image" [alt]="title">
        </a>
        <div class="icon">
            <a [routerLink]="link">
                <fa-icon [icon]="icon" [size]="iconSize"></fa-icon>
            </a>
        </div>
    </div>
    <div class="services-content">
        <h3>
            <a [routerLink]="link">
                {{title}}
            </a>
        </h3>
        <p>{{description}}</p>
        <a [routerLink]="link" class="sci-default-btn">{{ctaText}}</a>
    </div>
</div>