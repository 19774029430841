import { Component, ViewChild, TemplateRef, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-embedded-video-player',
  templateUrl: './embedded-video-player.component.html',
  styleUrls: ['./embedded-video-player.component.scss']
})
export class EmbeddedVideoPlayerComponent {
  @ViewChild('template') template: TemplateRef<any>;
  @Input() vimeoLink: string = 'https://player.vimeo.com/video/616122545?h=85a6cbc73d';
  @Input() queryStrings: string = 'badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479';
  @Input() title: string = "Scinomix"
  combinedLink: SafeResourceUrl;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: "modal-fullscreen-xl"
  };
  currentData: any;

  constructor(private modalService: BsModalService, private sanitizer: DomSanitizer, private gtmService: GtmService) {
    const safeCombinedLink = `${this.vimeoLink}?${this.queryStrings}`;
    this.combinedLink = this.sanitizer.bypassSecurityTrustResourceUrl(safeCombinedLink);
    // Now you can use the safeCombinedLink in your template or wherever you need it
    // console.log(safeCombinedLink);
  }

  openModal(data?: any) {
    const gtmPayload = {
      event: 'media_viewer_opened',
      media_type: 'video',
      external_video_url: data.external_video_url || null,
      media_title: data.title
    };

    if (data && data.external_video_url) {
      const safeCombinedLink = `${data.external_video_url}?${this.queryStrings}`;
      this.combinedLink = this.sanitizer.bypassSecurityTrustResourceUrl(safeCombinedLink);
    }
    this.gtmService.push(gtmPayload)
    this.currentData = data;
    this.modalRef = this.modalService.show(this.template, this.config);
  }
}
