import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Product } from '../../models/product.interface';

import * as ProductsActions from '../../store/products.actions';
import { selectAllProducts, selectLoading, selectError } from '../../store/products.selectors';

@Component({
  selector: 'app-product-list-container',
  templateUrl: './product-list-container.component.html',
  styleUrls: ['./product-list-container.component.scss']
})
export class ProductListContainerComponent implements OnInit {
  products$: Observable<Product[]> = this.store.select(selectAllProducts);
  loading$: Observable<boolean> = this.store.select(selectLoading);
  error$: Observable<string | null> = this.store.select(selectError);

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(ProductsActions.loadProducts());
  }
}
