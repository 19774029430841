import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../models/product.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { loadProductFormState, saveProductFormState } from '../../store/products.actions';
import { selectFormState } from '../../store/products.selectors';
import * as ProductActions from '../../store/products.actions';
import { SeoData } from 'src/app/shared/models/seo-data.interface';
import { ProductClass } from '../../models/product-class.interface';
import { Observable } from 'rxjs';
import { Media } from '../../models/media.interface';
import { selectMediaInBackendOrder, selectSpecificationsInBackendOrder } from '../../store/media.selectors';
import { Specification } from '../../models/specification.interface';
import { Feature } from '../../models/feature.interface';
import { Benefit } from '../../models/benefit.interface';
import { loadProductClass } from '../../store/product-class.actions';
import { ProductAttributeValue } from '../../models/product-attribute-value.interface';
import { OwlLogger } from 'ngx-owl-carousel-o/lib/services/logger.service';
@Component({
  selector: 'app-product-admin-manage',
  templateUrl: './product-admin-manage.component.html',
  styleUrls: ['./product-admin-manage.component.scss']
})
export class ProductAdminManageComponent implements OnInit, AfterViewInit {
  @Input() product: Product;
  @Input() classes: ProductClass[];
  @Output() save = new EventEmitter<{
    product: Product;
    imageFiles: File[];
  }>();
  @Output() cancel = new EventEmitter<void>();
  mediaItems$: Observable<Media[]>;
  specifications$: Observable<Media[]>;
  isEditMode: boolean = false;
  productForm: FormGroup;
  public editorDisabled = false;
  selectedImage: File = null;
  isSettingValue = false;
  productClassId: any;
  solutionTagIds: number[] = [];
  specifications: Specification[] = [];
  productAttributeValues: ProductAttributeValue[] = [];
  productFeatures: Feature[] = [];
  isFormValid: boolean = true;

  config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
        ["delete", ["deleteRow", "deleteCol", "deleteTable"]],
      ],
      image: [
        ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
        ["float", ["floatLeft", "floatRight", "floatNone"]],
        ["remove", ["removeMedia"]],
      ],
      link: [["link", ["linkDialogShow", "unlink"]]],
      air: [["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]]],
    },
    height: "600px",
    uploadImagePath: "/api/upload",
    toolbar: [
      ["misc", ["codeview", "undo", "redo", "codeBlock"]],
      ["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]],
      ["style", ["style"]],
      ["fontsize", ["fontname", "fontsize", "color"]],
      ["para", ["style0", "ul", "ol", "paragraph", "height"]],
      ["insert", ["table", "picture", "link", "video", "hr"]],
      ["customButtons", ["testBtn"]],
    ],
    codeviewFilter: false,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
    styleTags: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "pre", "blockquote"],
    fontNames: ["Poppins", "Heebo", "Nunito Sans", "sansans-serif"],
    fontNamesIgnoreCheck: ["Poppins", "Heebo", "Nunito Sans"],
    styleWithSpan: false,
    dialogsInBody: true
  };

  get f() {
    return this.productForm.controls;
  }

  onSeoDataChange(seoData: SeoData) {
    this.product = {
      ...this.product,
      ...seoData,
    };
  }

  onProductClassChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.productClassId = Number(selectElement.value);
    this.product = {
      ...this.product,
      product_class: Number(selectElement.value),
    };
  }

  onSolutionTagsChange(solutionTagIds: string[] | number[]) {
    if (typeof solutionTagIds[0] === 'number') {
      this.solutionTagIds = solutionTagIds as number[];
    }
  }

  handleImageSelected(file: File | File[]) {
    if (Array.isArray(file)) {
      // If file is an array, take only the first file
      this.selectedImage = file[0];
    } else {
      // If file is a single File object, use it as is
      this.selectedImage = file;
    }
  }

  ngAfterViewInit(): void {
    this.productClassId = this.product.product_class;
    
  }

  ngOnInit(): void {
    // Populate the form with actual data if post is available
    this.mediaItems$ = this.store.pipe(select(selectMediaInBackendOrder))
    this.specifications$ = this.store.pipe(select(selectSpecificationsInBackendOrder));
    if (this.product && this.product.slug) {
      // this.store.dispatch(ProductActions.loadProduct({ slug: this.product.slug }));
      this.store.dispatch(loadProductClass({ slug: this.product.product_class_details.slug }))
      this.productClassId = this.product.product_class;
      this.isEditMode = true;
      this.solutionTagIds = this.product.solution_tags_ids;
      this.specifications = this.product.specifications;
      this.productForm.patchValue({
        title: this.product.title,
        upc: this.product.upc,
        description: this.product.description,
        slug: this.product.slug,
        product_name: this.product.product_name,
      });
    }

    this.productForm.valueChanges.subscribe((product) => {
      if (!this.isSettingValue) {
        this.store.dispatch(saveProductFormState({ product }));
      }
    });

    this.store.dispatch(loadProductFormState());

    // Select the formState from the store and use it to set the form values
    this.store.pipe(select(selectFormState)).subscribe((formState) => {
      if (formState) {
        this.isSettingValue = true; // Add this line
        this.productForm.patchValue(formState);
        this.isSettingValue = false; // Add this line
      }
    });
    this.cdr.detectChanges();
  }

  constructor(private fb: FormBuilder, private store: Store, private cdr: ChangeDetectorRef) {
    this.productForm = this.fb.group({
      title: ["", Validators.required],
      upc: ["", Validators.required],
      product_name: ["", Validators.required],
      description: ["", Validators.required],
      slug: ["", Validators.required]
    });
  }

  onSave() {
    if (this.productForm.invalid) {
      this.markFormGroupTouched(this.productForm);
      return;
    }

    if (this.productForm.valid) {
      const formValue = { ...this.productForm.value };
      const solutionTagJson = {
        solution_tag_ids: this.solutionTagIds
      }
      this.product.specifications = this.specifications;
      // this.product.features = this.product.features;
      this.product.attributes = this.productAttributeValues
      const updatedProduct = {
        ...this.product,
        ...formValue,
        ...solutionTagJson,
      };
      this.save.emit({ product: updatedProduct, imageFiles: [this.selectedImage] });
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      control.markAsTouched();
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  handleSpecificationUpdate(specifications: Specification[]): void {
    // Update the product's specifications with the new values
    this.specifications = specifications;
  }

  handleProductAttributeValueUpdate(productAttributeValues: ProductAttributeValue[]): void {
    // Update the product's productAttributeValues with the new values
    this.productAttributeValues = productAttributeValues;
  }

  handleFeatureUpdate(features: Feature[]): void {
    // Update the product's features with the new values
    this.product.features = features;
  }

  handleBenefitUpdate(benefits: Benefit[]): void {
    // Update the product's features with the new values
    this.product.benefits = benefits;
  }
  
  // onEditProductAttributeValue(productAttributeValue: any) {
  //   console.log(productAttributeValue);
  // }

}
