import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from "@ngrx/store";
import { AppState } from "../../store/app.state";
import { RoleService } from '@fluentllc/core';
import { PermissionService } from '@fluentllc/core';

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionsService {

  constructor(
    private roleService: RoleService,
    private permissionService: PermissionService,
    private store: Store<AppState>
  ) {}

  
checkRolesAndPermissions(
    roles: string[] | undefined,
    permissions: string[] | undefined,
    rolesOp: 'AND' | 'OR' = 'AND',
    permissionsOp: 'AND' | 'OR' = 'AND',
    operator: 'AND' | 'OR' = 'AND'
  ): Observable<boolean> {
    // console.log('Checking roles and permissions', { roles, permissions, rolesOp, permissionsOp, operator });

    const roleChecks$ = roles ? roles.map(role => this.roleService.hasRole(role)) : [of(true)];
    const permissionChecks$ = permissions ? permissions.map(permission => this.permissionService.hasPermission(permission)) : [of(true)];

    return combineLatest([...roleChecks$, ...permissionChecks$]).pipe(
      take(1),
      map(results => {
        // console.log('Results from role and permission checks', results);
        const rolesLength = roles?.length || 0;
        const rolesResult = rolesOp === 'AND' ? results.slice(0, rolesLength).every(Boolean) : results.slice(0, rolesLength).some(Boolean);
        const permissionsResult = permissionsOp === 'AND' ? results.slice(rolesLength).every(Boolean) : results.slice(rolesLength).some(Boolean);
        // console.log('Roles result', rolesResult, 'Permissions result', permissionsResult);

        let finalResult: boolean;
        if (operator === 'AND') {
          finalResult = rolesResult && permissionsResult;
        } else {
          finalResult = rolesResult || permissionsResult;
        }

        // console.log('Final result', finalResult);
        return finalResult;
      })
    );
  }
}