import { Component, Input, OnInit } from '@angular/core';
import { faCheckSquare, faFilePdf, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faBoxOpen, faCheck } from '@fortawesome/free-solid-svg-icons';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { ProductSlug } from 'src/app/shared/models/product-slug.interface';
import { SupportGuide } from 'src/app/shared/models/support-guide.interface';

@Component({
  selector: 'app-troubleshooting',
  templateUrl: './troubleshooting.component.html',
  styleUrls: ['./troubleshooting.component.scss']
})
export class TroubleshootingComponent implements OnInit {
  constructor(private metaTagsService: MetaTagsService) {

  }
  ngOnInit(): void {
    const newMetaTags = {
      title: 'Scinomix Lab Automation Troubleshooting',
      description: "Need help with your Scinomix lab automation products? Dive into our troubleshooting guide for step-by-step solutions, expert advice, and seamless operation of your equipment.",
      keywords: '', // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
    };

    this.metaTagsService.updateMetaTags(newMetaTags);
  }
  @Input() supportGuides: SupportGuide[] = [];
  @Input() productSlugs: ProductSlug[] = [];
  checkIcon: IconDefinition = faCheck;
  pdfIcon: IconDefinition = faFilePdf;
}
