import { Component, Input } from '@angular/core';
import { Link } from 'src/app/shared/models/link.interface';

@Component({
  selector: 'app-job-posting-sidebar-navigation',
  templateUrl: './job-posting-sidebar-navigation.component.html',
  styleUrls: ['./job-posting-sidebar-navigation.component.scss']
})
export class JobPostingSidebarNavigationComponent {
  @Input() links: Link[] = [];
  @Input() menuTitle: string;
}
