import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-play-embedded-video-button-container',
  templateUrl: './play-embedded-video-button-container.component.html',
  styleUrls: ['./play-embedded-video-button-container.component.scss']
})
export class PlayEmbeddedVideoButtonContainerComponent {
  @Input() vimeoLink: string;
  @Input() queryStrings: string;
  @Input() title: string;
  @Input() buttonCta: string;
}
