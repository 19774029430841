import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error404Component } from './error404/error404.component';
import { SearchModule } from '../search/search.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    Error404Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    SearchModule
  ],
  exports: [
    Error404Component
  ]
})
export class PagesModule { }
