import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @Output() filterChange = new EventEmitter<{ index: string }>();
  @Input() searchIndexes;
  @Input() searchPerformed = false;
  indexCheckStates = {};

  ngOnInit(): void {
    // Initialize indexCheckStates object with search indexes
    for (let item of this.searchIndexes) {
      // console.log(item)
      this.indexCheckStates[item.index] = false;
    }
  }

  onIndexChange(event, index): void {
    this.indexCheckStates[index] = event.target.checked;
    let checkedIndexes = [];
    for (let key in this.indexCheckStates) {
      if (this.indexCheckStates[key]) {
        checkedIndexes.push(key);
      }
    }
    this.filterChange.emit({ index: checkedIndexes.join() });
  }
}
